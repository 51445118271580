<template lang="pug">
  section.section-item-archive(v-if="itemGroups.length > 0")
    HeadingArticle.mb-xs {{ itemGroupName }}を見ている人は、こんな商品も見ています
    ItemArchive
      .item(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" :archive="true" eventCategory="商品詳細 こんな商品も見ています")
</template>

<script>
  import { GetSameTagItemGroups } from '../../../graphqls/ItemGroups'
  import HeadingArticle from '../typography/HeadingArticle'
  import ItemCard from '../item/ItemCardForApi'
  import ItemArchive from '../item/ItemArchive'

  export default {
    name: 'SectionItemSameTag',
    components: {
      HeadingArticle,
      ItemCard,
      ItemArchive
    },
    props: {
      itemGroupId: {
        type: String,
        required: true,
        default: ''
      },
      itemGroupName: {
        type: String,
        required: true,
        default: ''
      },
    },
    data() {
      return {
        itemGroups: []
      }
    },
    mounted() {
      this.fetchSameTagItemGroups()
    },
    methods: {
      async fetchSameTagItemGroups() {
        const { data } = await this.$apollo
        .query({
          query: GetSameTagItemGroups,
          variables: { perPage: 6, itemGroupId: this.itemGroupId },
          client: 'apiClient'
        })
        this.itemGroups = data.sameTagItemGroups.items
      }
    }
  }
</script>
