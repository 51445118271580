<template lang="pug">
  .icon-base(:class="color" :style="{width: size + 'px', 'min-width': size + 'px', height: size + 'px'}")
    slot
</template>

<script>
  export default {
    name: 'IconBase',
    props: {
      color: {
        type: String,
        required: false,
        default: ''
      },
      size: {
        type: Number,
        required: false,
        default: 16
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .icon-base {
    display: inline-block;
    vertical-align: top;

    > svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    @each $name, $color in $colors {
      &.#{$name} {
        > svg {
          path:not(.not-change),
          rect:not(.not-change) {
            fill: $color;
          }
        }
      }
    }
  }
</style>
