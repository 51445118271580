<template lang="pug">
  a.review-card(
    :href="`/shop/${review.adminShop.code}/item/${review.itemGroup.code}/reviews/`" 
    @click="$_gaSendEvent('レビュー詳細へ遷移', 'トップレビュー', `${review.title} ${review.adminShop.name} ${review.itemGroup.name}`)"
  )
    .thumbnail
      img(:src="review.thumbnailReviewImageUrl" :alt="`${review.itemGroup.name}のレビュー`")
    .body
      p.title.my-xxxs {{ review.title }}
      .row.my-xxxs
        UserIconDefault.mr-xxs
        span.username
          | {{ review.displayAuthor }}さん
          template(v-if="review.user && review.user.prefecture") ({{ review.user.prefecture }})
      .row.my-xxxs
        img.star.mr-xxs(:src="review.starImgUrl" :alt="`評価星${review.stars}個`")
        span.posttime(v-if="review.postedAt") {{ review.postedAt | moment('YYYY/MM/DD') }} 投稿
      p.comment.my-xxxs {{ review.comment }}
      .row.link.my-xxxs
        span.link {{ itemGroupName.length > 20 ? itemGroupName.slice(0, 20) + '…' : itemGroupName }}
        include ../../../../../assets/images/icon/arrow-right.svg
</template>

<script>
  import UserIconDefault from '../../../components/svg/UserIconDefault'
  import ImagePopup from '../../v1/image/ImagePopup'
  import GlobalMixin from '../../../mixin/GlobalMixin'

  export default {
    name: 'ReviewCard',
    mixins: [GlobalMixin],
    components: {
      UserIconDefault,
      ImagePopup,
    },
    props: {
      review: {
        type: Object,
        required: true,
        default: () => {
        }
      }
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    computed: {
      itemGroupName() {
        return this.review && this.review.itemGroup ? this.review.itemGroup.name : ''
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .review-card {
    cursor: pointer;
    text-decoration: none;
    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    @include font-size(xs);
    line-height: $line-height-normal;
    color: $black-400;
    &:hover {
      color: $olive-400;
    }

    > .thumbnail {
      width: 100%;
      height: 136px;
      overflow: hidden;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .body {
      > .title {
        font-weight: $font-weight-bold;
        @include font-size(sm);
      }
      > .row {
        display: flex;
        align-items: center;
        > .star {
          height: 13px;
        }

        &.link {
          justify-content: flex-end;
           > svg {
            width: 10px;
            height: 10px;
            color: $black-400;
          }
        }
      }
      > .comment {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }
</style>
