
const mutations = {
  setLoading (state, payload) {
    state.loading = payload.loading
  },
  setHasSide (state, payload) {
    state.hasSide = payload.hasSide
  },
  setHasBanner (state, payload) {
    state.hasBanner = payload.hasBanner
  },
  setBreadcrumbs (state, payload) {
    state.breadcrumbs.splice(0, state.breadcrumbs.length)
    state.breadcrumbs.push(...payload.breadcrumbs)
  },
  setFlashMessage (state, payload) {
    state.flashMessages[payload.variant] = payload.messages || [];
  },
  setErrorMessages (state, payload) {
    state.errorMessages = payload || [];
  },
  setPageInfo (state, payload) {
    state.pageInfo = payload.pageInfo;
  },
  setCurrentUser (state, payload) {
    state.currentUser = payload.currentUser;
  },
  setToastMessage (state, payload) {
    state.toastMessage = payload;
  },
  setFavoriteItems (state, payload) {
    state.favoriteItems = payload
  },
  addFavoriteItem (state, payload) {
    state.favoriteItems.unshift(payload)
  },
  removeFavoriteItem (state, payload) {
    state.favoriteItems = state.favoriteItems.filter(favoriteItem => favoriteItem.id !== payload)
  },
  setCurrentShop (state, payload) {
    state.currentShop = payload.currentShop
  },
  setToast (state, payload) {
    state.toast = payload.toast
  },
  setCurrentItemGroup (state, payload) {
    state.currentItemGroup = payload.currentItemGroup
  },
  setCoupon (state, payload) {
    state.coupon.hasCoupon = payload.hasCoupon
    state.coupon.shopCode = payload.shopCode
    state.coupon.pageName = payload.pageName
  },
}

export default mutations
