<template lang="pug">
  .section-item-slider(v-if="imageList.length > 0")
    carousel(:center="true" :auto-width="true" :loop="true" :responsive="responsive")
      .image(v-for="image in imageList")
        img(:src="image.mdImage")
    .item-slider-dots.owl-dots
      button.owl-dot(role='button' v-for="(image, i) in imageList" :data="i")
        img(:src="image.xsSqImage")
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import Carousel from 'vue-owl-carousel'

  export default {
    name: 'SectionItemSlider',
    mixins: [GlobalMixin],
    components: {
      Carousel
    },
    props: ['itemGroup'],
    data() {
      return {
        init: false,
        responsive: {
          0: {
            margin: 1,
            dots: false,
            nav: false
          },
          768 : {
            margin: 5,
            dotsContainer: '.item-slider-dots',
            navText: ['', '']
          }
        },
        imageList: [],
      }
    },
    watch: {
      itemGroup: {
        async handler(newValue) {
          let list = []
          if (newValue && newValue.itemGroupImageSets.length > 0) {
            for (const itemGroupImageSet of newValue.itemGroupImageSets) {
              if (await this.checkImage(itemGroupImageSet.mdImage)) {
                list.push({...itemGroupImageSet})
              }
            }
          }
          this.imageList = list
        },
        immediate: true,
        deep: true
      }
    },
    methods : {
      async checkImage (url) {
        let img = new Image()
        return new Promise(resolve => {
          img.onload = () => {
            img = null;
            resolve(true);
          }
          img.onerror = () => {
            img = null;
            resolve(false);
          }
          img.src = url;
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";
  .section-item-slider {
    text-align: center;

    @include mq-down(md) {
      margin: 0 -15px;
    }

    .image {
      display: inline-block;
      height: 450px;

      @include mq-down(md) {
        height: 240px;
      }

      > img {
        width: auto;
        height: 100%;
      }
    }

    > .item-slider-dots {
      display: inline-flex;
      max-width: 100%;
      margin-top: 3px;
      overflow: auto;

      @include mq-down(md) {
        display: none;
      }

      > button {
        background: none;
        border: none;
        padding: 0;
        outline: none;

        &:nth-child(n+2) {
          margin-left: 4px;
        }

        > img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
</style>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";
  .section-item-slider {
    .owl-theme {
      .owl-nav {
        margin: 0;

        > [class*='owl-'] {
          position: absolute;
          top: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 50px;
          padding: 0;
          margin: 0;
          background: rgba(56, 56, 56, 0.4);
          transform: translateY(-50%);
          overflow: hidden;

          &:hover {
            background: rgba(56, 56, 56, 0.4);
          }

          &:before {
            content: "";
            display: block;
            width: 25px;
            height: 25px;
            border: solid 5px $white;
            transform: rotate(45deg);
          }
        }

        > .owl-prev {
          left: 5px;

          &:before {
            margin-right: -13px;
            border-top: none;
            border-right: none;
          }
        }

        > .owl-next {
          right: 5px;

          &:before {
            margin-left: -15px;
            border-bottom: none;
            border-left: none;
          }
        }
      }
    }
  }
</style>
