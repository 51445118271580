<template lang="pug">
  section.section-item-new
    HeadingRoof.mb-sm(title="新着・再販売商品" subtitle="New & Resale Items")
    ItemCardList
      .item(v-for="(itemGroup, index) in newItemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" eventCategory="トップ新着" pageContents="新着・再販売商品")
    .text-center
      ButtonBase(href="/shops/items/new/" eventAction="商品一覧へ遷移" eventCategory="トップ新着") 新着商品一覧
</template>

<script>
import {GetNewItemGroupsForApi} from "../../../graphqls/ItemGroups";
  import GlobalMixin from "../../../mixin/GlobalMixin";
  import HeadingRoof from '../heading/HeadingRoof'
  import ItemCard from '../item/ItemCardForApi'
  import ItemCardList from '../item/ItemCardList'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: "newItemGroupsSection",
    mixins: [GlobalMixin],
    components: {
      HeadingRoof,
      ItemCard,
      ItemCardList,
      ButtonBase,
    },
    data() {
      return {
        newItemGroups: []
      }
    },
    mounted () {
      for (let i = 0; i < 20; i++) {
        this.newItemGroups.push({id: i})
      }
      this.getNewItemGroups()
    },
    methods: {
      async getNewItemGroups() {
        this.loadingStart()
        const { data } = await this.$apollo
        .query({
          query: GetNewItemGroupsForApi,
          variables: {
            nowOnSale: true,
            stockFilter: 'instock',
            perPage: 12,
            orderBy: 'item_groups.released_at desc'
          },
          client: 'apiClient'
        })
        this.newItemGroups = data.newItemGroups.items
        this.loadingStop()
      }
    }
  }
</script>
