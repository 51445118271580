<template lang="pug">
  div
    .page-link(@click="openModal()")
      .image
        img(:src="page.itemGroupDescriptionImageSet.xsSqImage")
      .desc
        .title.mb-xxs {{page.title}}
        .description {{page.firstDescription}}
        .button-area
          ButtonBase(variant="outlined" size="small")
            include ../../../../../assets/images/icon/clone-regular.svg
            span 続きを読む
    ModalPage(ref="modalPage" :page="page")
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import ModalPage from '../modal/ModalPage'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'PageLink',
    mixins: [GlobalMixin],
    components: {
      ModalPage,
      ButtonBase
    },
    props: {
      page: {
        type: Object,
        default() {
          return {}
        }
      },
      itemGroup: {
        type: Object,
        default() {
          return {}
        }
      },
    },
    methods: {
      openModal(e) {
        this.$refs.modalPage.open()
        this.$_gaSendEvent('共通ページモーダル表示', '商品説明', `${this.itemGroup.adminShop.name} ${this.itemGroup.name} ${this.page.title}`)
      },
      closeModal(e) {
        this.$refs.modalPage.close()
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .page-link {
    display: flex;
    border: 1px solid $gray-300;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    @include padding(xxs);

    &:hover {
      border-color: $olive-300;
      > .desc {
        > .title,
        > .description {
          color: $olive-300;
        }
      }
    }

    > .image {
      width: 15%;
      @include mq-down() {
        width: 30%;
      }
    }

    > .desc {
      width: 85%;
      @include mq-down(md) {
        width: 70%;
      }
      @include padding-left(xxs);

      > .title {
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        font-size: 18px;
        @include mq-down(md) {
          font-size: 16px;
        }
        line-height: $line-height-snug;
        color: $black-400;
      }

      > .description {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xs);
        line-height: $line-height-relaxed;
        color: $black-400;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      > .button-area {
        text-align: right;
        @include margin-top(xxs);
        > .button-base {
          > svg {
            width: 14px;
            height: auto;
            vertical-align: text-top;
          }
          > span {
            margin-left: 3px;
          }
        }
      }
    }
  }
</style>
