<template lang="pug">
  .modal-base(:class="stateClass" @click.self="close()" @keydown.esc="close()")
    .window(:class="sizeClass")
      .header
        .title
          slot(name="header")
        .icon(@click="close()")
          IconClose
      .body
        slot(name="body")
      .footer
        slot(name="footer")
      .spacing
</template>

<script>
  import IconClose from '../icon/IconClose'

  export default {
    name: 'ModalBase',
    components: {
      IconClose
    },
    props: {
      size: {
        type: String,
        required: false,
        default: 'medium',
        validator: function (value) {
          return ['small', 'medium', 'large'].includes(value)
        }
      }
    },
    data() {
      return {
        isOpen: false
      }
    },
    computed: {
      sizeClass: function() {
        return [
          `-${this.size}`
        ]
      },
      stateClass: function() {
        return [
          {'-open': this.isOpen}
        ]
      }
    },
    methods: {
      open: function(e) {
        this.isOpen = true
        // キーイベントは、フォーカス可能な要素のみキャプチャできます。
        // タブインデックスを追加すると、フォーカス可能となり、キーイベントのターゲットにできます。
        this.$el.tabIndex = 0
        this.$nextTick(() => this.$el.focus())
        this.$emit('open', e)
      },
      close: function(e) {
        this.isOpen = false
        this.$emit('close', e)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .modal-base {
    display: none;
    width: 100%;
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    background: rgba(255, 255, 255, 0.85);

    &.-open {
      display: flex;
      justify-content: center;
    }

    > .window {
      @include margin(lg, xxxs);

      &.-small {
        width: 480px;
      }
      &.-medium {
        width: 600px;
      }
      &.-large {
        width: 768px;
      }

      > .header {
        position: relative;
        background: $gray-100;
        border-top: 1px solid $gray-400;
        border-left: 1px solid $gray-400;
        border-right: 1px solid $gray-400;
        border-radius: 4px 4px 0 0;
        @include padding(xs, md);
        display: flex;
        justify-content: space-between;

        > .title {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(md);
          line-height: $line-height-snug;
          color: $black-400;
          margin-right: 1em;
        }

        > .icon {
          position: absolute;
          top: 22px;
          right: 22px;
          cursor: pointer;
        }
      }

      > .body {
        background: $white;
        border: 1px solid $gray-400;
        @include padding(md);
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-relaxed;
        color: $black-400;
        text-align: justify;
      }

      > .footer {
        background: $gray-100;
        border-bottom: 1px solid $gray-400;
        border-left: 1px solid $gray-400;
        border-right: 1px solid $gray-400;
        border-radius: 0 0 4px 4px;
        @include padding(xs, md);
        display: flex;
        justify-content: flex-end;

        > .button-base:not(:first-child) {
          @include margin-left(xs);
        }
      }

      > .spacing {
        @include padding-bottom(lg);
      }
    }
  }
</style>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/variables";
  .modal-base {
    > .window {
      > .body {
        p {
          // 既存スタイル上書きのため仕方なく設定
          line-height: $line-height-relaxed !important;
        }
      }
    }
  }
</style>
