<template lang="pug">
  a.category-image(:href="href" @click="click")
    img(:src="src" :alt="name" width="100%" height="auto")
    .name {{ name }}
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  export default {
    name: 'CategoryImage',
    mixins: [GlobalMixin],
    props: {
      href: {
        type: String,
        required: true,
        default: '',
      },
      src: {
        type: String,
        required: true,
        default: '',
      },
      name: {
        type: String,
        required: true,
        default: '',
      },
      eventAction: {
        type: String,
        required: false,
        default: ''
      },
      eventCategory: {
        type: String,
        required: false,
        default: ''
      },
    },
    methods: {
      click() {
        this.$_gaSendEvent(this.eventAction, this.eventCategory, this.name)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .category-image {
    position: relative;
    display: block;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      > .name {
        color: $olive-300;
      }
    }

    > .name {
      background: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      @include padding(sm);

      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(sm);
      line-height: $line-height-snug;
      color: $black-400;
      white-space: nowrap;
    }
  }
</style>
