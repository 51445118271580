<template lang="pug">
  VueMarkdown.item-markdown(:source="description" :linkify="false")
</template>

<script>
  import VueMarkdown from 'vue-markdown'

  export default {
    name: 'ItemMarkdown',
    components: {
      VueMarkdown
    },
    props: {
      description: {
        type: String,
        required: true,
        default: ''
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";

  .item-markdown {
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    @include font-size(sm);
    line-height: $line-height-relaxed;
    color: $black-400;

    h2 {
      border-bottom: 3px solid $gray-200;
      padding-bottom: 5px;
      @include margin-top(xlg);
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(md);
      line-height: $line-height-snug;
      color: $black-400;
    }
    
    h3 {
      padding: 7px 7px 7px 10px;
      border-left: 4px solid $gray-200;
      @include margin-top(md);
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(sm);
      line-height: $line-height-snug;
      color: $black-400;
    }

    p, span, div {
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(sm);
      line-height: $line-height-relaxed;
      color: $black-400;
    }
    
    .red_text {      
      color: $red-300;
      font-weight: $font-weight-bold;
    }

    a {
      color: $olive-400;
    }

    .linkBtn {
      padding: 12px;
      display: inline-flex;
      border-radius: 3px;
      justify-content: center;
      align-items: center;
      font-family: $font-family-sans;
      @include font-size(xs);
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      line-height: $line-height-snug;
      color: $black-400;
      border: 1px solid $gray-300;
      cursor: pointer;
      text-decoration: none;

      @include mq-down(md) {
        width: 100%;
      }
    } 
    .linkBtn:hover {
        background: $gray-100;
      }        

    ul,
    ol {
      @include font-size(sm);
      line-height: 1.75;
      margin-left: 20px;
    }

    ul li {
      list-style: disc;
    }

    ol li {
      list-style: decimal;
    }

    dl {
      @include font-size(sm);

      dt {
        font-weight: $font-weight-bold;
        @include margin-top(xxxs);
      }
    }

    .block-quote,
    p,
    ul,
    ol {
      @include margin-top(lg);
    }

    .block-quote {
      position: relative;
      padding: 10px 15px 10px 50px;
      box-sizing: border-box;
      font-style: italic;
      background: #f1f0ec;
      color: $black-200;
      @include font-size(sm);
      &:before{
        display: inline-block;
        position: absolute;
        top: 10px;
        left: -3px;
        vertical-align: middle;
        content: "“";
        font-family: sans-serif;
        color: #cfcfcf;
        font-size: 90px;
        line-height: 1;
      }
      >p {
        padding: 0;
        margin: 10px 0;
        line-height: 1.7;
      }
      >cite {
        display: block;
        text-align: right;
        color: $black-100;
        font-size: 0.9em;
      }
    }

    .img-responsive {
      display: block;
      max-width: 100%;
      height: auto;
      @include margin-top(sm);
    }
    .img-circle {
      border-radius: 50%;
    }

    .video {
      position: relative;
      height: 0;
      padding: 30px 0 52.25%;
      overflow: hidden;
    }
    .video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    video {
      width: 100%;
      height: auto;
    }

    .row:after {
      clear: both;
      content: " ";
      display: table;
    }
    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
      float: left;
    }
    .col-xs-12 {
      width: 100%;
    }
    .col-xs-11 {
      width: 91.66666667%;
    }
    .col-xs-10 {
      width: 83.33333333%;
    }
    .col-xs-9 {
      width: 75%;
    }
    .col-xs-8 {
      width: 66.66666667%;
    }
    .col-xs-7 {
      width: 58.33333333%;
    }
    .col-xs-6 {
      width: 50%;
    }
    .col-xs-5 {
      width: 41.66666667%;
    }
    .col-xs-4 {
      width: 33.33333333%;
    }
    .col-xs-3 {
      width: 25%;
    }
    .col-xs-2 {
      width: 16.66666667%;
    }
    .col-xs-1 {
      width: 8.33333333%;
    }

    .row.new_margin_0 {
      margin-right:0rem;
      margin-left:0rem;
      [class*="col-"] {
        padding: 0 0rem;
      }
    }
    .row.new_margin_0_1 {
      margin-right:-.1rem;
      margin-left:-.1rem;
      [class*="col-"] {
        padding: 0 .1rem;
      }
    }
    .row.new_margin_0_2 {
      margin-right:-.2rem;
      margin-left:-.2rem;
      [class*="col-"] {
        padding: 0 .2rem;
      }
    }
    .row.new_margin_0_3 {
      margin-right:-.3rem;
      margin-left:-.3rem;
      [class*="col-"] {
        padding: 0 .3rem;
      }
    }
    .row.new_margin_0_4 {
      margin-right:-.4rem;
      margin-left:-.4rem;
      [class*="col-"] {
        padding: 0 .4rem;
      }
    }
    .row.new_margin_0_5 {
      margin-right:-.5rem;
      margin-left:-.5rem;
      [class*="col-"] {
        padding: 0 .5rem;
      }
    }
    .row.new_margin_0_6 {
      margin-right:-.6rem;
      margin-left:-.6rem;
      [class*="col-"] {
        padding: 0 .6rem;
      }
    }.row.new_margin_0_7 {
      margin-right:-.7rem;
      margin-left:-.7rem;
      [class*="col-"] {
        padding: 0 .6rem;
      }
    }
    .row.new_margin_0_8 {
      margin-right:-.8rem;
      margin-left:-.8rem;
      [class*="col-"] {
        padding: 0 .8rem;
      }
    }
    .row.new_margin_0_9 {
      margin-right:-.9rem;
      margin-left:-.9rem;
      [class*="col-"] {
        padding: 0 .9rem;
      }
    }
    .row.new_margin_1 {
      margin-right:-1rem;
      margin-left:-1rem;
      [class*="col-"] {
        padding: 0 1rem;
      }
    }
    .row.new_margin_1_5 {
      margin-right:-1.5rem;
      margin-left:-1.5rem;
      [class*="col-"] {
        padding: 0 1.5rem;
      }
    }
    .row.new_margin_2 {
      margin-right:-2rem;
      margin-left:-2rem;
      [class*="col-"] {
        padding: 0 2rem;
      }
    }
    .row.new_margin_2_5 {
      margin-right:-2.5rem;
      margin-left:-2.5rem;
      [class*="col-"] {
        padding: 0 2.5rem;
      }
    }
    .row.new_margin_3 {
      margin-right:-3rem;
      margin-left:-3rem;
      [class*="col-"] {
        padding: 0 3rem;
      }
    }

    .table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
    }
    .table-bordered {
        border: 1px solid #eceeef;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #eceeef
    }
    .table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
        padding: .75rem;
        line-height: 1.5;
        vertical-align: top;
        border-top: 1px solid #eceeef;
        @include font-size(xs);
    }

    .mt2 {
        margin-top: 2rem !important;
    }
    
    .youtube{
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      @include margin-top(md);
    }
    .youtube>iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
