<template lang="pug">
  .site-navbar-sp
    nav.sitebar
      h1.logo(v-if="isHome")
        a(href="/" @click="$_gaSendEvent('トップへ遷移', 'Gナビ SP', 'ロゴ')")
          include ../../../../../assets/images/logo/logo-sp.svg
      .logo(v-if="!isHome")
        template(v-if="isSimple")
          include ../../../../../assets/images/logo/logo-sp.svg
        a(v-else href="/" @click="$_gaSendEvent('トップへ遷移', 'Gナビ SP', 'ロゴ')")
          include ../../../../../assets/images/logo/logo-sp.svg
      ul.navigation(v-if="!isSimple" )
        li
          a(href="/shop/favorite/" @click="$_gaSendEvent('お気入りへ遷移', 'Gナビ SP', 'お気入り')")
            .icon
              include ../../../../../assets/images/icon/heart-regular.svg
            span.text お気入り
        li(v-if="!currentUserObj.user")
          a(href="/login/" @click="$_gaSendEvent('ログインへ遷移', 'Gナビ SP', 'ログイン')")
            .icon
              include ../../../../../assets/images/icon/user.svg
            span.text ログイン
        li(v-if="currentUserObj.user")
          a(href="/mypage/" @click="$_gaSendEvent('マイページへ遷移', 'Gナビ SP', 'マイページ')")
            .icon
              include ../../../../../assets/images/icon/user.svg
            span.text マイページ
        li(v-if="currentUserObj.user")
          a.news(href="/mypage/contacts/" @click="$_gaSendEvent('お知らせへ遷移', 'Gナビ SP', 'お知らせ')")
            .icon
              include ../../../../../assets/images/icon/bell.svg
            span.text お知らせ
            span.num(v-if="currentUserObj.unconfirmedUserNoticeNum > 0") {{ currentUserObj.unconfirmedUserNoticeNum }}
        li
          a(href="/shop/cart/" @click="$_gaSendEvent('カートへ遷移', 'Gナビ SP', 'カート')")
            .icon
              include ../../../../../assets/images/icon/shopping-cart-solid.svg
            span.text カート
        li
          a(@click="isOpanMenu = true")
            .icon
              include ../../../../../assets/images/icon/menu.svg
            span.text メニュー
    .overlay-menu(v-if="isOpanMenu")
      .header
        .title MENU
        FormSearch
        .icon-close(@click="isOpanMenu = false")
          include ../../../../../assets/images/icon/close.svg
      .body
        TheSideMenu(:isHeaderMenu="true")
        .close-button
          .icon-close(@click="isOpanMenu = false")
            include ../../../../../assets/images/icon/close.svg
          p.text 閉じる
</template>

<script>
  import GlobalMixin from "../../../mixin/GlobalMixin"
  import FormSearch from '../form/FormSearch'
  import TheSideMenu from '../sidemenu/TheSideMenu'

  export default {
    name: 'SiteNavBarSp',
    mixins: [GlobalMixin],
    components: {
      FormSearch,
      TheSideMenu
    },
    props: {
      currentUserObj: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {
        isOpanMenu: false
      }
    },
    computed: {
      isHome: function () {
        return document.getElementById('home') != null
      },
      pathname() {
        return location.pathname
      },
      isOrder() {
        const regexp = /^\/shop\/.+\/order.*/;
        return regexp.test(this.pathname)
      },
      isOrderComplete () {
        const regexp = /^\/shop\/.+\/order\/create.*/;
        return regexp.test(this.pathname) || this.pathname.includes('/order/dev_complete')
      },
      isSubscriptionOrder () {
        const regexp = /^\/shop\/.+\/subscriptionorder\/.*/;
        return regexp.test(this.pathname)
      },
      isSubscriptionOrderComplete () {
        const regexp = /^\/shop\/.+\/subscriptionorder\/create.*/;
        return regexp.test(this.pathname)
      },
      isCart() {
        const regexp = /^\/shop\/cart/;
        return regexp.test(this.pathname)
      },
      isSimple() {
        return this.isCart || (this.isOrder && !this.isOrderComplete)|| (this.isSubscriptionOrder && !this.isSubscriptionOrderComplete)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .site-navbar-sp {
    background-color: $white;
    
    > .sitebar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      @include padding(xxxs, xxs);

      > .logo {
        svg {
          display: block;
          width: 130px;
          height: 30px;
          path {
            fill: $black-400;
          }
        }
      }

      > .navigation {
        display: flex;
        align-items: center;

        li {
          width: 45px;
          
        .news {
            position: relative;
            .num {
              position: absolute;
              top: -5px;
              left: calc(100% - 18px);
              background: $red-300;
              box-sizing: border-box;
              border-radius: 10px;
              min-width: 18px;
              height: 18px;
              padding: 4px 2px 0px 2px;
              font-family: $font-family-sans;
              @include font-size(xxs);
              color: $white;
              letter-spacing: 0px;
              text-align: center;
              justify-content: center;
            }
          }
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;

            i {
              font-size: 1.5rem;
            }
            .icon {
              svg {
                width: 20px;
                height: 20px;
                color: $black-400;
              }
            }

            .text {
              font-family: $font-family-sans;
              font-weight: $font-weight-regular;
              font-style: $font-style-not-italic;
              @include font-size(xxxs);
              transform: scale(0.8);
              line-height: $line-height-none;
              color: $black-400;
              white-space: nowrap;
            }  
          }
        }
      }
    }

    > .overlay-menu {
      position: fixed;
      z-index: 1000;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white;
      @include padding(xs);

      > .header {
        height: 100px;
        @include padding-bottom(sm);

        .title {
          @include padding(sm, zero);
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          font-size: #{map-get($font-size-pc, sm)}px;
          line-height: $line-height-none;
          color: $black-400;
          text-align: center;
        }

        .icon-close {
          position: absolute;
          z-index: 1001;
          top: 15px;
          right: 25px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      > .body {
        height: calc(100% - 100px);
        overflow-y: auto;

        .icon-close {
          svg {
            width: 20px;
            height: 20px;
          }
        }

        .text {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xxxs);
          line-height: $line-height-none;
          color: $black-400;
        }

        .close-button {
          text-align: center;
          @include margin(sm, zero);
        }
      }
    }
  }
</style>
