<template lang="pug">
  .skeleton
    .skeleton-image
    .skeleton-line-wrap
      .skeleton-line.icon
      .skeleton-line.name
      .skeleton-line.size
      .skeleton-line.price
</template>

<script>
  export default {
    name: "ListItemSkeleton"
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/mixin/variables";
  @import "../../../../assets/stylesheets/_variables";
  @import "../../../../assets/stylesheets/mixin/_mediaqueries";

  .skeleton {
    position: relative;
    margin: 0 auto;
    width: 95%;
    @include mq-down() {
      width: 100%;
      display: flex;
    }
    &::before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      background: linear-gradient( 90deg, rgba($color_white, 0), rgba($color_white, 0.2) 50%, rgba($color_white, 0) 90% );
      position: absolute;
      top: 0;
      left: 0;
      animation: skeleton-animation 1.8s linear infinite;

    }

    .skeleton-image {
      width : 100% ;
      padding-top : 100% ;
      @include mq-down() {
        width: 30%;
        padding-top : 30% ;
      }
      background-color: $color_gray_light;
      border-radius: 5px;
    }
    .skeleton-line-wrap {
      @include mq-down() {
        width: 70%;
        padding-left: 15px;
      }
      .skeleton-line {
        &.icon {
          width : 30% ;
        }
        &.name {
          width : 100% ;
        }
        &.size {
          width : 40% ;
        }
        &.price {
          width : 60% ;
        }
        width : 100% ;
        height: 20px;
        margin: 10px 0;
        border-radius: 10px;
        background-color: $color_gray_light;
      }
    }
  }
  @keyframes skeleton-animation {
    //0% {
    //  transform: translateX(-100%)
    //}
    //100% {
    //  transform: translateX(100%)
    //}
  }

</style>
