<template lang="pug">
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import {GetToastCoupon} from '../../../graphqls/Coupons'
  import { HideCoupon } from '../../../graphqls/Coupons'
  import { mapGetters } from 'vuex'

  export default {
    name: 'CouponToast',
    mixins: [GlobalMixin],
    // watch: {
    //   hasCoupon(newValue) {
    //     if (newValue) this.fetchCoupon()
    //   }
    // },
    computed: {
      ...mapGetters([
        'couponShopCode',
        'couponPageName'
      ]),
    },
    mounted() {
      this.fetchCoupon()
    },
    methods: {
      async fetchCoupon() {
        const { data } = await this.$apollo
        .query({
          query: GetToastCoupon,
          variables: {
            shopCode: this.couponShopCode,
            pageName: this.couponPageName
          },
          client: 'apiClient'
        })
        if (!data.toastCoupon) return
        const coupon = data.toastCoupon
        this.$toast.show('this is a custom toast message', `${coupon.name}プレゼント`, {
          timeout: 0,
          position: 'bottomCenter',
          backgroundColor: '#f2e6d7',
          layout: 2,
          maxWidth: 550,
          titleLineHeight: '20',
          image: coupon.ogImageSet.xsImage,
          imageWidth: this.$_isPC() ? 150 : 100,
          buttons: [['<button>クーポンを詳しく見る</button>', function (instance, toast) {
              location.href = `/coupon/${coupon.code}`
          }, true]],
          progressBar: false,
          onClosed: function (instance, toast, closedBy) {
            this.hideCoupon()
          }.bind(this)
        })
      },
      async hideCoupon() {
        const { data } = await this.$apollo
        .mutate({
          mutation: HideCoupon,
          variables: {
            input: {}
          },
          client: 'apiClient'
        })
      }
    }
  }
</script>

<style lang="scss">
  .iziToast-wrapper {
    > .iziToast-capsule {
      > .iziToast {
        > .iziToast-body {
          > .iziToast-texts {
            > .iziToast-message {
              display: none;
            }
          }
          > .iziToast-buttons {
            > button {
              background: #80AB60;
              color: #FFFFFF;
              outline: none;
            }
          }
        }
        > .iziToast-cover {
          background-size: cover;
        }
      }
    }
  }
</style>
