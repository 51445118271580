<template lang="pug">
  .img-responsive(:class="{'is-full': isFull}")
    img(:src="src" :alt="alt")

</template>

<script>
  export default {
    name: "img-responsive",
    props: {
      src: {
        type: String,
        default: ''
      },
      alt: {
        type: String,
        default: ''
      },
      isFull: {
        type: Boolean,
        default: false
      }
    }
  }

</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .img-responsive {
    max-width: 100%;

    &.is-full {
      max-width: none;
      margin: 0 -15px;
    }

    > img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
</style>
