<template lang="pug">
  Select2.form-pulldown(v-model="localValue" :class="{'is-disabled': disabled, 'is-error': isError}" :options="options" :settings="settings")
</template>

<script>
import Select2 from 'v-select2-component';

export default {
  name: "FormPulldown",
  components: {
    Select2,
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value);
        this.$emit('change', value);
      },
    },
    isUnselected() {
      return this.localValue === ""
    },
    settings() {
      return {
        placeholder: this.placeholder,
        disabled: this.disabled,
        multiple: this.multiple,
        allowClear: this.allowClear,
        width: '100%'
      }
    }
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "選択してください"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: false
    }
  }
}

</script>

<style lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .form-pulldown {
    position: relative;
    display: block;
    width: 100%;

    &.is-error {
      > .select2-container {
        &.select2-container--open {
          > .selection {
            > .select2-selection {
              border-color: $red-base;
            }
          }
        }
        > .selection {
          > .select2-selection {
            background: $red-lighter;
            border-color: $red-base;
          }
        }
      }
    }

    &.is-disabled {
      > .select2-container {
        &:after {
          background-image: url(../../../../assets/images/icon/arrow-bottom-black-lighter.svg);
        }

        > .selection {
          > .select2-selection--single,
          > .select2-selection {
            background: $gray-light;
            border-color: $gray-light !important;
            cursor: not-allowed;

            > .select2-selection__rendered {
              color: $black-lighter;
            }
          }
        }
      }
    }

    > .select2-container--default,
    > .select2-container {
      height: 100%;

      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 4px);
        right: 15px;
        display: block;
        width: 10px;
        height: 8px;
        background-image: url(../../../../assets/images/icon/arrow-bottom-black-base.svg);
        background-size: contain;
        pointer-events: none;
      }

      &.select2-container--open {
        &:after {
          transform: rotate(180deg);
        }

        > .selection {
          > .select2-selection--single,
          > .select2-selection {
            border-color: $black-lighter !important;
          }
        }

        > .select2-dropdown {
          z-index: 10051;
        }

        &.select2-container--below {
          > .selection {
            > .select2-selection {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }

        &.select2-container--above {
          > .selection {
            > .select2-selection {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
          }
        }
      }

      > .selection {
        > .select2-selection--single,
        > .select2-selection {
          display: flex;
          align-items: center;
          min-height: 40px;
          background: $white;
          border-color: $gray-dark !important;
          border-radius: 3px;

          > .select2-selection__rendered {
            width: 100%;
            padding: 0 37px 0 12px;
            color: $black-dark;
            line-height: 150%;
            letter-spacing: 0.01em;
            @include font-size(sm);

            > .select2-selection__choice {
              margin-top: 6px;
            }

            > .select2-search--inline {
              > .select2-search__field {
                width: 100% !important;
                margin-top: 7px;
              }
            }

            > .select2-selection__placeholder {
              color: $black-lighter;
            }
          }

          > .select2-selection__arrow {
            display: none;
          }

          &.select2-selection--multiple {
            padding: 0 12px 6px;
          }
        }
      }
    }
  }
</style>
