import gql from 'graphql-tag'
import {Errors} from "./Common";

export const GetUserCorporateNameForApi = gql`
query {
  user {
    corporateName
  }
}`



export const LogoutUser = gql`
mutation LogoutUser($input: LogoutUserInput!) {
  logoutUser(input: $input) {
    user {
      id
    }
    errors {
      model
      attribute
      message
    }
  }
}`

export const GetUsers = gql`
  query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
    users (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
      items {
        id
        email
        name
        nameKana
        tel
        zipCode
        prefecture
        city
        address1
        address2
        status
        confirmedAt
      }
      pageInfo {
        currentPage
        totalCount
        perPage
        orderBy
      }
    }
  }`

export const GetUser = gql`
query($userId: ID!) {
  user(userId: $userId) {
    id
    status
    monitorStatus
    email
    name
    nameKana
    birthday
    tel
    zipCode
    prefecture
    city
    address1
    address2
    memo
    confirmedAt
    troubleCount
    holdingPoint
    scheduledGrantPoint,
    holdingPointExpiredAt,
  }
}`

export const GetCurrentUser = gql`
query {
  currentUser {
    user {
      id
      status
      monitorStatus
      email
      name
      nameKana
      nickname
      birthday
      tel
      zipCode
      prefecture
      prefectureId
      city
      address1
      address2
      jwtToken
      bankTransferMethod
    }
    isPc
    isMobile
    adminUser {
      id
      adminShop {
        id
        code
        name
        isSystemAdmin
        unshippedAcceptOrderNum
      }
    }
    cartItemsNum
    unconfirmedUserNoticeNum
  }
}`

export const SignupUser = gql`
mutation ($input: SignupUserInput!) {
  signupUser (input: $input) {
    user {
      id
      email
    }
    confirmationUrl
    message
    ${Errors}
  }
}`

export const LoginUser = gql`
mutation ($input: LoginUserInput!) {
  loginUser (input: $input) {
    user {
      id
      email
      jwtToken 
    }
    previousUrl
    message
    ${Errors}
  }
}`

export const RecoverUser = gql`
mutation ($input: RecoverUserInput!) {
  recoverUser (input: $input) {
    user {
      email
    }
    message
    ${Errors}
  }
}`

export const RecoverPassword = gql`
mutation ($input: RecoverPasswordInput!) {
  recoverPassword (input: $input) {
    user {
      email
    }
    message
    ${Errors}
  }
}`

export const GetTemporaryUser = gql`
query ($confirmationToken: String!) {
  temporaryUser (confirmationToken: $confirmationToken) {
    user {
      email
    }
    ${Errors}
  }
}`

export const ConfirmUser = gql`
mutation ($input: ConfirmUserInput!) {
  confirmUser (input: $input) {
    user {
      id
      jwtToken
    }
    message
    previousUrl
    ${Errors}
  }
}`
