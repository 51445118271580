import gql from 'graphql-tag'

export const HideCoupon = gql`
mutation ($input: HideCouponInput!) {
  hideCoupon(input: $input) {
    expiredAt
  }
}
`

export const GetToastCoupon = gql`
query($shopCode: String, $pageName: String) {
  toastCoupon(shopCode: $shopCode, pageName: $pageName) {
    id
    code
    name
    ogImage
    ogImageSet {
      xsImage
    }
  }
}
`

export const GetCouponForApi = gql`
query($code: String!) {
  coupon(code: $code) {
    id
    code
    name
    title
    subTitle
    description
    minUseAmount
    displayBenefit
    displayMinUseAmount
    displayPublishedStartAt
    displayPublishedEndAt
    displayExpirationStartAt
    displayExpirationEndAt
    userIssueNumber
    availableAdminShopName
    publishedStartAt
    publishedEndAt
    expirationStartAt
    expirationEndAt
    totalIssueNumber
    minOrderNum
    maxOrderNum
    benefitType
    isSoldout
    isPublished
    mainImageSet {
      lgImage
    }
    paymentAdminShop {
      name
    }
    displayMetaTitle
  }
}`

export const CreateCouponForApi = gql`
mutation ($input: CreateCouponInput!) {
  createCoupon(input: $input) {
    errors {
      statusCode
      model
      attribute
      message
      fullMessage
    }
  }
}`

export const GetCoupons = gql`
query {
  coupons {
    items {
      id
      code
      name
      displayThumbnail
      displayBenefit
      displayAvailableShopname
      displayExpirationDays
      displayExpirationDate
      displayMinUseAmount
      availableAdminShop {
        id
        code
        name
      }
    }
  }
}`
