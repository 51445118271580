import { render, staticRenderFns } from "./SectionItemCommon.vue?vue&type=template&id=24f1248e&scoped=true&lang=pug&"
import script from "./SectionItemCommon.vue?vue&type=script&lang=js&"
export * from "./SectionItemCommon.vue?vue&type=script&lang=js&"
import style0 from "./SectionItemCommon.vue?vue&type=style&index=0&id=24f1248e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f1248e",
  null
  
)

export default component.exports