<template lang="pug">
  .users-regist.mx-auto
    .intro.mb-sm
      h1.heading-1.text-center.mb-md 新規会員登録
      p.paragraph.text-center たべるとくらすとでお買い物するには会員登録が必要です。
      p.paragraph.text-center
        | 会員の方は
        a.anker-link(href="/login/") ログイン画面
        | よりログインしてください。
    ContainerSheet
      section
        h3.heading-3.text-center.my-sm 外部アカウントで新規会員登録
        p.paragraph.text-center.mb-xs
          a.anker-link.text-xs(@click="$refs.invalidMailModal.open()") ご利用いただけないメールアドレスについて
        InvalidMailModal(ref="invalidMailModal")
        ButtonAmazon.mx-auto.mb-xs(href="/auth/amazon")
        ButtonYahoo.mx-auto.mb-xs(text="Yahoo! JAPAN IDで会員登録" href="/shop/page/24032817012/")
        ButtonFacebook.mx-auto.mb-xs(text="Facebookで会員登録" href="/auth/facebook")
        ButtonGoogle.mx-auto.mb-xs(text="Googleで会員登録" href="/auth/google_oauth2")
      hr.my-sm
      section
        h3.heading-3.text-center.my-md メールアドレスで新規会員登録
        AlertBase(v-if="recaptchaErrorMessage" type="error")
          p {{ recaptchaErrorMessage }}
        FormItem
          template(slot="title")
            FormLabel(type="required") メールアドレス
          template(slot="input")
            FormTextfield(
              v-model="form.email"
              type="email"
              placeholder="info@taberutokurasuto.com"
              :error="'email' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.email"
            )
        FormItem
          template(slot="title")
            FormLabel(type="required") パスワード
          template(slot="input")
            FormTextfield(
              v-model="form.password"
              type="password"
              placeholder="8~20文字 半角英数字・記号"
              :error="'password' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.password"
            )
        FormItem
          template(slot="title")
            FormLabel(type="required") パスワード(確認)
          template(slot="input")
            FormTextfield(
              v-model="form.passwordConfirmation"
              type="password"
              placeholder="8~20文字 半角英数字・記号"
              :error="'password_confirmation' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.password_confirmation"
            )
        FormCheckbox.my-md(v-model="form.agreeTerm")
          a.anker-link(href="/terms/" target="_blank") たべるとくらすと利用規約
          | に同意する
        ButtonBase.mb-md(@click="signupUser()" variant="primary" :block="true" :disabled="!form.agreeTerm") 会員登録
</template>

<script>
  import { SignupUser } from "../../graphqls/Users"
  import GlobalMixin from "../../mixin/GlobalMixin"
  import ContainerSheet from '../../components/v1/container/ContainerSheet'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormTextfield from '../../components/v1/form/FormTextfield'
  import FormCheckbox from '../../components/v1/form/FormCheckbox'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import ButtonAmazon from '../../components/v1/button/ButtonAmazon'
  import ButtonYahoo from '../../components/v1/button/ButtonYahoo'
  import ButtonFacebook from '../../components/v1/button/ButtonFacebook'
  import ButtonGoogle from '../../components/v1/button/ButtonGoogle'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import InvalidMailModal from "../../components/v1/modal/InvalidMailModal"

  export default {
    name: 'Regist',
    mixins: [GlobalMixin],
    components: {
      ContainerSheet,
      FormItem,
      FormLabel,
      FormTextfield,
      FormCheckbox,
      ButtonBase,
      ButtonAmazon,
      ButtonYahoo,
      ButtonFacebook,
      ButtonGoogle,
      AlertBase,
      InvalidMailModal
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    data() {
      return {
        form: {
          email: '',
          password: '',
          passwordConfirmation: '',
          agreeTerm: false,
          recaptchaToken: ''
        }
      }
    },
    methods: {
      async signupUser() {
        this.loadingStart()
        this.$_clearValidationErrors()

        const token = await this.$_createRecaptchaToken('regist')
        this.form.recaptchaToken = token

        const { data } = await this.$apollo
            .mutate({
              mutation: SignupUser,
              variables: {
                input: this.form
              }
            })
            .catch(error => {
              return this.$_redirect_internal_server_error(error)
            })

        const errors = data.signupUser.errors
        if (errors) {
          this.$_parseError(errors)
          this.form.agreeTerm = false
          this.loadingStop()
        } else {
          this.setToast({ toast: { type: 'success', messages: [data.signupUser.message] }})
          setTimeout(function () {
            location.href = data.signupUser.confirmationUrl
          }.bind(this), 2000)
        }
      }
    },
    mounted: function () {
      // パンくずリストを設定
      this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/regist/',
          name: '新規会員登録',
        },
      ]})
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .users-regist {
    width: 450px;
    @include mq-down(md) {
      width: 100%;
    }

    .intro {
      @include mq-down(md) {
        padding: #{map-get($padding-margin-pc, 'xs')}px;
      }
    }

    hr {
      width: 100%;
      border-top: 1px solid $black-400;
    }
  }
</style>
