<template lang="pug">
  Modal(ref="alcoholModal" size="md")
    template(v-slot:header) 年齢確認
    template(v-slot:body)
      section.mb1
        h3 20歳未満のアルコール類の購入や飲酒は法律で禁止されています。
        p.description
          | 本商品はアルコール類がふくまれているため、
          br
          | 法令に基づき、年齢確認をさせていただいております。

        strong お客様は20歳以上ですか？
    template(v-slot:footer)
      ButtonBlock(align="between")
        ButtonBase(@click="$refs.alcoholModal.close()") いいえ、20歳未満です。
        ButtonBase(@click="agree" color="primary-base") はい、20歳以上です

</template>
<script>
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Modal from "./Modal";
  import ButtonBase from "../button/ButtonBase";
  import ButtonBlock from "../button/ButtonBlock";

  export default {
    name: "AlcoholModal",
    components: {ButtonBlock, ButtonBase, Modal},
    mixins: [GlobalMixin],
    props: {
      cartItem: {
        type: Object
      }
    },
    methods: {
      open(e) {
        this.$refs.alcoholModal.open()
        this.$emit('open', e)
      },
      close(e) {
        this.$refs.alcoholModal.close()
        this.$emit('close', e)
      },
      agree(e) {
        this.$refs.alcoholModal.close()
        this.$emit('agree', e)
      }
    }
  }
</script>

<style scoped lang="scss">
  section {
    text-align: center;
  }
</style>
