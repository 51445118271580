import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

export const state = {
  loading: false,
  hasSide: false,
  hasBanner: true,
  breadcrumbs: [],
  flashMessages: {
    success: [],
    warning: [],
    danger: [],
    error: []
  },
  errorMessages: [],
  pageInfo: {
    currentPage: 1,
    perPage: 30,
    totalCount: 0,
    orderBy: ''
  },
  currentUser: {
    user: {
      id: '',
      email: '',
      status: '',
      nickname: '',
      jwtToken: ''
    },
    adminUser: null,
    isMobile: null,
    isPc: null
  },
  toastMessage: {
    valiant: '',
    message: []
  },
  favoriteItems: [],
  currentShop: {
    code: ''
  },
  toast: null,
  currentItemGroup: {},
  coupon: {
    hasCoupon: false,
    shopCode: '',
    pageName: '',
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
