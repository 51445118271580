const setConfig = {
  // 公開用
  production: {
    graphql_url: 'https://taberutokurasuto.com/graphql',
    graphql_api_url: 'https://taberutokurasuto.com/frontapi/graphql/',
    google_analytics_id: 'G-FNY1RSTPV0',
    pay_jp_public_key: 'pk_live_e924e7adfc5740fd7f0d3f00',
    contact_email_address: 'contact@taberutokurasuto.com',
    recaptcha_v3_sitekey: '6Lf2J9EZAAAAAIQbrkxghiZdGwWkQuVCBr_TJKV9',
    admin_site_host: 'https://taberutokurasuto.com',
    site_host: 'https://taberutokurasuto.com',
    sentry_dsn: 'https://bb6a44182e2c4853b50da8258a1aa6f7@o848509.ingest.sentry.io/5815709',
    cookie_user_csrf_token: 'user_csrf_token',
    cookie_admin_csrf_token: 'admin_csrf_token'

  },
  // ステージング用
  staging: {
    graphql_url: 'https://staging.taberutokurasuto.com/graphql',
    graphql_api_url: 'https://staging.taberutokurasuto.com/frontapi/graphql/',
    google_analytics_id: '',
    pay_jp_public_key: 'pk_test_87202581de26bd3caf8eaca7',
    contact_email_address: 'admin@taberutokurasuto.com',
    recaptcha_v3_sitekey: '6Lf2J9EZAAAAAIQbrkxghiZdGwWkQuVCBr_TJKV9',
    admin_site_host: 'https://staging.taberutokurasuto.com',
    site_host: 'https://staging.taberutokurasuto.com',
    sentry_dsn: 'https://6bb97685041c4f0f932a9ef79e2a517a@o848509.ingest.sentry.io/5815690',
    cookie_user_csrf_token: 'stg_user_csrf_token',
    cookie_admin_csrf_token: 'stg_admin_csrf_token'
  },
  // 開発用
  development: {
    graphql_url: 'http://localhost:3010/graphql',
    graphql_api_url: 'http://localhost:3012/frontapi/graphql/',
    google_analytics_id: '',
    pay_jp_public_key: 'pk_test_87202581de26bd3caf8eaca7',
    contact_email_address: 'admin@taberutokurasuto.com',
    recaptcha_v3_sitekey: '6Lf2J9EZAAAAAIQbrkxghiZdGwWkQuVCBr_TJKV9',
    admin_site_host: 'http://localhost:3011',
    site_host: 'http://localhost:3010',
    sentry_dsn: 'https://568077bfbdb44b329714d7cf76d88218@o848509.ingest.sentry.io/5815661',
    cookie_user_csrf_token: 'dev_user_csrf_token',
    cookie_admin_csrf_token: 'dev_admin_csrf_token'
  }
}

export const config = setConfig[process.env.RAILS_ENV];
