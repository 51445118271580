<template lang="pug">
  .button-count
    button(@click="localValue--" :disabled="min !== null && localValue <= min")
      MinusIcon
    input(v-model="localValue" type="number" :min="min")
    button(@click="localValue++" :disabled="max !== null && localValue >= max")
      PlusIcon
</template>

<script>
  import MinusIcon from '../icon/MinusIcon'
  import PlusIcon from '../icon/PlusIcon'

  export default {
    name: "button-count",
    components: {
      MinusIcon,
      PlusIcon
    },
    props: {
      value: {
        type: Number,
        default: 0
      },
      min: {
        type: Number,
        default: null
      },
      max: {
        type: Number,
        default: null
      },
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .button-count {
    display: flex;
    height: 32px;

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 100%;
      background: $white;
      border: 1px solid $black-dark;
      box-sizing: border-box;
      outline: none;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }

      > .icon-base {
        width: 12px;
        height: 13px;
      }
    }

    > input {
      width: 95px;
      height: 100%;
      padding: 0;
      outline: none;
      border-top: 1px solid $black-dark;
      border-bottom: 1px solid $black-dark;
      border-left: none;
      border-right: none;
      box-sizing: border-box;
      -moz-appearance: textfield;
      color: $black-dark;
      line-height: 100%;
      text-align: center;
      @include font-size(sm);

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button, {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
</style>
