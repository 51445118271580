<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="#383838" xmlns="http://www.w3.org/2000/svg">
    <rect width="12" height="3" y="5"/>
  </svg>
</template>
<script>
  export default {
    name: "minus-svg"
  }
</script>
