import gql from 'graphql-tag'
import {Errors} from "./Common"

export const CreateOpenContact = gql`
mutation CreateOpenContact($input: CreateOpenContactInput!) {
  createOpenContact(input: $input) {
    openContact {
      code
    }
    ${Errors}
  }
}`
