<template lang="pug">
  .chat-remark
    .icon(v-if="iconUrl" :style="{backgroundImage: `url('${iconUrl}')`}")
    .icon(v-else)
      UserIconDefault
    .box
      .name {{nickname || "匿名"}}
      .balloon
        .wrapper
          template(v-if="itemGroupQaComment.stashComment")
            FormTextarea(
              v-model="itemGroupQaComment.comment"
              placeholder="コメントする..."
              :error="'comment' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.comment"
            )
          .text(v-else) {{itemGroupQaComment.comment}}
            span.edited(v-if="itemGroupQaComment.isEdited") （編集済み）
          .menu
            template(v-if="itemGroupQaComment.stashComment")
              .cancel(@click="cancelEditComment")
                | キャンセル
              .save(@click="updateComment")
                | 変更を保存
              //.delete(@click="deleteComment")
                | 削除
            template(v-else)
              .date {{itemGroupQaComment.elapsedTime}}
              .like(@click="toggleCommentLike")
                LikeFillSvg(v-if="itemGroupQaComment.isLiked")
                LikeSvg(v-else)
                span(:class="{liked: itemGroupQaComment.isLiked}") いいね！
                | {{itemGroupQaComment.likeCount}}件
              .edit(v-if="itemGroupQaComment.isMyComment" @click="editComment")
                EditSvg
                | 編集
</template>

<script>
  import {
    UpdateItemGroupQaComment,
    CreateItemGroupQaCommentLike,
    DeleteItemGroupQaCommentLike
  } from "../../../graphqls/ItemGroupQas";
  import GlobalMixin from "../../../mixin/GlobalMixin";

  import UserIconDefault from "../../svg/UserIconDefault";
  import LikeSvg from "../../svg/LikeSvg";
  import LikeFillSvg from "../../svg/LikeFillSvg";
  import EditSvg from "../../svg/EditSvg";
  import FormTextarea from '../form/FormTextarea'

  export default {
    name: "ChatRemark",
    components: {
      FormTextarea,
      EditSvg,
      UserIconDefault,
      LikeSvg,
      LikeFillSvg
    },
    mixins: [GlobalMixin],
    props: {
      itemGroupQaComment: {
        type: Object,
        default() {
          return {
            id: null,
            comment: '',
            stashComment: '',
            likeCount: 0,
            isLiked: false,
            elapsedTime: '',
            userId: null,
            adminShopId: null,
            isMyComment: false,
            isShopComment: false,
            isEdited: false,
            user: {
              id: '',
              nickname: '',
              iconUrl: '',
            },
            adminUser: null
          }
        }
      }
    },
    computed: {
      user() {
        return this.itemGroupQaComment.user
      },
      adminUser() {
        return this.itemGroupQaComment.adminUser
      },
      iconUrl() {
        if (this.user) {
          return this.user.iconUrl
        }
        if (this.adminUser) {
          return this.adminUser.adminShop.logoImageSet.smSqImage
        }
        return null
      },
      nickname() {
        if (this.user) {
          return this.user.nickname
        }
        if (this.adminUser) {
          return this.adminUser.adminShop.name
        }
        return null
      }
    },

    methods: {
      // コメント編集
      editComment() {
        this.validateErrors = []
        this.itemGroupQaComment.stashComment = this.itemGroupQaComment.comment
      },

      // コメント編集キャンセル
      cancelEditComment() {
        this.itemGroupQaComment.comment = this.itemGroupQaComment.stashComment
        this.itemGroupQaComment.stashComment = ''
      },

      // コメント更新
      async updateComment() {
        this.loadingStart();
        this.$_clearValidationErrors()
        const { data } = await this.$apollo
        .mutate({
          mutation: UpdateItemGroupQaComment,
          // Parameters
          variables: {
            input: {
              id: this.itemGroupQaComment.id,
              comment: this.itemGroupQaComment.comment
            }
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        // Result
        const errors = data.updateItemGroupQaComment.errors;
        if (errors) {
          this.$_parseError(errors)
        } else {
          this.$emit('updateComment', data.updateItemGroupQaComment.itemGroupQas);
        }
        this.loadingStop();
      },

      // いいね切り替え
      toggleCommentLike() {
        this.itemGroupQaComment.isLiked ? this.deleteItemGroupQaCommentLike() : this.addItemGroupQaCommentLike()
      },

      // いいね登録
      async addItemGroupQaCommentLike() {
        this.loadingStart();
        this.$_clearValidationErrors()
        const { data } = await this.$apollo
        .mutate({
          mutation: CreateItemGroupQaCommentLike,
          // Parameters
          variables: {
            input: {
              itemGroupQaCommentId: this.itemGroupQaComment.id,
            }
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        // Result
        const errors = data.createItemGroupQaCommentLike.errors;
        if (errors) {
          this.$_parseError(errors)
        }
        this.$emit('clickLike', data.createItemGroupQaCommentLike.itemGroupQas);
        this.loadingStop();
      },

      // いいね解除
      async deleteItemGroupQaCommentLike() {
        this.loadingStart();
        this.$_clearValidationErrors()
        const { data } = await this.$apollo
        .mutate({
          mutation: DeleteItemGroupQaCommentLike,
          // Parameters
          variables: {
            input: {
              itemGroupQaCommentId: this.itemGroupQaComment.id,
            }
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        // Result
        const errors = data.deleteItemGroupQaCommentLike.errors;
        if (errors) {
          this.$_parseError(errors)
        }
        this.$emit('clickLike', data.deleteItemGroupQaCommentLike.itemGroupQas);
        this.loadingStop();
      },

      // // コメント削除
      // deleteComment() {
      //   this.$emit('deleteComment', this.itemGroupQaComment);
      // },

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";

  .chat-remark {
    display: flex;
    background: $white;

    > .icon {
      width: 36px;
      min-width: 36px;
      height: 36px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
      @include margin-right(xxs);

      > svg {
        width: 100%;
        height: 100%;
      }
    }

    > .box {
      flex-grow: 1;

      > .name {
        color: $black-400;
        font-weight: bold;
        line-height: 167%;
        @include font-size(xxs);
        @include margin-left(xxs);
      }

      > .balloon {
        position: relative;
        margin-top: 3px;

        &:before,
        &:after {
          content: "";
          position: absolute;
          top:9px;
          left: -8px;
          border: 16px solid transparent;
        }

        &:before {
          border-top-width: 14px;
          border-top-color: $gray-200;
          z-index: 0;
        }

        &:after {
          border-top-width: 3px;
          border-top-color: $white;
          z-index: 1;
        }

        > .wrapper {
          position: relative;
          background: $gray-200;
          border-radius: 18px;
          z-index: 2;
          @include padding(xxs);

          > .text {
            color: $black-400;
            line-height: 150%;
            word-break: break-all;
            white-space: pre-wrap;
            @include font-size(xs);

            .edited {
              color: $black-100;
              @include font-size(xxs);
            }
          }

          > .menu {
            display: flex;
            color: $black-100;
            line-height: 200%;
            @include font-size(xxxs);

            > .like, .edit, .save, .cancel, .delete {
              display: flex;
              align-items: center;
              cursor: pointer;
              @include margin-left(xxxs);

              > svg {
                width: 11px;
                height: 11px;
                margin-top: .5px;
                margin-right: 2px;
              }

              > span {
                &.liked {
                  color: $olive-400;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
