<template lang="pug">
  nav.sidemenu-nav
    .title(@click="isOpen = !isOpen")
      | {{ title }}
      IconArrowDown(v-if="!isOpen" :size="12")
      IconArrowUp(v-if="isOpen" :size="12")
    .content(v-if="isOpen")
      slot
</template>

<script>
  import IconArrowDown from '../icon/IconArrowDown'
  import IconArrowUp from '../icon/IconArrowUp'
  import IconArrowRight from '../icon/IconArrowRight'

  export default {
    name: 'SideMenuNav',
    components: {
      IconArrowDown,
      IconArrowUp,
      IconArrowRight
    },
    props: {
      title: {
        type: String,
        require: true,
        default: ''
      }
    },
    data() {
      return {
        isOpen: true
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";

.sidemenu-nav {
  width: 100%;
  background: $ivory-100;
  padding: #{map-get($padding-margin-pc, xxxs)}px;

  .title {
    background: $white;
    padding: #{map-get($padding-margin-pc, xxs)}px #{map-get($padding-margin-pc, xxxs)}px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: $font-family-sans;
    font-weight: $font-weight-bold;
    font-style: $font-style-not-italic;
    font-size: #{map-get($font-size-pc, xs)}px;
    line-height: $line-height-none;
    color: $black-400;
  }

  .content {
    margin: #{map-get($padding-margin-pc, xxxs)}px #{map-get($padding-margin-pc, zero)}px;
  }

  p {
    margin: #{map-get($padding-margin-pc, xxxs)}px;
    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    font-size: #{map-get($font-size-pc, xs)}px;
    line-height: $line-height-normal;
    color: $black-400;
    text-align: justify;
  
    &.subtitle {
      font-size: #{map-get($font-size-pc, xxs)}px;
    }

    a {
      display: inline-flex;
      align-items: center;
      text-decoration: underline;
      color: $black-400;
      cursor: pointer;
      &:hover {
        color: $olive-300;
      }
    }
  }

  ul {
    li {
      border-bottom: 1px solid $white;
      &:first-child {
        border-top: 1px solid $white;
      }
      a {
        display: block;
        padding: #{map-get($padding-margin-pc, xxs)}px #{map-get($padding-margin-pc, zero)}px #{map-get($padding-margin-pc, xxs)}px #{map-get($padding-margin-pc, xxxs)}px;
        cursor: pointer;
        text-decoration: none;
        font-family: $font-family-sans;
        font-weight: $font-weight-bold;
        font-style: $font-style-not-italic;
        font-size: #{map-get($font-size-pc, xs)}px;
        line-height: $line-height-none;
        color: $black-400;
        &:hover {
          color: $olive-300;
        }
        .icon-base {
          margin-right: #{map-get($padding-margin-pc, xxxs)}px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/variables";
  .sidemenu-nav {
    p {
      a {
        &:hover {
          .icon-base {
            svg {
              path {
                fill: $olive-300;
              }
            }
          }
        }
      }
    }
  }
</style>
