import gql from 'graphql-tag'

export const GetPosts = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  posts (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      adminShop {
        name
        code
        logoImageSet {
          xsImage
        }
      }
      displayListImage
      ogImageSet {
        smImage
        mdImage
      }
      title
      publishedStatus
      publishedStartAt
      publishedEndAt
      postedAt
      customUrl
      firsDescription
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}`

export const GetPost = gql`
query($postId: ID!) {
  post(postId: $postId) {
    id
    ogImage
    ogImageSet {
      smImage
      mdImage
    }
    title
    publishedStatus
    publishedStartAt
    publishedEndAt
    postedAt
    customUrl
    metaTitle
    metaDescription
    postBodies {
      id
      contentType
      description
      image
      alt
      title
      itemGroupId
      itemGroup {
        id
        name
        properPrice
        listImageUrl
        listImageSet {
          xsImage
        }
      }

    }
  }
}`

