<template lang="pug">
  div.shops-index
    .list-visual(style="background: url(https://cdn.taberutokurasuto.com/image/home/mainslide/20170607_tabekura.jpg) no-repeat center; background-size: cover;")
      .title_area
        i.yane-icon
          LogoYaneSvg
        h1.title 生産者一覧
        p.sub-title producer
    Row.shop-list
      Col(v-for="adminShop in adminShopItems" :key="adminShop.id" :sizes="['xs-6', 'md-4']")
        a(:href="`/shop/${adminShop.code}/`" @click="$_gaSendEvent('ショップトップへ遷移', '生産者一覧', adminShop.name)" :key="adminShop.id")
          ImgResponsive.image(:src="adminShop.ogImageSet.ogpImage"  :alt="`${adminShop.name}ロゴ`")
          .shop_info
            | {{adminShop.prefectureName}} ({{adminShop.businessContents}})
          .shop_name {{adminShop.name}}
      Col(:sizes="['xs-12']")
        Pager(@change="changePage" :custom-page-info="customPageInfo")

</template>

<script>
import {GetAdminShops, GetNewlyAdminShops} from "../../graphqls/AdminShops";
  import Pager from "../../components/paginate/Pager";
  import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Row from "../../components/layout/Row";
  import Col from "../../components/layout/Col";
  import ImgResponsive from "../../components/image/ImgResponsive";
  import YaneHeading from "../../components/heading/YaneHeading";


  export default {
    name: "ShopsIndex",
    mixins: [GlobalMixin],
    components: {
      YaneHeading,
      ImgResponsive,
      Col,
      Row,
      LogoYaneSvg,
      Pager
    },
    data: function () {
      return {
        adminShops: {},
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 60,
          orderBy: 'newly_at desc'
        },
      }
    },
    computed: {
      adminShopItems() {
        return this.adminShops.items
      },
      hasAdminSHopItem() {
        return this.adminShopItems.length > 0
      },
      customPageInfo() {
        return this.pageInfo
      },
      searchQuery() {
        return this.$route.query
      },
    },
    mounted () {
      this.fetchAdminShops()
    },
    methods: {
      getPageInfo() {

      },
      changePage(newPage) {
        this.pageInfo.currentPage = newPage
        newPage === 1 ? this.$router.push({name: 'shops-index', query: this.searchQuery}) : this.$router.push({name: 'shops-page-index', params: {page: newPage}, query: this.searchQuery})
        this.fetchAdminShops();
      },
      async fetchAdminShops() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetAdminShops,
          variables: {
            ...this.pageInfo,
            search: JSON.stringify({...this.searchQuery}),
          },
          client: 'apiClient'
        })
        .catch(error => {
          // this.setErrorMessages([error]);
          // this.$router.push({ name: "errors-index" });
          this.loadingStop()
          return;
        });

        this.adminShops = data.adminShops;
        this.pageInfo = data.adminShops.pageInfo
        // this.setPageInfo({ pageInfo: data.adminShops.pageInfo });
        // this.setQuery();
        // this.$scrollTo("body");
        this.loadingStop()
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";

  .shops-index {
    > .list-visual {
      position: relative;
      z-index: 0;
      overflow: hidden;
      text-align: center;
      @include margin-bottom(sm);
      > .title_area {
        display: inline-block;
        background: rgba(255,255,255,0.9);
        padding: 1rem;
        @include margin-top(md);
        @include margin-bottom(md);
        color: $black-dark;
        > .yane-icon {
          height: 1rem;
          display: inline-block;
          vertical-align: text-bottom;
          > svg {
            margin: 0;
            line-height: 1;
            height: 1rem;
            width: auto;
          }
        }
        > .title {
         margin: 0
        }
        > .sub-title {
          margin: 0;
          @include font-size(xxxs);
        }
      }
    }
    > .shop-list {
      .image {
        @include margin-bottom(xxxs)
      }
      .shop_info {
        @include font-size(xxxs);
      }
      .shop_name {
        @include margin-bottom(xs)
      }
    }
  }

</style>
