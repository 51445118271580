<template lang="pug">
  .container
    h1.heading-1.text-center.mb-sm レシピ
    p(v-if="!hasRecipes") 現在、レシピがありません。
    BlogList(v-else)
      BlogCard(v-for="recipe in recipesItems" :key="recipe.id" :post="recipe" :shopInfo="false")
    .col-xs-12
      Pager(@change="changePage" :custom-page-info="pageInfo")
</template>

<script>
  import { GetRecipesForIndex } from "../../graphqls/Recipes";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import BlogList from "../../components/v1/post/BlogList";
  import BlogCard from "../../components/v1/post/BlogCard";
  import Pager from "../../components/paginate/Pager";

  export default {
    name: "recipes-index",
    mixins: [GlobalMixin],
    components: {
      BlogList,
      BlogCard,
      Pager,
    },
    data: function () {
      return {
        recipes: {
          items: []
        },
        pageInfo: {
          perPage: 10,
          currentPage: 1,
        },
      }
    },
    computed: {
      hasRecipes() {
        return this.recipes.items.length > 0;
      },
      recipesItems() {
        return this.recipes.items.map(recipe => ({
          id: recipe.id,
          frontUrl: recipe.frontUrl,
          eyeCatchImage: this.getEyeCatchImage(recipe),
          logoImage: recipe.adminShop.logoImageSet.xsSqImage || "https://cdn.taberutokurasuto.com/image/common/noimage_100x100.gif",
          shopName: recipe.adminShop.name,
          elapsedTime: recipe.elapsedTime,
          title: recipe.title,
        }));
      },
    },
    mounted () {
      this.fetchRecipes()
      this.showSide()
      this.setBreadcrumbs({ breadcrumbs: [
          {
            path: '/',
            name: 'TOP',
          },
          {
            path: '/recipes/',
            name: 'おすすめレシピ一覧',
          },
        ]})
    },
    methods: {
      async fetchRecipes() {
        this.loadingStart();
        try {
          const { data } = await this.$apollo
          .query({
            query: GetRecipesForIndex,
            variables: { ...this.pageInfo },
            client: 'apiClient',
          })
          .catch(error => {
            this.loadingStop();
            return;
          });
          this.recipes = data.recipes;
          this.pageInfo = data.recipes.pageInfo
          this.loadingStop();
        }
        catch (error) {
          this.loadingStop();
          console.log('e', error)
        }
      },
      getEyeCatchImage(recipe) {
        if (recipe.eyeCatchImage) {
          return recipe.eyeCatchImageSet.xsImage;
        } else if (recipe.mainImage) {
          return recipe.mainImageSet.xsImage;
        } else {
          return "https://cdn.taberutokurasuto.com/image/common/noimage_300x300.gif";
        }
      },
      changePage(newPage) {
        this.pageInfo.currentPage = newPage
        this.fetchRecipes()
        this.$scrollTo("body")
      },
    }
  }
</script>

<style scoped lang="scss">
@import "../../../../assets/stylesheets/v1/styles";
.container {
  > h1 {
    margin: 16px 0 48px;
    font-size: 24px;
  }
  > p {
    font-size: 14px;
  }
  > .col-xs-12 {
    > .pager-wrap {
      @include margin-top(md);
    }
  }
  @include mq-down() {
    margin: 24px 0 32px;
    > h1 {
      font-size: 18px;
    }
  }
}
</style>
