import gql from 'graphql-tag'

export const CreateFavoriteItem = gql`
mutation ($input: CreateFavoriteItemInput!) {
  createFavoriteItem (input: $input) {
    favoriteItem {
      id
      itemId
    }
    errors {
      model
      fullMessage
      attribute
      message
    }
  }
}`

export const DeleteFavoriteItem = gql`
mutation ($input: DeleteFavoriteItemInput!) {
  deleteFavoriteItem (input: $input) {
    favoriteItem {
      id
      itemId
    }
    errors {
      model
      fullMessage
      attribute
      message
    }
  }
}`

export const GetFavoriteItems = gql`
query {
  favoriteItems {
    items {
      id
      itemId
    }
  }
}`
