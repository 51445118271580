<template lang="pug">
  section.section-item-recipe(v-if="recipes.length > 0")
    HeadingArticle.mb-xs この商品を使ったレシピ
    PostList
      PostItem(v-for="recipe in recipes" :key="recipe.id" :post="recipe")
</template>

<script>
  import { GetItemGroupRecipes } from '../../../graphqls/Recipes'
  import HeadingArticle from '../typography/HeadingArticle'
  import PostList from '../post/PostList'
  import PostItem from '../post/PostItem'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'SectionItemRecipe',
    components: {
      HeadingArticle,
      PostList,
      PostItem,
      ButtonBase
    },
    props: {
      itemGroup: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {
        recipes: []
      }
    },
    computed: {
    },
    mounted() {
      this.fetchItemGroupRecipes()
    },
    methods: {
      async fetchItemGroupRecipes() {
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupRecipes,
          variables: {
            itemGroupId: this.itemGroup.id,
            perPage: -1,
            orderBY: 'published_at desc',
          }
        })
        this.recipes = data.itemGroupRecipes.items.map((item) => ({
          id: item.id,
          href: `/shop/${item.adminShop.code}/recipes/${item.customUrl}/`,
          src: item.mainImageSet.mdSqImage,
          title: item.title
        }))
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-item-recipe {

  }
</style>
