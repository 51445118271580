<template lang="pug">
  section.section-item-archive(v-if="itemGroups.length > 0")
    HeadingArticle.mb-xs 最近チェックした商品
    ItemArchive
      .item(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" :archive="true" eventCategory="最近チェックした商品" pageContents="最近チェックした商品")
</template>

<script>
import {GetArchiveItemGroupsFroApi} from '../../../graphqls/ItemGroups'
  import HeadingArticle from '../typography/HeadingArticle'
  import ItemCard from '../item/ItemCardForApi'
  import ItemArchive from '../item/ItemArchive'

  export default {
    name: 'SectionItemArchive',
    components: {
      HeadingArticle,
      ItemCard,
      ItemArchive
    },
    props: {
      excludeItemGroupId: {
        type: String,
        required: false,
        default: ''
      }
    },
    data() {
      return {
        itemGroups: [],
        excludeIds: [this.excludeItemGroupId]
      }
    },
    mounted: function () {
      this.fetchArchiveItemGroups()
    },
    methods: {
      async fetchArchiveItemGroups() {
        const { data } = await this.$apollo
        .query({
          query: GetArchiveItemGroupsFroApi,
          variables: { perPage: 6, excludeIds: JSON.stringify(this.excludeIds) },
          client: 'apiClient'
        })
        this.itemGroups = data.archiveItemGroups.items
      }
    }
  }
</script>
