export default {
  data () {
    return {}
  },
  computed: {
    $_wrappingOptions() {
      return [
        {id: '1', text: 'ラッピングなし（＋0円）'},
        {id: '2', text: 'ラッピングあり（＋300円）'},
      ]
    },
    $_noshiOptions() {
      return [
        {id: '1', text: 'のしなし'},
        {id: '2', text: 'お歳暮'},
        {id: '3', text: 'その他'},
      ]
    },
    $_noshiTypeOptions() {
      return [
        {id: '1', text: '名前なし'},
        {id: '2', text: '名入れあり(1名)'},
        {id: '3', text: '名入れあり(2名)'},
        {id: '4', text: '名入れあり(連名)'},
      ]
    },
    $_messageOptions() {
      return [
        {id: '1', text: 'メッセージカードなし'},
        {id: '2', text: '誕生日カード'},
      ]
    },
  },
}
