<template lang="pug">
  .form-item(:class="classes")
    slot
</template>

<script>
  export default {
    name: "form-item",
    props: {
      isInline: {
        type: Boolean,
        default: false
      },
      col: {
        type: Number,
        default: 1
      }
    },
    computed: {
      classes() {
        let classes = [`col-${this.col}`];
        if (this.isInline) classes.push('is-inline');
        return classes;
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .form-item {
    margin-left: #{map_get($padding-margin-pc, md)}px;
    @include mq-down(md) {
      margin-left: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &.col-1 {
      width: 100%;
    }
    &.col-2 {
      width: calc((100% - #{map_get($padding-margin-pc, md)}px) / 2);
      @include mq-down(md) {
        width: 100%;
      }
    }
    &.col-3 {
      width: calc((100% - #{map_get($padding-margin-pc, md) * 2}px) / 3);
      @include mq-down(md) {
        width: 100%;
      }
    }
    &.is-inline {
      display: inline-block;
      width: auto;
      @include mq-down(md) {
        width: 100%;
      }
    }

    + .form-item {
      @include mq-down(md) {
        margin-top: #{map_get($padding-margin-sp, md)}px;
      }
    }
    > .form-checkbox {
      @include mq-up(md) {
        margin-top: #{map_get($padding-margin-sp, md)}px;
      }
    }
    > .form-switch {
      @include mq-up(md) {
        margin-top: #{map_get($padding-margin-sp, md)}px;
      }
    }
  }
</style>
