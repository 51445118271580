<template lang="pug">
  ModalBase(ref="modalBase" size="medium")
    template(slot="header") キャンセル・交換・返品
    template(slot="body")
      section
        h3.heading ご注文のキャンセル
        p.word-pre {{ adminShop.lawCancel }}
      section
        h3.heading 返品・交換
        p.word-pre {{ adminShop.lawReturn }}
      section
        h3.heading 返品送料
        p.word-pre {{ adminShop.lawReturnShipping }}
      section
        h3.heading 返品のお問い合わせ
        p
          a.link(:href="`/shop/${adminShop.code}/contact/`") こちらのお問い合わせフォーム
          | よりご連絡ください。
      section(v-if="adminShop.isShowAddress")
        h3.heading 【製品ご返送先】
        p
          | {{ adminShop.name }}
          br
          | 〒{{ adminShop.zipCode }}
          br
          | {{ adminShop.address }}
          template(v-if="adminShop.isShowTel")
            br
            | TEL：{{ adminShop.tel }}
    template(slot="footer")
      ButtonBase(@click="close()" variant="normal" size="inline") 閉じる
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import ModalBase from '../modal/ModalBase'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'ModalReturnPolicy',
    mixins: [GlobalMixin],
    components: {
      ModalBase,
      ButtonBase
    },
    props: {
      adminShop: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    methods: {
      open: function(e) {
        this.$refs.modalBase.open()
        this.$emit('open', e)
      },
      close: function(e) {
        this.$refs.modalBase.close()
        this.$emit('close', e)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .modal-base {
    .body {
      section {
        @include margin-bottom(md);

        .heading {
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-snug;
          color: $black-400;
          @include margin-bottom(xxxs);
        }

        .link {
          color: $black-400;
          text-decoration: underline;
          &:hover {
            color: $olive-300;
          }
        }
      }
    }
  }
</style>
