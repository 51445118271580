<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="0 0 51.2 50.9" fill="#4B4B4B" enable-background="new 0 0 51.2 50.9" xml:space="preserve">
    <defs>
    </defs>
    <g>
      <g>
        <g>
          <path d="M11.5,0L11.5,0C5.1,0.3,0,5.6,0,12v26.9c0,6.6,5.4,12,12,12c6.1,0,11.2-4.6,11.9-10.5h15.9l11.4-11.4V0H11.5z M20.9,38.9
            c0,4.9-4,8.9-8.9,8.9s-8.9-4-8.9-8.9V20.1C5.3,22.5,8.5,24,12,24s6.7-1.5,8.9-3.9V38.9z M12,20.9c-4.9,0-8.9-4-8.9-8.9
            s4-8.9,8.9-8.9s8.9,4,8.9,8.9S16.9,20.9,12,20.9z M40.8,35.1V30h5.1L40.8,35.1z M48.1,26.9H37.6v10.4H24V12
            c0-3.5-1.5-6.7-3.9-8.9h28L48.1,26.9L48.1,26.9z"></path>
        </g>
      </g>
      <g>
        <g>
          <path d="M12,7.3c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7C16.7,9.4,14.6,7.3,12,7.3z M12,13.6
            c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C13.6,12.9,12.9,13.6,12,13.6z"></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
  export default {
    name: "wrapping-svg"
  }
</script>
