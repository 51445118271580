<template lang="pug">
  section.section-ranking#ranking
    HeadingGift.mb-sm
      h2 
        span.sub {{ giftPageBody.subTitle }}
        br 
        | {{ giftPageBody.title }}
    ItemCardList
      .item(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" :rank="index + 1" eventCategory="人気ランキング" pageContents="人気ランキング")
    .text-center
      ButtonBase(href="/shops/items/category/4522742051/" eventAction="商品一覧へ遷移" eventCategory="ギフトページ") 人気順にすべて見る
</template>

<script>
import {GetItemGroupsForApi} from '../../../graphqls/ItemGroups'
  import HeadingGift from '../heading/HeadingGift'
  import ItemCard from '../item/ItemCardForApi'
  import ItemCardList from '../item/ItemCardList'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'SectionRanking',
    components: {
      HeadingGift,
      ItemCard,
      ItemCardList,
      ButtonBase
    },
    props: {
      giftPageBody: {
        type: Object,
        required: true,
        default: function () {
          return {
          }
        }
      }
    },
    data() {
      return {
        itemGroups: []
      }
    },
    mounted() {
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        const {data} = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            categoryCode: '4522742051',
            nowOnSale: true,
            stockFilter: 'instock',
            currentPage: 1,
            totalCount: 0,
            perPage: 16,
            orderBy: 'popular'
          },
          client: 'apiClient'
        })
        this.itemGroups = data.itemGroups.items
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-ranking {

  }
</style>
