<template lang="pug">
  .form-textarea
    textarea.input(
      v-model="localValue" 
      :placeholder="placeholder" 
      :disabled="disabled"
      :class="classes"
      :rows="rows"
    )
    p.message(v-if="errorMessage") {{ errorMessage }}
</template>

<script>
  export default {
    name: 'FormTextarea',
    props: {
      value: {
        type: String,
        required: true,
        default: undefined
      },
      rows: {
        type: Number,
        required: false,
        default: 5
      },
      placeholder: {
        type: String,
        required: false,
        default: undefined
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      error: {
        type: Boolean,
        required: false,
        default: false
      },
      errorMessage: {
        type: String,
        required: false,
        default: undefined
      }
    },
    data() {
      return {
      }
    },
    computed: {
      localValue: {
        get: function () {
          return this.value; 
        },
        set: function (newValue) {
          this.$emit('input', newValue); 
        }
      },
      classes: function () {
        return [
          { '-error': this.error }
        ]
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .form-textarea {
    .input {
      width: 100%;
      padding: 11px;
      box-sizing: border-box;
      outline: none;
      appearance: none;
      resize: vertical;
      border-radius: 3px;
      border: 1px solid $gray-400;
      background: $white;
  
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      font-size: 16px; // iPhoneで16px以下の場合、フォーカス時に拡大されるため16pxで固定
      line-height: $line-height-normal;
      color: $black-400;
  
      &::placeholder {
        color: $black-100;
      }
  
      &:focus {
        border-color: $black-100;
      }
  
      &:disabled {
        background: $gray-200;
        border: none;
        cursor: not-allowed;
      }
  
      &.-error {
        background: $red-100;
        border-color: $red-300;
      }
    }

    .message {
      @include margin-top(xxxs);
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(xs);
      line-height: $line-height-tight;
      color: $red-300;
    }
  }
</style>
