<template>
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.80714 0C2.83214 0 1.85714 0.345278 1.11429 1.03278C-0.371429 2.40778 -0.371429 4.64139 1.11429 6.01639L6.5 11L11.8857 6.01639C13.3714 4.64139 13.3714 2.40778 11.8857 1.03278C11.1398 0.345278 10.1679 0 9.19286 0C8.21786 0 7.24286 0.345278 6.5 1.03278C5.75714 0.345278 4.78214 0 3.80714 0Z" fill="#A98948"/>
  </svg>
</template>
<script>
  export default {
    name: "like-fill-svg"
  }
</script>
