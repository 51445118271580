<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <h1>請求書一括ダウンロード</h1>
        <AlertBase v-if="errors.length > 0" type="error">
          <ul>
            <li v-for="error of errors">{{error.message}}</li>
          </ul>
        </AlertBase>

        <p>ダウンロードする年月を選択してダウンロードボタンを押してください。</p>
        <FormItem>
          <template #title>
            <FormLabel type="required">法人・団体名(宛名)</FormLabel>
          </template>
          <template #input>
            <FormTextfield v-model="form.corporateName"
                           :error="'corporateName' in validationErrorMessage"
                           :errorMessage="validationErrorMessage.corporateName"
                           placeholder="株式会社XXXXX"
            />
          </template>
        </FormItem>
        <FormItem>
          <template #title>
            <FormLabel type="required">請求月</FormLabel>
          </template>
          <template #input>
            <FormPulldown v-model="form.downloadOn" title="年月" :options="dateOption" @change="changeDate"></FormPulldown>
          </template>
        </FormItem>
        <div class="text-center">
          <ButtonBase @click="downloadInvoicePdfUrls">ダウンロード</ButtonBase>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalMixin from "../../mixin/GlobalMixin";
import Alert from "../../components/alert/Alert";
import DropDown from "../../components/v1/dropdown/DropDown";
import FormGroup from "../../components/v1/form/FormGroup";
import FormLabel from "../../components/v1/form/FormLabel";
import FormMessage from "../../components/form/FormMessage";
import FormTextfield from "../../components/v1/form/FormTextfield";
import FormItem from "../../components/v1/form/FormItem";
import FormBlock from "../../components/form/FormBlock";
import FormBody from "../../components/form/FormBody";
import ButtonBase from "../../components/v1/button/ButtonBase";
import FormPulldown from "../../components/form/FormPulldown";
import {InitDownloadInvoicePdf, CreateInvoicePdfUrl} from "../../graphqls/DownloadInvoicePdf";
import FormWrapper from "../../components/form/FormWrapper";
import AlertBase from "../../components/v1/alert/AlertBase";

export default {
  name: "mypage-invoice",
  mixins: [GlobalMixin],
  components: {
    AlertBase,
    FormWrapper,
    FormPulldown,
    ButtonBase,
    FormBody,
    FormBlock,
    FormItem,
    FormTextfield,
    FormMessage,
    FormLabel,
    FormGroup,
    DropDown,
    Alert,
  },
  props: [],
  data() {
    return {
      errors: [],
      form: {
        downloadOn: "",
        corporateName: ""
      },
      dateOption: []
    }
  },
  computed: {
    validationErrorMessage () {
      let errorMessage = {}
      for (const error of this.errors) {
        errorMessage[error.attribute] = error.message
      }
      return errorMessage
    },
  },
  async mounted() {
    try {
      this.loadingStart();
      const {data} = await this.$apollo
          .query({
            query: InitDownloadInvoicePdf,
            client: 'apiClient'
          })

      this.dateOption = data.initDownloadInvoicePdf.downloadDateSelectOption
      this.form.downloadOn = data.initDownloadInvoicePdf.downloadOn
      this.form.corporateName = data.initDownloadInvoicePdf.corporateName || ''

    } catch (error) {
      location.href="/"
      return;
    }
    this.loadingStop();
  },
  methods: {
    changeDate(date) {
      this.date = date;
    },
    async downloadInvoicePdfUrls() {
      try {
        this.loadingStart();
        this.errors = [];
        const {data} = await this.$apollo
            .mutate({
              mutation: CreateInvoicePdfUrl,
              variables: {
                input: this.form
              },
              client: 'apiClient'
            })
        if (data.createInvoicePdfUrl.errors) {
          this.errors = data.createInvoicePdfUrl.errors;

          return
        }

        const link = document.createElement('a')
        // link.download = 'result.csv'
        link.href = data.createInvoicePdfUrl.downloadUrl
        link.click()
      } catch (error) {
        location.href="/"
        return;
      } finally {
        this.loadingStop();
      }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
