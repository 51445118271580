<template>
  <svg width="10" height="6" viewBox="0 0 9.46 5.42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.73065 4.04156L8.77221 -3.01268e-08L9.46143 0.68922L4.73065 5.42L-0.000134498 0.68922L0.689086 -3.83451e-07L4.73065 4.04156Z" fill="#383838"/>
  </svg>
</template>
<script>
  export default {
    name: "arrow-svg"
  }
</script>
