<template>
  <svg width="25" height="26" viewBox="0 0 25 26" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1136 7.99302C15.2362 8.06378 15.3522 8.14586 15.4683 8.22795L13.3559 9.54505L18.7483 10.7936L20.0682 5.3424L17.7985 6.7649C17.3792 6.37184 16.9226 6.01763 16.4213 5.72816C12.7333 3.59892 7.97741 4.91474 5.81331 8.66309C5.12027 9.86346 4.75228 11.2213 4.74456 12.5984L7.32918 12.5732C7.33247 11.654 7.57747 10.7537 8.04168 9.94964C9.48332 7.45263 12.6569 6.5746 15.1136 7.99302ZM6.24626 14.2429L4.93185 19.6973L7.19831 18.2805C7.62087 18.6679 8.07745 19.0221 8.57883 19.3115C12.2668 21.4408 17.0227 20.125 19.1868 16.3766C19.8733 15.1876 20.2468 13.8329 20.2536 12.4704L17.6723 12.4899C17.6611 13.397 17.4161 14.2974 16.9584 15.0901C15.5168 17.5871 12.3432 18.4651 9.88645 17.0467C9.7639 16.9759 9.64788 16.8938 9.53187 16.8118L9.53184 16.8118L11.6386 15.4914L6.24626 14.2429Z"/>
  </svg>
</template>
<script>
  export default {
    name: "reload-svg"
  }
</script>
