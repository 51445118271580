<template lang="pug">
  .dropdown(:class="{ 'is-active': isDropdownActive }" v-on-clickaway="away")
    .dropdown-trigger(@click="isDropdownActive=!isDropdownActive")
      button#item_list_order.btn.pmd-ripple-effect.btn-default(aria-haspopup="true" aria-controls="{ 'dropdown-menu'}")
        span {{selectedItem}}
        span.caret
    .dropdown-menu(role="menu")
      ul.dropdown-content
        a.dropdown-item(v-for="option in options" :key="option.id" @click="select(option.id)") {{option.text}}
</template>
<script>
  import {mixin as clickaway} from 'vue-clickaway';

  export default {
    name: "DropDown",
    mixins: [clickaway],
    props: {
      value: {
        type: String,
        default: ""
      },
      options: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data () {
      return {
        isDropdownActive: false,
      }
    },
    computed: {
      selectedItem () {
        for(let option of this.options) {
          if (option.id === this.value) {
            return option.text
          }
        }
        return ''
      }
    },
    methods: {
      select (type) {
        this.$emit('change', type)
        this.isDropdownActive = false
      },
      away () {
        this.isDropdownActive = false;
      }
    }
  };
</script>
<style scoped lang="scss">
  .dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
    display: block;
  }

  .dropdown.is-up .dropdown-menu {
    bottom: 100%;
    padding-bottom: 4px;
    padding-top: initial;
    top: auto;
  }

  .dropdown-menu {
    display: none;
    left: 0;
    min-width: 12rem;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    z-index: 20;

    .dropdown-content {
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;

      .dropdown-item {
        color: #4a4a4a;
        display: block;
        font-size: 0.875rem;
        line-height: 2.5;
        padding: 0.375rem 1rem;
        position: relative;
      }

      a.dropdown-item, button.dropdown-item {
        padding-right: 3rem;
        text-align: left;
        white-space: nowrap;
        width: 100%;
      }

      a.dropdown-item:hover, button.dropdown-item:hover {
        background-color: whitesmoke;
        color: #0a0a0a;
      }
    }
  }


</style>
