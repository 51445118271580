<template lang="pug">
  .item-card-list
    slot
</template>

<script>
  export default {
    name: 'ItemCardList'
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .item-card-list {
    display: flex;
    flex-direction: column;

    > .item {
      @include margin-bottom(lg)
    }

    @include mq-up() {
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -#{map-get($padding-margin-pc, xxxs)}px;
      margin-right: -#{map-get($padding-margin-pc, xxxs)}px;
      
      > .item {
        width: 25%;
        &.lg {
          width: (100% / 3);
        }
        @include padding-left(xxxs);
        @include padding-right(xxxs);
      }
    }
  }
</style>
