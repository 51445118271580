<template lang="pug">
  .form-label
    slot
    .-required(v-if="type === 'required'")
</template>

<script>
  export default {
    name: 'FormLabel',
    props: {
      type: {
        type: String,
        required: false,
        default: 'default',
        validator: function (value) {
          return ['default', 'required'].includes(value)
        }
      }
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .form-label {
    display: flex;
    align-items: center;
    
    font-family: $font-family-sans;
    font-weight: $font-weight-bold;
    font-style: $font-style-not-italic;
    @include font-size(sm);
    line-height: $line-height-none;
    color: $black-400;

    > .-required {
      &:after {
        content: "必須";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 16px;
        background: $red-300;
        border-radius: 3px;
        @include margin-left(xxxs);
        
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xxxs);
        line-height: $line-height-none;
        color: $white;
      }
    }
  }
</style>
