<template lang="pug">
  section.section-budget#budget
    HeadingGift.mb-sm
      h2 
        span.sub {{ giftPageBody.subTitle }}
        br 
        | {{ giftPageBody.title }}
    .buttons
      .button-item
        ButtonBase(href="/shops/items/category/4522742051/?hp=2999" :block="true" eventAction="商品一覧へ遷移" eventCategory="ギフトページ") 2,999円以下
      .button-item
        ButtonBase(href="/shops/items/category/4522742051/?lp=3000&hp=4999" :block="true" eventAction="商品一覧へ遷移" eventCategory="ギフトページ") 3,000円 ~ 4,999円
      .button-item
        ButtonBase(href="/shops/items/category/4522742051/?lp=5000&hp=9999" :block="true" eventAction="商品一覧へ遷移" eventCategory="ギフトページ") 5,000円 ~ 9,999円
      .button-item
        ButtonBase(href="/shops/items/category/4522742051/?lp=10000" :block="true" eventAction="商品一覧へ遷移" eventCategory="ギフトページ") 10,000円以上
</template>

<script>
  import HeadingGift from '../heading/HeadingGift'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'SectionBudget',
    components: {
      HeadingGift,
      ButtonBase
    },
    props: {
      giftPageBody: {
        type: Object,
        required: true,
        default() {
          return {
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-budget {
    .buttons {
      display: flex;
      flex-direction: column;
      .button-item {
        @include margin-top(xs);
      }
      @include mq-up() {
        flex-direction: row;
        flex-wrap: wrap;
        .button-item {
          width: 50%;
          @include padding-left(xs);
          @include padding-right(xs);
        }
      }
    }
  }
</style>
