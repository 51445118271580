import gql from 'graphql-tag'

import {Errors} from './Common'


export const GetCartItems = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  cartItems (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      userId
      itemId
      adminShopId
      quantity
      item {
        id
        code
        displayName
        size
        thumItemImageOrItemGroupImageUrl
        sellingPriceInTax
      }
      adminShop {
        id
        code
        name
      }
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}`

export const GetCartItem = gql`
query($cartItemId: ID!) {
  cartItem(cartItemId: $cartItemId) {
    id
    name
    nameKana
    tel
    zipCode
    prefecture
    city
    address1
    address2
    tel
    addressType
  }
}`

export const CreateCartItem = gql`
mutation ($input: CreateCartItemInput!) {
  createCartItem (input: $input) {
    cartItem {
      id
      item {
        skuName
        displayName
      }
      itemTotalInTax
      quantity
      adminShop {
        isAddTax
      }
    }
    errors {
      model
      fullMessage
      attribute
      message
    }
  }
}`

export const DeleteCartItem = gql`
mutation ($input: DeleteCartItemInput!) {
  deleteCartItem (input: $input) {
    cartItem {
      id
    }
    errors {
      model
      attribute
      message
    }
  }
}`

export const CheckGiftSetting = gql`
mutation ($input: CheckGiftSettingInput!) {
  checkGiftSetting (input: $input) {
    cartItem {
      id
      item {
        skuName
      }
      itemTotalInTax
      quantity
    }
    ${Errors}
  }
}`
