<template lang="pug">
  section.section-shop-newly(v-if="adminShops.length > 0")
    HeadingRoof.mb-sm(title="生産者一覧" subtitle="Shop")
    .shop-list
      .item(v-for="adminShop in adminShops" :key="adminShop.id")
        ShopCard(:adminShop="adminShop")
    .text-center.mt-md
      ButtonBase(href="/shops/" eventAction="ショップ一覧へ遷移" eventCategory="トップ生産者一覧") 生産者一覧
</template>

<script>
  import { GetAdminShops } from '../../../graphqls/AdminShops'
  import HeadingRoof from '../heading/HeadingRoof'
  import ShopCard from '../shop/ShopCard'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'SectionShopNewly',
    components: {
      HeadingRoof,
      ShopCard,
      ButtonBase
    },
    data() {
      return {
        adminShops: []
      }
    },
    mounted() {
      this.getAdminShops()
    },
    methods: {
      async getAdminShops() {
        const { data } = await this.$apollo
        .query({
          query: GetAdminShops,
          variables: {
            perPage: this.limit,
            orderBy: 'newly_at desc'
          },
          client: 'apiClient'
        })
        this.adminShops = data.adminShops.items
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .section-shop-newly {
    > .shop-list {
      display: flex;
      flex-wrap: wrap;
      > .item {
        width: (100% / 2);
        @include padding(zero, xs);
        @include margin-bottom(xs);
        @include mq-up() {
          width: (100% / 3);
        }
      }
    }
  }
</style>
