<template lang="pug">
  .contacts-show
    .sheet
      .contact(v-if="noticeType === 'user_contact_notice'")
        .contact-header {{ contact.adminShop.name }}へのお問い合わせ
        .contact-body
          template
            p.paragraph.font-bold お問い合わせ番号 | お問い合わせ日時
            p.paragraph {{ contact.code }} | {{ contact.contactedAt | moment('YYYY/MM/DD HH:mm') }}
            .chat-list
              ChatItem(
                v-for="(contactComment, index) in contact.contactComments"
                :key="contactComment.id"
                :class="{ '-first': isShowAvatar(index) }"
                :isShowAvatar="isShowAvatar(index)"
                :avatarName="avatarName(contactComment)"
                :avatarImage="avatarImage(contactComment)"
                :commentText="contactComment.comment"
                :commentImage="contactComment.imageUrl"
                :elapsedTime="contactComment.elapsedTime"
              )
          AlertBase.my-md(v-if="errorMessages.length > 0" type="error")
            p(v-for="errorMessage of errorMessages") {{ errorMessage }}
          FormItem
            template(slot="title" v-if="!contact.id")
              FormLabel お問い合わせ内容
            template(slot="input")
              FormTextarea(
                v-model="form.comment"
                placeholder="ご入力してください"
                :error="'comment' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.comment"
              )
          FormUploadImage.my-md(ref="uploader" @onChange="uploadImage" :limit="5")
          .text-center
            ButtonBase(@click="createContactComment") 送信

      // 商品Q&A
      ChatComment.contact(v-if="noticeType === 'item_group_qa_notice'"
        :key="itemGroupQa.id"
        :loggedIn="true"
        :itemGroupQa="itemGroupQa"
        :defaultOpen="true"
        @toggleQuestionLike="toggleQuestionLike"
        @toggleCommentLike="toggleCommentLike"
        @updateQuestion="updateQuestion"
        @updateComment="updateComment"
        @addComment="addComment"
      )

        //template
          //  p.paragraph.font-bold お問い合わせ番号 | お問い合わせ日時
          //  p.paragraph {{ contact.code }} | {{ contact.contactedAt | moment('YYYY/MM/DD HH:mm') }}
          //  .chat-list
          //    ChatItem(
          //      v-for="(contactComment, index) in contact.contactComments"
          //      :key="contactComment.id"
          //      :class="{ '-first': isShowAvatar(index) }"
          //      :isShowAvatar="isShowAvatar(index)"
          //      :avatarName="avatarName(contactComment)"
          //      :avatarImage="avatarImage(contactComment)"
          //      :commentText="contactComment.comment"
          //      :commentImage="contactComment.imageUrl"
          //      :elapsedTime="contactComment.elapsedTime"
          //    )
          //AlertBase.my-md(v-if="errorMessages.length > 0" type="error")
          //  p(v-for="errorMessage of errorMessages") {{ errorMessage }}
          //FormItem
          //  template(slot="title" v-if="!contact.id")
          //    FormLabel お問い合わせ内容
          //  template(slot="input")
          //    FormTextarea(
          //      v-model="form.comment"
          //      placeholder="ご入力してください"
          //      :error="'comment' in $_validationErrorMessage"
          //      :error-message="$_validationErrorMessage.comment"
          //    )
          FormUploadImage.my-md(ref="uploader" @onChange="uploadImage" :limit="5")
          .text-center
            ButtonBase(@click="createContactComment") 送信
    .button-block
      ButtonBase(variant="normal" href="/mypage/purchasehistory/") 注文履歴へ戻る
      ButtonBase(variant="normal" href="/mypage/contacts/") メッセージ一覧へ戻る
</template>

<script>
  // import { GetAdminShopForContact } from '../../graphqls/AdminShops'
  import { CreateContactComment } from '../../graphqls/ContactComments'
  import {GetUserNotice} from "../../graphqls/UserNotices";
  // import { GetContactWithComments } from '../../graphqls/Contacts'
  import GlobalMixin from '../../mixin/GlobalMixin'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import ChatItem from '../../components/v1/chat/ChatItem'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormTextfield from '../../components/v1/form/FormTextfield'
  import FormTextarea from '../../components/v1/form/FormTextarea'
  import FormUploadImage from '../../components/v1/form/FormUploadImage'
  import ChatComment from "../../components/v1/chat/ChatComment";

  export default {
    name: 'ContactsShow',
    mixins: [GlobalMixin],
    components: {
      ChatComment,
      ButtonBase,
      ChatItem,
      AlertBase,
      FormItem,
      FormLabel,
      FormTextfield,
      FormTextarea,
      FormUploadImage,
    },
    data() {
      return {
        // adminShop: {
        //   name: ''
        // },
        userNotice: null,
        // contact: {
        //   code: '',
        //   contactedAt: '',
        //   adminShop: {
        //     name: ''
        //   },
        //   contactComments: []
        // },
        form: {
          orderId: '',
          comment: '',
        },
        images: [],
        errorMessages: [],
        itemGroupQas: []
      }
    },
    computed: {
      isNewContact() {
        return this.$route.name === 'mypage-contacts-new'
      },
      noticeType() {
        return this.userNotice ? this.userNotice.noticeType : ""
      },
      contact() {
        return this.userNotice ? this.userNotice.contact : {}
      },
      itemGroupQa() {
        return this.userNotice ? this.userNotice.itemGroupQa : {}
      }
    },
    mounted() {
      this.getUserNotice()
      // if (this.isNewContact) {
      //   this.getAdminShop()
      // } else {
      //   this.getContactWithComments(this.$route.params.id)
      // }
    },
    methods: {
      // QA更新
      updateQuestion(itemGroupQas) {
        this.setItemGroupQa(itemGroupQas)
      },
      // コメント更新
      updateComment(itemGroupQas) {
        this.setItemGroupQa(itemGroupQas)
      },
      // QAいいね更新
      toggleQuestionLike(itemGroupQas) {
        this.setItemGroupQa(itemGroupQas)
        this.itemGroupQas = itemGroupQas
      },
      // コメントいいね更新
      toggleCommentLike(itemGroupQas) {
        this.setItemGroupQa(itemGroupQas)
      },
      // コメント追加
      addComment(itemGroupQas) {
        this.setItemGroupQa(itemGroupQas)
      },

      // async getAdminShop () {
      //   this.loadingStart()
      //   const { data } = await this.$apollo.query({
      //     query: GetAdminShopForContact,
      //     variables: {
      //       adminShopCode: this.$route.query.shop_code
      //     }
      //   })
      //   this.contact.adminShop = data.adminShop
      //   this.setBreadcrumbs({ breadcrumbs: [
      //     {
      //       path: '/',
      //       name: 'TOP',
      //     },
      //     {
      //       path: '/mypage/',
      //       name: 'マイページ',
      //     },
      //     {
      //       path: '/mypage/contacts/',
      //       name: 'メッセージ',
      //     },
      //     {
      //       path: `/mypage/contacts/new/?shop_code=${this.contact.adminShop.code}`,
      //       name: `${this.contact.adminShop.name}へのお問い合わせ`,
      //     },
      //   ]})
      //   this.loadingStop()
      // },
      setItemGroupQa(itemGroupQas) {
        for (let itemGroupQa of itemGroupQas) {
          if (itemGroupQa.id === this.userNotice.itemGroupQa.id) {
            this.userNotice.itemGroupQa = itemGroupQa
            return
          }
        }
      },
      async getUserNotice() {
        this.loadingStart()
        const { data } = await this.$apollo
        .query({
          query: GetUserNotice,
          variables: {
            userNoticeId: this.$route.params.id,
            confirm: true
          }
        })
        this.userNotice = data.userNotice
        this.setBreadcrumbs({ breadcrumbs: [
          {
            path: '/',
            name: 'TOP',
          },
          {
            path: '/mypage/',
            name: 'マイページ',
          },
          {
            path: '/mypage/contacts/',
            name: 'メッセージ',
          },
          {
            path: `/mypage/contacts/${this.userNotice.id}/`,
            name: `${this.userNotice.adminShop.name}へのお問い合わせ`,
          },
        ]})
        this.loadingStop()
      },
      async createContactComment() {
        this.errorMessages = []
        if (!this.form.comment && this.images.length === 0) {
          this.errorMessages.push('お問い合わせ内容を入力、または、画像を追加してください')
          return
        }

        this.loadingStart()
        this.form.contactId = this.contact.id
        this.form.shopCode = this.$route.query.shop_code
        for (let i = 0; i < 5; i++) {
          this.form[`image${i + 1}`] = this.images[i] || ''
        }

        const { data } = await this.$apollo
        .mutate({
          mutation: CreateContactComment,
          variables: {
            input: this.form
          }
        })

        if (this.isNewContact) {
          this.getContactWithComments(data.createContactComment.contactComments[0].contactId)
        } else {
          this.contact.contactComments = data.createContactComment.contactComments
        }

        this.form.comment = ''
        this.images = []
        this.$refs.uploader.clear()
        this.loadingStop()
      },
      isUserComment(contactComment) {
        return contactComment.user ? true : false
      },
      isShowAvatar(index) {
        if (index === 0) return true
        const previous = this.contact.contactComments[index - 1]
        const current = this.contact.contactComments[index]
        if (this.isUserComment(current)) {
          return previous.userId !== current.userId
        } else {
          return previous.adminUserId !== current.adminUserId
        }
      },
      avatarName(contactComment) {
        if (this.isUserComment(contactComment)) return contactComment.user.name
        return contactComment.adminUser.adminShop.name
      },
      avatarImage(contactComment) {
        if (!this.isUserComment(contactComment)) return contactComment.adminUser.adminShop.logoImageSet.smSqImage
        return ''
      },
      uploadImage(list) {
        this.images = list.map(img => img.file)
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .contacts-show {
    > .sheet {
      width: 100%;
      background-color: $gray-100;
      @include padding(lg, xs);
      @include mq-down() {
        width: 100vw;
        margin-left: -10px;
        margin-right: -10px;
      }
      > .contact {
        width: 100%;
        max-width: 600px;
        background-color: $white;
        margin: 0 auto;
        > .contact-header {
          @include padding(sm);
          border-bottom: 1px solid $gray-200;
          font-family: $font-family-serif;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-normal;
          color: $black-400;
          text-align: center;
        }
        > .contact-body {
          @include padding(sm);
          > .chat-list {
            > .chat-item {
              @include margin-top(xs);
              &.-first {
                @include margin-top(md);
              }
            }
          }
        }
      }
    }
    .button-block {
      display: flex;
      justify-content: center;
      @include margin-top(lg);
      @include margin-bottom(lg);
      .button-base {
        @include margin-right(xxs);
        @include margin-left(xxs);
      }
    }
  }
</style>
