<template lang="pug">
  .container
    FlashMessages.mb-xs
    h1 注文履歴
    P お客様の声が生産者さんの力になります。レビューを書くとポイントをプレゼント！
    .orders(v-if="orderItems.length > 0")
      .order-wrap(v-for="order in orderItems" )
        .order-info
          .shop
            a.logo(:href="`/shop/${order.adminShop.code}/`")
              img(v-if="order.adminShop.publishedStatus === 'published'" :alt="`${order.adminShop.name}ロゴ`" :src="order.adminShop.logoImageSet.xsSqImage")
              img(v-else :alt="`${order.adminShop.name}ロゴ`" src="https://cdn.taberutokurasuto.com/image/common/noimage_100x100.gif")
            .shop-name
              a(:href="`/shop/${order.adminShop.code}/`") {{order.adminShop.name}}
              span.message(v-if="order.adminShop.publishedStatus !== 'published'" ) 閉店したショップです
          .order-status
            .order-status-details
              .item 
                span 注文日
                span {{order.formatOrderedOn}}
              .item 
                span 注文番号
                span {{order.code}}
              .item
                span 注文状況
                span(:class="order.currentOrderSummary.orderStatus") {{order.currentOrderSummary.orderStatusLabel}}
            .receipt(v-if="order.currentOrderSummary.orderStatus === 'complete'")
              ButtonBase(variant="normal" size="inline" @click="openInvoiceModal(order.id)") 領収書(PDF)
        .order-details
          .column
            .products
              .product-info(v-for="orderItem in order.currentOrderItems" )
                .product-thumbnail
                  a(:href="orderItem.itemGroup.frontUrl")
                    img(:alt="orderItem.name" :src="itemImage(orderItem)")
                .product-info-wrap
                  .product-info-detail
                    a(:href="orderItem.itemGroup.frontUrl")
                      span {{orderItem.name}}
                      span {{orderItem.item.size}}
                    .price
                      p {{orderItem.priceInTaxWithCurrency}}{{ order.currentOrderPayment.isAddTax ? '(税込)' : ''}}
                    .state(v-if="orderItem.isPreSale")
                      p 予約商品
                    .state(v-if="order.subscriptionOrderId")
                      p 定期購入
                    .quantity
                      p 数量：{{orderItem.quantity}}点
                  GiftDetail(:order-item="orderItem" )
                  .button-area
                    .button
                      ButtonBase(variant="primary" :disabled="disabledReviewButton(order.currentOrderSummary) || isDraftItemGroup(order, orderItem)" size="small" @click="goReview(orderItem.id)") {{reviewButtonLabel(orderItem)}}
                      ButtonBase(variant="primary" size="small" :href="orderItem.itemGroup.frontUrl" :disabled="isDraftItemGroup(order, orderItem)") もう一度購入
                    p(v-if="showReviewMessage(order.currentOrderSummary)" ) 出荷完了後、レビューを書くことができます
          .column
            OrderDetail(:order="order")
            .inquiry(v-if="order.adminShop.publishedStatus === 'published'" )
              ButtonBase(variant="normal" :href="`/mypage/purchasehistory/${order.id}/`") ご注文に関するお問い合わせ

    .pager-wrap(v-if="orderItems.length > 0")
      Pager(@change="changePage" :custom-page-info="pageInfo")
    AlertBase(v-if="orderItems.length === 0" type="info")
      p 注文履歴はありません
    .text-center.my-lg
      ButtonBase(variant="normal" href="/mypage/") マイページへ戻る

    ModalBase(ref="modalReceipt" size="medium")
      template(slot="header") 法人名・団体名（宛名）
      template(slot="body")
        section
        FormBlock
          FormBody
            FormGroup
              FormItem
                FormLabel 法人名・団体名（宛名）
                FormTextfield(v-model="form.corporateName" placeholder="株式会社XXXXX" :isError="'corporateName' in validationErrorMessage")
                FormMessage(:isError="'corporateName' in validationErrorMessage") {{validationErrorMessage.corporateName}}
      template(slot="footer")
        ButtonBase(@click="$refs.modalReceipt.close()" variant="normal" size="inline") キャンセル
        ButtonBase(@click="downloadReceipt()" variant="primary") ダウンロード

</template>

<script>
import GlobalMixin from '../../mixin/GlobalMixin'
import ButtonBase from '../../components/v1/button/ButtonBase'
import AlertBase from "../../components/v1/alert/AlertBase";
import Pager from "../../components/v1/pager/Pager";
import OrderDetail from "../../components/v1/purchasehistory/OrderDetail";
import FormGroup from "../../components/form/FormGroup";
import FormItem from "../../components/form/FormItem";
import FormLabel from "../../components/form/FormLabel";
import FormTextfield from "../../components/form/FormTextfield";
import FormMessage from "../../components/form/FormMessage";
import ModalBase from "../../components/v1/modal/ModalBase";
import FormBlock from "../../components/form/FormBlock";
import FormBody from "../../components/form/FormBody";
import {GetPurchaseHistories, CreateReceiptPdfUrl} from "../../graphqls/Orders";
import {GetUserCorporateNameForApi} from "../../graphqls/Users";
import GiftDetail from "../../components/v1/purchasehistory/GiftDetail";
import FlashMessages from "../../components/v1/alert/FlashMessages";

export default {
  name: "purchase-history",
  mixins: [GlobalMixin],
  components: {
    GiftDetail,
    FormBody,
    FormBlock,
    ModalBase,
    FormMessage,
    FormTextfield,
    FormLabel,
    FormItem,
    FormGroup,
    OrderDetail,
    Pager,
    ButtonBase,
    AlertBase,
    FlashMessages,
  },
  data() {
    return {
      showDetail: false,
      arrowIcon: require('../../../../assets/images/icon/arrow-up.svg'),
      orders: null,
      pageInfo: {
        currentPage:  Number(this.$route.query.page || 1),
        totalCount: 0,
        perPage: 5,
      },
      selectedOrderId: "",
      form: {
        orderId: "",
        corporateName: ""
      },
      showGift: false,
      errors: []
    };
  }, 
  mounted() {
    this.getPurchaseHistories()
    this.getUserCorporateNameForApi()
    this.setBreadcrumbs({ breadcrumbs: [
       {
         path: '/',
         name: 'TOP',
       },
       {
         path: '/mypage/',
         name: 'マイページ',
       },
       {
         path: '/mypage/purchasehistory/',
         name: '注文履歴',
       },
     ]})
  },
  computed: {
    orderItems() {
      return this.orders?.items || [];
    },
    validationErrorMessage() {
      let result = {}
      this.errors.forEach(error => {
        result[error.attribute] = error.message
      });
      return result
    }
  },
  methods: {
    async getUserCorporateNameForApi() {
      this.loadingStart()
      const { data } = await this.$apollo
          .query({
            query: GetUserCorporateNameForApi,
            client: 'apiClient'
          })
      this.form.corporateName = data.user.corporateName
    },
    async getPurchaseHistories() {
      this.loadingStart()
      const { data } = await this.$apollo
      .query({
        query: GetPurchaseHistories,
        variables: {
          ...this.pageInfo
        },
        client: 'apiClient'
      })
      this.orders = data.orders
      this.pageInfo =  this.orders.pageInfo
      this.$scrollTo("body")
      this.loadingStop()
    },
    async downloadReceipt() {
      try {
        this.loadingStart();
        this.errors = [];
        const {data} = await this.$apollo
            .mutate({
              mutation: CreateReceiptPdfUrl,
              variables: {
                input: this.form
              },
              client: 'apiClient'
            })
        const errors = data.createReceiptPdfUrl.errors;
        if (errors) {
          this.errors = errors
          return
        }

        const link = document.createElement('a')
        // link.download = 'result.csv'
        link.href = data.createReceiptPdfUrl.downloadUrl
        link.click()
        this.$refs.modalReceipt.close()
      } catch (error) {
        // location.href="/"
        return;
      } finally {
        this.loadingStop();
      }
    },
    changePage(newPage) {
      this.pageInfo.currentPage = newPage
      this.$router.push({name: 'mypage-purchase-history', query: {page: newPage}})
      this.getPurchaseHistories()
    },
    disabledReviewButton(orderSummary) {
      return orderSummary.orderStatus !== 'complete'
    },
    isDraftItemGroup(order, orderItem) {
      return order.adminShop.publishedStatus !== 'published' || orderItem.itemGroup.publishedStatus !== 'published'
    },
    goReview(orderItemId) {
      this.$router.push({ name: 'mypage-reviews-show', params: { id: orderItemId } })
    },
    showReviewMessage(orderSummary) {
      return orderSummary.orderStatus === 'accept' || orderSummary.orderStatus === 'confirmed'
    },
    reviewButtonLabel(orderItem) {
      return  !orderItem?.review?.postedAt ? 'レビューを書く' : 'レビューを編集'
    },
    openInvoiceModal(orderId) {
      this.form.orderId = orderId
      this.$refs.modalReceipt.open()
    },
    itemImage(orderItem) {
      if (!!orderItem.item.itemImageUrl) {
        return orderItem.item.itemImageSets.xsSqImage
      }
      return orderItem.itemGroup?.listImageOrItemGroupImageSet?.xsSqImage
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";
  .container {
    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    font-size: 14px;
    color: $black-400;
    line-height: $line-height-tight;
    > h1 {
      @include margin-bottom(xxs);
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      text-align: left;
      @include font-size(md);
    }
    > p {
      @include margin-bottom(xxs);
    }
    > .orders {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-self: stretch;      
      > .order-wrap {
        border: 1px solid $gray-300;
        > .order-info {
            background-color: $gray-100;
            border-bottom: 1px solid $gray-300;
            border-left: 2px solid $olive-300;
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-end;
            flex: 1 0 0;  
            @media (max-width: 768px) {            
              > .order-info {
                gap: 8px;
              }
            }
            > .shop {
              display: flex;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              @include margin-bottom(xxxs);  
              > .logo {
                width: 18px;
                height: 18px;  
                > img {
                width: 18px;
                height: 18px;
                }
              }
              > .shop-name {
                display:flex;
                flex-flow: column;
                > a {
                  text-decoration: none;
                  font-weight: $font-weight-bold;
                  color: $black-400;
                  font-size: 16px;
                  &:hover {
                    color: $olive-400;
                  }
                }
                > .message {
                  color: $black-200;
                  font-size: 12px;
                }
              }
            }
            > .order-status {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              align-self: stretch;
              position:relative;
              
              > .order-status-details {
                display: flex;
                gap: 16px;
                > .item {
                  > span:first-child {
                      @include margin-right(xxs);
                      font-weight: $font-weight-bold;
                    }  
                  > .accept,
                    .confirmed,
                    .complete {
                    color: $red-300;
                  }
                }                
              }
              @media (max-width: 768px) {            
                > .order-status-details {
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 4px;
                }
              }
            }
          }
        > .order-details {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            > .column {
              width: 100%;
              > .inquiry {
                  padding: 16px;
                > .button-base {
                  width: 100%;
                }
              }      
              > .products {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                align-self: stretch;          
                > .product-info {
                  display: flex;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;  
                    > .product-thumbnail {
                        width: 96px;
                        height: 96px;
                        @include margin-right(xxxs);
                        cursor: pointer;  
                        > a {
                          > img {
                            width: 100%;
                          }
                        }
                    }    
                    @media (max-width: 768px) {
                      > .product-thumbnail {
                          width: 56px;
                          height: 56px;
                        @include margin-right(xxxs);  
                        > a {
                          > img {
                            width: 100%;
                          }
                        }
                      }
                    }
                  > .product-info-wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                    flex: 1 0 0;      
                    > .product-info-detail {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 4px;
                      align-self: stretch;  
                      > a {
                        text-decoration: none;
                        color: $black-400;
                        cursor: pointer;
                        > span {
                          @include margin-right(xxxs);
                        }
                      }  
                      > .price {
                        font-size: 16px;
                        font-weight: $font-weight-bold;
                      }  
                      > .state {
                        font-size: 12px;
                        color: $olive-400;
                      }
                    }
                    > .button-area {
                      > .button {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;
                      }
                      > p {
                        font-size: 12px;
                        color: $black-200;
                        @include margin-top(xxxs);
                      }
                    }
                    @media (max-width: 768px) {
                      > .button-area {
                        width: 100%;
                        > .button {
                          > button {
                            width: 100%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            > .column:first-child {
              border-bottom: 1px solid $gray-300;
            }              
            @media (min-width: 768px) {
              > .column:first-child {
                width: calc(100% - 320px);
                border-bottom: none;
              }
              > .column:nth-child(2) {
                order: 1;
                border-left: 1px solid $gray-300;
                width: 320px;
              }
            }
          }
      }  
    }
  > .pager-wrap {
      @include margin-top(md);
    }
  }
</style>