<template lang="pug">
  img.lazyload(
    data-sizes="auto"
    :src="loading"
    :data-src="src",
    :alt="alt",
    :class="{'img-responsive' : isImgResponsive}"
  )

</template>

<script>
  import 'lazysizes';

  export default {
    name: "LazyImg",
    props: {
      loading: {
        type: String,
        default: "https://cdn.taberutokurasuto.com/image/common/blank.png"
      },
      src: {
        type: String,
        default: ''
      },
      alt: {
        type: String,
        default: ''
      },
      isImgResponsive: {
        type: Boolean,
        default: true
      }
    }
  }

</script>

<style>
  .lazyloading {
    min-height: 280px;
  }

</style>
