const getters = {
  loading: (state) => {
    return state.loading
  },
  hasSide: (state) => {
    return state.hasSide
  },
  hasBanner: (state) => {
    return state.hasBanner
  },
  breadcrumbs: (state) => {
    return state.breadcrumbs
  },
  flashMessages: (state) => {
    return state.flashMessages
  },
  errorMessages: (state) => {
    return state.errorMessages
  },
  pageInfo: (state) => {
    return state.pageInfo
  },
  currentUser: (state) => {
    return state.currentUser
  },
  currentAdminShop: (state) => {
    return state.currentAdminShop
  },
  isLogin: (state)  => {
    return state.currentUser.id !== ''
  },
  isPc: (state) => {
    return !!state.currentUser.isPc
  },
  isMobile: (state) => {
    return !!state.currentUser.isMobile
  },
  toastMessage: (state) => {
    return state.toastMessage
  },
  favoriteItems: (state) => {
    return state.favoriteItems
  },
  currentShop: (state) => {
    return state.currentShop
  },
  toast: (state) => {
    return state.toast
  },
  currentItemGroup: (state) => {
    return state.currentItemGroup
  },
  hasCoupon: (state) => {
    return state.coupon.hasCoupon
  },
  couponShopCode: (state) => {
    return state.coupon.shopCode
  },
  couponPageName: (state) => {
    return state.coupon.pageName
  },
}

export default getters
