<template lang="pug">
  .alert(:class="classes")
    slot
</template>

<script>
  export default {
    name: "Alert",
    components: {
    },
    props: {
      valiant: {
        type: String,
        default: "",
        validator: function (value) {
          return ['info', 'success', 'warning', 'danger'].indexOf(value) !== -1
        }
      }
    },
    computed: {
      classes() {
        let result = []
        if (this.valiant) {
          result.push(`alert-${this.valiant}`)
        }
        return result
      }
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
</style>
