<template lang="pug">
  .chat-comment
    .comment
      .info
        .icon(v-if="user.iconUrl" :style="{backgroundImage: `url('${user.iconUrl}')`}")
        .icon(v-else)
          UserIconDefault
        .box
          .text
            | {{user.nickname || "匿名"}}さんが
            a(:href="`/shop/${adminShop.code}/`" target="_blank") {{adminShop.name}}
            | さんの
            a(:href="`/shop/${adminShop.code}/item/${itemGroup.code}/`" target="_blank") {{itemGroup.name}}
            | について質問をしました。
          .date {{itemGroupQa.elapsedTime}}
      template(v-if="itemGroupQa.stashQuestion")
        FormTextarea(
          v-model="itemGroupQa.question"
          placeholder="コメントする..."
          :error="'question' in $_validationErrorMessage"
          :error-message="$_validationErrorMessage.question"
        )
      .text(v-else) {{itemGroupQa.question}}
        span.edited(v-if="itemGroupQa.isEdited") （編集済み）
      .menu
        template(v-if="itemGroupQa.stashQuestion")
          .cancel(@click="cancelEditQuestion")
            | キャンセル
          .save(@click="updateQuestion")
            | 変更を保存
        template(v-else)
          .like(@click="toggleQuestionLike")
            LikeFillSvg(v-if="itemGroupQa.isLiked")
            LikeSvg(v-else)
            span(:class="{liked: itemGroupQa.isLiked}") いいね！
            | {{itemGroupQa.likeCount}}件
          .remark(:class="{'is-open': isOpen}" @click="open")
            CommentSvg
            | コメント{{itemGroupQa.itemGroupQaComments.length}}件
          .edit(v-if="itemGroupQa.isMyQuestion" @click="editQuestion")
            EditSvg
            | 編集

    .remark
      TransitionBlock(class="transition" :is-open="isOpen")
        .list(v-if="itemGroupQa.itemGroupQaComments.length > 0")
          ChatRemark(v-for="(itemGroupQaComment, i) in itemGroupQa.itemGroupQaComments" :key="itemGroupQaComment.id"
            :itemGroupQaComment="itemGroupQaComment"
            @clickLike="toggleCommentLike"
            @updateComment="updateComment"
          )
        p.none(v-else) コメントがありません
        template(v-if="loggedIn && itemGroupQa.isMyQuestion")
          FormTextfield.mb-md(
            v-if="!isAdminShopUser && currentUser && !currentUser.nickname"
            v-model="commentForm.nickname"
            placeholder="ニックネームを入力してください"
            :error="'nickname' in $_validationErrorMessage"
            :error-message="$_validationErrorMessage.nickname"
          )
          FormTextarea.mb-md(
            v-model="commentForm.comment"
            placeholder="コメントを入力してください"
            :error="'comment' in $_validationErrorMessage"
            :error-message="$_validationErrorMessage.comment"
          )
      .text-center
        ButtonBase(v-if="loggedIn && itemGroupQa.isMyQuestion" @click="addComment") コメントする
      //p.notice(v-if="isAdminShopUser") ショップ ( {{currentAdminUser.adminShop.name}} ) としてコメントします
</template>

<script>
  import {
    UpdateItemGroupQa,
    CreateItemGroupQaComment,
    CreateItemGroupQaLike,
    DeleteItemGroupQaLike,
  } from "../../../graphqls/ItemGroupQas";

  import GlobalMixin from "../../../mixin/GlobalMixin";
  import UserIconDefault from "../../svg/UserIconDefault";
  import LikeSvg from "../../svg/LikeSvg";
  import LikeFillSvg from "../../svg/LikeFillSvg";
  import CommentSvg from "../../svg/CommentSvg";
  import EditSvg from "../../svg/EditSvg";
  import ChatRemark from "./ChatRemark";
  import TransitionBlock from "../../transition/TransitionBlock";
  import FormItem from '../form/FormItem'
  import FormLabel from '../form/FormLabel'
  import FormTextarea from '../form/FormTextarea'
  import FormTextfield from '../form/FormTextfield'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: "chat-comment",
    mixins: [GlobalMixin],
    components: {
      UserIconDefault,
      LikeSvg,
      LikeFillSvg,
      CommentSvg,
      EditSvg,
      ChatRemark,
      TransitionBlock,
      FormItem,
      FormLabel,
      FormTextarea,
      FormTextfield,
      ButtonBase
    },
    props: {
      loggedIn: {
        type: Boolean,
        default: false
      },
      adminUser: {
        type: Object,
        default () {
          return {
            id: null,
            adminShop: {
              id: "",
              name: ""
            }
          }
        }
      },
      itemGroupQa: {
        type: Object,
        default () {
          return {
            id: null,
            question: '',
            isLiked: false,
            isMyQuestion: false,
            user: {
              nickname: '',
              iconUrl: ''
            },
            adminShopId: null,
            adminShop: {
              name: '',
              code: ''
            },
            itemGroup: {
              name,
              code
            },
            iconUrl: '',
            date: null,
            likeCount: 0,
            itemGroupQaComments: []
          }
        }
      },
      defaultOpen: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        commentForm: {
          itemGroupQaId: this.itemGroupQa.id,
          comment: '',
          nickname: '',
        },
        isOpen: false,
        formRemark: ""
      }
    },
    mounted () {
      this.isOpen = this.defaultOpen;
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser.user
      },

      currentAdminUser () {
        return this.$store.getters.currentUser.adminUser
      },

      user () {
        return this.itemGroupQa.user
      },

      adminShop () {
        return this.itemGroupQa.adminShop
      },

      itemGroup () {
        return this.itemGroupQa.itemGroup
      },
      isAdminShopUser() {
        return this.adminUser && (this.adminUser.adminShop.isSystemAdmin || this.itemGroupQa.adminShopId === this.adminUser.adminShop.id)
      }
    },
    methods: {
      // QA編集
      editQuestion () {
        this.itemGroupQa.stashQuestion = this.itemGroupQa.question
      },
      // QA編集キャンセル
      cancelEditQuestion () {
        this.itemGroupQa.question = this.itemGroupQa.stashQuestion
        this.itemGroupQa.stashQuestion = ''
      },
      // QA更新
      async updateQuestion () {
        this.loadingStart();
        this.$_clearValidationErrors()
        const {data} = await this.$apollo
        .mutate({
          mutation: UpdateItemGroupQa,
          // Parameters
          variables: {
            input: {
              id: this.itemGroupQa.id,
              question: this.itemGroupQa.question
            }
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        // Result
        const errors = data.updateItemGroupQa.errors;
        if (errors) {
          this.$_parseError(errors)
        } else {
          this.$emit('updateQuestion', data.updateItemGroupQa.itemGroupQas);
        }
        this.loadingStop();
      },


      // 質問いいね更新
      toggleQuestionLike () {
        this.itemGroupQa.isLiked ? this.deleteItemGroupQaLike() : this.addItemGroupQaLike()
      },
      // 質問いいね登録
      async addItemGroupQaLike () {
        this.loadingStart();
        this.$_clearValidationErrors()
        const {data} = await this.$apollo
        .mutate({
          mutation: CreateItemGroupQaLike,
          // Parameters
          variables: {
            input: {
              itemGroupQaId: this.itemGroupQa.id
            }
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        // Result
        const errors = data.createItemGroupQaLike.errors;
        if (errors) {
          this.$_parseError(errors)
        } else {
          this.$emit('toggleQuestionLike', data.createItemGroupQaLike.itemGroupQas);
        }
        this.loadingStop();
      },
      // 質問いいね削除
      async deleteItemGroupQaLike () {
        this.loadingStart();
        this.$_clearValidationErrors()
        const {data} = await this.$apollo
        .mutate({
          mutation: DeleteItemGroupQaLike,
          // Parameters
          variables: {
            input: {
              itemGroupQaId: this.itemGroupQa.id
            }
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        // Result
        const errors = data.deleteItemGroupQaLike.errors;
        if (errors) {
          this.$_parseError(errors)
        } else {
          this.$emit('toggleQuestionLike', data.deleteItemGroupQaLike.itemGroupQas);
        }
        this.loadingStop();
      },


      // コメント追加
      addComment () {
        this.isOpen ? this.createComment() : this.open()
      },
      // コメント追加
      async createComment () {
        this.loadingStart();
        this.$_clearValidationErrors()
        const {data} = await this.$apollo
        .mutate({
          mutation: CreateItemGroupQaComment,
          // Parameters
          variables: {
            input: this.commentForm
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        // Result
        const errors = data.createItemGroupQaComment.errors;
        if (errors) {
          this.$_parseError(errors)
        } else {
          this.$emit('addComment', data.createItemGroupQaComment.itemGroupQas);
          this.commentForm.comment = ''
        }
        this.loadingStop();
      },
      // コメントいいね更新
      toggleCommentLike (itemGroupQas) {
        this.$emit('toggleCommentLike', itemGroupQas);
      },
      // コメント更新
      updateComment (itemGroupQas) {
        this.$emit('updateComment', itemGroupQas);
      },

      open () {
        if (this.isOpen) return;
        this.isOpen = true;
      },

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";

  .chat-comment {
    width: 100%;
    max-width: 570px;
    background: $white;
    border-radius: 4px;

    > .comment {
      border-bottom: 1px solid $gray-200;
      @include padding(md, md, xxs);

      > .info {
        display: flex;

        > .icon {
          width: 36px;
          min-width: 36px;
          height: 36px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
          @include margin-right(xxs);

          > svg {
            width: 100%;
            height: 100%;
          }
        }

        > .box {
          > .text {
            color: $black-400;
            line-height: 143%;
            @include font-size(xs);

            > a {
              color: $black-400;
              font-weight: bold;
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }
          }


          > .date {
            margin-left: 1px;
            color: $black-400;
            line-height: 167%;
            @include font-size(xxs);
          }
        }
      }

      > .text {
        color: $black-400;
        line-height: 143%;
        word-break: break-all;
        white-space: pre-wrap;
        @include font-size(xs);
        @include margin(xs, zero);

        > .edited {
          color: $black-100;
          @include font-size(xxs);
        }

      }

      > .menu {
        display: flex;
        color: $black-100;
        line-height: 167%;
        @include font-size(xxs);

        > .like, .cancel {
          display: flex;
          align-items: center;
          cursor: pointer;

          > svg {
            margin-top: 1px;
            margin-right: 3px;
            width: 13px;
            height: 13px;
          }

          > span {
            &.liked {
              color: $olive-300;
            }
          }
        }

        > .remark, .edit, .save {
          display: flex;
          align-items: center;
          cursor: pointer;
          @include margin-left(xs);

          &.is-open {
            cursor: default;
          }

          > svg {
            margin-right: 3px;
            width: 14px;
            height: 12px;
          }
        }

      }
    }

    > .remark {
      @include padding(sm, md);

      > .transition {
        > .list {
          @include padding(xxxs, zero, md);

          > .chat-remark {
            &:nth-child(n+2) {
              @include margin-top(sm);
            }
          }
        }

        > .form-message {
          @include margin-bottom(sm);
        }

        > .none {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-none;
          color: $black-400;
          @include margin-bottom(sm);
        }
      }

      > .button {
        margin: 0 auto;
      }
      > .notice {
        @include font-size(xxs);
        text-align: center;
        @include margin-top(xxxs);
      }
    }
  }
</style>
