<template lang="pug">
  .container
    AlertBase(v-if="validateErrors.length > 0" type="error")
      p(v-for="message of $_validationErrorMessage") {{message}}
    h1.my-md 商品レビュー
    .review-wrap
      .review-info
        p 商品をご購入頂きありがとうございます。お買上げ頂いた商品のご感想や商品を使ったレシピ、生産者の方へのメッセージなど、お客様の声を是非お聞かせください。
        a(href="https://essnu.notion.site/323c68c0b8c847d7b6a4a021c292e63e" target="_blank") レビューについて、詳しくはこちらをご覧ください。
        p.point 付与ポイント：
          span 本文1文字につき1ポイント(1円)※最大200ポイントまで
        ReviewAccordion
          template(slot="title")
            span レビュー投稿時のご注意
          template(slot="body")
            p ご入力いただいた文章や画像はサイト上で公開します。また、下記のようなレビューはポイント付与の対象から除外の上、掲載を控えさせて頂く場合がございます。 
            ul
              li 過度の単語の繰り返しやメッセージのコピーが含まれる場合。 
              li 公序良俗に反する内容が含まれる場合。
              li 他の会員、第三者又は当社を誹謗中傷する内容がある場合。
              li レビュー内、ニックネーム等に個人を特定できる情報が記入されている場合。
              li その他弊社が悪質であると判断した場合。
      .form_wrap
        .product
          .image
            img(:alt="orderItem.itemName" :src="orderItem.itemImage")
          .product-info
            .shop
              .logo
                img(:alt="orderItem.shopName" :src="orderItem.shopImage")
              p {{ orderItem.shopName }}
            p {{ orderItem.itemName }}
            p {{ orderItem.itemSize }}
        .form-wrap
          FormItem
            template(slot="title")
              FormLabel(type="required") ニックネーム
            template(slot="input")
              FormTextfield(
                v-model="form.author"
                type="text"
                placeholder="ニックネームを入力してください"
                :error="'author' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.author"
              )
          FormItem
            template(slot="title")
              FormLabel(type="required") 満足度  
            template(slot="input")
              StarRating(
                v-model="form.stars"
                :initialRating="form.stars"
                fillIcon="★"
                outlineIcon="☆"
                :error-message="$_validationErrorMessage.stars"
              )
          FormItem
            template(slot="title")
              FormLabel(type="required") タイトル
            template(slot="input")
              FormTextfield(
                v-model="form.title"
                type="text"
                placeholder="最も伝えたいことを教えてください"
                :error="'title' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.title"
              )
          .text
            FormItem
              template(slot="title")
                FormLabel(type="required") 本文
              template(slot="input")
                FormTextarea(
                  v-model="form.comment"
                  placeholder="ご購入の目的 / 購入の際に惹かれた点 / 味や質 / どんな料理に使ったか / どんな場面で召し上がったか / 商品を使ったレシピ / 生産者へ伝えたいこと などの感想を教えてください"
                  :error="'comment' in $_validationErrorMessage"
                  :error-message="$_validationErrorMessage.comment"
                )
            ReviewAccordion
              template(slot="title")
                span レビューの書き方に迷ったら
              template(slot="body")
                p 以下は、商品レビューを書く際のヒントです。ご感想を書く際にご参考ください。
                ul
                  li ご購入の目的やきっかけ
                  li 購入の際に惹かれたポイント
                  li 風味
                  li 質や新鮮さ
                  li どんな料理に使ったか 
                  li どんな場面で召し上がったか
                  li 商品を使ったレシピ
                  li 生産者へ伝えたいこと
          FormUploadImage.my-md(ref="uploader" v-model="uploadImages" @onDelete="deleteImage" :limit="5")
      .button-area
        p
          | たべるとくらすとの
          a.text-link(href="/terms/" target="_blank") 利用規約
          br
          a.text-link(href="/privacy/" target="_blank") プライバシーポリシー
          | に同意の上
        ButtonBase(@click="createOrderItemReview") {{ orderItem.hasReview ? 'レビューを更新する' : 'レビューを投稿する' }}
</template>

<script>
import { mapActions } from 'vuex'
import { GetOrderItemReview, CreateOrderItemReview } from '../../graphqls/OrderItems'
import GlobalMixin from '../../mixin/GlobalMixin'
import ContainerSheet from '../../components/v1/container/ContainerSheet'
import ButtonBase from '../../components/v1/button/ButtonBase'
import FormItem from '../../components/v1/form/FormItem'
import FormLabel from '../../components/v1/form/FormLabel'
import FormTextfield from '../../components/v1/form/FormTextfield'
import FormTextarea from '../../components/v1/form/FormTextarea'
import FormUploadImage from '../../components/v1/form/FormUploadImage'
import StarRating from '../../components/v1/form/StarRating'
import AlertBase from '../../components/v1/alert/AlertBase'
import ReviewAccordion from '../../components/v1/review/ReviewAccordion'

export default {
  name: 'ReviewsShow',
  mixins: [GlobalMixin],
  components: {
    ContainerSheet,
    ButtonBase,
    FormItem,
    FormLabel,
    FormTextarea,
    FormTextfield,
    FormUploadImage,
    StarRating,
    AlertBase,
    ReviewAccordion,
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: this.$_itemListElement
        }
      }]
    }
  },
  data() {
    return {
      orderItem: {
        itemImage: '',
        itemName: '',
        itemSize: '',
        shopImage: '',
        shopName: '',
        hasReview: false,
      },
      form: {
        author: '',
        stars: 0,
        title: '',
        comment: '',
        deleteImageIds: [],
        uploadImageIds: [],
      },
      uploadImages: [],
    }
  },
  async mounted() {
    this.loadingStart()
    const { data } = await this.$apollo
    .query({
      query: GetOrderItemReview,
      variables: {
        orderItemId: this.$route.params.id,
      },
      client: 'apiClient'
    })
    .catch(error => {
      const gqError = error.graphQLErrors[0];
      if (gqError?.extensions?.code === '404') {
        // 注文商品が存在しない場合
        // エラーメッセージを格納して注文履歴画面に遷移
        this.setFlashMessage({
          variant: 'error',
          messages: ['該当の注文商品が見つかりませんでした。'],
        })
        this.$router.push({ name: 'mypage-purchase-history' })
        return
      }
      return this.$_redirect_internal_server_error(error)
    })

    this.orderItem.itemImage = data.orderItem.itemGroup.listImageOrItemGroupImageSet.smSqImage
    this.orderItem.itemName = data.orderItem.name
    this.orderItem.itemSize = data.orderItem.size
    this.orderItem.shopImage = data.orderItem.adminShop.logoImageSet.xsSqImage
    this.orderItem.shopName = data.orderItem.adminShop.name
    this.orderItem.hasReview = data.orderItem.review?.postedAt ? true : false

    if (this.orderItem.hasReview) {
      this.form.author = data.orderItem.review?.author
      this.form.stars = data.orderItem.review?.stars
      this.form.title = data.orderItem.review?.title
      this.form.comment = data.orderItem.review?.comment
      const reviewImages = data.orderItem.review?.reviewImages ?? []
      reviewImages.sort((a, b) => a.rowOrder - b.rowOrder);
      for (const reviewImage of reviewImages) {
        this.uploadImages.push({ id: reviewImage.id, path: reviewImage.imageSet.smSqImage })
      }
    } else {
      this.form.author = data.orderItem.order.user.nickname ?? ''
    }

    // 画面アップロードを開く
    this.$refs.uploader.openUploader()
    // パンくずリストを設定
    this.setBreadcrumbs({ breadcrumbs: [
      {
        path: '/',
        name: 'TOP',
      },
      {
        path: '/mypage/',
        name: 'マイページ',
      },
      {
        path: '/mypage/purchasehistory/',
        name: '注文履歴',
      },
      {
        path: `/mypage/review/${this.$route.params.id}/`,
        name: '商品レビュー',
      },
    ]})
    this.loadingStop()
  },
  methods: {
    ...mapActions([
      'setFlashMessage',
    ]),
    async createOrderItemReview() {
      this.loadingStart()
      this.$_clearValidationErrors()

      this.form.orderItemId = this.$route.params.id
      this.form.uploadImageIds = this.uploadImages.map(image => image.id)
      const uploadImageFiles = this.uploadImages.map(image => image.file)
      for (let i = 0; i < 5; i++) {
        this.form[`image${i + 1}`] = uploadImageFiles[i] || ''
      }

      const { data } = await this.$apollo
      .mutate({
        mutation: CreateOrderItemReview,
        variables: {
          input: this.form
        },
        client: 'apiClient'
      })
      .catch(error => {
        const gqError = error.graphQLErrors[0];
        if (gqError?.extensions?.code === '404') {
          // 注文商品が存在しないまたはレビュー作成できない場合
          // エラーメッセージを格納して注文履歴画面に遷移
          this.setFlashMessage({
            variant: 'error',
            messages: ['該当の注文商品が見つかりませんでした。'],
          })
          this.$router.push({ name: 'mypage-purchase-history' })
          return
        }
        return this.$_redirect_internal_server_error(error)
      });

      // バリデーションエラーが発生した場合
      const errors = data.createOrderItemReview.errors
      if (errors) {
        this.$_parseError(errors)
        this.$scrollTo("body")
        this.loadingStop()
        return
      }

      // 成功メッセージを格納して注文履歴画面に遷移
      this.setFlashMessage({
        variant: 'success',
        messages: ['ありがとうございました。レビューの投稿が完了しました。'],
      })
      this.$router.push({ name: 'mypage-purchase-history' })
      this.loadingStop()
    },

    /**
     * 削除する画像のIDを配列に格納する
     */
    deleteImage(deleteImage) {
      if (deleteImage.id) {
        this.form.deleteImageIds.push(deleteImage.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/stylesheets/v1/styles";
.container {
  font-family: $font-family-sans;
  font-weight: $font-weight-regular;
  font-style: $font-style-not-italic;
  font-size: 14px;
  color: $black-400;
  line-height: $line-height-snug;
  margin: auto;
    .info-accordion {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 4px;
      background: $gray-100;
      padding: 8px;
      justify-content: center;
      align-self: stretch;
      color: $black-400;
    > .title {
      font-weight: $font-weight-bold;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      > .svg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        > svg {
        height: auto;
          > path {
            width: 100%;
            height: 100%;
          }
        }
      }
      > .label {
        color: $black-400;
      }
    }
    > .details {
      margin-top: 8px;
      > ul {
        margin-left: 16px;
        > li {
        list-style: disc;
        margin-bottom: 4px;
        }
      }
    }
  }
  > h1 {
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    @include font-size(md);
    text-align: center;
  }
  > .review-wrap {
    background: $ivory-100;
    @include padding(xs);
    display: flex;
    flex-direction: column;
    align-items: center;
    > .review-info {
        background: $white;
        padding: 16px;
        width: 100%;
        @include margin-bottom(xs);
        > a {
          color: $olive-300;
          text-decoration: underline;
          line-height: $line-height-snug;
        }
        > p.point {
          @include margin-top(xxs);
          @include margin-bottom(xxs);
          span {
            color: $red-300;
          }
        }
    }
    > .form_wrap {
      background: $white;
      width: 100%;
      > .product {
        display: flex;
        padding: 24px 16px;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        font-family: $font-family-serif;
        border-bottom: 1px solid $ivory-100;
        > .image {
          width: 96px;
          height: 96px;
          > img {
            width: 96px;
            height: 96px;
          }
        }
        > .product-info{
          line-height: $line-height-tight;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          p {
            line-height: $line-height-tight;
          }
          > .shop {
            display: flex;
            align-items: flex-start;
            gap: 4px;
            align-items: center;
            align-self: stretch;
            > .logo {
              width: 18px;
              height: 18px;
              > img {
                width: 18px;
                height: 18px;
              }
            }
            > p {
              font-size: 12px;
            }
          }
        }
      }
      > .form-wrap {
        display: flex;
        padding: 0px 16px;
        flex-direction: column;
        align-self: stretch;
        > .text {
          > .form-item {
            margin-bottom: 8px;
          }
        }
        > .form-item {
          margin-bottom: 8px;
        }
      }
    }
    > .button-area {
      display: flex;
      padding: 24px 0;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      > p {
        text-align: center;
        > a {
          color: $olive-300;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>