import gql from 'graphql-tag'

export const GetItemGroupReviews = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  reviews (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {	
    items {
      id
      user {
        id
        email
        name
        prefecture
      }
      adminShop {
        id
        name
        code
        profileImageSet {
          smSqImage
        }
      }
      displayAuthor
      itemGroupId
      title
      author
      stars
      comment
      replyComment
      reviewImages {
        id
        imageSet {
          xsImage
          smImage
        }
      }
      starImgUrl
      postedAt
    }
    pageInfo {
      currentPage
      totalCount
      perPage
    }
  }
}
`

export const GetReviewPageUrls = gql`
query($itemGroupId: ID, $itemGroupCode: String) {
  reviewPageUrls(itemGroupId: $itemGroupId, itemGroupCode: $itemGroupCode) {
    items {
      url
    }
  }
}
`


export const GetReviews = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  reviews (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      displayAuthor
      title
      author
      stars
      comment
      starImgUrl
      postedAt
      user {
        id
        name
        prefecture
      }
      adminShop {
        id
        name
        code
        profileImageSet {
          smSqImage
        }
      }
      itemGroupId
      itemGroup {
        id
        name
        code
      }
      replyComment
      thumbnailReviewImageUrl
    }
    pageInfo {
      currentPage
      totalCount
      perPage
    }
  }
}
`

