<template lang="pug">
  component.button-base(
    :is="type"
    @click="click()"
    :class="classes"
    :disabled="disabled"
    :to="to"
    :href="href"
  )
    slot
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  export default {
    name: 'ButtonBase',
    mixins: [GlobalMixin],
    props: {
      variant: {
        type: String,
        required: false,
        default: 'primary',
        validator: function (value) {
          return ['primary', 'normal', 'outlined', 'delete', 'amazon', 'yahoo', 'facebook', 'google'].includes(value)
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      to: {
        type: Object,
        required: false,
        default: undefined
      },
      href: {
        type: String,
        required: false,
        default: undefined,
      },
      block: {
        type: Boolean,
        required: false,
        default: false
      },
      size: {
        type: String,
        required: false,
        default: 'medium',
        validator: function (value) {
          return ['medium', 'small', 'inline'].includes(value)
        }
      },
      eventAction: {
        type: String,
        required: false,
        default: ''
      },
      eventCategory: {
        type: String,
        required: false,
        default: ''
      },
    },
    data() {
      return {
      }
    },
    computed: {
      type: function () {
        if (this.to) return 'router-link'
        if (this.href) return 'a'
        return 'button'
      },
      classes: function () {
        return [
          `-${this.variant}`,
          {'-disabled': this.disabled},
          {'-block': this.block},
          `-${this.type}`,
          `-${this.size}`
        ]
      }
    },
    methods: {
      click: function (e) {
        if (this.disabled) {
          e.preventDefault()
        } else {
          this.$emit('click', e)
          this.$_gaSendEvent(this.eventAction, this.eventCategory, this.$slots.default[0].text)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .button-base {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.-button {
      display: inline-block; // iPhone6sでbuttonタグにflexboxが使えない対応
    }

    padding: 0 1em;
    border-radius: 3px;
    border: none;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.1s;

    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    line-height: $line-height-none;

    // size variation
    &.-medium {
      height: 40px;
      min-width: 180px;
      @include font-size(sm);
      @include mq-down(md) {
        width: 100%;
      }
    }

    &.-small {
      height: 32px;
      min-width: 110px;
      @include font-size(xxs);
    }

    &.-inline {
      height: auto;
      min-width: auto;
      @include font-size(xs);
      @include padding(xxs, xs);

      > .icon-base {
        height: 14px !important;
        width: 14px !important;
        @include mq-down(md) {
          height: 12px !important;
          width: 12px !important;
        }
      }
    }
    

    // variant variation
    &.-primary {
      background: $olive-300;
      color: $white;
      border: 1px solid $olive-300;

      &.-disabled {
        background: $olive-100;
        border-color: $olive-100;
        cursor: not-allowed;
      }

      &:hover:not(.-disabled) {
        background: $olive-400;
        border-color: $olive-400;
      }
    }

    &.-normal {
      background: $white;
      color: $black-400;
      border: 1px solid $gray-400;

      &.-disabled {
        background: $gray-100;
        color: $black-100;
        border: 1px solid $gray-300;
        cursor: not-allowed;
      }

      &:hover:not(.-disabled) {
        background: $gray-100;
      }
    }

    &.-outlined {
      background: $white;
      color: $olive-300;
      border: 1px solid $olive-300;

      &:hover {
        background-color: $olive-100;
      }
    }

    &.-delete {
      background: $white;
      color: $red-300;
      border: 1px solid $red-300;

      &.-disabled {
        color: $red-200;
        border: 1px solid $red-200;
        cursor: not-allowed;
      }

      &:hover:not(.-disabled) {
        background-color: $red-100;
      }
    }

    &.-amazon {
      background-color: #febd68;
      color: $black-400;

      &:hover {
        background-color: #fea735;
      }
    }

    &.-yahoo {
      background-color: #ff0033;
      color: $white;

      &:hover {
      }
      img {
        width: 36px;
        margin-right: 8px;
      }
    }
    &.-google {
      background-color: #dd4b39;
      color: $white;

      &:hover {
        background-color: #c23321;
      }
    }

    &.-facebook {
      background-color: #315096;
      color: $white;

      &:hover {
        background-color: #243b70;
      }
    }

    &.-block {
      width: 100%;
    }

    > .icon-base, > i {
      @include margin-right(xxxs);
    }
  }
</style>
