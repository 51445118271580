<template lang="pug">
  .chat-item
    .avatar-area
      .avatar(v-if="isShowAvatar" :style="{backgroundImage: `url('${avatarImage}')`}")
        UserIconDefault(v-if="!avatarImage")
    .comment-area
      .name(v-if="isShowAvatar") {{ avatarName }}
      .balloon(v-if="commentText")
        .wrapper
          .text
            VueMarkdown.markdown(:source="commentText")
          .menu
            .elapsedTime {{ elapsedTime }}
      .comment-image(v-if="commentImage")
        ImagePopup(:src="commentImage" width="100%")
        .elapsedTime {{ elapsedTime }}
</template>

<script>
  import UserIconDefault from '../../svg/UserIconDefault'
  import ImagePopup from '../image/ImagePopup'
  import VueMarkdown from 'vue-markdown'


  export default {
    name: 'ChatItem',
    components: {
      UserIconDefault,
      ImagePopup,
      VueMarkdown,
    },
    props: {
      isShowAvatar: {
        type: Boolean,
        required: false,
        default: true
      },
      avatarImage: {
        type: String,
        required: false,
        default: ''
      },
      avatarName: {
        type: String,
        required: true,
        default: ''
      },
      commentText: {
        type: String,
        required: false,
        default: ''
      },
      commentImage: {
        type: String,
        required: false,
        default: ''
      },
      elapsedTime: {
        type: String,
        required: true,
        default: ''
      },
    },
    data() {
      return {
        isShowPopup: false
      }
    },
    methods: {
      toggleImage() {
        this.isShowPopup = !this.isShowPopup
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";

  .chat-item {
    display: flex;

    > .avatar-area {
      width: 36px;
      min-width: 36px;
      @include margin-right(xxs);

      > .avatar {
        width: 36px;
        min-width: 36px;
        height: 36px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;

        > svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    > .comment-area {
      flex-grow: 1;

      > .name {
        color: $black-400;
        font-weight: bold;
        line-height: 167%;
        @include font-size(xxs);
        @include margin-left(xxs);
      }

      > .balloon {
        position: relative;

        &:before,
        &:after {
          content: "";
          position: absolute;
          top:9px;
          left: -8px;
          border: 16px solid transparent;
        }

        &:before {
          border-top-width: 14px;
          border-top-color: $gray-200;
          z-index: 0;
        }

        &:after {
          border-top-width: 3px;
          border-top-color: $white;
          z-index: 1;
        }

        > .wrapper {
          position: relative;
          background: $gray-200;
          border-radius: 18px;
          z-index: 2;
          @include padding(xs);

          > .text {
            color: $black-400;
            line-height: 150%;
            word-break: break-all;
            //white-space: pre-wrap;
            @include font-size(xs);

            .edited {
              color: $black-100;
              @include font-size(xxs);
            }
          }

          > .menu {
            display: flex;
            color: $black-100;
            line-height: 200%;
            @include font-size(xxxs);

            > .like, .edit, .save, .cancel, .delete {
              display: flex;
              align-items: center;
              cursor: pointer;
              @include margin-left(xxxs);

              > svg {
                width: 11px;
                height: 11px;
                margin-top: .5px;
                margin-right: 2px;
              }

              > span {
                &.liked {
                  color: $olive-400;
                }
              }
            }
          }
        }
      }

      > .comment-image {
        > .elapsedTime {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xxxs);
          line-height: $line-height-relaxed;
          color: $black-100;
        }
      }
    }
  }
</style>
