import "core-js/stable"
import "regenerator-runtime/runtime"
import 'isomorphic-unfetch'

import Vue from 'vue'
import App from '../v1_app.vue'
import { router } from './router/router'
import store from './store'
import { config } from './config/config'

// Apollo
import VueApollo from 'vue-apollo'
import {apolloApiClient, apolloClient} from './config/apollo'
const apolloProvider = new VueApollo({
  clients: {
    default: apolloClient,
    apiClient: apolloApiClient
  },
  defaultClient: apolloClient,
})
Vue.use(VueApollo)
// Apollo

// moment
import moment from 'vue-moment'
Vue.use(moment)
// moment

// google analytics
import VueGtag from "vue-gtag"
Vue.use(VueGtag, {
  // pageTrackerTemplate(to) {
  //   return {
  //     page_title: to.meta.title || to.name,
  //     page_path: to.path
  //   }
  // },
  config: {
    id: config.google_analytics_id,
    params: {
      send_page_view: false
    }
  }
})
// google analytics

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

// VueScrollTo
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 300,
  easing: 'ease',
  offset: 0,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
})
// VueScrollTo

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueIziToast from 'vue-izitoast'
import 'izitoast/dist/css/iziToast.min.css'
Vue.use(VueIziToast)

//// Sentry
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
// Sentry.init({
//   Vue,
//   dsn: config.sentry_dsn,
//   integrations: [new Integrations.BrowserTracing()],
//   environment: process.env.RAILS_ENV,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
//// Sentry

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#v1-app',
    router,
    apolloProvider,
    store,
    // i18n: i18n,
    render: h => h(App)
  })
})
