<template>
  <svg width="18" height="23" viewBox="0 0 18 23" fill="#4B4B4B" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 10.651V9.26175H14.8235H14.521L14.8235 5.71141V5.09396L14.521 4.78523L9.83193 0.61745L8.92437 0L8.16807 0.61745L3.47899 4.78523L3.02521 5.09396V5.71141L3.32773 8.95302V9.26175H0V10.4966H3.63025L3.78151 11.4228V11.5772H0V12.5034H3.78151L3.93277 13.4295V13.5839H0V14.8188H4.08403L4.38655 18.0604V18.5235L4.68908 18.8322L8.01681 22.0738L8.92437 22.8456L9.83193 22.0738L13.1597 18.8322L13.6134 18.5235V18.0604L13.916 14.8188H18V13.5839H14.3697H14.0672L14.2185 12.5034H18V11.5772H14.6723H14.3697L14.521 10.4966L18 10.651ZM13.0084 11.5772V12.6577L12.8571 13.5839L12.7059 14.9732L12.4034 18.0604L9.07563 21.302L5.7479 18.0604L5.44538 14.9732L5.14286 13.5839L4.9916 12.6577V11.5772L4.84034 10.651L4.68908 9.26175L4.23529 5.71141L8.92437 1.69799L13.6134 5.71141L13.3109 9.26175L13.1597 10.651L13.0084 11.5772Z" fill="#4B4B4B"/>
    <path d="M8.9243 2.77832L5.74783 5.55684L5.44531 5.71121L6.35288 6.63738L7.10918 7.25483V6.17429H7.86548V8.02664L8.62178 8.95282V6.17429H9.37809V7.71792V8.95282L10.1344 8.02664V6.17429H10.8907V6.79174V7.25483L12.4033 5.86557L12.5546 5.71121L8.9243 2.77832Z"/>
    <path d="M8.92432 15.1274L6.50415 17.5972L8.92432 20.2214L10.8907 18.3691L11.4957 17.5972L8.92432 15.1274Z" fill="#4B4B4B"/>
    <path d="M8.16802 10.6511L7.10919 11.5773L6.50415 12.1948L6.80667 12.6579L7.86549 13.584L8.6218 14.3558V13.584V12.6579V11.5773V10.6511V10.3424V10.0337L8.16802 10.6511Z"/>
    <path d="M9.83196 10.6511L9.52943 10.3424L9.37817 10.0337V10.6511V11.5773V12.6579V13.584V14.3558L10.1345 13.584L11.4958 12.1948L10.8908 11.5773L9.83196 10.6511Z"/>
  </svg>
</template>
<script>
  export default {
    name: "noshi-svg"
  }
</script>
