<template lang="pug">
  i.icon-base(:class="color" :style="{width: size + 'px', height: size + 'px'}")
    slot
</template>
<script>
  export default {
    name: "icon-base",
    props: {
      color: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: '16'
      }
    }
  }
</script>
<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .icon-base {
    display: inline-flex;

    > svg {
      width: 100%;
      height: auto;
    }
    @each $name, $color in $colors {
      &.#{$name} {
        > svg {
          path:not(.not-change),
          rect:not(.not-change) {
            fill: $color;
          }
        }
      }
    }
  }
</style>
