<template lang="pug">
  label.form-radio
    input.input(type="radio" v-model="localValue" :value="id" :disabled="disabled")
    span.label(v-if="label") {{ label }}
</template>

<script>
  export default {
    name: 'FormRadio',
    props: {
      id: {
        type: String,
        required: true,
        default: undefined
      },
      value: {
        type: String,
        required: true,
        default: undefined
      },
      label: {
        type: String,
        required: true,
        default: undefined
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
      }
    },
    computed: {
      localValue: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
          this.$emit('change', newValue)
        }
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .form-radio {
    display: inline-flex;
    align-items: center;
    height: 24px;
    cursor: pointer;

    > .input {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      overflow: hidden;
      pointer-events: none;
      border: none;
      outline: none;
      appearance: none;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: $white;
        border: 1px solid $gray-400;
        box-sizing: border-box;
        border-radius: 50%;
      }

      &:checked {
        &:after {
          content: "";
          position: absolute;
          top: 6px;
          left: 6px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $olive-300;
        }
      }
    }

    > .label {
      margin-left: 0.5em;
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(sm);
      line-height: $line-height-none;
      color: $black-400;
    }
  }
</style>
