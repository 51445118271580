<template lang="pug">
.review-item
  .user
    .icon
      UserIconDefault
    span.name
      | {{ review.displayAuthor }}さん
      template(v-if="review.user") {{ review.user.prefecture }}
  .comment
    .inline
      span.time(v-if="review.postedAt") {{ review.postedAt | moment('YYYY/MM/DD') }} 投稿
      img(:src="review.starImgUrl", height="13")
    p.title {{ review.title }}
    .texts
      p.text {{ formatMessage(review.comment) }}
    .images(v-if="review.reviewImages.length > 0 && isReadMore")
      template(v-for="reviewImage in review.reviewImages")
        ImagePopup(:src="reviewImage.imageSet.xsImage", :width="150")
  .reply(v-if="review.replyComment")
    .user
      .icon
        img.image(:src="review.adminShop.profileImageSet.smSqImage")
      span.name {{ review.adminShop.name }} (生産者)さんの返信
    .comment
      .texts
        p.text {{ formatMessage(review.replyComment, 50) }}
  .item(v-if="review.item")
    a.imagewrap(:href="review.item.href")
      img.image(:src="review.item.src")
    .content
      span.text {{ review.item.company }}
      a.title(href="#") {{ review.item.title }}
      span.text {{ review.item.text }}
  .expand(v-if="!isReadMore", @click="toggleReadMore")
    include ../../../../../assets/images/icon/angle-down-solid.svg
    span.label もっとみる
</template>

<script>
import UserIconDefault from "../../../components/svg/UserIconDefault";
import ImagePopup from "../../v1/image/ImagePopup";

export default {
  name: "ReviewItem",
  components: {
    UserIconDefault,
    ImagePopup,
  },
  props: {
    review: {
      type: Object,
      required: true,
      default: () => {},
    },
    readMore: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isReadMore: false,
    };
  },
  mounted() {
    this.isReadMore =
      this.readMore ||
      (this.review.comment.length < 140 &&
        this.review.reviewImages.length === 0 &&
        !this.review.replyComment);
  },
  methods: {
    toggleReadMore() {
      this.isReadMore = !this.isReadMore;
    },
    formatMessage(message, maxLength = 100) {
      if (this.isReadMore) {
        return message;
      }
      return message.length > maxLength
        ? message.substr(0, maxLength) + "..."
        : message;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";

.review-item {
  border: 1px solid $gray-200;
  border-radius: 5px;
  @include padding(sm);
  position: relative;

  .user {
    display: flex;
    align-items: center;

    > .icon {
      width: 34px;
      height: 34px;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;

      > svg {
        width: auto;
        height: 100%;
      }

      > .image {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
      }
    }

    > .name {
      display: inline-block;
      font-family: $font-family-sans;
      font-weight: $font-weight-bold;
      font-style: $font-style-not-italic;
      @include font-size(xs);
      line-height: $line-height-none;
      color: $black-400;
    }
  }

  .comment {
    @include margin-top(xxxs);

    > .inline {
      display: flex;
      align-items: center;

      > .time {
        display: inline-block;
        @include margin-right(xxxs);
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xs);
        line-height: $line-height-normal;
        color: $black-400;
      }
    }

    > .images {
      display: flex;
      flex-wrap: wrap;
      > .image-popup {
        @include margin-top(xxs);
        @include margin-right(xxs);
      }
    }

    > .title {
      @include margin-top(xxxs);
      font-family: $font-family-sans;
      font-weight: $font-weight-bold;
      font-style: $font-style-not-italic;
      @include font-size(sm);
      line-height: $line-height-normal;
      color: $black-400;
    }

    > .texts {
      @include margin-top(xxxs);
      > .text {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xs);
        line-height: $line-height-normal;
        color: $black-400;
      }
    }
  }

  > .reply {
    border-radius: 5px;
    @include padding(sm);
    @include margin-top(xs);
    background-color: $ivory-100;
  }

  > .item {
    display: flex;
    @include margin-top(xs);
    @include padding-top(xs);
    border-top: 1px solid #f1f0ec;

    > .imagewrap {
      overflow: hidden;
      width: 64px;
      height: 64px;
      position: relative;

      > .image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }

    > .content {
      @include padding-left(md);

      > .text {
        font-family: $font-family-sans;
        line-height: $line-height-tight;
        display: block;
        @include font-size(xs);
        color: $black-400;
      }

      > .title {
        font-family: $font-family-serif;
        line-height: $line-height-tight;
        display: block;
        @include font-size(sm);
        text-decoration: underline;
        @include padding-bottom(xxxs);
        color: $black-400;

        &:hover {
          color: $olive-300;
        }
      }
    }
  }

  > .expand {
    height: 50px;
    width: 100%;
    // @include padding(xs);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), $white);
    > svg {
      width: 14px;
      height: auto;
      > path {
        color: $black-200;
      }
    }
    > .label {
      margin-left: 4px;
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(xs);
      line-height: $line-height-normal;
      color: $black-400;
    }
  }
}
</style>
