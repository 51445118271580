<template lang="pug">
  section.section-menu
    HeadingGift.mb-sm
      h2 
        span.sub {{ giftPageBody.subTitle }}
        br 
        | {{ giftPageBody.title }}
    .comment
      ContainerBrackets.mb-sm
        p {{ giftPageBody.description }}
      VueMarkdown.notice.mb-sm(:source="giftPageBody.noticeMessage")
    .buttons
      template(v-for="giftPageBody in giftPageBodies")
        .button-item(v-if="giftPageBody.bodyType === 'ranking'")
          ButtonBase(@click="$scrollTo('#ranking', {offset: -70})" :block="true" eventAction="人気ランキングへページ内遷移" eventCategory="ギフトページ") ランキングから選ぶ
        .button-item(v-else-if="giftPageBody.bodyType === 'budget'")
          ButtonBase(@click="$scrollTo('#budget', {offset: -70})" :block="true" eventAction="予算別へページ内遷移" eventCategory="ギフトページ") 価格から選ぶ
        .button-item(v-else-if="giftPageBody.bodyType === 'category'")
          ButtonBase(@click="$scrollTo('#category', {offset: -70})" :block="true" eventAction="人気カテゴリへページ内遷移" eventCategory="ギフトページ") カテゴリから選ぶ
        .button-item(v-else-if="giftPageBody.bodyType === 'recommend'")
          ButtonBase(@click="$scrollTo('#recommend', {offset: -70})" :block="true" eventAction="スタッフおすすめへページ内遷移" eventCategory="ギフトページ") おすすめから選ぶ
</template>

<script>
  import HeadingGift from '../heading/HeadingGift'
  import ContainerBrackets from '../container/ContainerBrackets'
  import ButtonBase from '../button/ButtonBase'
  import VueMarkdown from 'vue-markdown'

  export default {
    name: 'SectionMenu',
    components: {
      HeadingGift,
      ContainerBrackets,
      ButtonBase,
      VueMarkdown
    },
    props: {
      giftPageBody: {
        type: Object,
        required: true,
        default: function() {
          return {}
        }
      },
      giftPageBodies: {
        type: Array,
        required: true,
        default: []
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-menu {
    .comment {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      text-align: center;
      .notice {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xs);
        line-height: $line-height-normal;
        color: $black-400;
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      .button-item {
        @include margin-top(xs);
      }
      @include mq-up() {
        flex-direction: row;
        flex-wrap: wrap;
        .button-item {
          width: 50%;
          @include padding-left(xs);
          @include padding-right(xs);
        }
      }
    }
  }
</style>
