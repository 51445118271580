<template lang="pug">
  component(:is="component")
    slot
</template>

<script>
  export default {
    name: "common-heading",
    props: {
      size: {
        type: Number,
        default: 1
      }
    },
    computed: {
      component() {
        let size = 1;
        if (this.size < 2) size = 1;
        else if (this.size < 3) size = 2;
        else if (this.size >= 3) size = 3;

        return `h${size}`;
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  h1,
  h2,
  h3 {
    font-family: 'Noto Serif JP', serif;
    margin: 0;
    line-height: 1.3;
  }

  h1 {
    @include font-size(lg);
  }

  h2 {
    @include font-size(md);
  }

  h3 {
    @include font-size(sm);
  }
</style>
