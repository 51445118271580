<template lang="pug">
  transition(name="transition" @enter="enter" @leave="leave" @after-enter="afterEnter" @after-leave="afterLeave")
    div(v-show="isOpen")
      slot
</template>

<script>
  export default {
    name: "transition-block",
    props: {
      isOpen: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      nextFrame(func) {
        window.requestAnimationFrame(() => window.requestAnimationFrame(func));
      },
      enter(el) {
        el.style.overflow = 'hidden';
        el.style.height = '0';

        this.nextFrame(() => {
          el.style.height = `${el.scrollHeight}px`;
        })
      },
      leave(el) {
        el.style.overflow = 'hidden';
        el.style.height = `${el.scrollHeight}px`;

        this.nextFrame(() => {
          el.style.height = '0';
        })
      },
      afterEnter(el) {
        el.style.height = '';
        el.style.overflow = '';
      },
      afterLeave(el) {
        el.style.height = '';
        el.style.overflow = '';
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .transition-enter-active,
  .transition-leave-active {
    transition: height 300ms ease;
  }
</style>
