<template lang="pug">
  IconBase
    PlusSvg
</template>
<script>
  import IconBase from './IconBase'
  import PlusSvg from "../svg/PlusSvg";
  export default {
    name: "plus-icon",
    components: {
      IconBase,
      PlusSvg
    }
  }
</script>
