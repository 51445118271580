import gql from 'graphql-tag'
import {Errors} from "./Common"

export const CreateContactComment = gql`
mutation($input: CreateContactCommentInput!) {
  createContactComment(input: $input) {
    contactComments {
      id
      userId
      adminUserId
      comment
      image
      imageUrl
      elapsedTime
      user {
        nickname
        name
      }
      adminUser {
        adminShop {
          name
          logoImageSet {
            smSqImage
          }
        }
      }
      contactId
    }
    errors {
      message
    }
  }
}
`
