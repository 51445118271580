<template lang="pug">
  section.section-shop-post(v-if="posts.length > 0")
    HeadingRoof.mb-md(title="生産者のお便りとお知らせ" subtitle="Information")
    .post-list
      .item(v-for="post in posts" :key="post.id")
        PostCard(:post="post")
</template>

<script>
  import { GetPosts } from '../../../graphqls/Posts'
  import HeadingRoof from '../heading/HeadingRoof'
  import PostCard from '../post/PostCard'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'SectionShopPost',
    components: {
      HeadingRoof,
      PostCard,
      ButtonBase
    },
    data() {
      return {
        posts: []
      }
    },
    mounted () {
      this.getPosts()
    },
    methods: {
      async getPosts() {
        const { data } = await this.$apollo
        .query({
          query: GetPosts,
          variables: {
            perPage: 10 , orderBy: 'published_start_at desc',
            search: JSON.stringify({shop_published_order_lteq: 2}),
          },
          client: 'apiClient'
        })
        this.posts = data.posts.items
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .section-shop-post {
    > .post-list {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 768px) {
          .item:nth-child(odd) {
            margin-right: 40px;
          }
        }
      > .item {
        width: calc(50% - 20px);
        @media (max-width: 768px) {
        width: 100%;
        }
      }
    }
  }
</style>