<template lang="pug">
  .heading-gift
    .ribbon-top
    .heading
      slot
    .ribbon-bottom
</template>

<script>
  export default {
    name: 'HeadingGift'
  }
</script>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";
    .general .heading-gift  {
      text-align: center;
      position: relative;
      > .heading {
        @include margin-top(xs);
        @include margin-bottom(sm);
        padding-bottom: 16px;
        display: inline-block;
        position: relative;
  
        > h1, > h2 {
          font-family: $font-family-serif;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(md);
          line-height: $line-height-snug;
          color: $black-400;
          text-align: center;
  
          > .sub {
            @include font-size(sm);
          }
        }
      }
      .heading::after {
        content: "";
        display: block;
        margin: 16px auto 0;
        width: 32px;
        height: 1px;
        background-color: $olive-400;
      }
    }
    .gift .heading-gift  {
      > .ribbon-top {
        height: 6px;
        background-image: url(https://cdn.taberutokurasuto.com/image/gift/ribbon_bg4.gif);
        background-size: 6px auto;
        background-position: center center;
        background-repeat: repeat-x;
      }
  
      > .heading {
        @include margin-top(xs);
        @include margin-bottom(xxs);
  
        > h1, > h2 {
          font-family: $font-family-serif;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(md);
          line-height: $line-height-snug;
          color: $black-400;
          text-align: center;
  
          > .sub {
            @include font-size(sm);
          }
        }
      }
  
      > .ribbon-bottom {
        height: 40px;
        background-image: url(https://cdn.taberutokurasuto.com/image/gift/ribbon_bg2.gif);
        background-size: cover;
        background-position: center center;
        @include mq-up() {
          height: 50px;
        }
      }
    }
</style>
