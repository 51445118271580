<template lang="pug">
  h2.heading-article
    slot
</template>

<script>
  export default {
    name: 'HeadingArticle',
    props: {
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .heading-article {
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    @include font-size(md);
    line-height: $line-height-snug;
    color: $black-400;
    border-bottom: 3px solid $gray-200;
    padding-bottom: 5px;
  }
</style>
