<template lang="pug">
  section.section-item-common
    HeadingRoof.mb-xs(:title="itemTagName" :subtitle="itemTagSubTitle")
    .text-center.mb-md
      a.anker-link(:href="`/shops/items/tag/${itemTagCode}/`" @click="$_gaSendEvent('商品一覧へ遷移', 'トップタグ', itemTagName)")
          | すべて見る
          include ../../../../../assets/images/icon/arrow-right.svg
    ItemCardList
      .item(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" eventCategory="トップタグ" :pageContents="`タグ ${itemTagName}`")
    //- .text-center
    //-   ButtonBase(href="/shops/items/new/" eventAction="商品一覧へ遷移" eventCategory="トップ新着") 新着商品一覧
</template>

<script>
import {GetTagItemGroupsForApi} from '../../../graphqls/ItemGroups'
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import HeadingRoof from '../heading/HeadingRoof'
  import ItemCard from '../item/ItemCardForApi'
  import ItemCardList from '../item/ItemCardList'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'SectionItemCommon',
    mixins: [GlobalMixin],
    components: {
      HeadingRoof,
      ItemCard,
      ItemCardList,
      ButtonBase,
    },
    props: {
      itemTagName: {
        type: String,
        required: true,
        default: ''
      },
      itemTagSubTitle: {
        type: String,
        required: false,
        default: ''
      },
      itemTagCode: {
        type: String,
        required: false,
        default: ''
      },
      itemTagId: {
        type: String,
        required: false,
        default: ''
      },
      // itemGroups: {
      //   type: Array,
      //   required: true,
      //   default: []
      // },
    },
    data() {
      return {
        itemGroups: []
      }
    },
    mounted() {
      for (let i = 0; i < 4; i++) {
        this.itemGroups.push({id: i})
      }
      this.fetchItemTagItemGroups()
    },
    methods: {
      async fetchItemTagItemGroups() {
        const { data } = await this.$apollo
        .query({
          query: GetTagItemGroupsForApi,
          variables: {
            itemTagId: this.itemTagId,
            nowOnSale: true,
            stockFilter: 'instock',
            perPage: 4,
            // orderBy: 'item_group_item_tags.row_order'
          },
          client: 'apiClient'
        })
        this.itemGroups = data.tagItemGroups.items
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-item-common {
    .anker-link {
      @include font-size(sm);
      svg {
        width: 12px;
        height: 12px;
        color: $black-400;
      }
    }
  }
</style>
