<!-- Generator: Adobe Illustrator 19.2.1, SVG Export Plug-In  -->
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <rect fill="#E9E9E9" width="24" height="24" rx="12"/>
      <path
          d="M12 12.107c1.657 0 3-1.535 3-3.428 0-1.894-1.343-3.429-3-3.429S9 6.785 9 8.679c0 1.893 1.343 3.428 3 3.428zm2.1.857h-.391c-.52.273-1.1.429-1.709.429-.61 0-1.186-.156-1.709-.429H9.9c-1.74 0-3.15 1.613-3.15 3.6v1.115c0 .71.504 1.285 1.125 1.285h8.25c.621 0 1.125-.576 1.125-1.285v-1.115c0-1.987-1.41-3.6-3.15-3.6z"
          fill="#B4B3B3" fill-rule="nonzero"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: "user-icon-default"
  }
</script>
