<template lang="pug">
  .col(:class="sizes")
    slot
</template>

<script>
  export default {
    name: "col",
    props: {
      sizes: {
        type: [String, Array],
        default() {
          return '';
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .col {
    max-width: 100%;
    min-width: 100%;
    padding: 0 15px;

    @each $bp, $size in $breakpoint-down {
      @for $i from 1 through 12 {
        &.#{$bp}-#{$i} {
          @include mq-up($bp) {
            max-width: calc(100% * (#{$i} / 12));
            min-width: calc(100% * (#{$i} / 12));
          }
        }
      }
    }
  }
</style>
