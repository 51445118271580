<template lang="pug">
  section.section-item-ranking(v-if="itemGroups.length > 0")
    HeadingArticle.mb-xs {{ adminShop.name }}の人気商品
    ItemArchive
      .item(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" :archive="true" :rank="index + 1" :eventCategory="`${adminShop.name}の人気商品`" :pageContents="`${adminShop.name}の人気商品`")
</template>

<script>
import {GetItemGroupsForApi} from '../../../graphqls/ItemGroups'
  import HeadingArticle from '../typography/HeadingArticle'
  import ItemCard from '../item/ItemCardForApi'
  import ItemArchive from '../item/ItemArchive'

  export default {
    name: 'SectionItemRanking',
    components: {
      HeadingArticle,
      ItemCard,
      ItemArchive
    },
    props: {
      adminShop: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {
        itemGroups: []
      }
    },
    computed: {
    },
    mounted () {
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        const { data } = await this.$apollo
            .query({
              query: GetItemGroupsForApi,
              variables: {
                nowOnSale: true,
                stockFilter: 'instock',
                perPage: 6,
                adminShopCode: this.adminShop.code,
                orderBy: 'monthranking'
              },
              client: 'apiClient'
            })
        this.itemGroups = data.itemGroups.items
      }
    }
  }
</script>
