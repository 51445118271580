<template lang="pug">
  .form-message(:class="{'is-error': isError}")
    slot
</template>

<script>
  export default {
    name: "FormMessage",
    props: {
      isError: {
        type: Boolean,
        default: false
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .form-message {
    color: $black-base;
    line-height: 100%;
    letter-spacing: 0.01em;
    @include font-size(xs);
    @include margin-top(xxxs);
    @include margin-left(xxs);

    &.is-error {
      color: $red-base;
    }
  }
</style>
