<template lang="pug">
  div
    .review-unit.js-review-unit
      .user
        .icon
          UserIconDefault
        span.name
          | {{review.displayAuthor}}さん
          template(v-if="review.user") {{review.user.prefecture}}
      .comment
        .inline
          span.time(v-if="review.postedAt") {{review.postedAt | moment('YYYY/MM/DD')}} 投稿
          img(:src="review.starImgUrl" height="13")
        p.title {{review.title}}
        .texts
          p.text {{formatMessage(review.comment)}}
        .images(v-if="review.reviewImages.length > 0 && isReadMore")
          a.js-modal-item(v-for="reviewImage in review.reviewImages" :key="reviewImage.id" @click="showReviewImage(reviewImage.imageSet.smImage)" :class="`review${review.id}`", :data-reviewid="review.id" )
            img.image.sp-image(:src="reviewImage.imageSet.xsImage")
      .review-unit.-reply(v-if="review.replyComment")
        .user
          .icon
            img.image(:src="review.adminShop.profileImageSet.smSqImage")
          span.name {{review.adminShop.name}} (生産者)さんの返信
        .comment
          .texts
            p.text {{formatMessage(review.replyComment, 50)}}
    .review-unit-expand.textlink.more.text-center.p1(v-if="!isReadMore" @click="toggleReadMore")
      .fa.fa-angle-down もっとみる

</template>

<script>
  import UserIconDefault from "../../components/svg/UserIconDefault";

  export default {
    name: "ReviewItem",
    components: {
      UserIconDefault
    },
    props: ['review'],
    data() {
      return {
        isReadMore: false
      }
    },
    mounted() {
      this.isReadMore = this.review.length < 140 && this.review.reviewImages.length === 0 && this.review.replyComment
    },
    methods: {
      toggleReadMore () {
        this.isReadMore = !this.isReadMore
      },
      formatMessage(message, maxLength = 100) {
        if (this.isReadMore) {
          return message
        }
        return message.length > maxLength ? message.substr(0, maxLength) + "..." : message
      },
      showReviewImage(image) {
        //todo modal
        return false
      }
    }
  }
</script>

<style scoped>

</style>
