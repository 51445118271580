<template lang="pug">
  a.post-item(:href="post.href")
    img(:src="post.src" :alt="post.title" width="100%" height="auto")
    p.title {{ post.title }}
</template>

<script>
  export default {
    name: 'PostItem',
    props: {
      post: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {

      }
    },
    computed: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .post-item {
    text-decoration: none;
    cursor: pointer;
    > .title {
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(sm);
      line-height: $line-height-snug;
      color: $black-400;
    }
    &:hover {
      > .title {
        color: $olive-300;
      }
    }
  }
</style>
