<template lang="pug">
  .form-item
    .title
      slot(name="title")
    .input
      slot(name="input")
</template>

<script>
  export default {
    name: 'FormItem'
  }
</script>

<style scoped lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";

  .form-item {
    @include margin-top(md);
    @include margin-bottom(md);
    
    .title {
      @include margin-bottom(xxs);
    }
    .input {
      .form-checkbox {
        @include margin-bottom(xxs);
      }
    }
  }
</style>
