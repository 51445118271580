<template lang="pug">
  .admin-toolbar
    .container
      ul.tool-list
        li.tool-item
          a.link(:href="`${adminSiteHost}/admin/dashboards/`" target="_blank")
            include ../../../../../assets/images/icon/tachometer-alt-solid.svg
            span.text ダッシュボード
        li.tool-item
          a.link(:href="`${adminSiteHost}/admin/orders/`" target="_blank" :class="{ warning: adminUser.adminShop.unshippedAcceptOrderNum > 0 }")
            include ../../../../../assets/images/icon/shopping-cart-solid.svg
            span.text 受注管理
            span.badge-order(v-if="adminUser.adminShop.unshippedAcceptOrderNum > 0")
              | {{ adminUser.adminShop.unshippedAcceptOrderNum }}
        li.tool-item
          a.link(:href="`${adminSiteHost}/admin/item_groups/`" target="_blank")
            include ../../../../../assets/images/icon/cubes-solid.svg
            span.text 商品管理
        li.tool-item
          a.link(:href="`${adminSiteHost}/admin/contacts/`" target="_blank")
            include ../../../../../assets/images/icon/comment-dots-regular.svg
            span.text 問合せ管理
        li.tool-item
          a.link(:href="`${adminSiteHost}/admin/admin_shops/${adminUser.adminShop.code}/`" target="_blank")
            include ../../../../../assets/images/icon/home-solid.svg
            span.text ショップ管理
        li.tool-item(v-if="currentItemGroup.id")
          a.link(:href="`${adminSiteHost}/admin/item_groups/${currentItemGroup.id}/`" target="_blank")
            include ../../../../../assets/images/icon/edit-solid.svg
            span.text 商品の編集
        li.tool-item
          a.link(@click="logoutAdminUser()")
            include ../../../../../assets/images/icon/sign-out-alt-solid.svg
            span.text ログアウト
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import { config } from '../../../config/config'
  import { LogoutAdminUser } from '../../../graphqls/AdminUsers'
  import { mapGetters } from 'vuex'

  export default {
    name: 'AdminToolBar',
    mixins: [GlobalMixin],
    props: {
      adminUser: {
        type: Object,
        required: true,
        default() {
          return {
            adminShop: {
              code: '',
              unshippedAcceptOrderNum: 0
            }
          }
        }
      }
    },
    data() {
      return {
      }
    },
    computed: {
      adminSiteHost: function () {
        return config.admin_site_host
      },
      ...mapGetters([
        'currentItemGroup'
      ]),
    },
    mounted: function () {
    },
    methods: {
      async logoutAdminUser() {
        this.loadingStart()
        const { data } = await this.$apollo
        .mutate({
          mutation: LogoutAdminUser,
          variables: {
            input: {}
          }
        })
        location.href = '/'
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .admin-toolbar {
    height: 40px;
    width: 100%;
    background-color: $black-500;
    
    > .container {
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      @include padding(zero, xs);

      > .tool-list {
        width: 100%;
        display: flex;

        > .tool-item {
          &:last-child {
            margin-left: auto;
          }

          > .link {
            display: flex;
            align-items: center;
            justify-content: center;
            @include padding(zero, xs);
            cursor: pointer;
            text-decoration: none;
            color: $gray-400;
            &:hover {
              color: $white;
              > .text {
                text-decoration: underline;
              }
            }

            > svg {
              width: 16px;
              margin-right: 5px;
            }

            > .text {
              font-family: $font-family-sans;
              font-weight: $font-weight-regular;
              font-style: $font-style-not-italic;
              @include font-size(xs);
              line-height: 40px;
            }

            > .badge-order {
              padding: 3px 7px;
              margin-left: 5px;
              border-radius: 18px;
              background-color: #f89406;
              color: $white;
              font-weight: $font-weight-bold;
            }

            @include mq-down(md) {
              width: 40px;
              height: 40px;
              padding: 0;
              text-align: center;
              &.warning {
                color: #f4c21c;
              }

              > svg {
                width: 24px;
                margin-right: 0;
              }

              > .text, > .badge-order {
                display: none;
              }
            }
          }
        }
      }
    }
  }
</style>
