import gql from 'graphql-tag'
import {Errors} from "./Common";
import {ItemGroupQa} from "./ItemGroupQas";

export const GetUserNotices = gql`
query($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  userNotices(currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      adminShop {
        name
        profileImageSet {
          xsSqImage
        }
      }
      contactId
      orderId
      itemGroupQaId
      noticeType
      noticeTypeLabel
      title
      shopName
      lastComment
      lastCommentElapsedTime
      confirmedAt
      
    }
    pageInfo {
      currentPage
      totalCount
      perPage
    }
  }
}
`

export const GetUserNotice = gql`
query($userNoticeId: ID!, $confirm: Boolean) {
  userNotice(userNoticeId: $userNoticeId, confirm: $confirm) {
    id
    noticeType
    adminShop {
      name
    }
    contact {
      id
      code
      contactedAt
      adminShop {
        name
      }
      contactComments {
        id
        userId
        adminUserId
        comment
        image
        imageUrl
        elapsedTime
        user {
          nickname
          name
        }
        adminUser {
          adminShop {
            name
            logoImageSet {
              smSqImage
            }
          }
        }
      }
    }
    itemGroupQa {
      ${ItemGroupQa}
      id
      itemGroup {
        name
      }
    }
  }
}
`


export const UpdateUserNotice = gql`
mutation ($input: UpdateUserNoticeInput!) {
  updateUserNotice (input: $input) {
    userNotice {
      id
    }
    ${Errors}
  }
}
`
