import gql from 'graphql-tag'
import {PageInfo, Errors} from './Common'

export const ItemGroupQa = `
  id
  user {
    id
    email
    nickname
    name
  }
  adminShopId
  adminShop {
    id
    name
    code
    profileImageSet {
      smSqImage
    }
  }
  question
  itemGroup {
    id
    name
    code
  }
  likeCount
  isLiked
  isEdited
  isMyQuestion
  stashQuestion
  commentCount
  elapsedTime
  itemGroupQaComments {
    id
    comment
    stashComment
    likeCount
    isLiked
    elapsedTime
    userId
    adminShopId
    isMyComment
    isShopComment
    isEdited
    user {
      id
      nickname
    }
    adminUser {
      adminShop {
        name
        logoImageSet {
          smSqImage
        }
      }
    }
  }
`

export const GetItemGroupQas = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  itemGroupQas (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {	
    items {
      ${ItemGroupQa}
    }
    ${PageInfo}
  }
}
`

export const CreateItemGroupQa = gql`
mutation ($input: CreateItemGroupQaInput!) {
  createItemGroupQa (input: $input) {
    itemGroupQas {
      ${ItemGroupQa}
    }
    ${Errors}
  }
}`

export const UpdateItemGroupQa = gql`
mutation ($input: UpdateItemGroupQaInput!) {
  updateItemGroupQa (input: $input) {
    itemGroupQas {
      ${ItemGroupQa}
    }
    ${Errors}
  }
}`


export const CreateItemGroupQaComment = gql`
mutation ($input: CreateItemGroupQaCommentInput!) {
  createItemGroupQaComment (input: $input) {
    itemGroupQas {
      ${ItemGroupQa}
    }
    ${Errors}
  }
}`

export const UpdateItemGroupQaComment = gql`
mutation ($input: UpdateItemGroupQaCommentInput!) {
  updateItemGroupQaComment (input: $input) {
    itemGroupQas {
      ${ItemGroupQa}
    }
    ${Errors}
  }
}`

export const DeleteItemGroupQaComment = gql`
mutation ($input: DeleteItemGroupQaCommentInput!) {
  deleteItemGroupQaComment (input: $input) {
    itemGroupQas {
      ${ItemGroupQa}
    }
    ${Errors}
  }
}`

export const CreateItemGroupQaLike = gql`
mutation ($input: CreateItemGroupQaLikeInput!) {
  createItemGroupQaLike (input: $input) {
    itemGroupQas {
      ${ItemGroupQa}
    }
    ${Errors}
  }
}`

export const DeleteItemGroupQaLike = gql`
mutation ($input: DeleteItemGroupQaLikeInput!) {
  deleteItemGroupQaLike (input: $input) {
    itemGroupQas {
      ${ItemGroupQa}
    }
    ${Errors}
  }
}`

export const CreateItemGroupQaCommentLike = gql`
mutation ($input: CreateItemGroupQaCommentLikeInput!) {
  createItemGroupQaCommentLike (input: $input) {
    itemGroupQas {
      ${ItemGroupQa}
    }
    ${Errors}
  }
}`

export const DeleteItemGroupQaCommentLike = gql`
mutation ($input: DeleteItemGroupQaCommentLikeInput!) {
  deleteItemGroupQaCommentLike (input: $input) {
    itemGroupQas {
      ${ItemGroupQa}
    }
    ${Errors}
  }
}`
