<template lang="pug">
  footer.the-footer
    .inner
      nav.sns
        p.title Follow us
        ul.sns-list
          li
            a(href="https://www.instagram.com/taberutokurasuto/" target="_blank")
              include ../../../../../assets/images/sns/instagram-brands.svg
          li
            a(href="https://www.facebook.com/taberutokurasuto/" target="_blank")
              include ../../../../../assets/images/sns/facebook-square-brands.svg
          li
            a(href="https://twitter.com/taberutokurasut" target="_blank")
              include ../../../../../assets/images/sns/twitter-brands.svg
      nav.main-menu
        ul.main-menu-list
          li
            a(href="/about/") たべるとくらすとについて
          li
            a(href="/shops/") 生産者一覧
          li
            a(:href="`/shop/${shopCode}/contact/`") お問合せ
          li
            a(href="/opencontacts/") 出店のお問合せ
          li
            a(href="/sitemap/") サイトマップ
      nav.sub-menu
        ul.sub-menu-list
          li
            a(href="http://www.ess.nu/" target='_blank') 運営会社
          li
            a(href="/terms/") 利用規約
          li
            a(href="/privacy/") プライバシーポリシー
          li
            a(:href="`/shop/${shopCode}/law/`") 特定商取引法に基づく表記
      small.copyright © {{ new Date().getFullYear() }} たべるとくらすと
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'

  export default {
    name: 'TheFooter',
    mixins: [GlobalMixin],
    computed: {
      shopCode() {
        return this.$route.params.shop_code || this.$route.params.adminShopCode || this.$route.params.admin_shop_code || 'tabekura'
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .the-footer {
    width: 100%;
    background: $beige-100;
    text-align: center;

    > .inner {
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      @include padding(sm, xs);

      > .sns {
        @include margin-bottom(sm);
        > .title {
          @include margin-bottom(xxxs);
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-none;
          color: $black-400;
        }
        > .sns-list {
          > li {
            display: inline-block;
            @include margin(zero, xxxs);
            > a {
              color: $black-400;
              cursor: pointer;
              &:hover {
                color: $olive-300;
              }
              > svg {
                width: 20px;
              }
            }
          }
        }
      }

      > .main-menu {
        @include margin(xs, zero);
        > .main-menu-list {
          > li {
            display: inline-block;
            @include margin(zero, xxxs);
            > a {
              font-family: $font-family-sans;
              font-weight: $font-weight-regular;
              font-style: $font-style-not-italic;
              @include font-size(xs);
              line-height: $line-height-none;
              color: $black-400;
              text-decoration: none;
              cursor: pointer;
              &:hover {
                color: $olive-300;
              }
            }
          }
        }
      }

      > .sub-menu {
        @include margin-bottom(sm);
        > .sub-menu-list {
          > li {
            display: inline-block;
            @include margin(zero, xxxs);
            > a {
              font-family: $font-family-sans;
              font-weight: $font-weight-regular;
              font-style: $font-style-not-italic;
              @include font-size(xxs);
              line-height: $line-height-none;
              color: $black-400;
              text-decoration: none;
              cursor: pointer;
              &:hover {
                color: $olive-300;
              }
            }
          }
        }
      }

      > .copyright {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xxs);
        line-height: $line-height-none;
        color: $black-400;
      }
    }
  }
</style>
