import gql from "graphql-tag";

export const AllUnsubscribeMailMagazine = gql`
mutation ($input: AllUnsubscribeInput!) {
  allUnsubscribeMailMagazine(input: $input) {
    message
    errors {
      model
      attribute
      message
    }
  }
}
`
