<template lang="pug">
  .form-label(:class="{'is-edit': isEdit}" @click='click')
    slot
    <!--  IconEdit.is-edit(v-if="isEdit")  -->
    .is-required(v-if="isRequired")
    .is-admin(v-if="isAdmin")
</template>

<script>
  export default {
    name: "form-label",
    props: {
      isRequired: {
        type: Boolean,
        default: false
      },
      isEdit: {
        type: Boolean,
        default: false
      },
      isAdmin: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      click() {
        this.$emit('click');
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .form-label {
    display: flex;
    align-items: center;
    color: $black-dark;
    line-height: 157%;
    @include font-size(xs);
    @include margin-bottom(xxxs);

    &.is-edit {
      cursor: pointer;
    }

    > .is-required {
      &:after {
        content: "必須";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 100%;
        background: $red-base;
        border-radius: 3px;
        color: $white;
        @include font-size(xxxs);
        @include margin-left(xxxs);
      }
    }
    > .is-admin {
      &:after {
        content: "管理者";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 100%;
        background: $black-dark;
        border-radius: 3px;
        color: $white;
        @include font-size(xxxs);
        @include margin-left(xxxs);
      }
    }
    > .is-edit {
      @include margin-left(xxxs);
    }
  }
</style>
