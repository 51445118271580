<template lang="pug">
  div.item-list
    ListItemSkeleton(v-if="showSkeleton")
    a.list-item(v-else :href="localItemGroup.detailPagePath" @click="gaSendEvent")
      .thumb.overlay-title.-center-center
        .ribbon-top-right(v-if="ranking > 0" :class="`rank${ranking}`")
          span {{ranking}}
        //img.img-responsive(:src="listImageUrl" :width="imageWidth" :alt="localItemGroup.name")
        LazyImg(:src="listImageUrl" :alt="localItemGroup.name")
        .title(v-if="localItemGroup.preRelease")
          .title_main.font_sm.font_bold.pt0_5.pr1.pb0_5.pl1 準備中
        .title(v-else-if="localItemGroup.soldOut")
          .title_main.font_sm.font_bold.pt0_5.pr1.pb0_5.pl1 品切れ
        .ribbon-top-left(v-if="localItemGroup.maxOffRate > 0")
          .naname
            span {{localItemGroup.saleName}}
        .recoicon
          .item_icon(v-for="(icon, index) in localItemGroup.icons" :key="index") {{icon}}
      .text
        .recoicon
          .item_icon(v-for="(icon, index) in localItemGroup.icons" :key="index") {{icon}}
        a.shop-name(:href="`/shop/${localItemGroup.adminShop.code}/`" @click="$_gaSendEvent('ショップトップへ遷移', eventCategory, `${localItemGroup.adminShop.name} ${localItemGroup.name}`)") {{localItemGroup.adminShop.name}}（{{localItemGroup.adminShop.prefectureName}}）
        .item-title
          .itemtitle {{localItemGroup.name}}
          .skusize_box(v-if="localItemGroup.displaySize")
            .skusize {{`(${localItemGroup.displaySize})`}}
        template(v-if="localItemGroup.isSale")
          .price.delete
            | {{localItemGroup.properPriceInTax | numberToCurrency }}
            template(v-if="localItemGroup.isRangeProperPrice") ~ {{localItemGroup.maxProperPriceInTax | numberToCurrency }}
          .sale_price_area
            .sale_name
              template(v-if="localItemGroup.offSaleEndAt") {{localItemGroup.offSaleEndAt | moment('M月D日') }} までの
              template(v-if="localItemGroup.saleName") {{localItemGroup.saleName}}価格
              template(v-else) セール価格
            .sale_price
              | {{localItemGroup.salePriceInTax | numberToCurrency }}
              template(v-if="localItemGroup.isRangeSalePrice") ~ {{localItemGroup.maxSalePriceInTax | numberToCurrency }}

        template(v-else)
          .price
            | {{localItemGroup.properPriceInTax | numberToCurrency }}
            template(v-if="localItemGroup.isRangeProperPrice") ~ {{localItemGroup.maxProperPriceInTax | numberToCurrency }}

        .item-notice(v-if="localItemGroup.publishedNotice || localItemGroup.displayShippingWithinYearLastAt")
          div {{localItemGroup.displayShippingWithinYearLastAt}}
          div(v-if="localItemGroup.publishedNotice") {{localItemGroup.notice}}
        .reviewnum(v-if="localItemGroup.reviewNum > 0")
          .fa.fa-comment レビュー{{localItemGroup.reviewNum}}件


</template>

<script>
  import GlobalMixin from "../../mixin/GlobalMixin";
  import LazyImg from "../image/LazyImg";
  import { GetItemGroup } from "../../graphqls/ItemGroups";
  import ListItemSkeleton from "../skeleton/ListItemSkeleton";
  export default {
    name: "item-list",
    components: {ListItemSkeleton, LazyImg},
    mixins: [GlobalMixin],
    props: {
      itemGroup: {
        type: Object
      },
      imageSize: {
        type: String,
        default: 'mb'
      },
      eventCategory: {
        type: String,
        default: 'not set'
      },
      ranking: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        localItemGroup: this.itemGroup
      }
    },
    computed: {
      listImageUrl() {
        return this.imageSize === 'mb' ? this.localItemGroup.listImageOrItemGroupImageSet.mdSqImage :  this.localItemGroup.listImageOrItemGroupImageSet.smSqImage
      },
      imageWidth() {
        return this.imageSize === 'mb' ? '600px' : '300px'
      },
      showSkeleton() {
        return !this.localItemGroup.code
      }
    },
    mounted() {
      this.fetchIcons()
    },
    methods: {
      async fetchIcons() {
        if(this.itemGroup.code || !this.itemGroup.id) return
        const { data } = await this.$apollo
        .query({
          query: GetItemGroup,
          variables: {
            id: this.itemGroup.id
          }
        })
        .catch(error => {
          return;
        });
        this.localItemGroup = data.itemGroup;
        this.$emit('update', data.itemGroup)
      },
      gaSendEvent() {
        this.$_gaSendEvent('商品詳細へ遷移', this.eventCategory, `${this.localItemGroup.adminShop.name} ${this.localItemGroup.name}`)
        this.$gtag.event('select_item', {
          items: [{
            item_id: this.localItemGroup.id,
            item_name: `${this.localItemGroup.adminShop.name} ${this.localItemGroup.name}`,
            affiliation: this.localItemGroup.adminShop.code,
            item_brand: this.localItemGroup.adminShop.name,
          }]
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .item_icon {
    height: 15px;
    line-height: 13px;
    padding: 0 3px;
  }
</style>
