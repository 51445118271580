<template lang="pug">
  ul.credit-cards(:style="{fontSize: `${size}px`}")
    li
      i.fa.fa-cc-visa
      span VISA
    li
      i.fa.fa-cc-mastercard
      span MASTER
    li(v-if="acceptedBrands.includes('JCB')")
      i.fa.fa-cc-jcb
      span JCB
    li(v-if="acceptedBrands.includes('Discover')")
      i.fa.fa-cc-diners-club
      span DINERS
    li(v-if="acceptedBrands.includes('AMEX')")
      i.fa.fa-cc-amex
      span AMEX
</template>

<script>
  export default {
    name: "credit-cards",
    props: {
      size: {
        type: Number,
        default: 27
      },
      acceptedBrands: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .credit-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: .4em;
      padding-bottom: .35em;
      line-height: 1;

      > span {
        position: absolute;
        top: 1em;
        left: 50%;
        transform: scale(.35) translateX(-50%);
        transform-origin:top left;
      }
    }
  }
</style>
