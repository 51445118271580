<template lang="pug">
  section.section-item-category
    HeadingRoof.mb-md(title="おすすめカテゴリ" subtitle="Category")
    .category-list
      .category-item(v-for="category in categories" :key="category.id")
        a.category-link(:href="`/shops/items/category/${category.code}/`" @click="$_gaSendEvent('商品一覧へ遷移', eventCategory, category.name)")
          .category-banner(:style="`background-image: url(${category.ogImageSet.mdImage});`")
            h3.category-name {{ category.name }}
</template>

<script>
  import { GetHasItemRootItemCategories } from '../../../graphqls/ItemCategories'
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import HeadingRoof from '../heading/HeadingRoof'

  export default {
    name: 'SectionItemCategory',
    components: {
      HeadingRoof
    },
    mixins: [GlobalMixin],
    props: {
      eventCategory: {
        type: String,
        require: true,
        default: ''
      },
    },
    data() {
      return {
        categories: []
      }
    },
    mounted() {
      this.fetchItemCategories()
    },
    methods: {
      async fetchItemCategories() {
        const { data } = await this.$apollo
        .query({
          query: GetHasItemRootItemCategories,
          variables: { perPage: 9 },
          client: 'apiClient'
        })
        this.categories = data.hasItemRootItemCategories.items
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-item-category {
    > .category-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -#{map-get($padding-margin-pc, xs)}px;
      @include mq-down() {
        flex-direction: column;
        margin: 0;
      }

      > .category-item {
        width: 33.33333%;
        @include padding(zero, xs, xxs);
        @include mq-down() {
          width: 100%;
          @include padding(zero, zero, xxs);
        }

        > .category-link {
          cursor: pointer;
          text-decoration: none;

          > .category-banner {
            height: 100px;
            background: no-repeat center center;
            background-size: cover;
            background-color: $gray-100;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mq-down() {
              height: 60px;
            }

            > .category-name {
              font-family: $font-family-serif;
              font-weight: $font-weight-bold;
              font-style: $font-style-not-italic;
              @include font-size(md);
              line-height: $line-height-snug;
              color: $white;
              text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.45);
              filter: drop-shadow(3px 3px 4px rgba(0,0,0,0.6));
              @include padding(xxs);
            }
          }  
        }
      }
    }
  }
</style>