import gql from "graphql-tag";

export const GetTopBanners = gql`
query {
  topBanners {
    items {
      id
      image
      url
      title
      alt
      publishedStatus
      imageSet {
        mdImage
      }
    }
    pageInfo {
      currentPage
      totalCount
      perPage
    }
  }
}
`
