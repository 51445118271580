<template lang="pug">
  .unsubscribe
    AlertBase.mt-sm(v-if="errors.length > 0" type="error")
      p(v-for="error of errors") {{error.message}}
    AlertBase.mt-sm(v-if="successMessage" type="success")
      p {{ successMessage }}
    .other.text-center
      ButtonBase(variant="normal" href="/" @click="$_gaSendEvent('トップページへ遷移', 'メールマガジン配信停止', 'トップページへ戻る')") トップページへ戻る
</template>

<script>
  import GlobalMixin from '../../mixin/GlobalMixin'
  import ContainerSheet from '../../components/v1/container/ContainerSheet'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormTextfield from '../../components/v1/form/FormTextfield'
  import {AllUnsubscribeMailMagazine} from "../../graphqls/MailMagazineUser";

  export default {
    name: 'MailMagazineUnsubscribe',
    mixins: [GlobalMixin],
    components: {
      AlertBase,
      ContainerSheet,
      FormItem,
      FormLabel,
      FormTextfield,
      ButtonBase,
    },
    data() {
      return {
        form: {
          templateId: '',
          userToken: ''
        },
        successMessage: '',
        errors: []
      }
    },
    methods: {
      async allUnsubscribeMailMagazine() {
        this.loadingStart()
        this.successMessage = ''
        this.errors = []
        this.$_clearValidationErrors()

        const { data } = await this.$apollo
        .mutate({
          mutation: AllUnsubscribeMailMagazine,
          variables: {
            input: this.form
          },
          client: 'apiClient'
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        })

        const errors = data.allUnsubscribeMailMagazine.errors
        if (errors) {
          this.errors = errors
        } else {
          this.successMessage = data.allUnsubscribeMailMagazine.message
        }
        this.loadingStop()
      }
    },
    mounted() {
      this.form.templateId = this.$route.query.template_id
      this.form.userToken = this.$route.query.user_token
      // パンくずリストを設定
      this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/mailmagazine_unsubscribe/',
          name: 'メールマガジン配信停止',
        },
      ]})
      this.allUnsubscribeMailMagazine()
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .unsubscribe {
    > .alert-base {
      width: 100%;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      @include margin-bottom;
      &.-success {
        white-space: break-spaces;
      }
    }
  }
</style>
