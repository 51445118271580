<template lang="pug">
  .open-contacts-show
    template(v-if="!results")
      AlertBase(v-if="recaptchaErrorMessage || validateErrors.length > 0" type="error")
        p(v-if="recaptchaErrorMessage") {{ recaptchaErrorMessage }}
        p(v-for="message of $_validationErrorMessage") {{message}}
      h1.heading-1.text-center.my-md 出店に関するお問い合わせ
      p.paragraph.text-center.mb-sm
        | 手仕事が好きで、楽しく美味しく食べてもらうためには手間ひまを惜しまない、信念やこだわりを持つ
        strong 生産者さん
        | を募集しています。
        br
        |（基本的には農薬・化学肥料不使用の生鮮食品、食品添加物不使用の加工食品）
        br
        | 出店にご興味のある方はこちらのお問い合わせフォームよりご連絡ください。
      ContainerFrame.mt-xlg.mb-xlg
        .form-wrapper
          FormItem
            template(slot="title")
              FormLabel(type="required") 会社名/屋号
            template(slot="input")
              FormTextfield(
                v-model="form.companyName"
                placeholder="株式会社たべるとくらすと"
                :error="'company_name' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.company_name"
              )
          FormItem
            template(slot="title")
              FormLabel(type="required") お名前
            template(slot="input")
              FormTextfield(
                v-model="form.name"
                placeholder="田部倉 太郎"
                :error="'name' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.name"
              )
          FormItem
            template(slot="title")
              FormLabel(type="required") メールアドレス
            template(slot="input")
              FormTextfield(
                v-model="form.email"
                type="email"
                placeholder="info@taberutokurasuto.com"
                :error="'email' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.email"
              )
          AlertBase(type="info")
            p 「@taberutokurasuto.com」のドメインからのメールを受信できるよう設定にご注意ください。
          FormItem
            template(slot="title")
              FormLabel(type="required") お電話番号
            template(slot="input")
              FormTextfield(
                v-model="form.tel"
                type="tel"
                placeholder="09012345678"
                :error="'tel' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.tel"
              )
          FormItem
            template(slot="title")
              FormLabel サイトURL
            template(slot="input")
              FormTextarea(
                v-model="form.url"
                placeholder="ホームページやFacebook、Instagram、ショッピングサイトなどお店や商品のことがわかるサイトをお持ちの場合、サイトのURLをご記入ください。(複数可)"
                :error="'url' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.url"
              )
          FormItem
            template(slot="title")
              FormLabel 販売したい商品
            template(slot="input")
              FormTextarea(
                v-model="form.product"
                placeholder="販売したい商品をご記入ください。"
                :error="'product' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.product"
              )
          AlertBase(type="info")
            p たべるとくらすとではできるだけ自然な食を考えており、一部除く食品添加物の他に、酵母エキスや蛋白加水分解物などの原材料を使用した商品は許可しておりません。

          FormItem
            template(slot="title")
              FormLabel(type="required") お問い合わせ内容
            template(slot="input")
              FormCheckbox(v-model="form.questionFee") 出店費用を知りたい
              FormCheckbox(v-model="form.questionSystem") 出店の仕組みを知りたい
              FormCheckbox(v-model="form.questionCanOpen") 出店できるかを知りたい
              FormCheckbox(v-model="form.questionOther") その他
              p.error-message(v-if="$_validationErrorMessage.question") {{ $_validationErrorMessage.question }}

          FormItem
            template(slot="title")
              FormLabel(type="required") お問い合わせ内容詳細
            template(slot="input")
              FormTextarea(
                v-model="form.message"
                placeholder="お問い合わせ内容ご記入ください。"
                :error="'message' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.message"
              )
          div.text-center.mt-xlg
            ButtonBase(@click="openContact()") 送信
    template(v-if="results")
      h1.heading-1.text-center.my-md 出店に関するお問い合わせ
      ContainerFrame.mb-xlg
        .form-wrapper
          HeadingArticle.mb-md お問い合わせありがとうございました。
          p.paragraph.mb-md
            | 送信いただいた内容をご確認させていただきます。
            br 
            | 後ほどご連絡いたしますので今しばらくお待ちください。
          AlertBase.mb-xlg(type="info") 
            p.note 自動返信メールをお送りしましたのでご確認ください。自動返信メールが届いてない場合、迷惑メールフォルダ等に振り分けられている可能性があります。
            p.note 携帯電話のメールアドレスでお問い合わせのお客さまは、あらかじめ「@taberutokurasuto.com」のドメインを受信許可にしていただきますようお願いいたします。ご連絡のメールが受信ができない場合があります。
          div.text-center
            ButtonBase(variant="normal" href="/") トップページへ戻る
</template>

<script>
  import { CreateOpenContact } from "../../graphqls/OpenContacts"
  import GlobalMixin from "../../mixin/GlobalMixin"
  import HeadingArticle from '../../components/v1/typography/HeadingArticle'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import ContainerFrame from '../../components/v1/container/ContainerFrame'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormTextarea from '../../components/v1/form/FormTextarea'
  import FormTextfield from '../../components/v1/form/FormTextfield'
  import FormCheckbox from "../../components/v1/form/FormCheckbox";

  export default {
    name: 'OpenContactsShow',
    mixins: [GlobalMixin],
    components: {
      FormCheckbox,
      HeadingArticle,
      AlertBase,
      ButtonBase,
      ContainerFrame,
      FormItem,
      FormLabel,
      FormTextarea,
      FormTextfield
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    data() {
      return {
        form: {
          companyName: '',
          name: '',
          email: '',
          tel: '',
          url: '',
          product: '',
          message: '',
          recaptchaToken: '',
          questionFee: false,
          questionSystem: false,
          questionCanOpen: false,
          questionOther: false,
        },
        results: null
      }
    },
    computed: {
    },
    methods: {
      async openContact() {
        this.loadingStart()
        this.$_clearValidationErrors()
        
        const token = await this.$_createRecaptchaToken('open_contacts')
        this.form.recaptchaToken = token

        const { data } = await this.$apollo.mutate({
          mutation: CreateOpenContact,
          variables: {
            input: this.form
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        })

        const errors = data.createOpenContact.errors
        if (errors.length) {
          this.$_parseError(errors)
        } else {
          this.results = data.createOpenContact.openContact
        }

        this.$scrollTo("body")
        this.loadingStop()
      }
    },
    mounted: function () {
      // サイド表示
      this.showSide()
      // パンくずリストを設定
      this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/opencontacts/',
          name: '出店に関するお問い合わせ',
        },
      ]})
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";
  .form-item {
    .error-message {
      @include margin-top(xxxs);
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(xs);
      line-height: $line-height-tight;
      color: $red-300;
    }
  }
</style>
