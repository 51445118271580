<template lang="pug">
  .blogHeader
    .logo
      img(:src="adminShop.profileImageSet.mdSqImage" alt="logo")
    h1.heading-1.text-center.mb-sm {{ adminShop.name }}
    .category(v-if="subTitle") {{ subTitle }}
</template>

<script>
  export default {
    name: 'BlogHeader',
    components: {
    },
    props: {
      adminShop: {
        type: Object,
        required: false,
        default() {
          return {
            name: '',
            profileImageSet: {
              mdSqImage: "https://cdn.taberutokurasuto.com/image/common/noimage_300x300.gif",
            },
          }
        },
      },
      subTitle: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
      }
    },
    computed: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
    .blogHeader {
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin: 16px 0 48px;
      > .logo {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      > h1 {
        margin: 0;
        font-size: 24px;
      }
      > .category {
        color: $black-200;
        position: relative;
        display: inline-block;
        font-size: 14px;
      }
      > .category:before, .category:after {
        content: '';
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 16px;
        height: 1px;
        background-color: $olive-200;
      }
      > .category:before {
        left: -24px;
      }
      > .category:after {
        right: -24px;
      }
      @include mq-down() {
        margin: 24px 0 32px;
        gap: 12px;
        > .logo {
          width: 72px;
          height: 72px;
        }
        > h1 {
          font-size: 18px;
        }
        > .category {
          font-size: 12px;
        }
      }
    }
</style>
