<template lang="pug">
  .button-block(:class="[`align-${align}`]")
    slot
</template>

<script>
  export default {
    name: "button-block",
    props: {
      align: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .button-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &.align-left {
      justify-content: flex-start;
    }
    &.align-right {
      justify-content: flex-end;
    }
    &.align-between {
      justify-content: space-between;
    }
    @include mq-down(md) {
      flex-direction: column-reverse;
      align-items: center;
    }

    .button {
      + .button {
        margin-left: #{map_get($padding-margin-pc, md)}px;
        @include mq-down(md) {
          margin-bottom: #{map_get($padding-margin-sp, md)}px;
          margin-left: 0;
        }
      }
    }
  }
</style>
