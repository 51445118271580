<template lang="pug">
  ModalBase(ref="modalBase" size="medium")
    template(slot="header") クレジットカードの変更
    template(slot="body")
      FormItem 
        template(slot="title")
          FormLabel クレジットカード
        template(slot="input")
          FormRadioGroup
            FormRadio(v-for="cardData of cardDataList"
              :index="cardData.id"
              v-model="form.payJpCardId"
              :id="cardData.id"
              :label="`**** **** **** ${cardData.last4} ${cardData.name}  ${cardData.expMonth}/${cardData.expYear}`"
              :class="{ 'selected' : payJpCardId === cardData.id }")
      a(href="/mypage/credit") 新しいクレジットカードの登録はこちら
    template(slot="footer")
      ButtonBase(@click="close()" variant="normal" size="inline") キャンセル
      ButtonBase(@click="updateCreditCard()" variant="primary" ) 更新
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import ModalBase from '../modal/ModalBase'
  import ButtonBase from '../button/ButtonBase'
  import FormItem from '../form/FormItem'
  import FormLabel from '../form/FormLabel'
  import FormRadioGroup from '../form/FormRadioGroup'
  import FormRadio from '../form/FormRadio'
  import {PayjpCustomer} from "../../../graphqls/Payjp";
  import {
    UpdateSubscriptionOrderSummaryCard
  } from "../../../graphqls/SubscriptionOrderSummaries";

  export default {
    name: 'ModalCreditCard',
    mixins: [GlobalMixin],
    components: {
      ModalBase,
      ButtonBase,
      FormItem,
      FormLabel,
      FormRadioGroup,
      FormRadio,
    },
    props: {
      subscriptionOrderSummaryId: {
        type: String,
        default: ""
      },
      payJpCardId: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        form: {
          id: '',
          payJpCardId: '',
        },
        payjpCustomer: {
          cards: {
            data: []
          }
        },
      }
    },
    computed: {
      cardDataList() {
        return this.payjpCustomer.cards.data
      },
    },

    methods: {
      open(e) {
        this.$refs.modalBase.open()
        this.$emit('open', e)
        this.getPayjpCustomer();
        this.form.payJpCardId = this.payJpCardId
        this.form.id = this.subscriptionOrderSummaryId
        console.log(this.form)
      },
      close(e) {
        this.$refs.modalBase.close()
        this.$emit('close', e)
      },
      disabledUpdateCreditButton() {
        return !this.selectPayJpCardId ||
            this.modalSubscriptionOrderSummary.payJpCard.id === this.selectPayJpCardId ||
            (this.modalSubscriptionOrderSummary.latestSubscriptionOrder.isFixDeliveryOn === 'true' && !this.nextDeliveryOn)
      },
      async updateCreditCard() {
        this.loadingStart();
        console.log('{ ...this.form }', { ...this.form })
        const { data } = await this.$apollo
            .mutate({
              mutation: UpdateSubscriptionOrderSummaryCard,
              // Parameters
              variables: {
                input: { ...this.form }
              },
              client: 'apiClient'
            })
            .catch(error => {
              console.log(error.message)
            });
        const errors = data.updateSubscriptionOrderSummary.errors
        if (!errors) {
          this.close()
          this.$emit('update', data.updateSubscriptionOrderSummary.subscriptionOrderSummary)
        } else {
          this.errors = errors
        }
        this.loadingStop();
      },

      async getPayjpCustomer() {
        this.loadingStart();
        const { data } = await this.$apollo
            .query({
              query: PayjpCustomer,
              client: 'apiClient'
            })
            .catch(error => {
              this.loadingStop()
              return;
            });
        this.payjpCustomer = data.payjpCustomer
        this.loadingStop();
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .modal-base {
    .body {
      section {
        .heading {
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-snug;
          color: $black-400;
          @include margin-bottom(md);
        }
        .info {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          > .quantity {
            @include margin-right(sm);
          }
          > .price {
            font-weight: 700;
          }
          > .unit {
            @include font-size(xxs)
          }
        }
      }
      > a {
        color: $olive-400;
        text-decoration-color: $olive-400;       
        text-decoration-line: underline;
      }
      .selected {
        &:after {
          content: "使用中";
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 16px;
          background: $red-300;
          border-radius: 3px;
          @include margin-left(xxxs);
          
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xxxs);
          line-height: $line-height-none;
          color: $white;
        }
      }
    }
  }
</style>
