
<template lang="pug">
  a.post-card(
    :href="`/shop/${post.adminShop.code}/blog/entry/${post.customUrl}/`" 
    @click="$_gaSendEvent('ショップお知らせ詳細へ遷移', 'トップ生産者のお便りとお知らせ', `${post.adminShop.name} ${post.title}`)"
  )
    .thumbarea
      img(:src="post.displayListImage")
    .textarea
      .namedate        
        .shopinfo
          .shoplogo
            img(:src="post.adminShop.logoImageSet.xsImage")
          .shopname {{ post.adminShop.name }}
        .postdate
          | {{ formatPostDate(post.publishedStartAt) }}
      .posttitle.my-xxxs {{ post.title }}
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'

  export default {
    name: 'PostCard',
    mixins: [GlobalMixin],
    props: {
      post: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {

      }
    },
    computed: {

    },
    methods: {
      formatPostDate(publishedStartAt) {
        const now = new Date();
        const postDate = new Date(publishedStartAt);

        const timeDiffInMilliseconds = now - postDate;
        const timeDiffInSeconds = Math.floor(timeDiffInMilliseconds / 1000);
        const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
        const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
        const timeDiffInDays = Math.floor(timeDiffInHours / 24);

        if (timeDiffInDays < 7) {
          if (timeDiffInHours < 24) {
            return `${timeDiffInHours}時間前`;
          } else {
            return `${timeDiffInDays}日前`;
          }
        } else {
          const year = postDate.getFullYear();
          const month = String(postDate.getMonth() + 1).padStart(2, '0');
          const day = String(postDate.getDate()).padStart(2, '0');
          return `${year}/${month}/${day}`;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .post-card {
    display: flex;
    @include padding(xs);
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    @include font-size(sm);
    line-height: $line-height-snug;
    color: $black-400;
    border-bottom: 1px solid $gray-200;
    &:hover {
      color: $olive-400;
    }

    > .thumbarea {
      width: 80px;
      height: 60px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover; 
      }
    }
    > .textarea {
      width: calc(100% - 80px);
      @include padding-left(sm);
      > .namedate {
        display: flex;
        justify-content: space-between;
        @include font-size(xs);
        line-height: 1;
        color: $black-200;
        gap: 8px;
        >.shopinfo {
          width: calc(100% - 80px);
          display: flex;
          align-items: center;
          gap: 6px;
          >.shoplogo {
            width: 20px;
            height: 20px;
            margin-right: 6px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          >.shopname {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1 0 0;
            margin: auto 0;
            @include font-size(xs);
          }
        }
        >.postdate {
          margin: auto 0;
        }
      }
      > .posttitle {
        font-family: $font-family-serif;
        line-height: $line-height-tight;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      // > .postdescription {
      //   display: -webkit-box;
      //   -webkit-box-orient: vertical;
      //   -webkit-line-clamp: 3;
      //   @include font-size(xs);
      // }
    }
  }
</style>