<template lang="pug">
  .container
    FlashMessages.mb-xs
    .row
      .col-xs-12
        h1.heading-3.text-center.my-md 定期購入管理
        Alert(v-if="errors.length > 0" type="danger")
          ul
            li(v-for="error of errors") {{error.message}}
        //- 定期購入がないとき
        Alert(v-if="subscriptionOrderSummaryList.length === 0"  type="warning")
          p 定期購入のお申込みがありません。
        //- 定期購入があるとき
        template(v-else)
          //- 定期購入情報
          .contents-wrapper(v-for="subscriptionOrderSummary of subscriptionOrderSummaryList")
            .contents
              .item
                .product
                  .img
                    img(:src="subscriptionOrderSummary.latestSubscriptionOrder.item.itemImageOrItemGroupListImageSet.xsSqImage" class='img-responsive _mb-10')
                  .text
                    .shop
                      img(:src="subscriptionOrderSummary.adminShop.logoImageSet.xsSqImage" class='img-responsive _mb-10')
                      p {{subscriptionOrderSummary.adminShop.name}}
                    p.item_name {{subscriptionOrderSummary.latestSubscriptionOrder.item.displayName}}
                    p 数量：{{subscriptionOrderSummary.latestSubscriptionOrder.quantity}}点
                    p.link
                      a(:href="contactPagePath(subscriptionOrderSummary)") ショップへ問い合わせ
                .status_wrapper
                  .status(v-if="subscriptionOrderSummary.status === 'running'" )
                    .next_shipment
                      p.date
                        | 次回お届け日 
                        span {{subscriptionOrderSummary.latestSubscriptionOrder.formatDeliveryOn}}
                      p.shipment_status
                        | {{subscriptionOrderSummary.latestSubscriptionOrder.statusLabel}}
                  .status(v-else)
                    .next_shipment
                      p この定期購入は停止済みです
              .text-center(v-if="subscriptionOrderSummary.status === 'running'")
                ButtonBase(variant="primary" :href="`/mypage/subscriptionorders/${subscriptionOrderSummary.code}/`") 詳細・変更・停止
              .text-center(v-else)
                ButtonBase(variant="normal" :href="`/mypage/subscriptionorders/${subscriptionOrderSummary.code}/`") 詳細を見る

          .row
            .col-xs-12
              Pager(@change="changePage" :custom-page-info="customPageInfo")
</template>

<script>
  import GlobalMixin from "../../mixin/GlobalMixin";
  import {GetSubscriptionOrderSummaries} from "../../graphqls/SubscriptionOrderSummaries";

  import Alert from "../../components/v1/alert/AlertBase";
  import Pager from "../../components/v1/pager/Pager";
  import Modal from "../../components/modal/Modal";

  import ButtonBase from '../../components/v1/button/ButtonBase'
  import FlashMessages from "../../components/v1/alert/FlashMessages";

  export default {
    name: "mypage-subscription-orders",
    mixins: [GlobalMixin],
    components: {
      FlashMessages,
      Modal,
      Pager,
      Alert,
      ButtonBase,
    },
    props: [],
    data() {
      return {
        errors: [],
        subscriptionorders: [],
        subscriptionOrderSummaries: {
          items: []
        },
        modalSubscriptionOrderSummary: '',
        selectPayJpCardId: '',
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 5
        },
        nextDeliveryOn: ""
      }
    },
    computed: {
      subscriptionOrderSummaryList() {
        return this.subscriptionOrderSummaries.items
      },
      customPageInfo() {
        return this.pageInfo
      },
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    mounted() {
      this.getSubscriptionOrderSummaries()
      this.setBreadcrumbs({ breadcrumbs: [
          {
            path: '/',
            name: 'TOP',
          },
          {
            path: '/mypage/',
            name: 'マイページ',
          },
          {
            path: '/mypage/subscriptionorders/',
            name: '定期購入管理',
          },
        ]})
    },
    methods: {
      async getSubscriptionOrderSummaries() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetSubscriptionOrderSummaries,
          variables: {
            ...this.pageInfo
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.subscriptionOrderSummaries = data.subscriptionOrderSummaries
        this.pageInfo = data.subscriptionOrderSummaries.pageInfo
        this.loadingStop();
      },
      changePage(newPage) {
        this.pageInfo.currentPage = newPage
        this.getSubscriptionOrderSummaries();
        this.$scrollTo("body");
      },
      contactPagePath(subscriptionOrderSummary) {
        const subscriptionOrder = subscriptionOrderSummary?.subscriptionOrders.find((subscriptionOrder) => !!subscriptionOrder.orderId);
        return subscriptionOrder ? `/mypage/purchasehistory/${subscriptionOrder.orderId}/` : "/"
      }
    }

  }
</script>

<style scoped lang="scss">
@import "../../../../assets/stylesheets/v1/styles";
.container {
  margin: auto;
  .contents-wrapper {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    background: $ivory-100;
    > .contents {
      font-size: 14px;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      background: $white;
      > .item {
        display: flex;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        > .product {
          width: calc(50% - 16px);
          display: flex;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          > .img {
            width: 96px;
            height: 96px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          > .text {
            width: calc(100% - 106px);
            > .shop {
              display: flex;
              align-items: center;
              gap: 6px;
              font-size: 12px;
              width: calc(100% - 20px);
              > img {
                width: 18px;
                min-width: 18px;
                height: auto;
                margin: 0!important;
                padding-top: 4px;
              }
            }
            > .item_name {
              line-height: $line-height-tight;
            }
            > .link {
                margin-top: 8px;
              > a {
                color: $olive-400;
                text-decoration: underline
              }
            }
          }
        }
        > .status_wrapper {
          width: calc(50% - 16px);
          > .status {
            border-radius: 2px;
            border: 1px solid var(--gray-300, #DFDFDF);
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            > .next_shipment, .last_shipment {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              align-self: stretch;
            }
            > .next_shipment {
              > .date {
                > span {
                  font-weight: bold;
                }
              }
              > .shipment_status {
                font-weight: bold;
                color: $red-400;
              }
            }
          }
        }
      }
      @media (max-width: 768px) {
        .item {
          flex-direction: column;
          gap: 16px;
          > .product  {
            width: 100%;
          }
          > .status_wrapper {
            width: 100%;
          }
        }
      }
      > .text-center {
        margin-top: 24px;
        > a {
          max-width: 280px;
        }
      }
    }
  }
  .pager-wrap {
      @include margin-top(md);
    }
}
</style>

