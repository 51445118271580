<template lang="pug">
  table.table-base(:class="{'is-bordered': isBordered}")
    slot
</template>

<script>
  export default {
    name: 'TableBase',
    props: {
      isBordered: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .table-base {
    width: 100%;
    max-width: 100%;
    margin: 0;
    border-collapse: collapse;
    color: $black-400;

    &.is-bordered {
      border: 1px solid $gray-200;

      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            border: 1px solid $gray-200;
          }
        }
      }
    }

    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-top: 1px solid $gray-200;
          vertical-align: top;
          line-height: 1.5;
          @include padding(xxs);
          @include font-size(xs);
          
        }
      }
    }

    > thead {
      > tr {
        &:first-child {
          > th,
          > td {
            border-top: none;
          }
        }

        > th {
          vertical-align: bottom;
          border-bottom: 2px solid $gray-200;
        }
      }
    }
  }
</style>
