<template lang="pug">
  .post-list
    slot
</template>

<script>
  import PostItem from '../post/PostItem'

  export default {
    name: 'PostList',
    components: {
      PostItem
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .post-list {
    display: flex;
    flex-wrap: wrap;
    > .post-item {
      width: (100% / 3);
      @include padding-left(xxxs);
      @include padding-right(xxxs);
      @include margin-bottom(sm);
      @include mq-up() {
        width: (100% / 6);
      }
    }
  }
</style>
