<template lang="pug">
  div
    .visual-title.-xs.-white(:style="`background-image: url(https://cdn.taberutokurasuto.com/image/shop/item/list/freeshipping2.jpg);`")
      .title
        i.h1rem
          LogoYaneSvg
        h1.heading2 {{title}}
        .heading3.curve-bottom Free shipping
    .archives-description
      p たべるとくらすとの送料無料や送料込みの商品です。お届け先の地域からお選び頂けます。

    ItemFilterBlock
      DropDown(@change="$_changeOrder" v-model="pageInfo.orderBy" :options="$_orderDropDownOptions")
      DropDown(@change="changeRegion" v-model="regionCode" :options="regionOptions")
      .stock-checkbok
        FormCheckbox(@change="$_changeStockFilter" v-model="stockOnly") 販売中のみ表示
    ItemCardList
      .item.lg(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" eventCategory="商品一覧")
    .row
      .col-xs-12(v-if="itemGroups.length === 0")
        .success-notice
          .text-center 商品がありません。
    .row.mb-xs
      .col-xs-12
        Pager(@change="$_changePage" :custom-page-info="$_customPageInfo")

    Row
      template(v-for="region in regionOptions")
        Chip(v-if="region.id === regionCode" color="primary") {{region.text}}
        Chip(v-else @click="changeRegion(region.id)") {{region.text}}

    SectionItemArchive
</template>

<script>
  import {GetItemGroupsForApi} from "../../graphqls/ItemGroups";

  import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
  import ItemList from "../../components/list/ItemList";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Pager from "../../components/paginate/Pager";
  import DropDown from "../../components/v1/dropdown/DropDown";
  import ItemListMixin from "../../mixin/ItemListMixin";
  import Chip from "../../components/badge/Chip";
  import Row from "../../components/layout/Row";
  import Col from "../../components/layout/Col";
  import ItemCardList from "../../components/v1/item/ItemCardList";
  import ItemCard from "../../components/v1/item/ItemCardForApi";
  import SectionItemArchive from "../../components/v1/section/SectionItemArchive";
  import FormCheckbox from "../../components/v1/form/FormCheckbox";
  import ItemFilterBlock from "../../components/v1/item/ItemFilterBlock";

  export default {
    name: "free-shipping-items-index",
    mixins: [GlobalMixin, ItemListMixin],
    components: {
      ItemFilterBlock,
      FormCheckbox,
      SectionItemArchive,
      ItemCard,
      ItemCardList,
      Col,
      Row,
      Chip,
      DropDown,
      Pager,
      ItemList,
      LogoYaneSvg
    },
    props: [],
    data() {
      return {
        itemGroups: [],
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 48,
          orderBy: this.$route.query.order || 'popular'
        },
        stockOnly: this.$route.query?.stock === 'instock',
        withinYearItem: this.$route.query.wiy === 'true',
        regionCode: this.$route.params.region || ''
      }
    },
    computed: {
      regionOptions() {
        let result = []
        result.push({id: '', text: '全国'})
        result.push({id: '01', text: '北海道'})
        result.push({id: '02', text: '北東北'})
        result.push({id: '03', text: '南東北'})
        result.push({id: '04', text: '関東'})
        result.push({id: '05', text: '信越'})
        result.push({id: '06', text: '東海'})
        result.push({id: '07', text: '北陸'})
        result.push({id: '08', text: '関西'})
        result.push({id: '09', text: '中国'})
        result.push({id: '10', text: '四国'})
        result.push({id: '11', text: '九州'})
        result.push({id: '12', text: '沖縄'})
        return result
      },
      selectedRegion() {
        return this.regionOptions.find(r => r.id === this.regionCode)
      },
      title() {
        if (this.selectedRegion && this.regionCode) {
          return `${this.selectedRegion.text}への送料無料商品`
        }
        return '送料無料商品'
      },
      topRouterName() {
        if (this.regionCode) {
          return 'free-shipping-region-items-index'
        } else {
          return 'free-shipping-items-index'
        }
      },
      pageRouterName() {
        if (this.regionCode) {
          return 'free-shipping-region-items-page-index'
        } else {
          return 'free-shipping-items-page-index'
        }
      },
      stockFilter() {
        return this.stockOnly ? 'instock' : 'outstock'
      }
    },
    mounted() {
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            freeShippingItem: true,
            freeShippingRegion: this.regionCode,
            stockFilter: this.stockFilter,
            withinYearItem: this.withinYearItem,
            ...this.pageInfo
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroups = data.itemGroups.items;
        this.pageInfo = data.itemGroups.pageInfo
        this.loadingStop();
        this.$_gaSendViewItemList(this.itemGroups, 'shops/items/freeshipping', this.pageInfo)
      },
      updateItemGroup(newItemGroup) {
        if (this.itemGroups[0].id === newItemGroup.id) {
          this.firstItemGroup = newItemGroup
        }
      },
      changeRegion(region) {
        this.regionCode = region
        if (this.regionCode) {
          this.$router.push({name: 'free-shipping-region-items-index', params: {region: this.regionCode}})
        } else {
          this.$router.push({name: 'free-shipping-items-index'})
        }
        this.fetchItemGroups()
      }
    },
    watch: {
      regionCode: function (newQuestion, oldQuestion) {
        this.stockFilter = 'instock'
        this.pageInfo.orderBy = 'popular'
        this.pageInfo.currentPage = 1
        this.fetchItemGroups()
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
