import gql from 'graphql-tag'

export const GetItemGroupColumns = gql`
query ($itemGroupId: ID!, $currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  itemGroupColumns (itemGroupId: $itemGroupId, currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      ogImage
      ogImageSet {
        smImage
        mdImage
      }
      title
      columnCategoryId
      publishedStatus
      postedAt
      customUrl
      columnCategoryId
      firstTextColumnBody {
        description
      }
      metaDescription
      imageColumnBodies {
        id
        imageSet {
          xsImage
        }
      }
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}`

export const GetColumns = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  columns (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      ogImage
      ogImageSet {
        smImage
        mdImage
      }
      title
      columnCategoryId
      publishedStatus
      postedAt
      customUrl
      frontUrl
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}`

export const GetColumn = gql`
query($columnId: ID!) {
  column(columnId: $columnId) {
    id
    ogImage
    ogImageSet {
      smImage
      mdImage
    }
    title
    columnCategoryId
    publishedStatus
    postedAt
    customUrl
    metaTitle
    metaDescription
    columnBodies {
      id
      contentType
      description
      image
      alt
      title
      itemGroupId
      itemGroup {
        id
        name
        properPrice
        listImageUrl
        listImageSet {
          xsImage
        }
      }

    }
  }
}`

