<template lang="pug">
.detail-container
  .section-accordion(@click="open = !open")
    p 注文詳細
    .svg(v-if="open" )
      include ../../../../../assets/images/icon/arrow-up.svg
    .svg(v-else)
      include ../../../../../assets/images/icon/arrow-down.svg
  .detail-wrapper(v-if="open" )
    .payment
      h3 金額
      .payment-detail
        span 商品小計{{ order.currentOrderPayment.isAddTax ? '(税込)' : ''}}
        span {{order.currentOrderPayment.itemTotalPriceInTaxWithCurrency}}
      .payment-detail
        span 送料
        span {{order.currentOrderPayment.shippingPriceInTaxWithCurrency}}
      .payment-detail(v-if="order.currentOrderPayment.paymentType === 'cash_on_delivery'" )
        span 代引き手数料
        span {{order.currentOrderPayment.codFeeInTaxWithCurrency}}
      .payment-detail(v-if="Number(order.currentOrderPayment.couponDiscountAmount) > 0" )
        span クーポン利用
        span -{{order.currentOrderPayment.couponDiscountAmountWithCurrency}}
      .payment-detail(v-if="Number(order.currentOrderPayment.usePoint) > 0" )
        span ポイント利用
        span -{{order.currentOrderPayment.usePointWithCurrency}}
      .payment-detail
        span お支払い金額{{ order.currentOrderPayment.isAddTax ? '(税込)' : ''}}
        span.total {{order.currentOrderPayment.orderTotalPriceWithCurrency}}
    .payment-methods
      h3 お支払い方法
      p {{order.currentOrderPayment.paymentTypeLabel}}
    .reserve-shipping(v-if="isDiffShippingItem" )
      h3 発送予定日
      p {{scheduledShippingStartAt}}頃から順次発送
      .info-accordion
        .title(@click="showDiffShipping = !showDiffShipping")
          span.label 発送時期が異なる商品の配送について
          .svg(v-if="showDiffShipping" )
            include ../../../../../assets/images/icon/arrow-drop-up.svg
          .svg(v-else)
            include ../../../../../assets/images/icon/arrow-drop-down.svg
        .details(v-if="showDiffShipping" )
          p 発送時期が最も遅い商品が発送されるタイミングに合わせて、全てのご注文商品を同時にお届けいたします。<br>発送時期の異なる予約商品を同時にご注文の場合、送料追加となる場合がございます。その場合はショップよりご連絡させていただきます。<br>また、商品によっては出荷時に確保できない場合があり、キャンセルとなる恐れがございます。その場合は、購入ショップよりご連絡させていただきます。
    .deliver
      h3 お届け先
      p {{order.currentOrderDelivery.name}}
      p 〒{{order.currentOrderDelivery.zipCode}}
      p {{order.currentOrderDelivery.address}}
      p {{order.currentOrderDelivery.tel}}
    .shipping
      h3 配送情報
      .shipping-detail(v-if="order.currentOrderDelivery.displayDeliveredOn")
        span 出荷日
        span {{order.currentOrderDelivery.displayDeliveredOn}}
      .shipping-detail(v-if="order.currentOrderDelivery.displayDeliveryOn" )
        span 配送希望日
        span {{order.currentOrderDelivery.displayDeliveryOn}}
      .shipping-detail(v-if="order.currentOrderDelivery.displayDeliveryTime" )
        span 配送希望時間
        span {{order.currentOrderDelivery.displayDeliveryTime}}
      .shipping-detail
        span 配送方法
        span {{order.currentOrderDelivery.itemDeliveryMethod.name}}
      .shipping-detail(v-if="order.currentOrderDelivery.deliveryCompanyLabel")
        span 配送業者名
        span {{order.currentOrderDelivery.deliveryCompanyLabel}}
      .shipping-detail(v-if="order.currentOrderDelivery.trackingNumber")
        span トラッキングナンバー
        span {{order.currentOrderDelivery.trackingNumber}}
    .customer
      h3 注文者
      p {{order.currentOrderContactInformation.name}}
      p 〒{{order.currentOrderContactInformation.zipCode}}
      p {{order.currentOrderContactInformation.address}}
      p {{order.currentOrderContactInformation.tel}}
    .remarks(v-if="order.currentOrderDelivery.memo")
      h3 備考
      p {{order.currentOrderDelivery.memo}}

</template>
<script>
export default {
  name: "OrderDetail",
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      open: false,
      showDiffShipping: false
    }
  },
  computed: {
    isDiffShippingItem() {
      let result = false;
      let scheduledShippingStartAt = '';
      this.order.currentOrderItems.forEach((orderItem, index) => {
        if (index === 0) {
          scheduledShippingStartAt = orderItem.scheduledShippingStartAt
        } else {
          if (scheduledShippingStartAt !== orderItem.scheduledShippingStartAt) {
            result = true
          }
        }
      })
      return result
    },
    scheduledShippingStartAt() {
      let scheduledShippingStartAt = '';
      this.order.currentOrderItems.forEach((orderItem, index) => {
        if ( orderItem.scheduledShippingStartAt) {
          if (!scheduledShippingStartAt || scheduledShippingStartAt < orderItem.scheduledShippingStartAt) {
            scheduledShippingStartAt = orderItem.formatScheduledShippingStartAt
          }
        }
      })
      return scheduledShippingStartAt;
    }
  }
};

</script>
<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";

.detail-container{
  border-bottom: 1px solid $gray-300;
  .section-accordion {
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    > p {
      font-weight: $font-weight-bold;
      cursor: pointer;
    }
    > .svg {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      > svg {
        height: auto;
        > path {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  > .detail-wrapper {
    padding: 0 16px;
    > .payment,
    .payment-methods,
    .reserve-shipping,
    .deliver,
    .shipping,
    .customer {
      padding: 16px 0;
      border-bottom: 1px solid $gray-300;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      > h3 {
        padding-bottom: 4px;
      }
    }
    > .payment {
      > .payment-detail {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        > .total {
          font-weight: $font-weight-bold;
        }
      }
    }
    > .reserve-shipping {
      > .info-accordion {
        background-color: $gray-100;
        padding: 8px;
        > .title {
          display: flex;
          > .svg {
            width: 24px;
            text-align: center;
          }
        }
        > .details {
          margin-top: 8px;
        }
      }
    }
    > .shipping {
      > .shipping-detail {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
      }
    }
    > .remarks {
      padding: 16px 0;
      > h3 {
        padding-bottom: 4px;
      }
      > p {
        white-space: pre;
      }
    }
  }
}

</style>