import gql from 'graphql-tag'

import {Errors} from './Common'

export const InitSubscriptionOrder = gql`
mutation($input: InitSubscriptionOrderInput!) {
  initSubscriptionOrder(input: $input) {
    subscriptionOrder {
      id
      adminShop {
        name
        code
      }
      item {
        id
        code
      }
      cartPath
    }
    ${Errors}
  }
}`

export const GetSubscriptionOrders = gql`
  query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
    subscriptionOrders (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
      items {
        id
        formatDeliveryOn
        orderId
        order {
          id
          code
          currentOrderSummary {
            orderStatus
            orderStatusLabel
          }
          currentOrderItems {
            priceInTaxWithCurrency
          }
          currentOrderPayment {
            isAddTax
            itemTotalPriceWithCurrency
            itemTotalPriceInTaxWithCurrency
            orderTotalPriceWithCurrency
            shippingPriceInTaxWithCurrency
            codFeeInTaxWithCurrency
            usePoint
            usePointWithCurrency
            paymentType
          }
        }
      }
    }
  }`

export const UpdateSubscriptionOrder = gql`
mutation($input: UpdateSubscriptionOrderInput!) {
  updateSubscriptionOrder(input: $input) {
    subscriptionOrder {
      id
      interval
      displayInterval
      deliveryOn
      formatDeliveryOn
      displayDeliveryTime
      deliveryTime
      deliveryMemo
    }
    errors {
      message
      model
      attribute
    }
  }
}`
