<template>
  <svg width="20" height="16" viewBox="0 0 20 16" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.42849 0.142578H18.5714C19.3571 0.142578 20 0.785438 20 1.57116V4.42831L9.99996 10.1426L-8.96454e-05 4.42831V1.57116C-8.96454e-05 0.785438 0.642771 0.142578 1.42849 0.142578Z"/>
    <path d="M20 7.28564V14.4285C20 15.2143 19.3571 15.8571 18.5714 15.8571H1.42849C0.642771 15.8571 -8.96454e-05 15.2143 -8.96454e-05 14.4285V7.28564L9.99996 13L20 7.28564Z"/>
  </svg>
</template>
<script>
  export default {
    name: "mail-svg"
  }
</script>
