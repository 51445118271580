<template lang="pug">
  IconBase(:color="color" :size="size")
    CloseSvg
</template>
<script>
  import IconBase from './IconBase'
  import CloseSvg from "../svg/CloseSvg";
  export default {
    name: "CloseIcon",
    components: {
      IconBase,
      CloseSvg
    },
    props: {
      color: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: '16'
      }
    }
  }
</script>
