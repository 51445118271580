<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3643 0.628571L19.3714 1.63571C20.2071 2.47143 20.2071 3.82857 19.3714 4.67143L10.6214 13.4286L6.00714 14.5786C5.96429 14.5857 5.92143 14.5929 5.88572 14.5929C5.58571 14.5929 5.34286 14.3071 5.42143 14L6.57143 9.38571L15.3357 0.628571C15.75 0.207143 16.3 0 16.85 0C17.3929 0 17.9429 0.207143 18.3643 0.628571ZM7.75 10.5643L7.19286 12.8071L9.43572 12.25L7.75 10.5643ZM8.59286 9.38571L10.6143 11.4071L16.8429 5.17857L14.8214 3.15714L8.59286 9.38571ZM17.8571 4.16429L18.3643 3.65714C18.6429 3.37857 18.6429 2.92857 18.3643 2.64286L17.3571 1.63571C17.1714 1.45714 16.9643 1.42857 16.85 1.42857C16.7357 1.42857 16.5214 1.45714 16.3429 1.63571L15.8357 2.14286L17.8571 4.16429ZM16.55 18.5715C16.8786 18.5715 17.1429 18.3072 17.1429 17.9786H17.1357V14.2857C17.1357 13.8929 17.4571 13.5715 17.85 13.5715C18.2429 13.5715 18.5643 13.8929 18.5643 14.2857V17.9786C18.5643 19.0929 17.6571 20 16.5429 20H2.02143C0.907143 20 0 19.0929 0 17.9715V4.87861C0 3.76432 0.907143 2.85718 2.02143 2.85718H5.71428C6.10714 2.85718 6.42857 3.17861 6.42857 3.57146C6.42857 3.96432 6.10714 4.28575 5.71428 4.28575H2.02143C1.7 4.28575 1.42857 4.55718 1.42857 4.87861V17.9786C1.42857 18.3072 1.69286 18.5715 2.02143 18.5715H16.55Z" fill="#333333"/>
  </svg>
</template>
<script>
  export default {
    name: "EditSvg"
  }
</script>
