<template lang="pug">
  IconBase
    PenSvg
</template>
<script>
  import IconBase from './IconBase'
  import PenSvg from "../svg/PenSvg";
  export default {
    name: "pen-icon",
    components: {
      IconBase,
      PenSvg
    }
  }
</script>
