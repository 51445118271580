<template lang="pug">
  a.shop-card(:href="`/shop/${adminShop.code}/`" @click="$_gaSendEvent('ショップトップへ遷移', 'トップ生産者一覧', adminShop.name)")
    img.image(:src="adminShop.ogImageSet.ogpImage" :alt="`${adminShop.name}ロゴ`" width="100%" height="auto")
    p.info {{ adminShop.prefectureName }} ({{ adminShop.businessContents }})
    p.name {{ adminShop.name }}
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'

  export default {
    name: 'ShopCard',
    mixins: [GlobalMixin],
    props: {
      adminShop: {
        type: Object,
        required: true,
        default: () => {}
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .shop-card {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      > .info, > .name {
        color: $olive-300;
      }
    }

    > .image {
      margin-bottom: 3px;
    }

    > .info {
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(xxs);
      line-height: $line-height-snug;
      color: $black-400;
    }

    > .name {
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(sm);
      line-height: $line-height-snug;
      color: $black-400;
    }
  }
</style>
