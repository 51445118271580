<template lang="pug">
  Modal.gift-modal(ref="giftModal" size="md")
    template(v-slot:header) ギフトの設定
    template(v-slot:body)
      template(v-if="itemGroup.isWrappingPossible")
        h3 ラッピングの選択
        FormBlock
          FormBody
            FormGroup
              FormItem
                FormPulldown(v-model="form.wrappingId" :options="wrappingOptions" :allowClear="true")

      template(v-if="itemGroup.isNoshiPossible")
        h3 のしの選択（無料）
        FormBlock
          FormBody
            FormGroup
              FormItem
                FormPulldown(v-model="form.noshiId" :options="noshiOptions" :allowClear="true")
            FormGroup(v-if="form.noshiId")
              FormItem
                FormPulldown(v-model="form.noshiNamePrintType" :options="namePrintTypeOptions")
            template(v-if="form.noshiId && form.noshiNamePrintType !== 'nothing'")
              FormGroup(v-if="form.noshiNamePrintType === 'name_1'")
                FormItem
                  FormLabel(:is-required="true") お名前
                  FormTextfield(v-model="form.noshiNameMiddle" placeholder="お名前を入力してください" :isError="'noshi_name_middle' in $_validationErrorMessage")
                  FormMessage(:isError="'noshi_name_middle' in $_validationErrorMessage") {{$_validationErrorMessage.noshi_name_middle}}
              FormGroup.double-name(v-if="form.noshiNamePrintType === 'name_2'")
                FormItem.left-name(:col="2")
                  FormLabel(:is-required="true") お名前（左）
                  FormTextfield(v-model="form.noshiNameLeft" placeholder="お名前を入力してください" :isError="'noshi_name_left' in $_validationErrorMessage")
                  FormMessage(:isError="'noshi_name_left' in $_validationErrorMessage") {{$_validationErrorMessage.noshi_name_left}}
                FormItem.right-name(:col="2")
                  FormLabel(:is-required="true") お名前（右）
                  FormTextfield(v-model="form.noshiNameRight" placeholder="お名前を入力してください" :isError="'noshi_name_right' in $_validationErrorMessage")
                  FormMessage(:isError="'noshi_name_right' in $_validationErrorMessage") {{$_validationErrorMessage.noshi_name_right}}
              template(v-if="form.noshiNamePrintType === 'family_join2'")
                FormGroup
                  FormItem
                    FormLabel(:is-required="true") 姓
                    FormTextfield(v-model="form.noshiLastName" placeholder="姓を入力してください" :isError="'noshi_last_name' in $_validationErrorMessage")
                    FormMessage(:isError="'noshi_last_name' in $_validationErrorMessage") {{$_validationErrorMessage.noshi_last_name}}
                FormGroup.double-name
                  FormItem.left-name(:col="2")
                    FormLabel(:is-required="true") お名前（左）
                    FormTextfield(v-model="form.noshiNameLeft" placeholder="名を入力してください" :isError="'noshi_name_left' in $_validationErrorMessage")
                    FormMessage(:isError="'noshi_name_left' in $_validationErrorMessage") {{$_validationErrorMessage.noshi_name_left}}
                  FormItem.right-name(:col="2")
                    FormLabel(:is-required="true") お名前（右）
                    FormTextfield(v-model="form.noshiNameRight" placeholder="名を入力してください" :isError="'noshi_name_right' in $_validationErrorMessage")
                    FormMessage(:isError="'noshi_name_right' in $_validationErrorMessage") {{$_validationErrorMessage.noshi_name_right}}
            FormGroup(v-if="form.noshiId")
              FormItem
                FormLabel(:isRequired="isNoshiMemoRequired") {{isNoshiMemoRequired ? '表書き' : ''}} ご要望など
                FormTextarea(v-model="form.noshiMemo" :placeholder="`${isNoshiMemoRequired ? '表書き、' : '' }ご要望などがありましたらご入力ください`" :isError="'noshi_memo' in $_validationErrorMessage")
                FormMessage(:isError="'noshi_memo' in $_validationErrorMessage") {{$_validationErrorMessage.noshi_memo}}
        AlertMessage(color="black") ※のしの種類によっては対応できない場合もありますので、詳しくは事前にご相談ください。

      template(v-if="itemGroup.isMessageCardPossible")
        h3 メッセージカードの選択
        FormBlock
          FormBody
            FormGroup
              FormItem
                FormPulldown(v-model="form.messageCardId" :options="messageCardOptions" :allowClear="true")
            FormGroup(v-if="form.messageCardId")
              FormItem
                FormTextarea(v-model="form.messageCardMessage" placeholder="任意のメッセージを入力してください")
    template(v-slot:footer)
      ButtonBase(@click="setGift()") ギフトの設定を決定する
</template>
<script>
  import GlobalMixin from "../../mixin/GlobalMixin";
  import GiftMixin from '../../mixin/GiftMixin'
  import { CheckGiftSetting } from "../../graphqls/CartItems";

  import Modal from "./Modal";
  import FormBlock from '../form/FormBlock'
  import FormBody from '../form/FormBody'
  import FormGroup from '../form/FormGroup'
  import FormItem from '../form/FormItem'
  import FormLabel from '../form/FormLabel'
  import FormPulldown from '../form/FormPulldown'
  import FormTextfield from '../form/FormTextfield'
  import FormTextarea from '../form/FormTextarea'
  import AlertMessage from '../alert/AlertMessage'
  import ButtonBase from '../button/ButtonBase'
  import FormMessage from "../form/FormMessage";

  export default {
    name: "gift-modal",
    mixins: [GlobalMixin, GiftMixin],
    components: {
      FormMessage,
      Modal,
      FormBlock,
      FormBody,
      FormGroup,
      FormItem,
      FormLabel,
      FormPulldown,
      FormTextfield,
      FormTextarea,
      AlertMessage,
      ButtonBase
    },
    props: {
      item: {
        type: Object,
        default: () => {
          return {
            id: null,
          }
        }
      },
      itemGroup: {
        type: Object,
        default: () => {
          return {
            noshiPossible: false,
            wrappingPossible: false,
            messageCardPossible: false,
            noshis: [],
            wrappings: [],
            messageCards: [],
          }
        }
      },
      value: {
        type: Object,
        default: () => {
          return {
            giftForm: {
              noshiId: '',
              noshiNameMiddle: '',
              noshiNameRight: '',
              noshiNameLeft: '',
              noshiLastName: '',
              noshiMemo: '',
              noshiNamePrintType: 'nothing',
              wrappingId: '',
              messageCardId: '',
              messageCardMessage: '',
            }
          }
        }
      },
    },
    data() {
      return {
        form: {},
      }
    },
    computed: {
      wrappingOptions() {
        let result = []
        for (let wrapping of this.itemGroup.publishedWrappings) {
          result.push({id: wrapping.id, text: `${wrapping.name} (+${Number(wrapping.priceInTax).toLocaleString()}円)`})
        }
        return result
      },
      noshiOptions() {
        let result = []
        for (let noshi of this.itemGroup.publishedNoshis) {
          result.push({id: noshi.id, text: noshi.name})
        }
        return result
      },
      messageCardOptions() {
        let result = []
        for (let messageCard of this.itemGroup.publishedMessageCards) {
          result.push({id: messageCard.id, text: `${messageCard.name} (+${Number(messageCard.priceInTax).toLocaleString()}円)`})
        }
        return result
      },
      namePrintTypeOptions() {
        return [
          {id: 'nothing', text: '名前なし'},
          {id: 'name_1', text: '名入れあり(1名)'},
          {id: 'name_2', text: '名入れあり(2名)'},
          {id: 'family_join2', text: '名入れあり(連名)'},
        ]
      },
      isNoshiMemoRequired() {
        const noshi = this.itemGroup.publishedNoshis.find(i => i.id === this.form.noshiId)
        return noshi && noshi.isMemoRequired
      }
    },
    watch: {
      value: {
        handler(newValue) {
          this.form = JSON.parse(JSON.stringify(newValue))
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      async setGift() {
        this.$_clearValidationErrors()
        const { data } = await this.$apollo
        .mutate({
          mutation: CheckGiftSetting,
          // Parameters
          variables: {
            input: {
              itemId: this.item.id,
              quantity: 1,
              ...this.form
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        });
        const errors = data.checkGiftSetting.errors
        if (errors.length > 0) {
          this.$_parseError(errors)
        } else {
          this.$emit('input', this.form)
          this.close()
        }
        this.loadingStop();
      },
      open(e) {
        this.$refs.giftModal.open()
        this.$emit('open', e)
      },
      close(e) {
        this.$refs.giftModal.close()
        this.$emit('close', e)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .gift-modal {
    > .modal-content {
      > .body {
        > h3 {
          margin: 0;
          color: $olive-dark;
          line-height: 100%;
          @include font-size(sm);

          &:not(:first-child) {
            border-top: 1px solid $gray-base;
            @include padding-top(sm);
            @include margin-top(sm);
          }
        }

        > .form-block {
          @include margin-top(sm);

          > .form-body {
            > .form-group {
              + .form-group {
                @include margin-top(xs);
              }
              @include mq-down(md) {
                &.double-name {
                  flex-direction: column;
                  .left-name {
                    order: 2;
                    @include margin-top(md);
                  }
                  .right-name {
                    order: 1;
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }

        > .alert-message {
          @include margin-top(xxxs);
        }
      }
    }
  }
</style>
