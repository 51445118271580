import gql from "graphql-tag";

export const GetInfoBanner = gql`
query($path: String!)  {
  infoBanner(path: $path)  {
    id
    image
    url
    name
    imageSet {
      mdImage
      orgImage
    }
  }
}
`