<template>
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.80714 0.611111C4.66762 0.611111 5.47238 0.919722 6.07595 1.47889L6.5 1.87L6.92405 1.47889C7.52762 0.919722 8.33238 0.611111 9.19286 0.611111C10.0533 0.611111 10.8581 0.919722 11.4617 1.47889C12.056 2.02889 12.381 2.75306 12.381 3.52611C12.381 4.29611 12.0529 5.02333 11.4617 5.57333L6.5 10.1628L1.53833 5.57028C0.947143 5.02028 0.619048 4.29611 0.619048 3.52306C0.619048 2.75306 0.947143 2.02583 1.53833 1.47583C2.1419 0.919722 2.94976 0.611111 3.80714 0.611111ZM3.80714 0C2.83214 0 1.85714 0.345278 1.11429 1.03278C-0.371429 2.40778 -0.371429 4.64139 1.11429 6.01639L6.5 11L11.8857 6.01639C13.3714 4.64139 13.3714 2.40778 11.8857 1.03278C11.1398 0.345278 10.1679 0 9.19286 0C8.21786 0 7.24286 0.345278 6.5 1.03278C5.75714 0.345278 4.78214 0 3.80714 0Z" fill="#737373"/>
  </svg>
</template>
<script>
  export default {
    name: "like-svg"
  }
</script>
