<template lang="pug">
  .form-search
    input.textfield(type="text" placeholder="商品・生産者・キーワード検索" v-model="searchQuery"
      @keypress.prevent.enter.exact="enable_submit"
      @keyup.prevent.enter.exact="submit")
    button.button(@click="search()")
      include ../../../../../assets/images/icon/search-solid.svg
</template>

<script>
  import GlobalMixin from "../../../mixin/GlobalMixin"
  export default {
    name: 'FormSearch',
    mixins: [GlobalMixin],
    data() {
      return {
        searchQuery: '',
        canSubmitSearch: false,
      }
    },
    methods: {
      search: function() {
        this.$_gaSendEvent('検索結果へ遷移', 'Gナビ', '検索')
        location.href = `/shops/items/search/?q=${this.searchQuery}`
      },
      enable_submit() {
        this.can_submit_search = true;
      },
      submit() {
        if (!this.can_submit_search) return;
        this.search();
        this.can_submit_search = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .form-search {
    width: 100%;
    height: 34px;
    display: flex;

    .textfield {
      width: 100%;
      @include padding(xxxs, xxs);
      border: none;
      border-radius: 0;
      outline: none;
      appearance: none;
      background: $ivory-100;
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      font-size: 16px; // iPhoneで16px以下の場合、フォーカス時に拡大されるため16pxで固定
      line-height: $line-height-none;
      color: $black-400;
      &::placeholder {
        color: $black-100;
      }
    }

    .button {
      min-width: 60px;
      border: none;
      outline: none;
      appearance: none;
      cursor: pointer;
      color: $white;
      background: $olive-300;
      &:hover {
        background: $olive-400;
      }

      > svg {
        width: 14px;
        display: block;
        margin: 0 auto;
      }
    }
  }
</style>
