import {mapActions, mapGetters} from 'vuex'
export default {
  data () {
    return {}
  },
  computed: {
    $_orderDropDownOptions() {
      return [
        {id: "popular", text: "人気順"},
        {id: "new", text: "新着順"},
        {id: "low", text: "価格が安い順"},
        {id: "high", text: "価格が高い順"},
      ]
    },
    $_orderDropDownForSearchOptions() {
      return [{id: "related_score", text: "関連度順"}].concat(this.$_orderDropDownOptions)
    },
    $_orderDropDownForTagOptions() {
      return [{id: "recommend_tag", text: "おすすめ順"}].concat(this.$_orderDropDownOptions)
    },
    $_orderDropDownForShopOptions() {
      return [{id: "recommend_shop", text: "おすすめ順"}].concat(this.$_orderDropDownOptions)
    },
    $_rankingOrderDropDownOptions() {
      return [
        {id: "monthranking", text: "月間ランキング"},
        {id: "weekranking", text: "週間ランキング"}
      ]
    },
    $_stockDropDownOptions() {
      return [
        {id: "instock", text: "在庫あり"},
        {id: "outstock", text: "在庫無し含む"},
      ]
    },
    $_storageTypeDropDownOptions() {
      return [
        {id: "", text: "すべての温度帯"},
        {id: "normal", text: "常温"},
        {id: "cold", text: "冷蔵"},
        {id: "freeze", text: "冷凍"},
      ]
    },
    $_customPageInfo() {
      return this.pageInfo
    },
  },
  methods: {
    $_changePage(newPage) {
      this.pageInfo.currentPage = newPage
      if (newPage === 1) {
        this.$router.push({name: this.topRouterName, query: this.$route.query })
      } else {
        this.$router.push({name: this.pageRouterName, params: {page: newPage}, query: this.$route.query})
      }
      this.fetchItemGroups();
    },
    $_changeOrder(type) {
      this.pageInfo.orderBy = type
      this.pageInfo.currentPage = 1
      let queryParam = { ...this.$route.query, order: this.pageInfo.orderBy, stock: this.stockFilter, storage: this.storageFilter }
      this.$router.push({name: this.topRouterName, query: { ...queryParam }})
      this.fetchItemGroups()
    },
    $_changeStockFilter(type) {
      // this.stockFilter = type
      this.pageInfo.currentPage = 1
      let queryParam = { ...this.$route.query, order: this.pageInfo.orderBy, stock: this.stockFilter, storage: this.storageFilter }
      this.$router.push({name: this.topRouterName, query: { ...queryParam }})
      this.fetchItemGroups()
    },
    $_changeStorageFilter(type) {
      this.storageFilter = type
      this.pageInfo.currentPage = 1
      let queryParam = { ...this.$route.query, order: this.pageInfo.orderBy, stock: this.stockFilter, storage: this.storageFilter }
      this.$router.push({name: this.topRouterName, query: { ...queryParam }})
      this.fetchItemGroups()
    },
    $_gaSendViewItemList(itemGroups, itemListName, pageInfo) {
      let items = []
      let startIndex = (pageInfo.currentPage - 1) * pageInfo.perPage
      let listPosition = 1 + startIndex
      for (const itemGroup of itemGroups) {
        let item = {
          item_id: itemGroup.id,
          index: listPosition
        }
        items.push(item)
        listPosition++
      }

      this.$gtag.event('view_item_list', {
        'item_list_name': itemListName,
        items: items
      })
    },
  }
}
