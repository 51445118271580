<template lang="pug">
  Modal.delivery-modal(ref="deliveryModal" size="md")
    template(v-slot:header) 配送方法と送料
    template(v-slot:body)
      p(v-if="showCompact") {{`この商品1点で宅急便コンパクト容量の${compactPercent}%を使用します。宅急便コンパクト対応商品の合計が100%を超えた場合は宅配便料金となります。`}}
      p(v-if="showPostDelivery") {{`この商品1点でメール便内容量の${mailPercent}%を使用します。メール便対応商品の合計が100%を超えた場合は宅配便料金となります。`}}
      Table
        thead
          tr
            th 地域
            th(v-if="showCompact") コンパクト
            th(v-if="showPostDelivery") メール便
            th 宅急便
        tbody
          tr(v-for="(p, i) in postage" :key="i")
            td {{p.prefectureName}}
            td(v-if="showCompact") {{p.compactPriceInTax | numberToCurrency}}
            td(v-if="showPostDelivery") {{p.postDeliveryPriceInTax | numberToCurrency}}
            td {{p.priceInTax | numberToCurrency}}
    template(v-slot:footer)
      ButtonBase(color="black-dark" :simple="true" @click="close()") 閉じる
</template>
<script>
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Modal from "./Modal";
  import Table from '../table/Table'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: "delivery-modal",
    mixins: [GlobalMixin],
    components: {
      Modal,
      Table,
      ButtonBase
    },
    props: {
      postage: {
        type: Array,
        default: () => []
      },
      compactPercent: {
        type: Number,
        default: 0
      },
      mailPercent: {
        type: Number,
        default: 0
      },
      isCompactDelivery: {
        type: Boolean,
        default: false
      },
      isPostDelivery: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      showCompact() {
        return this.isCompactDelivery && this.postage.some(p => p.compactPriceInTax);
      },
      showPostDelivery() {
        return this.isPostDelivery && this.postage.some(p => p.postDeliveryPriceInTax);
      }
    },
    methods: {
      open(e) {
        this.$refs.deliveryModal.open()
        this.$emit('open', e)
      },
      close(e) {
        this.$refs.deliveryModal.close()
        this.$emit('close', e)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .delivery-modal {
    > .modal-content {
      > .body {
        > p {
          color: $black-dark;
          line-height: 228%;
          @include margin-bottom(sm);
          @include font-size(xs);
        }
        > .table {
          width: 100%;

          > thead,
          > tbody {
            > tr {
              > th,
              > td {
                word-break: keep-all;
                font-weight: normal;
                text-align: right;
                @include font-size(xxs);

                &:first-child {
                  width: 100%;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
