<template lang="pug">
  .yane-heading
    .title
      i.h1rem
        LogoYaneSvg
      h2.heading2 {{title}}
      .heading3 {{subTitle}}
      slot
</template>

<script>
  import LogoYaneSvg from "../../components/svg/LogoYaneSvg";

  export default {
    name: "yane-heading",
    components: {
      LogoYaneSvg
    },
    props: {
      title: String,
      subTitle: String
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";

  .yane-heading {
    text-align: center;
    @include mq-down(){
      margin-left: -15px;
      margin-right: -15px;
    }
    >.title {
      display: inline-block;
      background: rgba($white,0.9);
      @include mq-down(){
        min-width: 210px;
      }
      @include mq-up() {
        min-width: 280px;
      }
      >[class^="heading"] {
        margin: 0;
      }
      >.heading1 {
        @include font-size(xs);
      }
      >.heading2 {
        @include font-size(lg);
        //font-family: $serif;
      }
      >.heading3 {
        @include font-size(xs);
        &.curve-bottom {
          margin-top: 6px;
        }
      }
      > a {
        @include margin(xxs);
        display: inline-block;
        text-decoration: underline;
      }
      & .text {
        display: inline-block;
        padding-right: 4px;
        text-decoration: underline;
        @include mq-down() {
          font-size: 14px;
        }
      }
    }
    &.-primary {
      >.title {
        color: $primary-base;
        svg {
          path,polygon,circle,rect {
            fill: $primary-base;
          }
        }
      }
    }
    &.-sm {
      @include mq-down(){
        margin-left: -15px;
        margin-right: -15px;
        padding: 1.5rem 0;
      }
      @include mq-up() {
        padding: 3rem 0;
      }
    }
  }
</style>
