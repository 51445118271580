<template lang="pug">
  .order-detail
    .sheet
      .contact
        .contact-header ご注文に関するお問合せ
        .contact-body
          p.paragraph.font-bold 注文番号 | 注文日時
          p.paragraph {{ order.code }} | {{ order.orderedAt | moment('YYYY/MM/DD HH:mm') }}
          .chat-list(v-if="order.contact")
            ChatItem(
              v-for="(contactComment, index) in order.contact.contactComments"
              :key="contactComment.id"
              :class="{ '-first': isShowAvatar(index) }"
              :isShowAvatar="isShowAvatar(index)"
              :avatarName="avatarName(contactComment)"
              :avatarImage="avatarImage(contactComment)"
              :commentText="contactComment.comment"
              :commentImage="contactComment.imageUrl"
              :elapsedTime="contactComment.elapsedTime"
            )
          AlertBase.my-md(v-if="errorMessages.length > 0" type="error")
            p(v-for="errorMessage of errorMessages") {{ errorMessage }}
          FormItem
            template(slot="title" v-if="!order.contact")
              FormLabel お問い合わせ内容
            template(slot="input")
              FormTextarea(
                v-model="form.comment"
                placeholder="ご入力してください"
                :error="'comment' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.comment"
              )
          FormUploadImage.my-md(ref="uploader" @onChange="uploadImage" :limit="5")
          .text-center
            ButtonBase(@click="createContactComment") 送信
    .button-block
      ButtonBase(variant="normal" href="/mypage/purchasehistory/") 注文履歴へ戻る
      ButtonBase(variant="normal" href="/mypage/contacts/") メッセージ一覧へ戻る
</template>

<script>
  import { GetOrderDetail } from '../../graphqls/Orders'
  import { CreateContactComment } from '../../graphqls/ContactComments'
  import {UpdateUserNotice} from "../../graphqls/UserNotices";
  import GlobalMixin from '../../mixin/GlobalMixin'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import ChatItem from '../../components/v1/chat/ChatItem'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormTextfield from '../../components/v1/form/FormTextfield'
  import FormTextarea from '../../components/v1/form/FormTextarea'
  import FormUploadImage from '../../components/v1/form/FormUploadImage'

  export default {
    name: 'OrderDetail',
    mixins: [GlobalMixin],
    components: {
      ButtonBase,
      ChatItem,
      AlertBase,
      FormItem,
      FormLabel,
      FormTextfield,
      FormTextarea,
      FormUploadImage,
    },
    data() {
      return {
        order: {
          code: '',
          orderedAt: '',
          contact: {
            contactComments: [],
            userNotice: null
          }
        },
        form: {
          orderId: '',
          comment: '',
        },
        images: [],
        errorMessages: []
      }
    },
    computed: {
      hasUserNotice() {
        return this.order && this.order.contact && this.order.contact.userNotice
      }
    },
    mounted() {
      this.getOrderDetail()
      // パンくずリストを設定
      this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/mypage/',
          name: 'マイページ',
        },
        {
          path: '/mypage/purchasehistory/',
          name: '注文履歴',
        },
        {
          path: `/mypage/purchasehistory/${this.$route.params.id}/`,
          name: 'ご注文に関するお問合せ',
        },
      ]})
    },
    methods: {
      async getOrderDetail() {
        this.loadingStart()
        const { data } = await this.$apollo
        .query({
          query: GetOrderDetail,
          variables: {
            orderId: this.$route.params.id
          }
        })
        this.order = data.order
        if (this.hasUserNotice) {
          this.updateUserNotice()
        }
        this.loadingStop()
      },
      async createContactComment() {
        this.errorMessages = []
        if (!this.form.comment && this.images.length === 0) {
          this.errorMessages.push('お問い合わせ内容を入力、または、画像を追加してください')
          return
        }

        this.loadingStart()
        this.form.orderId = this.$route.params.id
        for (let i = 0; i < 5; i++) {
          this.form[`image${i + 1}`] = this.images[i] || ''
        }
        const { data } = await this.$apollo
        .mutate({
          mutation: CreateContactComment,
          variables: {
            input: this.form
          }
        })
        if (!this.order.contact)
          this.order.contact = {}
          this.order.contact.contactComments = []
        this.order.contact.contactComments = data.createContactComment.contactComments

        this.form.comment = ''
        this.images = []
        this.$refs.uploader.clear()
        this.loadingStop()
      },
      async updateUserNotice() {
        this.errorMessages = []
        this.loadingStart()
        const { data } = await this.$apollo
            .mutate({
              mutation: UpdateUserNotice,
              variables: {
                input: {
                  id: this.order.contact.userNotice.id,
                  confirmedAt: new Date()
                }
              }
            })
        const updateUserNotice = data.updateUserNotice
        this.loadingStop()
      },
      isUserComment(contactComment) {
        return contactComment.user ? true : false
      },
      isShowAvatar(index) {
        if (index === 0) return true
        const previous = this.order.contact.contactComments[index - 1]
        const current = this.order.contact.contactComments[index]
        if (this.isUserComment(current)) {
          return previous.userId !== current.userId
        } else {
          return previous.adminUserId !== current.adminUserId
        }
      },
      avatarName(contactComment) {
        if (this.isUserComment(contactComment)) return contactComment.user.name
        return contactComment.adminUser.adminShop.name
      },
      avatarImage(contactComment) {
        if (!this.isUserComment(contactComment)) return contactComment.adminUser.adminShop.logoImageSet.smSqImage
        return ''
      },
      uploadImage(list) {
        this.images = list.map(img => img.file)
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .order-detail {
    > .sheet {
      width: 100%;
      background-color: $gray-100;
      @include padding(lg, xs);
      @include mq-down() {
        width: 100vw;
        margin-left: -10px;
        margin-right: -10px;
      }
      > .contact {
        width: 100%;
        max-width: 600px;
        background-color: $white;
        margin: 0 auto;
        > .contact-header {
          @include padding(sm);
          border-bottom: 1px solid $gray-200;
          font-family: $font-family-serif;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-normal;
          color: $black-400;
          text-align: center;
        }
        > .contact-body {
          @include padding(sm);
          > .chat-list {
            > .chat-item {
              @include margin-top(xs);
              &.-first {
                @include margin-top(md);
              }
            }
          }
        }
      }
    }
    .button-block {
      display: flex;
      justify-content: center;
      @include margin-top(lg);
      @include margin-bottom(lg);
      .button-base {
        @include margin-right(xxs);
        @include margin-left(xxs);
      }
    }
  }
</style>
