<template lang="pug">
  .draggable-image(:class="classes")
    .handle.image
      img(:src="src")
    .close(@click="removeImage")
    .handle.label(v-if="isSortable")
      span 並び替え
</template>

<script>
  export default {
    name: 'DraggableImage',
    components: {

    },
    props: {
      src: {
        type: String,
        required: true,
        default: ''
      },
      isSortable: {
        type: Boolean,
        required: false,
        default: true
      },
      isDeletable: {
        type: Boolean,
        required: false,
        default: true
      },
      isSingle: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    data() {
      return {}
    },
    computed: {
      classes() {
        let classes = []
        if (this.isDeletable) classes.push('is-deletable');
        if (this.isSingle) classes.push('is-single');
        return classes
      }
    },
    methods: {
      removeImage (event) {
        this.$emit('click', event)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .draggable-image {
    width: 100%;
    height: 100%;
    position: relative;
    &.is-deletable {
      >.close {
        display: flex;
      }
    }
    >.image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      >img {
        width: 100%;
        image-orientation: from-image;
      }
    }
    >.close {
      display: none;
      width: 24px;
      height: 24px;
      position: absolute;
      right: -8px;
      top: -8px;
      background-image: url(../../../../../assets/images/icon/close.png);
      background-size: contain;
      cursor: pointer;
      opacity: 1;
    }
    >.label {
      width: 100%;
      height: 32px;
      position: absolute;
      bottom: 0;
      display: flex;
      align-content: center;
      align-items: center;
      background-color: rgba(51, 51, 51, .5);
      background-image: url(../../../../../assets/images/icon/move.svg);
      background-position: left 10px center;
      background-repeat: no-repeat;
      border-radius: 0;
      >span {
        padding-left: 38px;
        color: $white;
        font-size: 14px;
        line-height: 100%;
      }
    }
  }
</style>
