<template lang="pug">
.dropdown-wrap
  .dropdown(:class="{ 'is-active': isDropdownActive }", v-on-clickaway="away")
    .dropdown-trigger(@click="isDropdownActive = !isDropdownActive")
      button.button(
        aria-haspopup="true",
        aria-controls="{ 'dropdown-menu' }"
      )
        span.title {{ selectedItem }}
        span.caret
    .dropdown-menu(role="menu")
      ul.dropdown-content
        a.dropdown-item(
          v-for="( option, index) of options",
          :key="index",
          @click="select(option.id)"
        ) {{ option.text }}
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  name: "DropDown",
  mixins: [clickaway],
  props: {
    value: {
      type: String,
      required: true,
      default: undefined,
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      isDropdownActive: false,
    };
  },
  computed: {
    selectedItem() {
      for (let option of this.options) {
        if (option.id === this.value) {
          return option.text;
        }
      }
      return "";
    },
  },
  methods: {
    select(type) {
      this.$emit("change", type);
      this.isDropdownActive = false;
    },
    away() {
      this.isDropdownActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
.dropdown-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  > .dropdown.is-active .dropdown-menu {
    display: block;
  }
  > .dropdown {
    position: relative;
    > .dropdown-trigger {
      > .button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        appearance: none;
        padding: 12px 16px;
        transition: all 0.15s ease-in-out;
        &:hover {
          background-color: $gray-300;
        }

        > .title {
          color: $black-400;
          @include font-size(sm);
          font-family: $font-family-sans;
          line-height: $line-height-none;
        }

        > .caret {
          display: inline-block;
          width: 0;
          height: 0;
          vertical-align: middle;
          border-top: 4px dashed;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          margin-left: 0;
        }
      }
    }

    > .dropdown-menu {
      position: absolute;
      display: none;
      left: 0;
      min-width: 12rem;
      padding: 0;
      margin: 0;
      top: 100%;
      z-index: 20;
      box-shadow: 0 6px 12px rgba($black-100, 0.2);

      > .dropdown-content {
        display: flex;
        flex-direction: column;
        border: 1px solid rgba($black-900, 0.15);
        border-radius: 4px;
        background-color: $white;
        background-clip: padding-box;
        @include padding(xxs, 0);
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;

        > .dropdown-item {
          display: block;
          text-align: left;
          white-space: nowrap;
          width: 100%;
          color: $black-400;
          @include font-size(sm);
          line-height: $line-height-loose;
          padding: 0.375rem 1rem;
          position: relative;
          text-decoration: none;
          &:hover {
            background-color: $gray-100;
            color: $black-500;
          }
        }
      }
    }
  }
}
</style>
