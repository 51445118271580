import gql from "graphql-tag";

export const CreateInvoicePdfUrl = gql`
mutation($input: CreateInvoicePdfUrlInput!) {
  createInvoicePdfUrl(input: $input) {
    downloadUrl
    errors {
      attribute
      model
      message
    }
  }
}
`


export const InitDownloadInvoicePdf = gql`
query {
  initDownloadInvoicePdf {
    downloadInvoicePdf {
      id
    }
    corporateName
    downloadOn
    downloadDateSelectOption {
      id
      text
    }
  }
}`