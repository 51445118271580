<template lang="pug">
  section.section-item-tag
    CategoryTagList
      template(v-for="itemTag in itemTags")
        CategoryTag(
          :href="`/shops/items/tag/${itemTag.code}/`"
          :name="itemTag.name"
          eventAction="商品一覧へ遷移"
          eventCategory="商品詳細"
        )
</template>

<script>
  import {GetItemTags} from '../../../graphqls/ItemTags'
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import CategoryTag from '../categorytag/CategoryTag'
  import CategoryTagList from '../categorytag/CategoryTagList'

  export default {
    name: 'SectionItemTag',
    mixins: [GlobalMixin],
    components: {
      CategoryTag,
      CategoryTagList
    },
    props: {
      itemGroupCode: {
        type: String,
        require: true,
        default: ''
      },
      eventCategory: {
        type: String,
        require: true,
        default: ''
      },
    },
    data() {
      return {
        itemTags: []
      }
    },
    mounted() {
      this.fetchItemTags()
    },
    methods: {
      async fetchItemTags() {
        const { data } = await this.$apollo
        .query({
          query: GetItemTags,
          variables: { search: JSON.stringify({item_groups_code_eq: this.itemGroupCode}) },
          client: 'apiClient'
        })
        this.itemTags = data.itemTags.items
      }
    }
  }
</script>
