<template lang="pug">
  IconBase
    CartLineSvg
</template>
<script>
  import IconBase from './IconBase'
  import CartLineSvg from "../svg/CartLineSvg";
  export default {
    name: "cart-line-icon",
    components: {
      IconBase,
      CartLineSvg
    }
  }
</script>
