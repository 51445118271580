<template lang="pug">
  .container
    BlogHeader(:adminShop="adminShop" :subTitle="subTitle")
    p(v-if="!hasRecipes") 現在、レシピがありません。
    BlogList(v-else)
      BlogCard(v-for="recipe in recipesItems" :key="recipe.id" :post="recipe" :shopInfo="true")
    .col-xs-12
      Pager(@change="changePage" :custom-page-info="pageInfo")
</template>

<script>
  import { GetRecipesForIndex } from "../../graphqls/Recipes";
  import { GetAdminShop } from "../../graphqls/AdminShops";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import BlogHeader from "../../components/v1/post/BlogHeader";
  import BlogList from "../../components/v1/post/BlogList";
  import BlogCard from "../../components/v1/post/BlogCard";
  import Pager from "../../components/paginate/Pager";
  
  export default {
    name: "shop-recipes-index",
    mixins: [GlobalMixin],
    components: {
      BlogHeader,
      BlogList,
      BlogCard,
      Pager,
    },
    data: function () {
      return {
        recipes: {
          items: []
        },
        pageInfo: {
          perPage: 10,
          currentPage: 1,
        },
        adminShop: {
          id: '',
          code: '',
          name: '',
          ogImageSet: {
            lgImage: ''
          },
        },
        subTitle: 'レシピ',
      }
    },
    computed: {
      adminShopCode() {
        return this.$route.params.adminShopCode
      },
      hasRecipes() {
        return this.recipes.items.length > 0;
      },
      recipesItems() {
        return this.recipes.items.map(recipe => ({
          id: recipe.id,
          frontUrl: recipe.frontUrl,
          eyeCatchImage: this.getEyeCatchImage(recipe),
          logoImage: recipe.adminShop.logoImageSet.xsSqImage || "https://cdn.taberutokurasuto.com/image/common/noimage_100x100.gif",
          shopName: recipe.adminShop.name,
          elapsedTime: recipe.elapsedTime,
          title: recipe.title,
        }));
      },
    },
    mounted: async function() {
      await this.fetchAdminShop();
      this.setBreadcrumbs({
        breadcrumbs: [
          {
            path: '/',
            name: 'TOP',
          },
          {
            path: `/shop/${this.adminShop.code}/`,
            name: this.adminShop.name,
          },
          {
            path: `/shop/${this.adminShop.code}/recipes/`,
            name: `${this.adminShop.name}のレシピ一覧`,
          },
        ],
      });
      await this.fetchRecipes();
      this.showSide();
      this.fetchCurrentShop({
        shopCode: this.adminShop.code
      });
    },
    methods: {
      async fetchRecipes() {
        this.loadingStart();
        try {
          const { data } = await this.$apollo
          .query({
            query: GetRecipesForIndex,
            variables: {
              ...this.pageInfo,
              orderBy: 'published_at desc',
              search: JSON.stringify({                
                admin_shop_id_eq: this.adminShop.id,
              }),
            },
            client: 'apiClient',
          })
          .catch(error => {
            this.loadingStop();
            return;
          });
          this.recipes = data.recipes;
          this.pageInfo = data.recipes.pageInfo
          this.loadingStop();
        }
        catch (error) {
          this.loadingStop();
          console.log('e', error)
        }
      },    
      async fetchAdminShop() {
        const { data } = await this.$apollo
        .query({
          query: GetAdminShop,
          variables: {
            adminShopCode: this.adminShopCode,
          }
        })
        .catch(error => {
          return;
        });
        this.adminShop = data.adminShop;
      },
      getEyeCatchImage(recipe) {
        if (recipe.eyeCatchImage) {
          return recipe.eyeCatchImageSet.xsImage;
        } else if (recipe.mainImage) {
          return recipe.mainImageSet.xsImage;
        } else {
          return "https://cdn.taberutokurasuto.com/image/common/noimage_300x300.gif";
        }
      },
      changePage(newPage) {
        this.pageInfo.currentPage = newPage
        this.fetchRecipes()
        this.$scrollTo("body")
      },
    },
  }
</script>

<style scoped lang="scss">
@import "../../../../assets/stylesheets/v1/styles";
  .container {
    > p {
      font-size: 14px;
    }
    > .col-xs-12 {
      > .pager-wrap {
        @include margin-top(md);
      }
    }
  }
</style>
