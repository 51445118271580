import { apolloClient } from '../config/apollo'
import { GetAdminShop } from '../graphqls/AdminShops'

const actions = {
  loadingStart({ commit }) {
    commit('setLoading', { loading: true })
  },
  loadingStop({ commit }) {
    commit('setLoading', { loading: false })
  },
  showSide({ commit }) {
    commit('setHasSide', { hasSide: true })
  },
  hideSide({ commit }) {
    commit('setHasSide', { hasSide: false })
  },
  showBanner({ commit }) {
    commit('setHasBanner', { hasBanner: true })
  },
  hideBanner({ commit }) {
    commit('setHasBanner', { hasBanner: false })
  },
  setBreadcrumbs({ commit }, payload) {
    commit('setBreadcrumbs', payload )
  },
  resetBreadcrumbs({ commit }) {
    commit('setBreadcrumbs', { breadcrumbs: [] })
  },
  setFlashMessage({ commit }, payload) {
    commit('setFlashMessage', payload )
  },
  clearFlashMessage({ commit }, payload) {
    commit('setFlashMessage', { variant: payload.variant })
  },
  setErrorMessages({ commit }, payload) {
    commit('setErrorMessages', payload )
  },
  async setPageInfo({ commit }, payload) {
    commit('setPageInfo', payload )
  },
  setCurrentUser({ commit }, payload) {
    commit('setCurrentUser', payload )
  },
  setToastMessage({ commit }, payload) {
    commit('setToastMessage', payload )
  },
  setFavoriteItems({ commit }, payload) {
    commit('setFavoriteItems', payload )
  },
  addFavoriteItem({ commit }, payload) {
    commit('addFavoriteItem', payload )
  },
  removeFavoriteItem({ commit }, payload) {
    commit('removeFavoriteItem', payload )
  },
  async fetchCurrentShop({ commit }, payload) {
    const { data } = await apolloClient.query({
      query: GetAdminShop,
      variables: {
        adminShopId: payload.shopId,
        adminShopCode: payload.shopCode
      }
    })
    commit('setCurrentShop', {
      currentShop: data.adminShop
    })
  },
  setToast({ commit }, payload) {
    commit('setToast', payload )
  },
  clearToast({ commit }, payload) {
    commit('setToast', { toast: null })
  },
  setCurrentItemGroup({ commit }, payload) {
    commit('setCurrentItemGroup', payload )
  },
  showCommonCoupon({ commit }, payload) {
    commit('setCoupon', { hasCoupon: true })
  },
  showHomeCoupon({ commit }, payload) {
    commit('setCoupon', { hasCoupon: true, pageName: 'home' })
  },
  showShopCoupon({ commit }, payload) {
    commit('setCoupon', { hasCoupon: true, shopCode: payload.shopCode })
  },
}

export default actions
