<template lang="pug">
  .regist-confirm
    h1.heading-1.text-center.my-md ご本人様確認
    template(v-if="tokenError")
      AlertBase.mb-sm(type="error")
        p {{ tokenError.fullMessage }}
      .other.text-center
        ButtonBase.mb-sm(variant="normal" href="/regist/")
          | 会員登録
      .other.text-center
        ButtonBase.mb-sm(variant="normal" href="/login/")
          | ログイン
    template(v-else)
      AlertBase.mb-sm(type="warning")
        p.bold.text-center
          | ご本人様確認が完了していません。
          br
          | [ご本人様確認コード]メールの確認コードを入力して、
          br
          | 会員登録を完了してください。
      ContainerSheet.mb-sm
        FormItem
          template(slot="title")
            FormLabel(type="required") 確認コード
          template(slot="input")
            FormTextfield(
              v-model="form.confirmationCode"
              type="text"
              placeholder="確認コードを入力してください"
              :error="'confirmation_code_confirmation' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.confirmation_code_confirmation"
            )
        ButtonBase(@click="confirmUser" :block="true") 確認する
      p.paragraph
        | この度は、たべるとくらすとの会員登録にお申し込み頂き誠にありがとうございます。
      p.paragraph
        | 不正利用防止のため、ご本人様確認をおこなっております。
        | 会員登録お申込み時に、ご登録頂いたメールアドレス(
        b {{ user.email }}
        | )宛に[ご本人様確認コード]メールを送信いたしました。
        | メールに記載されている確認コードを入力して[確認する]ボタンをクリックしてください。
      AlertBase.my-sm(type="info")
        p メールが届かない場合は、下記をご確認ください。
        ol
          li 迷惑メールフォルダーをご確認ください。
          li @taberutokurasuto.comのメール受信が可能となっているか、ドメイン指定をご確認ください。(ドメイン指定の設定を変更した場合は、もう一度最初から
            a.anker-link(href="/regist/") 会員登録
            | を行ってください。)
          li 上記の方法で解決しない場合は、
            a.anker-link(href="/contacts/") お問い合わせフォーム
            | よりご連絡ください。
</template>

<script>
  import { GetTemporaryUser, ConfirmUser } from "../../graphqls/Users"
  import GlobalMixin from "../../mixin/GlobalMixin"
  import AlertBase from '../../components/v1/alert/AlertBase'
  import ContainerSheet from '../../components/v1/container/ContainerSheet'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormTextfield from '../../components/v1/form/FormTextfield'
  import ButtonBase from '../../components/v1/button/ButtonBase'

  export default {
    name: 'RegistConfirm',
    mixins: [GlobalMixin],
    components: {
      AlertBase,
      ContainerSheet,
      FormItem,
      FormLabel,
      FormTextfield,
      ButtonBase,
    },
    metaInfo() {
      return {
      }
    },
    data() {
      return {
        user: {},
        tokenError: null,
        form: {
          confirmationToken: '',
          confirmationCode: '',
        }
      }
    },
    methods: {
      async fetchTemporaryUser() {
        this.loadingStart()
        const { data } = await this.$apollo
        .query({
          query: GetTemporaryUser,
          variables: {
            confirmationToken: this.form.confirmationToken
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        })

        const errors = data.temporaryUser.errors
        if (errors) {
          for (let error of errors) {
            if (error.attribute === 'confirmation_token') {
              this.tokenError = error
            }
          }
        } else {
          this.user = data.temporaryUser.user
        }
        this.loadingStop()
      },
      async confirmUser() {
        this.loadingStart()
        this.$_clearValidationErrors()

        const { data } = await this.$apollo
        .mutate({
          mutation: ConfirmUser,
          variables: {
            input: this.form
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        })

        const errors = data.confirmUser.errors
        if (errors) {
          for (let error of errors) {
            if (!error.attribute || error.attribute === 'confirmation_token') {
              this.tokenError = error
            }
          }
          this.$_parseError(errors)
          this.loadingStop()
        } else {
          localStorage.setItem('user_access_token', data.confirmUser.user.jwtToken)
          this.setToast({ toast: { type: 'success', messages: [data.confirmUser.message] }})
          this.$gtag.event('sign_up', {'method': 'email'})
          setTimeout(function () {
            location.href = data.confirmUser.previousUrl
          }.bind(this), 2000)
        }
      }
    },
    mounted() {
      this.form.confirmationToken = this.$route.params.confirmation_token
      this.fetchTemporaryUser()
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .regist-confirm {
    width: 100%;
    max-width: 620px;
    margin: 0 auto;

    > .container-sheet {
      @include padding-top(md);
      @include padding-bottom(md);

      > .form-item {
        margin-top: 0;
      }
    }
  }
</style>
