<template lang="pug">
  .the-breadcrumbs(v-if="breadcrumbs.length > 0")
    .inner
      ol.breadcrumbs
        li(v-for="breadcrumb in breadcrumbs")
          a(:href="breadcrumb.path" @click="$_gaSendEvent(`${breadcrumb.name}へ遷移`, 'パンくず', breadcrumb.name)") {{ breadcrumb.name }}
</template>

<script>
  import { mapGetters } from 'vuex'
  import GlobalMixin from '../../../mixin/GlobalMixin'

  export default {
    name: 'TheBreadcrumbs',
    mixins: [GlobalMixin],
    computed: {
      ...mapGetters([
        'breadcrumbs'
      ])
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .the-breadcrumbs {
    @include margin-bottom(xs);

    > .inner {
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      @include padding(zero, xs);

      > .breadcrumbs {
        > li {
          display: inline;

          &:after {
            content: '>';
            color: $gray-400;
            @include padding(zero, xxxs);
          }
          &:last-child:after {
            content: '';
          }

          > a {
            font-family: $font-family-sans;
            font-weight: $font-weight-regular;
            font-style: $font-style-not-italic;
            @include font-size(xxs);
            line-height: $line-height-none;
            color: $black-400;
            text-decoration: none;
            cursor: pointer;
            &:hover {
              color: $olive-300;
            }
          }
        }
      }
    }
  }
</style>
