<template lang="pug">
  section.section-category#category
    HeadingGift.mb-sm
      h2 
        span.sub {{ giftPageBody.subTitle }}
        br 
        | {{ giftPageBody.title }}
    .category-images
      CategoryImage(
        href="/shops/items/category/4522753051/"
        src="https://cdn.taberutokurasuto.com/image/gift/oseibo/category_chomiryo.jpg"
        name="調味料・油・ドレッシング"
        eventAction="商品一覧へ遷移"
        eventCategory="ギフトページ"
      )
      CategoryImage(
        href="/shops/items/category/4522765051/"
        src="https://cdn.taberutokurasuto.com/image/gift/oseibo/category_suisan.jpg"
        name="魚介類・水産加工品"
        eventAction="商品一覧へ遷移"
        eventCategory="ギフトページ"
      )
      CategoryImage(
        href="/shops/items/category/4522760051/"
        src="https://cdn.taberutokurasuto.com/image/gift/oseibo/category_gohannootomo2.jpg"
        name="ご飯のお供"
        eventAction="商品一覧へ遷移"
        eventCategory="ギフトページ"
      )
    CategoryTagList
      template(v-for="rootChildCategory in rootChildCategories")
        CategoryTag(
          v-if="rootChildCategory.openItemNum > 0"
          :href="`/shops/items/category/${rootChildCategory.code}/`"
          :name="rootChildCategory.name"
          :num="rootChildCategory.openItemNum"
          eventAction="商品一覧へ遷移"
          eventCategory="ギフトページ"
        )
</template>

<script>
  import { GetItemCategory } from '../../../graphqls/ItemCategories'
  import HeadingGift from '../heading/HeadingGift'
  import CategoryImage from '../categorytag/CategoryImage'
  import CategoryTag from '../categorytag/CategoryTag'
  import CategoryTagList from '../categorytag/CategoryTagList'

  export default {
    name: 'SectionCategory',
    components: {
      HeadingGift,
      CategoryImage,
      CategoryTag,
      CategoryTagList
    },
    props: {
      giftPageBody: {
        type: Object,
        required: true,
        default() {
          return {
          }
        }
      }
    },
    data() {
      return {
        rootChildCategories: []
      }
    },
    mounted() {
      this.fetchItemCategory()
    },
    methods: {
      async fetchItemCategory() {
        const {data} = await this.$apollo
        .query({
          query: GetItemCategory,
          variables: {
            itemCategoryCode: '4522742051',
          }
        })
        this.rootChildCategories = data.itemCategory.rootChildCategories
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-category {
    .category-images {
      display: flex;
      flex-direction: column;
      .category-image {
        @include margin-bottom(sm);
      }
      @include mq-up() {
        flex-direction: row;
        margin-left: -#{map-get($padding-margin-pc, xxxs)}px;
        margin-right: -#{map-get($padding-margin-pc, xxxs)}px;
        .category-image {
          width: 33.33%;
          @include padding-left(xxxs);
          @include padding-right(xxxs);
        }
      }
    }
  }
</style>
