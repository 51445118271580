<template lang="pug">
  .pager-wrap
    paginate(v-if="totalPages > 1" v-model="currentPage"
      :page-count="totalPages"
      :page-range="3"
      prev-text="",
      next-text="",
      active-class="is-current"
      container-class="pagination-list"
      page-class="pagination-item"
      prev-class="pagination-previous"
      prev-link-class="pagination-previous-link"
      next-class="pagination-next"
      next-link-class="pagination-next-link"
      page-link-class="pagination-link"
      break-view-link-class="pagination-ellipsis"
      hide-prev-next=true
    )
</template>

<script>
  export default {
    name: "pager",
    components: {},
    data () {
      return {}
    },
    props: {
      customPageInfo: Object
    },
    computed: {
      totalPages () {
        return Math.ceil(this.totalCount / this.perPage);
      },
      currentPage: {
        get: function () {
          return this.customPageInfo.currentPage;
        },
        set: function (newPage) {
          // this.setPageInfo({pageInfo: {...this.pageInfo, currentPage: newPage}});
          this.$emit('change', newPage)
        }
      },
      totalCount () {
        return this.pageInfo.totalCount;
      },
      startCount () {
        return (this.currentPage - 1) * this.perPage + 1;
      },
      endCount () {
        let maxCount = (this.currentPage - 1) * this.perPage + this.perPage;
        return maxCount > this.totalCount ? this.totalCount : maxCount;
      },
      perPage () {
        return this.pageInfo.perPage;
      },
      pageInfo () {
        return this.customPageInfo != null ? this.customPageInfo : this.getPageInfo;
      }
    },
    methods: {
      getPageInfo () {
        return this.$store.getters.pageInfo
      }
    }
  }

</script>

<style lang="scss">
@import "../../../../assets/stylesheets/v1/styles";
.pager-wrap {
  > .pagination-list {
    display: flex;
    justify-content: center;
    align-items: center;

    > li {
      > a {
        background: none;
        border: none;
        padding: 0;
      }
      &:hover {
        > .pagination-link {
          background-color: $olive-100;
        }
      }

      &.is-current {
        > .pagination-link {
          background-color: $olive-300;
          border-color: $olive-300;
          color: $white;
        }
      }

      + li {
        margin-left: 12px;
      }

      > .pagination-previous-link,
      > .pagination-next-link {
        &:after {
          content: "";
          display: block;
          width: 28px;
          height: 28px;
          background-image: url("../../../../assets/images/icon/arrow-bottom-black-base.svg");
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 50%;
        }

        &:hover {
          &:after {
            background-color: $gray-100;
          }
        }
      }

      > .pagination-previous-link {
        &:after {
          transform: rotate(90deg);
        }
      }

      > .pagination-next-link {
        &:after {
          transform: rotate(-90deg);
        }
      }

      > .pagination-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background: $white;
        border: 1px solid $gray-400;
        border-radius: 4px;
        color: $black-400;
        line-height: 100%;
        @include font-size(xs);

        &.pagination-ellipsis {
          width: auto;
          height: auto;
          margin: -8px -3px 0;
          border: none;
          background: transparent;
          @include mq-down(md) {
            margin-top: -7px;
          }
        }


      }

      > .pagination-ellipsis {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background: $white;
        border: 1px solid $gray-400;
        border-radius: 4px;
        color: $black-400;
        line-height: 100%;
        @include font-size(xs);
      }
    }
  }

  + li {
    margin-left: 12px;
  }

  > .info {
    color: $black-400;
    line-height: 158%;
    text-align: center;
    @include font-size(xxs);
    @include margin-top(xxs);
  }
}



  //@import "../../../../assets/stylesheets/mixin/variables.scss";
  //
  //.pagination-list {
  //  text-align: center;
  //  margin: 2em 0;
  //
  //  .pagination-item, .pagination-previous, .pagination-next {
  //    display: inline-block;
  //    line-height: 1.3;
  //    *display: inline;
  //    *zoom: 1;
  //    background: $color_white;
  //    border: 1px solid $color_secondary;
  //    padding: 5px 0;
  //    margin: 0 2px;
  //    width: 40px;
  //    .pagination-link, .pagination-previous-link, .pagination-next-link {
  //      display: block;
  //      color: $color_secondary;
  //    }
  //
  //    &.is-current, &:hover {
  //      background: $color_secondary;
  //      a {
  //        color: $color_white;
  //      }
  //    }
  //    &.disabled {
  //      border: none;
  //      width: 20px;
  //      pointer-events: none;
  //      .pagination-ellipsis {
  //        color: $color_gray;
  //      }
  //    }
  //  }
  //}
</style>
