<template lang="pug">
  component.badge-chip(:is="component" :class="classes" :to="to" @click="click")
    slot
</template>

<script>

  export default {
    name: "Chip",
    components: {
    },
    props: {
      href: String,
      to: Object,
      color: {
        type: String,
        default: ''
      },
    },
    computed: {
      component() {
        let component = 'div';
        if (this.to) component = 'router-link';
        else if (this.href) component = 'a';
        return component;
      },
      classes() {
        let classes = [this.color];
        return classes
      }
    },
    methods: {
      click() {
        if (!this.disabled) {
          this.$emit('click');
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";

  .badge-chip {
    border-radius: 16px;
    box-sizing: border-box;
    line-height: 34px;
    text-transform: capitalize;
    background: $gray-base;
    color: $black-dark;
    display: inline-block;
    cursor: pointer;

    &.primary {
      background: $primary-lighter;
    }
    &:hover {
      background: $primary-lighter;
    }

    @include margin-bottom(xxxs);
    @include margin-right(xxxs);
    @include padding-right(xxs);
    @include padding-left(xxs);
  }
</style>
