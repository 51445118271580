<template lang="pug">
  .form-upload-image
    .open(v-if="!isOpen" @click="openUploader")
      IconArrowDown.arrow-down(:size="12")
      | 画像アップロード
    template(v-else)
      .drop-area(
        :class="{disabled: isDisabled}"
        @click="$refs.fileInput.click();"
        @dragleave.prevent='',
        @dragover.prevent='',
        @drop.prevent='onDrop($event)'
      )
        .image-area
          .title 画像を追加
          | 画像ファイルをドラッグ＆ドロップで追加きます。
          br
          | または、ファイルを選択する
          br
          input.file-input(type="file" name="resume" @change="onDrop($event)" :disabled="isDisabled" ref="fileInput")
      Draggable(:list="localImageList" class="image-list" draggable=".draggable-item" :options="{disabled: !isSortImage}" @end="draggingEnded" handle=".handle")
        .image-list-item.draggable-item(v-for="(image, idx) in localImageList" :class="{isSingle: isSingle}")
          DraggableImage(:src="image.path" @click="remove(idx)" :is-sortable="isSortImage" :isDeletable="isDeletable")
        .image-list-item.sp-drop-area(:class="{disabled: isDisabled}")
          a(href="#" @click.prevent="$refs.fileInput.click()") 画像を追加
          br
          input.file-input(type="file" name="resume" @change="onDrop($event)" :disabled="isDisabled" ref="fileInput")
      .close(@click="closeUploader")
        IconArrowUp.arrow-up(:size="12")
        | 画像アップロードを閉じる

</template>

<script>
  import Draggable from 'vuedraggable'
  import DraggableImage from './DraggableImage'
  import IconArrowDown from "../icon/IconArrowDown";
  import IconArrowUp from "../icon/IconArrowUp";

  export default {
    name: 'FormUploadImage',
    components: {
      IconArrowUp,
      IconArrowDown,
      Draggable,
      DraggableImage,
    },
    props: {
      value: {
        type: Array,
        required: false,
        default: () => []
      },
      limit: {
        type: Number,
        required: false,
        default: 10
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      isSortable: {
        type: Boolean,
        required: false,
        default: true
      },
      isDeletable: {
        type: Boolean,
        required: false,
        default: true
      },
      isSingle: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    data() {
      return {
        // value: [],
        isOpen: false
      }
    },
    computed: {
      isDisabled() {
        return this.disabled || this.localImageList.length >= this.limit
      },
      isSortImage() {
        return this.isSortable && this.localImageList.length > 1
      },
      localImageList() {
        return this.value
      }
    },
    methods: {
      onDrop (event) {
        if (this.isDisabled || !event.target) {
          return
        }
        let fileList = event.target.files ? event.target.files : event.dataTransfer.files
        if (fileList.length + this.localImageList.length > this.limit) {
          let errors = {fileNo: ['manyfile']}
          this.$emit('error', errors)
          return
        }
        let imgFile = null
        for (imgFile of fileList) {
          if (!imgFile.type.match(/image\/(png|jpe?g)/ig)) {
            let errors = {fileNo: ['file_extension']}
            this.$emit('error', errors)
          }
          this.parseImageFIle(imgFile)
        }
        event.target.value = '';
      },
      draggingEnded(event) {
        this.$emit('onDraggingEnded', event, this.localImageList)
      },

      parseImageFIle (imgFile) {
        let reader = new FileReader()
        reader.onload = (e) => {
          let result = String(e.target['result'])
          let fileData = result.substr(result.indexOf(',') + 1)
          this.uploadListImage(imgFile, fileData, imgFile.name)
          this.$emit('onChange', this.localImageList)
        }
        reader.readAsDataURL(imgFile)
      },
      // Base64データからimgタグのsrc urlを取得する
      getFileSrcToBase64(fileData, fileName) {
        if (fileName && fileData) {
          let fileType = "jpeg";
          let extension = fileName.split(".").slice(-1)[0];
          if (extension.toLowerCase() === "png") fileType = "png";
          return "data:image/" + fileType + ";base64," + fileData;
        }
        return '';
      },
      uploadListImage (listImage, data, name) {
        let img = {
          file: listImage,
          path: this.getFileSrcToBase64(data, name)
        }
        this.localImageList.push(img)
        this.$emit('onUpload', img)
      },
      remove (removeIdx) {
        const deleteImages = this.localImageList.splice(removeIdx, 1);
        this.$emit('onChange', this.localImageList)
        this.$emit('onDelete', deleteImages[0])
      },
      clear() {
        this.value = []
      },
      openUploader() {
        this.isOpen = true
      },
      closeUploader() {
        this.isOpen = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .form-upload-image {
    > .open {
      @include font-size(xs);
      .arrow-down {
        @include margin-right(xxs);
      }
    }
    > .close {
      @include font-size(xs);
      .arrow-up {
        @include margin-right(xxs);
      }
    }
    > .drop-area {
      cursor: pointer;
      padding: 2rem;
      border: 1px solid $gray-300;
      border-radius: 3px;
      background-color: $gray-100;
      width: 100%;
      height: 198px;
      text-align: center;
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(xxs);
      line-height: $line-height-normal;
      color: $black-400;
      display: flex;
      align-content: center;
      align-items: center;
      @include mq-down(md) {
        display: none;
      }
      &.disabled {
        display: none;
      }
      .file-input {
        visibility: hidden;
      }
      .image-area {
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 1em;
        .title {
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-normal;
          color: $black-400;
          padding-top: 40px;
          margin-bottom: 10px;
          background-image: url(../../../../../assets/images/icon/imageupload.svg);
          background-repeat: no-repeat;
          background-position: center top;
        }
      }
    }
    > .image-list {
      width: 100%;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      cursor: grab;
      @include mq-up() {
        @include margin-top(md);
      }
      > .image-list-item {
        width: 100px;
        height: 100px;
        @include margin-left(xxxs);
        @include margin-right(xxxs);
        @include margin-bottom(sm);
      }
      > .sp-drop-area {
        width: 100px;
        height: 100px;
        border: 1px solid $gray-300;
        border-radius: 3px;
        background-color: $gray-100;
        display: block;
        @include mq-up(md) {
          display: none;
        }
        &.disabled {
          display: none;
        }
        .file-input {
          visibility: hidden;
          max-width: 100%;
        }
        a {
          width: 100%;
          height: 100%;
          display: block;
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-none;
          color: $black-400;
          text-decoration: none;
          text-align: center;
          padding-top: 65px;
          background-image: url(../../../../../assets/images/icon/imageupload.svg);
          background-repeat: no-repeat;
          background-position: center top 25px;
        }
      }
    }
  }
</style>
