<template lang="pug">
  .read-more(:style="heightStyle")
    .contents(ref="contents")
      slot
    a(v-if="!isOpen" @click="open")
      ArrowSvg
      |{{moreText}}
</template>

<script>
  import ArrowSvg from '../../svg/ArrowSvg'

  export default {
    name: "read-more",
    components: {
      ArrowSvg
    },
    props: {
      minHeight: {
        type: Number,
        default: 150
      },
      moreText: {
        type: String,
        default: 'もっと見る'
      }
    },
    data() {
      return {
        height: 0,
        maxHeight: 0,
        isOpen: false
      }
    },
    computed: {
      heightStyle() {
        return this.height === null ? {} : {height: `${this.height}px`};
      }
    },
    watch: {
      minHeight () {
        if (!this.isOpen) {
          this.init();
        }
      }
    },
    mounted () {
      this.maxHeight = this.$refs.contents.scrollHeight;
      this.init();
    },
    methods: {
      init() {
        if (this.maxHeight > this.minHeight) {
          this.height = this.minHeight;
        } else {
          this.isOpen = true;
          this.height = null;
        }
      },
      open() {
        this.isOpen = true;
        this.height = this.maxHeight;
        setTimeout(() => {
          this.height = null;
        }, 300);
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .read-more {
    position: relative;
    overflow: hidden;
    transition: height 300ms ease;

    > a {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 20%, #fff 100%);
      cursor: pointer;
      text-decoration: underline;
      color: $black-400;
      @include padding(xs);
      @include font-size(xs);

      > svg {
        margin-right: .3em;
      }
    }
  }
</style>
