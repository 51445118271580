<template lang="pug">
  component.form-textfield(:is="isSuffix ? 'label' : 'div'" :class="{'is-focus': isFocus, 'is-error': isError, 'is-disabled': disabled}")
    input(v-model="localValue" :type="type" :min="min" :max="max" :placeholder='placeholder' :disabled='disabled' @focus='inputFocus' @blur='inputBlur')
    span(v-if="hasValiant" :class="valiant")
      slot
</template>

<script>
  export default {
    name: "form-textfield",
    props: {
      value: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'text'
      },
      min: {
        type: Number,
        default: null
      },
      max: {
        type: Number,
        default: null
      },
      placeholder: {
        type: String,
        default: '入力してください'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isError: {
        type: Boolean,
        default: false
      },
      valiant: {
        type: String,
        default: '' // 'suffix' or 'side-label'
      },
    },
    data() {
      return {
        isFocus: false
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      hasValiant() {
        return this.valiant !== '';
      },
      isSuffix() {
        return this.valiant === 'suffix';
      }
    },
    methods: {
      inputFocus() {
        this.isFocus = true
      },
      inputBlur() {
        this.isFocus = false
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .form-textfield {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    line-height: 100%;
    letter-spacing: 0.01em;
    cursor: text;
    @include font-size(sm);

    &.is-focus {
      > input,
      > .suffix {
        border-color: $black-lighter;
      }
    }

    &.is-error {
      > input,
      > .suffix {
        background: $red-lighter;
        border-color: $red-base;
      }
    }

    &.is-disabled {
      > input,
      > .suffix {
        background: $gray-light;
        border-color: $gray-light;
      }

      > .suffix {
        color: $black-lighter;
        cursor: not-allowed;
      }
    }

    > input,
    > .suffix {
      padding: 0 12px;
      background: $white;
      box-sizing: border-box;
      border: 1px solid $gray-dark;
    }

    > .suffix,
    > .side-label {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      height: 100%;
      @include font-size(xs);
    }

    > input {
      flex-grow: 1;
      width: 100%;
      height: 100%;
      outline: none;
      border-radius: 3px;
      color: $black-dark;

      &::placeholder {
        color: $black-lighter;
      }

      &:disabled {
        color: $black-lighter;
        cursor: not-allowed;
      }
    }

    > .suffix {
      position: relative;
      z-index: 1;
      margin-left: -12px;
      border-left: none;
      border-radius: 0 3px 3px 0;
      color: $black-base;
    }

    > .side-label {
      color: $black-dark;
      padding: 0 10px;
    }
  }
</style>
