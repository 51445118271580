<template lang="pug">
  .category-tag-list
    slot
</template>

<script>
  export default {
    name: 'CategoryTagList'
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .category-tag-list {
    > .category-tag {
      @include margin-right(xxxs);
      @include margin-bottom(xxxs);
    }
  }
</style>
