<template lang="pug">
  IconBase
    WrappingSvg
</template>
<script>
  import IconBase from './IconBase'
  import WrappingSvg from "../svg/WrappingSvg";
  export default {
    name: "wrapping-icon",
    components: {
      IconBase,
      WrappingSvg
    }
  }
</script>
