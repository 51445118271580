<template lang="pug">
  Modal#returnpolicy(ref="addCartCompleteModal" size="md")
    template(v-slot:header) カートに商品を追加しました。
    template(v-slot:body)
      section
        h3 {{cartItem.item.skuName}}
        .info
          .quantity 数量 : {{cartItem.quantity}}
          .price {{cartItem.itemTotalInTax | numberToCurrency}} (税込み)
    template(v-slot:footer)
      ButtonBlock(align="between")
        ButtonBase(@click="$refs.addCartCompleteModal.close()" color="white") 買い物を続ける
        ButtonBase(@click="waiting()" href="/shop/cart/" color="primary-base") カートから注文へ進む
</template>
<script>
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Modal from "./Modal";
  import ButtonBase from "../button/ButtonBase";
  import ButtonBlock from "../button/ButtonBlock";

  export default {
    name: "AddCartCompleteModal",
    components: {ButtonBlock, ButtonBase, Modal},
    mixins: [GlobalMixin],
    props: {
      cartItem: {
        type: Object
      }
    },
    methods: {
      open(e) {
        this.$refs.addCartCompleteModal.open()
        this.$emit('open', e)
      },
      close(e) {
        this.$refs.addCartCompleteModal.close()
        this.$emit('close', e)
      },
      waiting(e) {
        this.loadingStart()
        this.close(e)
      }
    }
  }
</script>

<style scoped lang="scss">
  .info {
    display: flex;

    > .price {
      flex: 1
    }
    > .quantity {
      flex: 1
    }
  }
  .footer {
    .button-block {
      margin: auto;
    }
  }
</style>
