<template lang="pug">
  IconBase
    MinusSvg
</template>
<script>
  import IconBase from './IconBase'
  import MinusSvg from "../svg/MinusSvg";
  export default {
    name: "minus-icon",
    components: {
      IconBase,
      MinusSvg
    }
  }
</script>
