<template lang="pug">
  .item-slider(v-if="imageList.length > 0")
    carousel(:center="true" :auto-width="true" :loop="true" :responsive="responsive")
      .image(v-for="(image, index) in imageList")
        template(v-if="image.url")
          a(:href="image.url" @click="$_gaSendEvent(`ショップトップバナー${index + 1}枚目から遷移`, 'ショップトップバナー', image.title)")
            img(:src="image.imageSet.mdImage")
            .title(v-if="image.title") {{ image.title }}
        template(v-else)
          img(:src="image.imageSet.mdImage")
          .title(v-if="image.title") {{ image.title }}
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import Carousel from 'vue-owl-carousel'
  import {GetShopBanners} from "../../../graphqls/ShopBanners";

  export default {
    name: 'SectionShopSlider',
    mixins: [GlobalMixin],
    components: {
      Carousel
    },
    props: {
      adminShop: {
        type: Object,
        required: true,
        default: () => {
          return {
            id: '',
            code: ''
          }
        }
      },
    },
    data() {
      return {
        init: false,
        responsive: {
          0: {
            margin: 1,
            dots: false,
            nav: false
          },
          768 : {
            margin: 5,
            dotsContainer: '.item-slider-dots',
            navText: ['', '']
          }
        },
        imageList: [],
        shopBanners: []
      }
    },
    mounted() {
      this.fetchRecipes()
    },
    watch: {
      shopBanners: {
        async handler(newValue) {
          let list = []
          if (newValue && newValue.length > 0) {
            for (const shopBanner of newValue) {
              if (await this.checkImage(shopBanner.imageSet.mdImage)) {
                list.push({...shopBanner})
              }
            }
          }
          this.imageList = list
        },
        immediate: true,
        deep: true
      }
    },
    methods : {
      async checkImage (url) {
        let img = new Image()
        return new Promise(resolve => {
          img.onload = () => {
            img = null;
            resolve(true);
          }
          img.onerror = () => {
            img = null;
            resolve(false);
          }
          img.src = url;
        })
      },
      async fetchRecipes() {
        const {data} = await this.$apollo
            .query({
              query: GetShopBanners,
              variables: {
                search: JSON.stringify({
                  admin_shop_id_eq: this.adminShop.id,
                }),
              },
              client: 'apiClient'
            })
        this.shopBanners = data.shopBanners.items;
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";
  .item-slider {
    margin-bottom: 32px;
    .image {
      max-height: 300px;
      width: auto;
      @include mq-down(md) {
        max-height: 240px;
      }
      img {
        width: auto;
        max-height: 300px;
        object-fit: cover;
        width: auto;
        @include mq-down(md) {
          max-height: 240px;
        }
      }
      .title {
        position: absolute;
        right: .5rem;
        bottom: .5rem;
        @include padding(xs);
        background: $white;
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-none;
        color: $black-400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 1rem);
      }
    }
  }
  .section-item-slider {
    text-align: center;
    padding: 24px 0;
    @include mq-down(md) {
      margin: 0 -15px;
    }
    .image {
      display: inline-block;
      height: 300px;

      @include mq-down(md) {
        height: 240px;
      }

      > img {
        width: auto;
        height: 100%;
      }
    }

    > .item-slider-dots {
      display: inline-flex;
      max-width: 100%;
      margin-top: 3px;
      overflow: auto;

      @include mq-down(md) {
        display: none;
      }

      > button {
        background: none;
        border: none;
        padding: 0;
        outline: none;

        &:nth-child(n+2) {
          margin-left: 4px;
        }

        > img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
</style>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";
  .section-item-slider {
    .owl-theme {
      .owl-nav {
        margin: 0;

        > [class*='owl-'] {
          position: absolute;
          top: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 50px;
          padding: 0;
          margin: 0;
          background: rgba(56, 56, 56, 0.4);
          transform: translateY(-50%);
          overflow: hidden;

          &:hover {
            background: rgba(56, 56, 56, 0.4);
          }

          &:before {
            content: "";
            display: block;
            width: 25px;
            height: 25px;
            border: solid 5px $white;
            transform: rotate(45deg);
          }
        }

        > .owl-prev {
          left: 5px;

          &:before {
            margin-right: -13px;
            border-top: none;
            border-right: none;
          }
        }

        > .owl-next {
          right: 5px;

          &:before {
            margin-left: -15px;
            border-bottom: none;
            border-left: none;
          }
        }
      }
    }
  }
</style>

