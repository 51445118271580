<template lang="pug">
  section.section-shop-recipe(v-if="recipes.length > 0")
    HeadingArticle.mb-xs 新着レシピ
    .recipe-list
      .item(v-for="recipe in recipes" )
        a.recipe-card(:href="recipe.frontUrl")
          .thumbarea
            img(:src="recipe.mainImageSet.smSqImage")
          .title {{recipe.title}}

    .row.mb4
      .col-xs-10.col-xs-push-1.col-sm-4.col-sm-push-4
        ButtonBase(variant="primary" :href="`/shop/${adminShop.code}/recipes/`") レシピ一覧
</template>

<script>
import HeadingRoof from '../heading/HeadingRoof'
import PostCard from '../post/PostCard'
import ButtonBase from '../button/ButtonBase'
import HeadingArticle from '../typography/HeadingArticle'
import {GetRecipes} from "../../../graphqls/Recipes";

export default {
  name: 'SectionShopRecipe',
  components: {
    HeadingRoof,
    PostCard,
    ButtonBase,
    HeadingArticle,
  },
  props: {
    adminShop: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: '',
          code: ''
        }
      }
    },
  },
  data() {
    return {
      recipes: []
    }
  },
  mounted() {
    this.fetchRecipes()
  },
  methods: {
    async fetchRecipes() {
      const {data} = await this.$apollo
          .query({
            query: GetRecipes,
            variables: {
              perPage: 6,
              orderBy: 'published_at desc',
              search: JSON.stringify({
                admin_shop_id_eq: this.adminShop.id,
              }),
            },
            client: 'apiClient'
          })
      this.recipes = data.recipes.items;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
.section-shop-recipe {
  > .recipe-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
    margin-bottom: 48px;
    > .item {
      flex: 1;
      > .recipe-card {
        > .thumbarea {
          > img {
            width: 100%;
            height: auto;
          }
        }
        > .title {
          font-size: 14px;
          line-height: $line-height-snug;
        }
      }
    }
  }
  @media (max-width: 768px) {
    > .recipe-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>