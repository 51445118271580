import gql from "graphql-tag";

export const UpdateItemGroupHourlyPageView = gql`
mutation($input: UpdateItemGroupHourlyPageViewInput!) {
  updateItemGroupHourlyPageView(input: $input ) {
    errors {
      model
      attribute
      message
    }
  }
}
`
