<template lang="pug">
.review-accordion
  .title(@click="isOpen = !isOpen")
    .svg(v-if="!isOpen")
      include ../../../../../assets/images/icon/arrow-drop-down.svg
    .svg(v-if="isOpen")
      include ../../../../../assets/images/icon/arrow-drop-up.svg
    span.label
      slot(name="title")
  .details(v-if="isOpen")
    slot(name="body")
</template>

<script>
  export default {
    name: 'ReviewAccordion',
    props: {

    },
    data() {
      return {
        isOpen: false
      }
    },
    computed: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
.review-accordion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: $gray-100;
  padding: 8px;
  justify-content: center;
  align-self: stretch;
  color: $black-400;
  > .title {
    width: 100%;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;
    > .svg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      > svg {
      height: auto;
        > path {
          width: 100%;
          height: 100%;
        }
      }
    }
    > .label {
      color: $black-400;
    }
  }
  > .details {
    margin-top: 8px;
    > ul {
      margin-top: 8px;
      margin-left: 16px;
      > li {
      list-style: disc;
      margin-bottom: 4px;
      }
    }
  }
}
</style>
