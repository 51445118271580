<template lang="pug">
  .alert-message(:class="classes")
    slot
</template>

<script>
  export default {
    name: "alert-message",
    components: {
    },
    props: {
      color: {
        type: String,
        default: 'red',
      },
      border: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      classes() {
        let classes = [this.color];
        if (this.border) classes.push('border');

        return classes;
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .alert-message {
    line-height: 136%;
    @include padding(xs);
    @include font-size(xxs);
    white-space: pre-wrap;
    word-wrap: break-word;

    &.red {
      background: $red-lighter;
      border-color: $red-base;
      color: $red-base;
    }

    &.black {
      background: $gray-light;
      border-color: $black-dark;
      color: $black-dark;
    }

    &.border {
      background: transparent;
      border-width: 1px;
      border-style: solid;
    }
    > p {
      margin: 0;
    }
  }
</style>
