<template>
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.981 8.21463L11.958 7.96529L12.1438 7.79747C13.0141 7.01168 13.5 6.05031 13.5 5.03571C13.5 3.84684 12.8309 2.7248 11.6575 1.88072C10.4848 1.0372 8.83958 0.5 7 0.5C5.16042 0.5 3.51524 1.0372 2.34254 1.88072C1.16907 2.7248 0.5 3.84684 0.5 5.03571C0.5 6.22459 1.16907 7.34663 2.34254 8.19071C3.51524 9.03423 5.16042 9.57143 7 9.57143C8.39155 9.57143 9.17481 9.46202 10.19 8.97736L10.5188 8.82037L10.769 9.08531L12.202 10.603L11.981 8.21463Z" stroke="#737373"/>
  </svg>
</template>
<script>
  export default {
    name: "comment-svg"
  }
</script>
