import gql from 'graphql-tag'
import {Errors} from "./Common"

export const CreateContact = gql`
mutation ($input: CreateContactInput!) {
  createContact(input: $input) {
    contact {
      id
      code
      contactComments {
        id
      }
      userNotice {
        id
      }
    }
    ${Errors}
  }
}`

export const GetContactWithComments = gql`
query($contactId: ID!, $confirm: Boolean) {
  contact(contactId: $contactId, confirm: $confirm) {
    id
    code
    contactedAt
    adminShop {
      name
    }
    contactComments {
      id
      userId
      adminUserId
      comment
      image
      imageUrl
      elapsedTime
      user {
        nickname
        name
      }
      adminUser {
        adminShop {
          name
          logoImageSet {
            smSqImage
          }
        }
      }
    }
  }
}
`
