import { render, staticRenderFns } from "./v1_app.vue?vue&type=template&id=5cada41a&scoped=true&lang=pug&"
import script from "./v1_app.vue?vue&type=script&lang=js&"
export * from "./v1_app.vue?vue&type=script&lang=js&"
import style0 from "./v1_app.vue?vue&type=style&index=0&id=5cada41a&lang=scss&scoped=true&"
import style1 from "./v1_app.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cada41a",
  null
  
)

export default component.exports