<template lang="pug">
  div
    .visual-title.-xs.-white(:style="`background-image: url(https://cdn.taberutokurasuto.com/image/shop/item/list/popular8.jpg);`")
      .title
        i.h1rem
          LogoYaneSvg
        h1.heading2 {{searchKeyword}}の商品一覧
        .heading3.curve-bottom Search

    ItemFilterBlock
      DropDown(@change="$_changeOrder" v-model="pageInfo.orderBy" :options="$_orderDropDownForSearchOptions")
      .stock-checkbok
        FormCheckbox(@change="$_changeStockFilter" v-model="stockOnly") 販売中のみ表示

    ItemCardList
      .item.lg(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" eventCategory="商品一覧")
    .row
      .col-xs-12(v-if="itemGroups.length === 0")
        .success-notice
          .text-center 商品がありません。
    .row
      .col-xs-12
        Pager(@change="$_changePage" :custom-page-info="$_customPageInfo")

    SectionItemArchive
</template>

<script>
  import {GetItemGroupsForApi} from "../../graphqls/ItemGroups";

  import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
  import ItemList from "../../components/list/ItemList";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Pager from "../../components/paginate/Pager";
  import DropDown from "../../components/v1/dropdown/DropDown";
  import ItemListMixin from "../../mixin/ItemListMixin";
  import ItemCardList from "../../components/v1/item/ItemCardList";
  import ItemCard from "../../components/v1/item/ItemCardForApi";
  import SectionItemArchive from "../../components/v1/section/SectionItemArchive";
  import ItemFilterBlock from "../../components/v1/item/ItemFilterBlock";
  import FormCheckbox from "../../components/v1/form/FormCheckbox";

  export default {
    name: "search-items-index",
    mixins: [GlobalMixin, ItemListMixin],
    components: {
      FormCheckbox,
      ItemFilterBlock,
      SectionItemArchive,
      ItemCard,
      ItemCardList,
      DropDown,
      Pager,
      ItemList,
      LogoYaneSvg
    },
    props: [],
    data() {
      return {
        itemGroups: [],
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 48,
          orderBy: this.$route.query.order || 'related_score'
        },
        stockOnly: this.$route.query?.stock === 'instock',
        withinYearItem: this.$route.query.wiy === 'true',
        searchKeyword: this.$route.query.q
      }
    },
    computed: {
      topRouterName() {
        return 'search-items-index'
      },
      pageRouterName() {
        return 'search-items-page-index'
      },
      stockFilter() {
        return this.stockOnly ? 'instock' : 'outstock'
      }
    },
    mounted() {
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            searchKeyword: this.searchKeyword,
            stockFilter: this.stockFilter,
            withinYearItem: this.withinYearItem,
            ...this.pageInfo
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroups = data.itemGroups.items;
        this.pageInfo = data.itemGroups.pageInfo
        this.loadingStop();
        this.$_gaSendViewItemList(this.itemGroups, 'shops/items/search', this.pageInfo)
      },
      updateItemGroup(newItemGroup) {
        if (this.itemGroups[0].id === newItemGroup.id) {
          this.firstItemGroup = newItemGroup
        }
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
