<template lang="pug">
.shopsection-profile(:class="{ 'is-box': isBox }")
  component(:is="component")
    .shop-profile
      .image
        ImgResponsive(:src="adminShop.profileImageSet.mdSqImage")
      .desc
        h2
          | {{ adminShop.name }}
          span {{ adminShop.prefectureName }}({{ adminShop.businessContents }})
        VueMarkdown.markdown(
          :source="adminShop.description",
          :linkify="false"
        )
</template>

<script>
import VueMarkdown from "vue-markdown";
import HeadingArticle from "../typography/HeadingArticle.vue";
import ReadMore from "../transition/ReadMore.vue";
import ImgResponsive from "../../image/ImgResponsive.vue";

export default {
  name: "SectionShopProfile",
  components: {
    VueMarkdown,
    ImgResponsive,
    HeadingArticle,
    ReadMore,
  },
  props: {
    adminShop: {
      type: Object,
      required: true,
      default: () => {},
    },
    isBox: {
      type: Boolean,
      required: false,
      default: false
    },
    isReadMore: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    component() {
      let component = 'div'
      if (this.isReadMore) {
        component = 'read-more'
      }
      return component
    }
  }
};
</script>

<style lang="scss">
@import "../../../../../assets/stylesheets/v1/styles";

.shopsection-profile {
  .shop-profile {
    display: flex;
    justify-content: space-between;
    $col: 100% / 12;

    > .image {
      width: calc(#{$col * 2} - 7.5px);
      > .img-responsive {
        border-radius: 50%;
        overflow: hidden;
      }
    }

    > .desc {
      width: calc(#{$col * 10} - 7.5px);

      > .markdown {
        > p {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-normal;
          color: $black-400;
          @include margin-bottom(xxs);
        }
      }

      > h2 {
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(md);
        line-height: $line-height-normal;
        color: $black-400;
        @include margin-bottom(xxs);

        > span {
          @include margin-left(xs);
          @include font-size(xxs);
        }
      }
    }
  }
  &.is-box {
    border: 1px solid $gray-400;
    @include padding(xs);

    > .shop-profile {
      margin-top: 0;

      > .image {
        > .img-responsive {
          border-radius: 0;
        }
      }
    }
  }
}
</style>
