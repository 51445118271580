<template lang="pug">
  .form-radio-group(:class="classes")
    slot
</template>

<script>
  export default {
    name: 'FormRadioGroup',
    props: {
      column: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
      }
    },
    computed: {
      classes: function () {
        return [
          { '-column': this.column }
        ]
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .form-radio-group {
    width: 100%;

    .form-radio {
      @include margin-right(sm);
      @include margin-bottom(xxs);
    }

    &.-column {
      display: flex;
      flex-direction: column;
    }
  }
</style>
