<!-- Generator: Adobe Illustrator 19.2.1, SVG Export Plug-In  -->
<template>
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
       x="0px" y="0px" viewBox="0 0 145.5 43.1" enable-background="new 0 0 145.5 43.1"
       xml:space="preserve">
<defs>
</defs>
    <path fill="#070001" d="M144,42.7l0.2,0.1l0.1-0.2l0.4-1.1l0.1-0.2l-0.2-0.1l-67.2-28l2.7-1.1L145,40l0,0l0,0l0.5-1.1l0,0L84.4,10.6
	l3.5-1.4l0.2-0.1l-0.1-0.2l-1.2-3.1l-0.1-0.2l-0.2,0.1l-6.6,2.8l-2.3-1.1l8.3-3.6l0.3-0.1L86,3.4l-1.4-3.1l-0.1-0.3l-0.3,0.1l-11,5
	l-11-5.1L61.8,0l-0.2,0.4l-1.4,3l-0.2,0.4l0.4,0.2l7.9,3.5l-1.9,0.9L60,5.5l-0.4-0.2l-0.2,0.4l-1.3,3.1l-0.2,0.4l0.4,0.2l2.9,1.2
	L0.6,38.4L0,38.6l0.3,0.6L0.6,40l0.2,0.4L1,40.9l0.6-0.3l64.6-28.1l1.5,0.6L1.6,41l-0.4,0.2l0.2,0.4l0.4,1.1L2,43.1L2.4,43
	l70.8-27.8L144,42.7z M73.2,9.5l2.3,0.8l-2.3,1.2l-1.7-1.1L73.2,9.5z M73.2,11.5L73.2,11.5L73.2,11.5L73.2,11.5L73.2,11.5L73.2,11.5
	L73.2,11.5L73.2,11.5L73.2,11.5z"/>
</svg>

</template>
<script>
  export default {
    name: "logo-yane-svg"
  }
</script>
