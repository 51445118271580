<template lang="pug">
  ModalBase(ref="modalBase" size="medium")
    template(slot="header") カートに商品を追加しました。
    template(slot="body")
      section
        h3.heading {{cartItem.item.displayName}}
        .info
          .quantity 数量 : {{cartItem.quantity}}
          .price {{itemTotalInTax}}
          .unit(v-if="isAddTax") 円(税込)
          .unit(v-else) 円
    template(slot="footer")
      ButtonBase(@click="close()" variant="normal" size="inline") 買い物を続ける
      ButtonBase(@click="waiting()" variant="primary" href="/shop/cart/") カートから注文へ進む
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import ModalBase from '../modal/ModalBase'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'ModalAddCart',
    mixins: [GlobalMixin],
    components: {
      ModalBase,
      ButtonBase
    },
    props: {
      cartItem: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    computed: {
      itemTotalInTax() {
        let total = this.cartItem.itemTotalInTax ? Number(this.cartItem.itemTotalInTax) : 0
        return total.toLocaleString()
      },
      isAddTax() {
        return this.cartItem?.adminShop?.isAddTax
      }
    },
    methods: {
      open: function(e) {
        this.$refs.modalBase.open()
        this.$emit('open', e)
      },
      close: function(e) {
        this.$refs.modalBase.close()
        this.$emit('close', e)
      },
      waiting(e) {
        this.loadingStart();
        this.close(e)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .modal-base {
    .body {
      section {
        .heading {
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-snug;
          color: $black-400;
          @include margin-bottom(md);
        }

        .info {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          > .quantity {
            @include margin-right(sm);
          }
          > .price {
            font-weight: 700;
          }
          > .unit {
            @include font-size(xxs)
          }
        }
      }
    }
  }
</style>
