<template lang="pug">
  section.section-new-review
    HeadingRoof.mb-xs(title="新着レビュー" subtitle="Review")
    .text-center.mb-md
      a.anker-link(href="/reviews/" @click="$_gaSendEvent('レビュー一覧へ遷移', 'トップレビュー', 'レビュー一覧')")
          | すべて見る
          include ../../../../../assets/images/icon/arrow-right.svg
    swiper.swiper(:options="swiperOption" ref="swiper")
      swiper-slide(v-for="review in reviews.items" :id="review.id" :key="review.id")
        ReviewCard(:review="review")
      .swiper-button-prev(slot="button-prev")
      .swiper-button-next(slot="button-next")
</template>

<script>
  import 'swiper/css/swiper.css'
  import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
  import {GetReviews} from "../../../graphqls/Reviews"
  import GlobalMixin from "../../../mixin/GlobalMixin"
  import HeadingRoof from '../heading/HeadingRoof'
  import ReviewCard from '../review/ReviewCard'

  export default {
    name: 'SectionNewReview',
    mixins: [GlobalMixin],
    components: {
      Swiper,
      SwiperSlide,
      HeadingRoof,
      ReviewCard
    },
    directives: {
      swiper: directive
    },
    data () {
      return {
        reviews: {items: []},
        swiperOption: {
          loop: true,
          slidesPerView: 1.3,
          centeredSlides: true,
          spaceBetween: 10,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            960: {
              slidesPerView: 3.5,
              spaceBetween: 20
            }
          }
        }
      }
    },
    computed: {},
    mounted () {
      this.fetchReviews()
    },
    methods: {
      async fetchReviews() {
        // this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetReviews,
          variables: {
            perPage: 10,
            search: JSON.stringify({stars_gteq: 3}),
            orderBy: 'reviews.posted_at desc'
          },
          client: 'apiClient'
        })
        .catch(error => {
          return;
        });
        this.reviews = data.reviews;
        // ２個めを中央に持ってきて
        this.$refs.swiper.$swiper.slideTo(5, 0)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";
  @import "../../../../../assets/stylesheets/_variables";
  @import "../../../../../assets/stylesheets/mixin/_mediaqueries";

  .section-new-review {
    .anker-link {
      @include font-size(sm);
      svg {
        width: 12px;
        height: 12px;
        color: $black-400;
      }
    }
  }

  .swiper {
    // .swiper-slide {
    //   .img-responsive {
    //     margin: 0 auto;
    //   }
    // }
    .swiper-button-prev, .swiper-button-next {
      color: #383838;
      width: 32px;
      height: 40px;
      background-color: white;
      border: 1px solid #DBD9D3;
      @include mq-down(){
        display: none;
      }

      &:focus {
        outline: none;
      }

      &::after {
        font-size: 100%;
      }
    }

    .swiper-button-prev {
      left: 15px;
    }

    .swiper-button-next {
      right: 15px;
    }
  }
</style>
