<template lang="pug">
  a.item-link(:href="itemGroup.detailPagePath" @click="gaSendEvent")
    .thumbnail
      .image
        img(v-if="itemGroup.listImageOrItemGroupImageSet" :src="itemGroup.listImageOrItemGroupImageSet.xsSqImage" alt="" width="100%" height="auto")
        .status(v-if="itemGroup.afterPreSale")
          span 予約終了
        .status(v-else-if="preRelease || soldOut || itemGroup.beforePreSale")
          span 準備中
    .desc
      .shop
        .image
          img(:src="adminShop.logoImageSet.xsSqImage" :alt="`${adminShop.name}ロゴ`" width="100%" height="auto")
        p {{adminShop.name}}
      ul.tags
        li.label-item(v-if="itemGroup.isNew") NEW
        li.label-item(v-if="isFreeShipping") 送料無料
        li.label-item(v-if="itemGroup.isNormal") 常温
        li.label-item(v-if="itemGroup.isCold") 冷蔵
        li.label-item(v-if="itemGroup.isFreeze") 冷凍
        li.label-item(v-if="itemGroup.isSecondGradeItem") 訳あり
        li.label-item(v-if="isGiftPossible") ギフト
        li.label-item(v-if="isSale") {{saleName ? saleName : 'SALE'}}
        li.label-item(v-if="hasSubscriptionItem") 定期購入可
        li.label-item(v-if="hasOnetimeItem") 初回限定
        li.label-item(v-if="fewInStock") 残り{{totalSalableStock}}個
        li.label-item(v-if="hasFreeShippingRegions") 送料無料あり
        li.label-item(v-if="hasPostDeliveryItem") メール便対応
        li.label-item(v-if="hasCompactDelivery") コンパクト便対応

      .name {{itemGroup.name}}
      .price(:class="{'is-sale': isSale}")
        |{{properPriceInTax | numberToCurrency}}
        template(v-if="maxProperPriceInTax") ~{{maxProperPriceInTax | numberToCurrency}}
        span(v-if="isAddTax") (税込)
      .sale-price(v-if="isSale")
        |{{salePriceInTax | numberToCurrency}}
        template(v-if="maxSalePriceInTax") ~{{maxSalePriceInTax | numberToCurrency}}
        span(v-if="isAddTax") (税込)
      .button-wrapper
        ButtonBaseV1.button(variant="normal" size="inline" :href="itemGroup.detailPagePath" @click="gaSendEvent")
          | 商品を見る
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import ButtonBaseV1 from '../../v1/button/ButtonBase'

  export default {
    name: 'ItemLinkForApi',
    mixins: [GlobalMixin],
    components: {
      ButtonBaseV1,
    },
    props: {
      itemGroup: {
        type: Object,
        default() {
          return {}
        }
      },
      adminShop: {
        type: Object,
        default() {
          return {}
        }
      },
      pageContents: {
        type: String,
        required: false,
        default: '商品埋込カード'
      },
    },
    computed: {
      items() {
        return this.itemGroup?.openItems || []
      },
      isSale() {
        for(let item of this.items) {
          if (item.isSale) return true
        }
        return false
      },
      saleName() {
        let result = ''
        for(let item of this.items) {
          if (item.isSale) {
            result = item.saleName
            break;
          }
        }
        return result
      },
      properPriceInTax() {
        let result = null
        for(let item of this.items) {
          if (!result || result > item.properPriceInTax) {
            result = item.properPriceInTax
          }
        }
        return result || 0
      },
      maxProperPriceInTax() {
        let result = 0
        if (this.items.length > 1 ) {
          for(let item of this.items) {
            if (result < item.properPriceInTax) {
              result = item.properPriceInTax
            }
          }
        }
        return result
      },
      isRangeProperPrice() {
        return this.maxProperPriceInTax > 0
      },
      salePriceInTax() {
        return this.salePrices[0]
      },
      maxSalePriceInTax() {
        return this.salePrices[-1]
      },
      isRangeSalePrice() {
        return this.salePrices.length > 1
      },
      salePrices() {
        let result = []
        for(let item of this.items) {
          if (item.isSale &&  item.salePriceInTax > 0 && result.indexOf(item.salePriceInTax) === -1) {
            result.push(item.salePriceInTax)
          }
        }
        return result.sort((a, b) => a - b);
      },
      isGiftPossible() {
        return this.itemGroup.isNoshiPossible ||
            this.itemGroup.isWrappingPossible ||
            this.itemGroup.isMessageCardPossible
      },
      delivery() {
        return this.itemGroup?.adminShop?.delivery
      },
      isFreeShipping() {
        if (this.itemGroup?.isForceFreeShipping) return true

        if (this.devlivery && this.devlivery.freeShippingStatus === 'free_shipping_on') {
          for(let item of this.items) {
            if (item.sellingPrice > this.devlivery.freeShippingPrice) return true
          }
        }
        return false
      },
      hasSubscriptionItem() {
        return this.items.some(item => item.isSubscriptionItem)
      },
      hasOnetimeItem() {
        return this.items.some(item => item.isOnetimeItem)
      },
      hasPostDeliveryItem() {
        return this.items.some(item => item.itemDeliveryMethod?.isPostDelivery)
      },
      hasCompactDelivery() {
        return this.items.some(item => item.itemDeliveryMethod?.isCompactDelivery)
      },
      hasFreeShippingRegions() {
        for(let item of this.items) {
          if (item && item.itemFreeShippingRegions.length > 0) {
            return true
          }
        }
        return false
      },
      totalSalableStock() {
        let result = 0
        this.items.forEach(item => result = result + item.salableStock)
        return result
      },
      fewInStock() {
        return 0 < this.totalSalableStock && this.totalSalableStock < 10
      },
      isAddTax() {
        return this.adminShop?.isAddTax;
      },
      preRelease() {
        return !this.nowOnSale
      },
      nowOnSale() {
        return this.itemGroup?.isNowOnSale && this.items.some(item => item.isNowOnSale)
      },

      soldOut() {
        return this.totalSalableStock === 0
      },
    },
    methods: {
      gaSendEvent() {
        this.$_gaSendEvent('商品詳細へ遷移', '商品詳細商品説明', `${this.adminShop.name} ${this.itemGroup.name}`)
        this.$gtag.event('select_item', {
          items: [{
            item_id: this.itemGroup.id,
            item_name: `${this.adminShop.name} ${this.itemGroup.name}`,
            affiliation: this.adminShop.code,
            item_brand: this.adminShop.name,
          }],
          page_contents: this.pageContents,
        })

      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .item-link {
    display: flex;
    cursor: pointer;
    @include padding(xs);
    text-decoration: none;border-radius: 3px;
    border: 1px solid $gray-300;

    &:hover {
      > .desc {
        > .name,
        > .price {
          color: $olive-300;
        }
      }
    }
    > .thumbnail {
        width: 20%;
        @include mq-down() {
          width: 30%;
        }
      > .image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        > .status {
          position: absolute;
          background: rgba(255, 255, 255, 0.8);
          padding: 1em;
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-snug;
          color: $black-400;
          white-space: nowrap;
        }
      }
    }

    > .desc {
      width: 80%;
      @include padding-left(xs);
      @include mq-down(){
        width: 70%;
      }

      > .shop {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $black-400;
        line-height: $line-height-tight;
        @include font-size(xs);
        > .image {
          width: 18px;
          height: 18px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      > .tags {
        @include margin-top(xxxs);
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        // margin: 0;

        > li {
          display: flex;
          align-items: center;
          height: 20px;
          padding: 0 5px;
          margin: -1px -1px 0 0;
          background: $white;
          border: 1px solid $olive-300;
          color: $olive-300;
          line-height: 1;
          @include font-size(xxxs);
        }
      }

      > .name,
      > .price,
      > .sale-price {
        color: $black-400;
        font-weight: bold;
        @include margin-top(xxxs);
        @include font-size(sm);
        line-height: $line-height-tight;
      }

      > .price,
      > .sale-price {
        > span {
          font-weight: normal;
          @include font-size(xxs);
          line-height: $line-height-tight;
        }
      }

      > .price {
        &.is-sale{
          font-weight: normal;
          text-decoration: line-through;
          @include font-size(xs);
          line-height: $line-height-tight;

          > span {
            @include font-size(xxxs);
            line-height: $line-height-tight;
          }
        }
      }

      > .sale-price {
        margin-top: 0;
        color: $olive-300;
        line-height: $line-height-tight;
      }
      > .button-wrapper {
        margin: 8px 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        align-self: stretch;
      }
    }
  }
</style>
