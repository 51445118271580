<template lang="pug">
  section.section-item-column(v-if="columns.length > 0")
    HeadingArticle.mb-xs {{ itemGroup.name }}の関連記事
    ColumnItem.mb-lg(v-for="column in columns" :key="column.id" :column="column")
</template>

<script>
  import { GetItemGroupColumns } from '../../../graphqls/Columns'
  import HeadingArticle from '../typography/HeadingArticle'
  import ColumnItem from '../post/ColumnItem'

  export default {
    name: 'SectionItemColumn',
    components: {
      HeadingArticle,
      ColumnItem
    },
    props: {
      itemGroup: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {
        columns: []
      }
    },
    mounted() {
      this.fetchItemGroupColumns()
    },
    methods: {
      async fetchItemGroupColumns () {
        const {data} = await this.$apollo
        .query({
          query: GetItemGroupColumns,
          variables: {
            itemGroupId: this.itemGroup.id,
            perPage: -1,
            orderBY: 'posted_at desc',
          }
        })
        this.columns = data.itemGroupColumns.items
      }
    }
  }
</script>
