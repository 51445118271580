<template lang="pug">
  .recover-password
    AlertBase.mt-sm(v-if="recaptchaErrorMessage || validateErrors.length > 0" type="error")
      p(v-if="recaptchaErrorMessage") {{ recaptchaErrorMessage }}
      p(v-for="message of $_validationErrorMessage") {{message}}
    AlertBase.mt-sm(v-if="successMessage" type="success")
      p {{ successMessage }}
    section.section
      h1.heading-1.text-center.my-md パスワード再設定
      p.paragraph.text-center.mb-sm 新しいパスワードを再設定してください。
      ContainerSheet.mb-md
        template(v-if="isComplete")
          ButtonBase(href="/login/" variant="primary" :block="true")
            | ログインする
        template(v-else)
          FormItem
            template(slot="title")
              FormLabel(type="required") パスワード
            template(slot="input")
              FormTextfield(
                v-model="form.password"
                type="password"
                placeholder="8~20文字 半角英数字・記号"
                :error="'password' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.password"
              )
          FormItem
            template(slot="title")
              FormLabel(type="required") パスワード(確認)
            template(slot="input")
              FormTextfield(
                v-model="form.passwordConfirmation"
                type="password"
                placeholder="8~20文字 半角英数字・記号"
                :error="'password_confirmation' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.password_confirmation"
              )
          ButtonBase(@click="doRecoverPassword" variant="primary" :block="true")
            | パスワードを再設定する
</template>

<script>
  import { RecoverPassword } from '../../graphqls/Users'
  import GlobalMixin from '../../mixin/GlobalMixin'
  import ContainerSheet from '../../components/v1/container/ContainerSheet'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormTextfield from '../../components/v1/form/FormTextfield'

  export default {
    name: 'RecoverPassword',
    mixins: [GlobalMixin],
    components: {
      AlertBase,
      ContainerSheet,
      FormItem,
      FormLabel,
      FormTextfield,
      ButtonBase,
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    data() {
      return {
        form: {
          resetPasswordToken: '',
          password: '',
          passwordConfirmation: '',
          recaptchaToken: ''
        },
        successMessage: '',
        isComplete: false
      }
    },
    mounted() {
      this.form.resetPasswordToken = this.$route.params.token
      // パンくずリストを設定
      this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/recover/',
          name: 'パスワード再設定',
        },
      ]})
    },
    methods: {
      async doRecoverPassword() {
        this.loadingStart()
        this.successMessage = ''
        this.$_clearValidationErrors()

        const token = await this.$_createRecaptchaToken('recover_password')
        this.form.recaptchaToken = token

        const { data } = await this.$apollo
        .mutate({
          mutation: RecoverPassword,
          variables: {
            input: this.form
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        })

        const errors = data.recoverPassword.errors
        if (errors) {
          this.$_parseError(errors)
        } else {
          this.user = data.recoverPassword.user
          this.successMessage = data.recoverPassword.message
          this.$scrollTo("body");
          this.isComplete = true
        }
        this.loadingStop()
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/v1/styles";

  .recover-password {
    > .alert-base {
      width: 100%;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }

    > .section {
      > .container-sheet {
        width: 100%;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 32px;
        padding-bottom: 32px;
  
        > .form-item {
          margin-top: 0;
        }
      }
    }
  }
</style>
