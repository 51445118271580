<template lang="pug">
.star-rating
  span(
    v-for="(star, index) in maxRating"
    :key="index"
    class="star"
    :class="{ 'filled': index < currentRating }"
    @click="setRating(index + 1)"
    ) {{ starIcons[index] }}
  p.message(v-if="errorMessage") {{ errorMessage }}
</template>
  
  
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    maxRating: {
      type: Number,
      default: 5
    },
    initialRating: {
      type: Number,
      default: 0
    },
    errorMessage: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      currentRating: this.initialRating
    };
  },
  computed: {
    starIcons() {
      const filledStar = '★';
      const outlineStar = '☆';
      return Array(this.maxRating)
      .fill(null)
      .map((_, index) => (index < this.localValue ? filledStar : outlineStar));
    },
    localValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    },
  },
  methods: {
    setRating(rating) {
      this.localValue = rating;
  }
  }
};
</script>


<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
.star-rating {
  font-size: 32px;
  cursor: pointer;
  color: $olive-200;
  line-height: $line-height-none;

  .message {
    @include margin-top(xxxs);
    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    @include font-size(xs);
    line-height: $line-height-tight;
    color: $red-300;
  }
}
.star {
  margin-right: 4px;
}
</style>