<template lang="pug">
  .about-index
    img(src="https://cdn.taberutokurasuto.com/image/about/20190226_main_sp.jpg" alt="たべるとくらすと" width="100%" height="auto")
    section.text-center
      h1.heading-1.mt-lg.mb-md たべるとくらすとについて
      h3.heading-3.my-md つくる人と、食べる人をつなぐ。

      p たべるとくらすとは、全国各地のこだわり生産者の直売モールです。
      p
        | 丁寧に想いを込めて作ったものを届けたい生産者と、
        br
        | 美味しく安心して食べられるものを願う生活者がつながることで
        br
        | 「食べる暮らしをゆたかにしたい」そんな気持ちで、2017年に開始しました。

      p たべるとくらすとの夢は、「世界一、人と人がつながるオンラインフードモール」になること。
      p つくる人と食べる人が、まるでマルシェで楽しくおしゃべりしているような、顔が見え、想いが伝わる関係性を目指しています。
    hr.my-md
    section
      h1.heading-1.text-center.mt-lg.mb-md たべるとくらすと ５つのこだわり
      .columns
        .column
          img.mt-sm(src="https://cdn.taberutokurasuto.com/image/about/second2.jpg" alt="たべるとくらすと" width="100%" height="auto")
        .column
          p 私たちは、私たちが本当に届けたいもの・食べたいものにこだわります。
          p 生産者との関係性も大切に考え、全ての生産者の方とお話しをして、お互い共感した方に出店いただいています。

          p
            | 1. 私たちが美味しいと思えるもの
            br
            | 2. 家族に安心して食べさせられるもの
            br
            | 3. オーガニック思考で作られているもの
            br
            | 4. 環境に配慮されて作られているもの
            br
            | 5. 愛情込めて作られているもの

          ContainerSheet.text-center
            p.font-bold 商品基準
            p
              | 農薬、化学肥料、除草剤を使用していない野菜やお米などの農作物
              br
              | 添加物を使用していない加工品や調味料
            p.font-small
              | ※生産者様から生産製造工程を伺った上で上記基準外の出品も許容する場合がございます。
              | 詳しくは商品ページをご確認の上、お買い求めください。
</template>

<script>
  import GlobalMixin from "../../mixin/GlobalMixin"
  import ContainerSheet from '../../components/v1/container/ContainerSheet'

  export default {
    name: "about-index",
    mixins: [GlobalMixin],
    components: {
      ContainerSheet
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    mounted () {
      // サイド表示
      this.showSide()
      // パンくずリストを設定
      this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/about/',
          name: '「たべるとくらすと」とは?',
        },
      ]})
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .about-index {
    > section {
      p {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-normal;
        color: $black-400;
        @include margin-bottom(sm);
      }
      > .columns {
        display: flex;
        flex-direction: column-reverse;
        > .column {
          > .container-sheet {
            p:last-child {
              margin-bottom: 0;
            }
          }
          .font-small {
            @include font-size(xs);
          }
        }

        @include mq-up(md) {
          flex-direction: row;
          > .column {
            width: 50%;
            @include padding(zero, xs);
            > img {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
</style>
