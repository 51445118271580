<template lang="pug">
  .item-archive
    slot
</template>

<script>
  export default {
    name: 'ItemArchive'
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .item-archive {
    display: flex;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;
    @include mq-up() {
      margin-left: -8px;
      margin-right: -8px;
    }
    
    > .item {
      width: 33.33%;
      @include mq-up() {
        width: 16.66%;
      }
      @include padding-left(xxxs);
      @include padding-right(xxxs);
      @include margin-bottom(lg);
    }
  }
</style>
