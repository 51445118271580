<template lang="pug">
  section.section-item-post(v-if="posts.length > 0")
    HeadingArticle.mb-xs {{ itemGroup.name }}に関するお知らせ
    PostList
      PostItem(v-for="post in posts" :key="post.id" :post="post")
</template>

<script>
  import { GetPosts } from '../../../graphqls/Posts'
  import HeadingArticle from '../typography/HeadingArticle'
  import PostList from '../post/PostList'
  import PostItem from '../post/PostItem'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'SectionItemPost',
    components: {
      HeadingArticle,
      PostList,
      PostItem,
      ButtonBase
    },
    props: {
      itemGroup: {
        type: Object,
        required: true,
        default: () => {}
      },
      adminShop: {
        type: Object,
        required: true,
        default: () => {}
      },
    },
    data() {
      return {
        posts: []
      }
    },
    computed: {
    },
    mounted() {
      this.fetchPosts()
    },
    methods: {
      async fetchPosts() {
        const { data } = await this.$apollo
        .query({
          query: GetPosts,
          variables: {
            orderBY: 'published_start_at desc',
            search: JSON.stringify({post_bodies_item_group_id_eq: this.itemGroup.id})
          }
        })
        this.posts = data.posts.items.map((item) => ({
          id: item.id,
          href: `/shop/${item.adminShop.code}/blog/entry/${item.customUrl}/`,
          src: item.displayListImage,
          title: item.title
        }))
      }
    }
  }
</script>
