<template lang="pug">
  .heading-roof
    include ../../../../../assets/images/icon/heading-roof.svg
    h2.title {{ title }}
    p.subtitle {{ subtitle }}
</template>

<script>
  export default {
    name: 'HeadingRoof',
    props: {
      title: {
        type: String,
        require: true,
        default: ''
      },
      subtitle: {
        type: String,
        require: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .heading-roof {
    text-align: center;

    svg {
      height: 1rem;
    }
    .title {
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(lg);
      line-height: $line-height-snug;
      color: $black-400;
    }
    .subtitle {
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(xs);
      line-height: $line-height-tight;
      color: $black-400;
    }
  }
</style>
