import gql from "graphql-tag";

export const GetShopBanners = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  shopBanners (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      image
      url
      title
      alt
      publishedStatus
      imageSet {
        mdImage
      }
    }
    pageInfo {
      currentPage
      totalCount
      perPage
    }
  }
}
`
