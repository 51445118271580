import {mapActions, mapGetters} from 'vuex'
import { config } from '../config/config'
import { breakpointupmd } from "../../../assets/stylesheets/v1/export-variables.scss"

export default {
  data () {
    return {
      validateErrors: [],
      recaptchaErrorMessage: ''
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'isPc',
      'isMobile',
      'breadcrumbs',
      'toast'
    ]),
    $_validationErrorMessage () {
      let errorMessage = {}
      for (const error of this.validateErrors) {
        // errorMessage[error.attribute] = this.$t(`model.${error.model}.${error.attribute}`) + error.message
        errorMessage[error.attribute] = error.fullMessage
      }
      return errorMessage
    },
    $_itemListElement () {
      return this.breadcrumbs.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': config.site_host + breadcrumb.path,
          name: breadcrumb.name
        }
      }))
    }
  },
  methods: {
    $_gaSendEvent (action, category, label, value = null) {
      if (action && category && label) {
        this.$gtag.event(action, {
          'event_category': category,
          'event_label': label,
          'value': value
        })
      }
    },
    $_clearValidationErrors() {
      this.validateErrors = []
      this.recaptchaErrorMessage = ''
    },
    $_parseError(errors) {
      this.$_clearValidationErrors()
      for (let error of errors) {
        if (error.redirectPath) {
          location.href = error.redirectPath
          return
        }
        if (error.statusCode === '400') {
          this.validateErrors.push(error)
        } else if (error.statusCode === '401' && error.attribute === 'recaptchaToken') {
          this.recaptchaErrorMessage = error.fullMessage
        }
      }
    },
    $_redirect_internal_server_error(error) {
      console.log('error', error)
      location.href = '/500/'
    },
    $_createRecaptchaToken: function (action_name) {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(config.recaptcha_v3_sitekey, {action: action_name})
          .then((token) => {
            return resolve(token)
          })
        })
      })
    },
    $_isPC() {
      return window.matchMedia(breakpointupmd).matches
    },
    ...mapActions([
      'loadingStart',
      'loadingStop',
      'showSide',
      'hideSide',
      'showBanner',
      'hideBanner',
      'setBreadcrumbs',
      'setToast',
      'clearToast',
      'fetchCurrentShop',
      'showCommonCoupon',
      "showHomeCoupon",
      'showShopCoupon',
    ])
  },
  filters: {
    numberToCurrency (value) {
      if (value == null) return ''
      else return `${Number(value).toLocaleString()}円`;
    },
    numberWithDelimiter (value) {
      if (value == null) return ''
      else return `${Number(value).toLocaleString()}`;
    }
  }
}
