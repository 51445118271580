<template lang="pug">
  .the-loading(v-if="isLoading")
    .loader
</template>

<script>
  export default {
    name: 'TheLoading',
    computed: {
      isLoading() {
        return this.$store.getters.loading
      }
    },
    watch: {
      isLoading: function () {
        const elements = document.getElementsByTagName('body')
        if (this.isLoading) {
          elements[0].classList.add('-fixed')
        } else {
          elements[0].classList.remove('-fixed')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .the-loading {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.4);
    z-index: 9999;

    > .loader {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border-top: 6px solid $gray-400;
      border-right: 6px solid $gray-400;
      border-bottom: 6px solid $gray-400;
      border-left: 6px solid $olive-400;
      animation: load 1.1s infinite linear;
    }
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>

<style lang="scss">
  body {
    &.-fixed {
      overflow: hidden;
    }
  }
</style>
