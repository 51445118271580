<template lang="pug">
  component.button(:is="component" :to="to" :href="href" :class="classes" :data-eventcategory="eventCategory" :data-eventlabel="eventLabel" @click="click")
    slot
</template>

<script>
  import GlobalMixin from "../../mixin/GlobalMixin";
  export default {
    name: "ButtonBase",
    mixins: [GlobalMixin],
    props: {
      href: String,
      to: Object,
      color: {
        type: String,
        default: 'olive-dark'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      simple: {
        type: Boolean,
        default: false
      },
      block: {
        type: Boolean,
        default: false
      },
      sendGa: {
        type: Boolean,
        default: true
      },
      eventAction: String,
      eventCategory: String,
      eventLabel: String,
    },
    computed: {
      component() {
        let component = 'button';
        if (this.to) component = 'router-link';
        else if (this.href) component = 'a';
        return component;
      },
      classes() {
        let classes = [`color-${this.color}`];
        if (this.sendGa) classes.push('ga_send');
        if (this.disabled) classes.push('is-disabled');
        if (this.simple) classes.push('is-simple');
        if (this.block) classes.push('is-block');
        return classes;
      }
    },
    methods: {
      click() {
        if (!this.disabled) {
          this.$emit('click');

          if (this.eventCategory && this.eventLabel && this.eventAction) {
            this.$_gaSendEvent(this.eventAction, this.eventCategory, this.eventLabel);
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-width: 252px;
    min-height: 40px;
    border: none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    line-height: 143%;
    @include padding(xxxs);
    @include font-size(xs);

    @include mq-down(md) {
      width: 100%;
    }

    @each $name, $color in $colors {
      &.color-#{$name} {
        background: $color;
        @if lightness($color) > 75% {
          color: $black-dark;
        }
        @else {
          color: $white;
        }

        &.is-simple {
          background: $white;
          color: $color;
        }
      }
    }

    &.is-disabled {
      background: $gray-darker;
      color: $white;
      cursor: not-allowed;
    }

    &.is-simple {
      min-width: auto;
      @include padding(xxxs, sm)
    }
    &.is-block {
      width: 100%;
    }
  }
</style>

<style lang="scss">
  button {
    &.button {
      display: inline-block !important;
    }
  }
</style>
