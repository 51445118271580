import gql from 'graphql-tag'

export const GetItemTags = gql`
query ($perPage: Int, $search: String) {
  itemTags (perPage: $perPage, search: $search) {
    items {
      id
      code
      name
      subTitle
    }
  }
}
`

export const GetItemTag = gql`
query ($itemTagId: ID, $itemTagCode: String) {
  itemTag (itemTagId: $itemTagId, itemTagCode: $itemTagCode) {
    id
    code
    name
    publishedStatus
    showMenuStatus
    showHomeStatus
    title
    subTitle
    description
    metaTitle
    metaDescription
    adminShopId
    adminShop {
      name
      code
    }
    mainImageUrl
    mainImageSet {
      lgImage
    }
    ogImageUrl
    ogImageSet {
      ogpImage
    }
  }
}`

export const GetItemTagForGiftPage = gql`
query ($itemTagId: ID) {
  itemTag (itemTagId: $itemTagId) {
    id
    code
    name
  }
}`
