<template lang="pug">
  .item-filter-block
    slot
</template>

<script>
export default {
  name: "ItemFilterBlock",
}
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
.item-filter-block {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  @include padding-bottom(sm);

  .stock-checkbok {
    @include padding-top(xxs);
    @include padding-bottom(xxs);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>