<template lang="pug">
  IconBase
    MailSvg
</template>
<script>
  import IconBase from './IconBase'
  import MailSvg from "../svg/MailSvg";
  export default {
    name: "mail-icon",
    components: {
      IconBase,
      MailSvg
    }
  }
</script>
