<template lang="pug">
  .modal(:class="modalClass")
    .close-mask(v-if="!disabledClose" @click="close")
    .modal-content(:class="`is-${this.size}`")
      .header
        slot(name="header")
        .close(v-if="!disabledClose" @click="close")
          CloseIcon
      .body
        slot(name="body")
      .footer
        slot(name="footer")
</template>

<script>
  import CloseIcon from '../icon/CloseIcon';

  export default {
    name: "Modal",
    components: {CloseIcon},
    props: {
      size: {
        type: String,
        default: "sm"
      },
      disabledClose: {
        type: Boolean,
        default: false
      },
      buttonBlock: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isOpen: false
      }
    },
    computed: {
      modalClass() {
        let classes = [];
        if (this.isOpen) classes.push('is-open');
        if (this.disabledClose) classes.push('disabled-close');
        if (this.buttonBlock) classes.push('is-button-block');
        return classes;
      }
    },
    methods: {
      open(e) {
        this.isOpen = true;
        this.$emit('open', e)
      },
      close(e) {
        this.isOpen = false;
        this.$emit('close', e)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles.scss";
  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /*display: none;*/
    visibility: hidden;
    background: rgba($white, .5);
    overflow: auto;
    z-index: 1040;

    &.is-open {
      display: block;
      visibility: visible;
    }

    > .close-mask {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &.is-button-block {
      > .modal-content {
        >.body {
          padding: 0;
        }
      }
    }

    > .modal-content {
      position: absolute;
      /*top: 50%;*/
      left: 0;
      right: 0;
      //transform: translateY(-50%);
      width: calc(100% - 20px);
      border: 1px solid $gray_dark;
      border-radius: 4px;
      margin: 15px auto;
      overflow: visible;

      &.is-sm {
        max-width: 480px;
      }

      &.is-md {
        max-width: 600px;
      }

      &.is-lg {
        max-width: 980px;
      }

      > .header {
        position: relative;
        background: $white;
        padding: 16px 52px 16px 18px;
        border-bottom: 1px solid $gray_dark;
        line-height: 100%;
        @include font-size(md);

        > .close {
          position: absolute;
          display: inline-block;
          top: 18px;
          right: 18px;
          cursor: pointer;
          width: 20px;
          height: 20px;
          @include mq-down(md) {
            top: 17px;
          }
        }
      }

      > .body {
        background: $white;
        border-bottom: 1px solid $gray_dark;
        padding: 24px;
        word-break: break-all;

        > .heading-3 {
          + .text-block {
            @include margin-top(xs);
          }
        }

        > .notice-message {
          @include margin-bottom(md);
        }
      }

      > .footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        background: $white;
        padding: 18px;

        > .button {
          & + .button {
            @include margin-left(xs);
          }
        }
      }
    }
  }
</style>
