<template lang="pug">
  ModalBase(ref="modalBase" size="medium")
    template(slot="header") お支払い方法
    template(slot="body")
      section(v-if="adminShop.isCreditCardAvailable")
        h3.heading クレジットカード
        p 以下のカードがご利用頂けます。
        ul.credits
          li.credit
            include ../../../../../assets/images/icon/cc-visa-brands.svg
            span.name VISA
          li.credit
            include ../../../../../assets/images/icon/cc-mastercard-brands.svg
            span.name MASTER
          li.credit(v-if="adminShop.creditBrandsAcceptedList.includes('JCB')")
            include ../../../../../assets/images/icon/cc-jcb-brands.svg
            span.name JCB
          li.credit(v-if="adminShop.creditBrandsAcceptedList.includes('Discover')")
            include ../../../../../assets/images/icon/cc-diners-club-brands.svg
            span.name DINERS
          li.credit(v-if="adminShop.creditBrandsAcceptedList.includes('American Express')")
            include ../../../../../assets/images/icon/cc-amex-brands.svg
            span.name AMEX
      section(v-if="adminShop.isCodAvailable")
        h3.heading 代金引換（{{ adminShop.minCodFee.feeInTax | numberToCurrency }}~）
      section(v-if="adminShop.isConvenienceAvailable")
        h3.heading コンビニ後払い
      section(v-if="adminShop.isBankTransferAvailable")
        h3.heading 銀行振込
        h5.bankname {{ adminShop.displayBankName }}
        table
          tbody
            tr
              th 支店名
              td {{ adminShop.displayBankBranchName }}
            tr
              th 預金種別
              td {{ adminShop.displayBankType === 'ordinary_deposit' ? '普通' : '当座' }}
            tr
              th 口座番号
              td {{ adminShop.displayBankAccountNumber }}
            tr
              th 口座名義
              td {{ adminShop.displayBankPersonName }}
        h5.bankname ゆうちょ銀行
        table
          tbody
            tr
              th 店名
              td {{ adminShop.yuutyoBankBranchName }}
            tr
              th 店番
              td {{ adminShop.yuutyoBankBranchNumber }}
            tr
              th 預金種別
              td {{ adminShop.yuutyoBankType}}
            tr
              th 口座番号
              td {{ adminShop.yuutyoBankAccountNumber }}
            tr
              th 記号番号
              td {{ adminShop.yuutyoBankKigouNumber }}
            tr
              th 口座名義
              td {{ adminShop.yuutyoBankPersonName }}
    template(slot="footer")
      ButtonBase(@click="close()" variant="normal" size="inline") 閉じる
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import ModalBase from "../modal/ModalBase"
  import HeadingArticle from '../typography/HeadingArticle'
  import ImgResponsive from '../../image/ImgResponsive'
  import VueMarkdown from 'vue-markdown'
  import ItemLink from '../../item/ItemLink'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'ModalPayment',
    mixins: [GlobalMixin],
    components: {
      ModalBase,
      HeadingArticle,
      ImgResponsive,
      VueMarkdown,
      ItemLink,
      ButtonBase
    },
    props: {
      adminShop: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    },
    methods: {
      open: function(e) {
        this.$refs.modalBase.open()
        this.$emit('open', e)
      },
      close: function(e) {
        this.$refs.modalBase.close()
        this.$emit('close', e)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .modal-base {
    .body {
      section {
        @include margin-bottom(md);

        .heading {
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-snug;
          color: $black-400;
          @include margin-bottom(xxxs);
        }

        .credits {
          display: flex;
          width: 100%;
          > .credit {
            text-align: center;
            margin-right: 8px;
            > svg {
              display: block;
              width: 28px;
              margin: 0 auto;
            }
            > .name {
              font-size: 10px;
              display: block;
            }
          }
        }

        .bankname {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-snug;
          color: $black-400;
          @include margin-top(md);
          @include margin-bottom(xxxs);
        }

        table {
          width: 100%;
          tbody {
            tr {
              border-top: 1px solid $gray-200;
              &:last-child {
                border-bottom: 1px solid $gray-200;
              }
              th, td {
                font-family: $font-family-sans;
                font-weight: $font-weight-regular;
                font-style: $font-style-not-italic;
                @include font-size(sm);
                line-height: $line-height-snug;
                color: $black-400;
                @include padding(xxs);
              }
            }
          }
        }
      }
    }
  }
</style>
