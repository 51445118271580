<template lang="pug">
  button.button-amazon(@click="onClick")
    img.image(src="../../../../../assets/images/sns/btnLWA_gold_312x64.png")
</template>

<script>
  export default {
    name: 'ButtonAmazon',
    props: {
      href: {
        type: String,
        required: true,
        default: '',
      },
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
      onClick() {
        location.href = this.href
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .button-amazon {
    padding: 0;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    display: block;
    .image {
      height: 40px;
      width: auto;
    }
  }
</style>
