<template lang="pug">
  ModalBase(ref="modalBase" size="medium")
    template(slot="header") ご利用いただけないメールアドレスについて
    template(slot="body")
      div
        | 以下のようなメールアドレスの場合、本サービスを正しくご利用いただくことができません。
      .heading-3.my-sm ご利用いただけないメールアドレスの例
      div * アットマーク(＠)の直前やメールアドレスの先頭にドット(.)がある場合
      AlertMessage.mb-sm .xxxx.@docomo.ne.jp
      div * アットマーク(＠)より前でドット(.)が連続している場合
      AlertMessage.mb-sm xx..xx＠ezweb.ne.jp
      div.text-xs.mb-sm 上記の多くは携帯電話のメールアドレス（@docomo.ne.jp、＠ezweb.ne.jp のキャリアメール等）に該当します。 *IETFが規定するRFCに準拠
      div * Hotmailのメールアドレス
      AlertMessage.mb-sm xxxxxxx@hotmail.com、xxxxxx@hotmail.co.jp

      div このようなメールアドレスを利用されている場合、本サービスを正しくご利用いただくことができない可能性がございますので、別のメールアドレスでのご登録をお願いいたします。

    template(slot="footer")
      ButtonBase(@click="close()" variant="normal" size="inline") 閉じる

</template>

<script>
import ModalBase from "./ModalBase";
import ButtonBase from "../../v1/button/ButtonBase";
import AlertMessage from "../../alert/AlertMessage";
export default {
name: "InvalidMailModal",
  components: {
    AlertMessage,
    ButtonBase,
    ModalBase
  },
  methods: {
    open: function(e) {
      this.$refs.modalBase.open()
      this.$emit('open', e)
    },
    close: function(e) {
      this.$refs.modalBase.close()
      this.$emit('close', e)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
