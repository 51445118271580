<template lang="pug">
  .row
    slot
</template>

<script>
  export default {
    name: "Row"
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
</style>
