<template lang="pug">
.flash-messages 
  AlertBase(v-if="localMessages.success.length > 0" type="success")
    p(v-for="message of localMessages.success") {{ message }}
  AlertBase(v-if="localMessages.warning.length > 0" type="warning")
    p(v-for="message of localMessages.warning") {{ message }}
  AlertBase(v-if="localMessages.danger.length > 0" type="danger")
    p(v-for="message of localMessages.danger") {{ message }}
  AlertBase(v-if="localMessages.error.length > 0" type="error")
    p(v-for="message of localMessages.error") {{ message }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AlertBase from './AlertBase'

export default {
  name: 'FlashMessages',
  components: {
    AlertBase
  },
  props: {},
  data() {
    return {
      localMessages: {
        success: [],
        warning: [],
        danger: [],
        error: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'flashMessages',
    ]),
  },
  mounted() {
    for (const variant in this.localMessages) {
      let messages = this.flashMessages[variant]
      if (messages) {
        this.localMessages[variant] = messages
        this.clearFlashMessage({ variant })
      }
    }
  },
  methods: {
    ...mapActions([
      'clearFlashMessage',
    ]),
  }
}
</script>

<style lang="scss" scoped>
</style>
