<template lang="pug">
  a.notice-item(:href="href")
    .unread(v-if="!notice.confirmedAt")
    .icon-area
      UserIconDefault(v-if="!notice.adminShop.profileImageSet.xsSqImage")
      img.icon(v-else :src="notice.adminShop.profileImageSet.xsSqImage")
    .text-area
      p.from {{notice.shopName}}
      .row
        span.type {{notice.noticeTypeLabel}}
        p.title {{notice.title}}
      p.desc
        //span.read 既読
        template(v-if="notice.lastComment")
          span.elapsedTime {{notice.lastCommentElapsedTime}}
          | {{notice.lastComment}}
    //- .status-area
    //-   span.status 未対応
</template>

<script>
  import UserIconDefault from '../../../components/svg/UserIconDefault'

  export default {
    name: 'NoticeItem',
    components: {
      UserIconDefault
    },
    props: {
      notice: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {

      }
    },
    computed: {
      typeLabel() {
        switch (this.notice.noticeType) {
          case 'order_contact_notice':
            return "受注の問い合わせ"
        }
        return ''
      },
      href() {
        switch (this.notice.noticeType) {
          case 'order_contact_notice':
            return `/mypage/purchasehistory/${this.notice.orderId}/`
          case 'user_contact_notice':
          case 'item_group_qa_notice':
            return `/mypage/contacts/${this.notice.id}/`
        }
        return ''
      }
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .notice-item {
    min-height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    background: $white;
    border: 1px solid $gray-200;
    text-decoration: none;
    position: relative;
    @include padding(xxs);
    margin-top: -1px;

    > .unread {
      position: absolute;
      height: 8px;
      width: 8px;
      background: $red-300;
      border-radius: 50%;
    }

    > .icon-area {
      height: 42px;
      width: 42px;
      @include mq-up() {
        height: 57px;
        width: 57px;
      }
      border: 1px solid $black-100;
      border-radius: 50%;
      @include margin-left(sm);
      @include margin-right(xs);

      > .icon, > svg {
        height: 40px;
        width: 40px;
        @include mq-up() {
          height: 55px;
          width: 55px;
        }
        border-radius: 50%;
      }
    }

    > .text-area {
      width: 80%;

      > .from {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xs);
        line-height: $line-height-none;
        color: $black-400;
        @include margin-bottom(xxxs);
      }

      > .row {
        display: flex;
        align-items: center;
        @include margin-bottom(xxxs);

        > .type {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xxs);
          line-height: $line-height-none;
          white-space: nowrap;
          color: $white;
          background: $olive-200;
          border-radius: 3px;
          padding: 0.3em;
        }

        > .title {
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-none;
          color: $black-400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @include margin-left(xxxs);
        }
      }

      > .desc {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xxs);
        line-height: $line-height-none;
        color: $black-100;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        > .read, > .elapsedTime {
          margin-right: 1em;
        }
      }
    }

    // > .status-area {
    //   margin-left: auto;
    //   > .status {
    //     font-family: $font-family-sans;
    //     font-weight: $font-weight-regular;
    //     font-style: $font-style-not-italic;
    //     @include font-size(xxs);
    //     line-height: $line-height-none;
    //     color: $white;
    //     white-space: nowrap;
    //     background: $olive-200;
    //     border-radius: 3px;
    //     padding: 4px 6px;
    //   }
    // }
  }
</style>
