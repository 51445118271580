<template lang="pug">
  section.section-item-qa#item-qa-section
    .section-header
      h2 商品に関連するQ&A
    .section-body
      .comment-box
        .box-header
          h3 新規ご質問
        .box-body
          template(v-if="currentUser")
            FormTextfield.mb-md(
              v-if="!currentUser.nickname"
              v-model="form.nickname"
              placeholder="ニックネームを入力してください"
              :error="'nickname' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.nickname"
            )
            FormTextarea.mb-md(
              v-model="form.question"
              placeholder="ご質問を入力してください"
              :error="'question' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.question"
            )
            .button-text
              p.mb-xs
                | たべるとくらすとの
                a.text-link(href="/terms/" target="_blank") 利用規約
                br
                a.text-link(href="/privacy/" target="_blank") プライバシーポリシー
                | に同意の上
              ButtonBase.mb-md(@click="createItemGroupQa") 新規に投稿する
              p
                | ※サイトの不具合や操作方法につきまして、
                a.text-link(href="/contacts/") こちらから
                | お問い合わせください。
          .text-center(v-else)
            ButtonBase(href="/login/") ログインして質問する

      ChatComment.first-comment(
        v-for="(itemGroupQa, i) in showItemGroupQas"
        :key="itemGroupQa.id"
        :loggedIn="loggedIn"
        :adminUser="currentAdminUser"
        :itemGroupQa="itemGroupQa"
        :defaultOpen="true"
        @toggleQuestionLike="toggleQuestionLike"
        @toggleCommentLike="toggleCommentLike"
        @updateQuestion="updateQuestion"
        @updateComment="updateComment"
        @addComment="addComment"
      )
      TransitionBlock(class="transition" :is-open="isOpen")
        ChatComment(
          v-for="(itemGroupQa, i) in hideItemGroupQas"
          :key="itemGroupQa.id"
          :loggedIn="loggedIn"
          :itemGroupQa="itemGroupQa"
          :defaultOpen="true"
          @toggleQuestionLike="toggleQuestionLike"
          @toggleCommentLike="toggleCommentLike"
          @updateQuestion="updateQuestion"
          @updateComment="updateComment"
          @addComment="addComment"
        )
      button.more-button(v-if="!isOpen && hasViewMore" @click="showAllComments")
        ArrowSvg
        span.label もっとみる
</template>

<script>
  import { GetItemGroupQas, CreateItemGroupQa } from '../../../graphqls/ItemGroupQas'
  import { GetCurrentUser } from '../../../graphqls/Users'
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import FormItem from '../form/FormItem'
  import FormLabel from '../form/FormLabel'
  import FormTextarea from '../form/FormTextarea'
  import FormTextfield from '../form/FormTextfield'
  import ButtonBase from '../button/ButtonBase'
  import ChatComment from '../chat/ChatComment'
  import TransitionBlock from '../../transition/TransitionBlock'
  import ArrowSvg from '../../svg/ArrowSvg'

  export default {
    name: 'SectionItemQA',
    mixins: [GlobalMixin],
    components: {
      FormItem,
      FormLabel,
      FormTextarea,
      FormTextfield,
      ButtonBase,
      ChatComment,
      TransitionBlock,
      ArrowSvg
    },
    props: {
      itemGroup: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {
        itemGroupQas: [],
        isOpen: false,
        form: {
          nickname: '',
          question: '',
          itemGroupId: this.itemGroup.id
        }
      }
    },
    computed: {
      currentUser() {
        return this.$store.getters.currentUser.user
      },
      currentAdminUser() {
        return this.$store.getters.currentUser.adminUser
      },
      hasViewMore() {
        return this.itemGroupQas.length > 3
      },
      showItemGroupQas() {
        return this.itemGroupQas.slice(0, 3);
      },
      hideItemGroupQas() {
        return this.itemGroupQas.slice(3, this.itemGroupQas.length);
      },
      loggedIn() {
        return !!this.currentUser || (this.itemGroupQas.length > 0 && this.currentAdminUser && (this.currentAdminUser.adminShop.isSystemAdmin || this.itemGroupQas[0].adminShopId === this.currentAdminUser.adminShop.id))
      }
    },
    mounted () {
      this.fetchCurrentUser()
      this.fetchItemGroupQas();
    },
    methods: {
      // CurrentUser取得
      async fetchCurrentUser () {
        const { data } = await this.$apollo
        .query({
          query: GetCurrentUser,
          client: 'apiClient'
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        this.$store.dispatch('setCurrentUser', { currentUser: data.currentUser })
        if (this.currentUser) {
          this.form.nickname = this.currentUser.nickname
        }
      },

      async fetchItemGroupQas () {
        const {data} = await this.$apollo
        .query({
          query: GetItemGroupQas,
          variables: {
            search: JSON.stringify({item_group_id_eq: this.itemGroup.id}),
            perPage: -1,
            orderBY: 'updated_at desc',
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        this.itemGroupQas = data.itemGroupQas.items;
        this.pageInfo = data.itemGroupQas.pageInfo;
      },

      // 質問追加
      async createItemGroupQa() {
        this.loadingStart();
        this.$_clearValidationErrors()
        const { data } = await this.$apollo
        .mutate({
          mutation: CreateItemGroupQa,
          // Parameters
          variables: {
            input: this.form
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        });
        // Result
        const errors = data.createItemGroupQa.errors;
        if (errors) {
          this.$_parseError(errors)
        } else {
          this.itemGroupQas = data.createItemGroupQa.itemGroupQas;
          this.fetchCurrentUser()
          this.form.question = ''
          this.$scrollTo('.first-comment', {offset: -100})
        }
        this.loadingStop();
      },

      // QA更新
      updateQuestion(itemGroupQas) {
        this.itemGroupQas = itemGroupQas
      },
      // コメント更新
      updateComment(itemGroupQas) {
        this.itemGroupQas = itemGroupQas
      },
      // QAいいね更新
      toggleQuestionLike(itemGroupQas) {
        this.itemGroupQas = itemGroupQas
      },
      // コメントいいね更新
      toggleCommentLike(itemGroupQas) {
        this.itemGroupQas = itemGroupQas
      },
      // コメント追加
      addComment(itemGroupQas) {
        this.itemGroupQas = itemGroupQas;
        this.fetchCurrentUser()
        this.$scrollTo('.first-comment', {offset: -100})
      },

      showAllComments() {
        this.isOpen = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-item-qa {
    background: $gray-200;
    margin-left: -#{map-get($padding-margin-sp, xs)}px;
    margin-right: -#{map-get($padding-margin-sp, xs)}px;
    @include mq-up() {
      margin-left: 0;
      margin-right: 0;
    }
    @include padding(md, xs);

    > .section-header {
      @include padding-bottom(md);
      text-align: center;

      > h2 {
        font-family: $font-family-serif;
        font-weight: $font-weight-bold;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-none;
        color: $black-400;
      }
    }

    > .section-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .comment-box {
        width: 100%;
        max-width: 570px;
        background: $white;
        border: 1px solid $gray-200;
        border-radius: 4px;

        > .box-header {
          @include padding(md);
          border-bottom: 1px solid $gray-200;
          text-align: center;

          > h3 {
            font-family: $font-family-serif;
            font-weight: $font-weight-regular;
            font-style: $font-style-not-italic;
            @include font-size(sm);
            line-height: $line-height-none;
            color: $black-400;
          }
        }

        > .box-body {
          @include padding(md);
          > .form-item:first-child {
            margin-top: 0;
          }
          > .button-text {
            text-align: center;
            > p {
              font-family: $font-family-sans;
              font-weight: $font-weight-regular;
              font-style: $font-style-not-italic;
              @include font-size(xxs);
              line-height: $line-height-normal;
              color: $black-400;
            }
          }
        }
      }

      .chat-comment {
        @include margin-top(md);
      }

      > .more-button {
        @include margin-top(md);
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $olive-300;
          > .label {
            color: $olive-300;
          }
        }

        > .label {
          @include margin-left(xxxs);
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-normal;
          color: $black-400;
        }
      }
    }
  }
</style>
