<template lang="pug">
  section.section-weekly-ranking(v-if="itemGroups.length > 0")
    //HeadingRoof.mb-md(title="今週の人気ランキング" subtitle="Weekly Ranking")
    HeadingRoof.mb-md(title="今月の人気ランキング" subtitle="Monthly Ranking")
    ItemCardList
      .item(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" :rank="index + 1" eventCategory="トップランキング" pageContents="今月の人気ランキング")
    .text-center
      ButtonBase(href="/shops/items/popular/" eventAction="商品一覧へ遷移" eventCategory="トップランキング") 月間人気ランキング
</template>

<script>
import {GetItemGroupsForApi, GetItemGroupsItems} from '../../../graphqls/ItemGroups'
  import HeadingRoof from '../heading/HeadingRoof'
  import ItemCard from '../item/ItemCardForApi'
  import ItemCardList from '../item/ItemCardList'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'SectionWeeklyRanking',
    components: {
      HeadingRoof,
      ItemCard,
      ItemCardList,
      ButtonBase
    },
    data() {
      return {
        itemGroups: []
      }
    },
    mounted() {
      for (let i = 0; i < 16; i++) {
        this.itemGroups.push({id: i})
      }
      this.getWeeklyRanking()
    },
    methods: {
      async getWeeklyRanking() {
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            nowOnSale: true,
            stockFilter: 'instock',
            perPage: 16,
            // orderBy: 'weekranking'
            orderBy: 'monthranking'
          },
          client: 'apiClient'
        })
        this.itemGroups = data.itemGroups.items
      }
    }
  }
</script>
