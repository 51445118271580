<template lang="pug">
  header.the-header
    AdminToolBar(v-if="currentUser.adminUser && currentUser.adminUser.id" :admin-user="currentUser.adminUser")
    SiteNavBarPc(:current-user-obj="currentUser")
    SiteNavBarSp(:current-user-obj="currentUser")
</template>

<script>
  import { GetCurrentUser } from '../../../graphqls/Users'
  import SiteNavBarPc from '../header/SiteNavBarPc'
  import SiteNavBarSp from '../header/SiteNavBarSp'
  import AdminToolBar from '../header/AdminToolBar'

  export default {
    name: 'TheHeader',
    components: {
      SiteNavBarPc,
      SiteNavBarSp,
      AdminToolBar
    },
    props: {

    },
    data() {
      return {
        currentUser: {
          user: null,
          adminUser: {
            id: '',
            adminShop: {
              code: '',
              unshippedAcceptOrderNum: 0
            }
          },
          cartItemsNum: 0
        }
      }
    },
    computed: {
      pathname() {
        return location.pathname
      },
      isOrder: function () {
        return this.pathname.startsWith('/shop/order/')
      },
      isSubscriptionOrder: function () {
        return this.pathname.startsWith('/shop/order/')
        return document.getElementById('subscription_order') != null
      },
      isCart: function () {
        return this.pathname.startsWith('/shop/cart/')
      },
      isSimple: function () {
        return this.isOrder || this.isCart || this.isSubscriptionOrder
      }
    },
    mounted: function () {
      this.fetchCurrentUser()
    },
    methods: {
      async fetchCurrentUser() {
        const { data } = await this.$apollo
        .query({
          query: GetCurrentUser,
          client: 'apiClient'
        })
        this.currentUser = data.currentUser
        this.$store.dispatch('setCurrentUser', { currentUser: data.currentUser })

        const globalElements = document.getElementsByClassName('global')
        if (this.currentUser.adminUser) {
          // v1切替前
          if (globalElements.length) {
            globalElements[0].classList.add('with-admin-toolbar')
          }
          // v1切替後
          const wrapperElements = document.getElementsByClassName('wrapper')
          if (wrapperElements.length) {
            wrapperElements[0].classList.add('with-admin-toolbar')
          }
        }
        if (this.isSimple) {
          globalElements[0].classList.add('is-simple')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .the-header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: $white;
    width: 100%;

    .site-navbar-pc {
      display: block;
    }
    .site-navbar-sp {
      display: none;
    }

    @include mq-down(md) {
      .site-navbar-pc {
        display: none;
      }
      .site-navbar-sp {
        display: block;
      }
    }
  }
</style>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/variables";
  @import "../../../../../assets/stylesheets/v1/mixins";
  body {
    > .global {
      &.with-admin-toolbar {
        padding-top: 183px;
        @include mq-down(md) {
          padding-top: 90px;
        }
      }
      &.is-simple {
        padding-top: 124px;
        &.with-admin-toolbar {
          padding-top: 160px;
          @include mq-down(md) {
            padding-top: 90px;
          }
        }
        @include mq-down(md) {
          padding-top: 50px;
        }
      }

    }
  }
</style>
