<template lang="pug">
  .container-sheet
    slot
</template>

<script>
  export default {
    name: 'ContainerSheet',
    props: {
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .container-sheet {
    width: 100%;
    padding: #{map-get($padding-margin-pc, 'xs')}px;
    background: $ivory-100;
  }
</style>
