<template lang="pug">
  section.section-item-review#item-review-section
    h2.heading-3.mb-xs {{ itemGroup.name }}のレビュー・口コミ
    .reviews(v-if="totalCount > 0")
      ReviewItem.mb-md(v-for="review in reviews" :key="review.id" :review="review")
      .text-center(v-if="totalCount > 3")
        ButtonBase(:href="`/shop/${itemGroup.adminShop.code}/item/${itemGroup.code}/reviews/`") レビュー・口コミ一覧({{totalCount}}件)
    p.paragraph(v-else) 投稿されたレビュー・口コミはまだありません。
</template>

<script>
  import { GetItemGroupReviews } from '../../../graphqls/Reviews'
  import HeadingArticle from '../typography/HeadingArticle'
  import ReviewItem from '../review/ReviewItem'
  import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'SectionItemReview',
    components: {
      HeadingArticle,
      ReviewItem,
      ButtonBase
    },
    props: {
      itemGroup: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {
        reviews: [],
        pageInfo: {}
      }
    },
    computed: {
      totalCount() {
        return this.pageInfo.totalCount
      }
    },
    mounted() {
      this.fetchItemGroupReviews()
    },
    methods: {
      async fetchItemGroupReviews() {
        const {data} = await this.$apollo
        .query({
          query: GetItemGroupReviews,
          variables: {
            search: JSON.stringify({item_group_id_eq: this.itemGroup.id}),
            perPage: 3,
            orderBY: 'posted_at desc',
          }
        })
        this.reviews = data.reviews.items
        this.pageInfo = data.reviews.pageInfo
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-item-review {

  }
</style>
