<template lang="pug">
  IconBase
    ReloadSvg
</template>
<script>
  import IconBase from './IconBase'
  import ReloadSvg from "../svg/ReloadSvg";
  export default {
    name: "reload-icon",
    components: {
      IconBase,
      ReloadSvg
    }
  }
</script>
