<template lang="pug">
  IconBase(:color="color" :size="size")
    include ../../../../../assets/images/icon/arrow-right.svg
</template>

<script>
  import IconBase from './IconBase'

  export default {
    name: 'IconArrowRight',
    components: {
      IconBase
    },
    props: {
      color: {
        type: String,
        required: false,
        default: ''
      },
      size: {
        type: Number,
        required: false,
        default: 16
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
