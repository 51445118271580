<template lang="pug">
  section.carousel-wrapper.mb2(v-if="topBanners.length > 0" :class="{ '-initialized': isInitialized }")
    Carousel(
      :center="true"
      :loop="true"
      :auto-width="true"
      :autoplay="true"
      :autoplayTimeout="8000"
      :autoplayHoverPause="true"
      :margin="5"
      :navText="[]"
      :responsive="responsive"
      @changed="isInitialized = true"
    )
      .image-wrapper(v-for="(topBanner, index) in topBanners")
        template(v-if="topBanner.url")
          a(:href="topBanner.url" @click="$_gaSendEvent(`バナー${index + 1}枚目から遷移`, 'トップバナー', topBanner.alt)")
            img(:src="topBanner.image" :alt="topBanner.alt")
            .title(v-if="topBanner.title") {{ topBanner.title }}
        template(v-else)
          img(:src="topBanner.image" :alt="topBanner.alt")
          .title(v-if="topBanner.title") {{ topBanner.title }}
</template>

<script>
  import { GetTopBanners } from '../../graphqls/TopBanners'
  import Carousel from 'vue-owl-carousel'
  import GlobalMixin from "../../mixin/GlobalMixin";

  export default {
    name: 'TopBannerSection',
    mixins: [GlobalMixin],
    components: {
      Carousel
    },
    data() {
      return {
        isInitialized: false,
        topBanners: [],
        responsive: {
          0: {
            dots: false,
            nav: false
          },
          768 : {
            dots: true,
            nav: true
          }
        }
      }
    },
    computed: {
    },
    mounted () {
      this.fetchTopBanners()
    },
    methods: {
      async fetchTopBanners() {
       const { data } = await this.$apollo
       .query({
         query: GetTopBanners,
         client: 'apiClient'
       })
       .catch(error => {
         return
       })
       this.topBanners = data.topBanners.items
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .carousel-wrapper {
    transition: all 0.5s;
    opacity: 0;

    &.-initialized {
      opacity: 1;
    }

    .owl-carousel {
      .owl-item {
        .image-wrapper {
          background: $gray-200;

          img {
            // width: 94vw;
            width: 86vw;
            max-width: 768px; 
            height: auto;
          }

          .title {
            position: absolute;
            right: .5rem;
            bottom: .5rem;
            @include padding(sm);
            background: $white;
            font-family: $font-family-serif;
            font-weight: $font-weight-regular;
            font-style: $font-style-not-italic;
            @include font-size(md);
            line-height: $line-height-none;
            color: $black-400;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  @import "../../../../assets/stylesheets/v1/variables";
  @import "../../../../assets/stylesheets/v1/styles";
  .carousel-wrapper{
    .owl-theme {
      .owl-dots {
        margin-top: 10px;
        .owl-dot {
          &.active, &:hover {
            span {
              background: $olive-300;
            }
          }
        }
        button {
          outline: none;
        }
      }
  
      .owl-nav {
        margin: 0;
  
        > [class*='owl-'] {
          position: absolute;
          top: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 50px;
          padding: 0;
          margin: 0;
          background: rgba(56, 56, 56, 0.4);
          transform: translateY(-50%);
          overflow: hidden;
  
          &:hover {
            background: rgba(56, 56, 56, 0.4);
          }
  
          &:before {
            content: "";
            display: block;
            width: 25px;
            height: 25px;
            border: solid 5px $white;
            transform: rotate(45deg);
          }
        }
  
        > .owl-prev {
          left: 5px;
  
          &:before {
            margin-right: -13px;
            border-top: none;
            border-right: none;
          }
        }
  
        > .owl-next {
          right: 5px;
  
          &:before {
            margin-left: -15px;
            border-bottom: none;
            border-left: none;
          }
        }
      }
    }
  }
</style>
