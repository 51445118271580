<template lang="pug">
  component.btn.btn-primary.pmd-ripple-effect(
    :is="component"
    @click='clickHandler'
    :to="to"
    :href="href"
    :class="[btnType, {'btn-block': isBlock, 'ga_send': sendGa}]"
    :data-eventcategory="eventCategory" :data-eventlabel="eventLabel"
    )
    span(v-if="label") {{ label }}
    span(v-else-if="$slots.default")
      slot
</template>

<script>
  import GlobalMixin from "../../mixin/GlobalMixin";

  export default {
    name: "button-main",
    mixins: [GlobalMixin],
    components: {
    },
    props: {
      type: String,
      label: String,
      eventCategory: String,
      eventLabel: String,
      eventAction: String,
      href: String,
      to: Object,
      isBlock: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      sendGa: {
        type: Boolean,
        default: true
      },
    },
    computed: {
      btnType() {
        return this.type ? `btn-${this.type}` : ''
      },
      component() {
        let component = 'button'
        if (this.to) component = 'router-link'
        else if (this.href) component = 'a'
        return component
      }
    },
    methods: {
      clickHandler(e) {
        if (!this.disabled) {
          this.$emit('click', e);
        }
        if (this.eventCategory && this.eventLabel && this.eventAction) {
           this.$_gaSendEvent(this.eventAction, this.eventCategory, this.eventLabel);
        }
      }
    }
  }
</script>
