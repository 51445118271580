import gql from 'graphql-tag'

import {Errors} from './Common'


export const CreateItemRestockNotice = gql`
mutation ($input: CreateItemRestockNoticeInput!) {
  createItemRestockNotice (input: $input) {
    itemRestockNotice {
      id
    }
    ${Errors}
  }
}`

