<template lang="pug">
  div.container-frame
    slot
</template>

<script>
  export default {
    name: 'ContainerFrame',
    props: {
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .container-frame {
    @include padding(xlg);
    background: $white;
    border: 8px solid $ivory-100;

    @include mq-down(md) {
      @include padding(zero);
      border: none;
    }

    > .form-wrapper {
      max-width: 700px;
      margin: 0 auto;
    }
  }
</style>
