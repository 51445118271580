<template lang="pug">
  textarea.form-textarea(v-model="localValue" :class="{'is-error': isError}" :placeholder="placeholder" :disabled="disabled" :rows="rows")
</template>

<script>
  export default {
    name: "form-textarea",
    props: {
      value: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: '入力してください'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isError: {
        type: Boolean,
        default: false
      },
      rows: {
        type: Number
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .form-textarea {
    display: block;
    min-width: 100%;
    max-width: 100%;
    min-height: 80px;
    outline: none;
    resize: vertical;
    background: $white;
    box-sizing: border-box;
    border: 1px solid $gray-dark;
    border-radius: 4px;
    color: $black-dark;
    line-height: 143%;
    @include padding(xxs);
    @include font-size(xs);

    &::placeholder {
      color: $black-lighter;
    }

    &:focus {
      border-color: $black-lighter;
    }

    &.is-error {
      background: $red-lighter;
      border-color: $red-base;
    }

    &:disabled {
      background: $gray-light;
      border-color: $gray-light;
      color: $black-lighter;
      cursor: not-allowed;
    }
  }
</style>
