import gql from 'graphql-tag'
import {ItemGroupItemsForApi} from "./ItemGroups";

export const GetGiftPage = gql`
query($code: String!) {
  giftPage(code: $code) {
    id
    name
    code
    metaTitle
    metaDescription
    mainImage
    mainImageSet {
      mdImage
      ogpImage
    }
    giftPageBodies {
      id
      bodyType
      title
      subTitle
      description
      noticeMessage
      giftPageBodyItemGroups {
        id
        itemGroup {
          id
          name
          code
          adminShop {
            code
            name
          }
          listImageOrItemGroupImageSet {
            smSqImage
            mdSqImage
          }
          properPrice
          properPriceInTax
          maxProperPriceInTax
          isRangeProperPrice
          salePrice
          salePriceInTax
          maxSalePriceInTax
          isRangeSalePrice
          preRelease
          soldOut
          maxOffRate
          saleName
          offSaleEndAt
          displaySize
          publishedNotice
          notice
          reviewNum
          reviewsRatingStarsUrl
          isSale
          icons
          detailPageUrl
          detailPagePath
          ogpImageUrl
          displayShippingWithinYearLastAt
        }
      }
    }
  }
}`

export const GetGiftPageForApi = gql`
query($code: String!) {
  giftPage(code: $code) {
    id
    name
    code
    metaTitle
    metaDescription
    mainImage
    mainImageSet {
      mdImage
      ogpImage
    }
    designType
    publishedGiftPageBodies {
      id
      bodyType
      title
      subTitle
      description
      noticeMessage
      rankingStatus 
      giftPageBodyItemGroups {
        id
        itemGroup {
          ${ItemGroupItemsForApi}
        }
      }
      itemTagId
      itemTag {
        id
        code
        name 
        publishedStatus
      }
      itemTagDisplayConditionStatus
      itemTagDisplayMaxCount
    }
  }
}`
