<template lang="pug">
  .description
    .body
      .photo
        //- ショッププロフィール画像を表示
        img(:src="profileImage" :alt="`${adminShop.name}プロフィール画像`")
      .text
        h1
          | {{adminShop.name}}
          span {{adminShop.prefectureName}}({{adminShop.businessContents}})
        .disc
          //- ショップ説明を表示
          .readless(v-if="!isReadMore")
            | {{description.slice(0, 100)}}
            span ...
            .readmore
              a(@click="activateReadMore") …つづきを読む
              include ../../../../../assets/images/icon/angle-down-solid.svg
          span(v-if="isReadMore" v-html="description")
</template>

<script>
export default {
  name: 'ShopInfo',
  props: {
    adminShop: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: '',
          prefectureName: '',
          businessContents: '',
          description: '',
          profileImageSet: {
            mdSqImage: ''
          }
        }
      }
    },
  },
  data() {
    return {
      isReadMore: false,
    }
  },
  computed: {
    profileImage() {
      return this.adminShop?.profileImageSet?.mdSqImage || ''
    },
    description() {
      return this.adminShop?.description || ''
    }
  },
  mounted() {
  },
  methods: {
    activateReadMore() {
      this.isReadMore = true;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";


.description {
  position: relative;
  z-index: 1; 
  > .body {
    display: flex;
    gap: 16px;

    > .photo {
      > img {
        width: 100%;
      }
    }

    > .text {
      > .disc {
        white-space:pre-wrap;
        word-wrap:break-word;
        .readless {
          > .readmore {
            display: flex;
            //align-items: right;
            justify-content: right;
            cursor: pointer;
            text-decoration: underline;

            > svg {
              margin-left: 4px;
              width: 12px;
              height: auto;

              > path {
                color: $black-200;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0.5rem auto 1rem;
    > .body {
      > .photo {
        width: 80px;
        margin-top: -1.5rem;
        padding: 0.2rem;
      }

      > .text {
        width: calc(100% - 80px);

        > h1 {
          font-size: 16px;
          margin-bottom: 0.5rem;

          > span {
            font-size: 14px;
            padding-left: 0.3rem;
          }
        }

        > .disc {
          font-size: 12px;
          line-height: 1.8;
        }
      }
    }
  }
  @media (min-width: 768px) {
    padding: 1.5rem;
    border: 1px solid #dbd9cf;
    margin: 32px 0;
    > .body {
      > .photo {
        width: 150px;
        height: 150px;
      }

      > .text {
        width: calc(100% - 150px);

        > h1 {
          font-size: 20px;
          margin-bottom: 8px;

          > span {
            font-size: 16px;
            padding-left: 0.5rem;
          }
        }

        > .disc {
          font-size: 14px;
          line-height: 1.3;
        }
      }
    }
  }
}
</style>