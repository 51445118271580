export const PageInfo = `
pageInfo {
  currentPage
  totalCount
  perPage
}
`

export const Errors = `
errors {
  statusCode
  redirectPath
  model
  attribute
  message
  fullMessage
}
`
