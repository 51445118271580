<template lang="pug">
  section.section-item-sku(:class="{skeleton: !selectedItem}")
    template(v-if="selectedItem")
      .image
        img(:alt="selectedItem.skuName" :src="listImageOrItemGroupListImageUrl")
      .info
        .shop
          .logo
            img(:alt="`${adminShop.name}ロゴ`" :src="adminShop.logoImageSet.xsSqImage")
          a(:href="`/shop/${adminShop.code}/`") {{adminShop.name}}
        h1 {{itemGroup.name}}
        .review(v-if="reviewNum > 0")
          .inline(v-scroll-to="{ el: '#item-review-section', offset: -60 }" @click="sendEventGoToReviewList()")
            img.star(:src="reviewsRatingStarsUrl")
            span.count {{reviewNum}}件のレビュー
            ArrowSvg.icon
        .postage
          select(v-model="selectedPrefectureId" @change="changePrefecture")
            option(v-for="p in postage" :key="`prefecture_${p.prefectureId}`" :value="p.prefectureId") {{p.prefectureName}}
          |への送料を表示中
        //.image
          img(:alt="selectedItem.skuName" :src="selectedItem.listImageOrItemGroupListImageUrl")
        ul.list
          ItemSkuList(v-for="item in items" :key="item.id",
            :item="item"
            :itemGroup="itemGroup"
            :itemId="item.id"
            :selectedItemId="selectedItem.id"
            :selectedPrefectureId="selectedPrefectureId"
            :position="position"
            @change="selectItem")
        .tags
          .tag(v-if="itemGroup.isNew") NEW
          .tag(v-if="isFreeShipping") 送料無料
          .tag(v-if="itemGroup.isPreSale") 予約商品
          .tag(v-if="itemGroup.isNormal") 常温
          .tag(v-if="itemGroup.isCold") 冷蔵
          .tag(v-if="itemGroup.isFreeze") 冷凍
          .tag(v-if="itemGroup.isSecondGradeItem") 訳あり
          .tag(v-if="isGiftPossible") ギフト
          .tag(v-if="isSale") {{saleName}}
          .tag(v-if="selectedItem.isSubscriptionItem") 定期購入可
          .tag(v-if="selectedItem.isOnetimeItem") 初回限定
          .tag(v-if="fewInStock") 残り{{selectedItem.salableStock}}個
          .tag(v-if="hasFreeShippingRegions") 送料無料あり
          .tag(v-if="selectedItem.itemDeliveryMethod.isPostDelivery") メール便
          .tag(v-if="selectedItem.itemDeliveryMethod.isCompactDelivery") コンパクト便対応

        h1 {{itemGroup.name}}
        .item
          .name {{selectedItem.size}}
          template(v-if="selectedItem.isSale")
            .original-price
              |{{selectedItem.properPriceInTax | numberWithDelimiter}}
              small(v-if="itemGroup.adminShop.isAddTax") 円(税込)
              small(v-else) 円
            .sale-name {{selectedItem.ribbonSaleName}}
          .prices
            .price(:class="{'sale-price' : selectedItem.isSale}")
              |{{selectedItem.sellingPriceInTax | numberWithDelimiter}}
              small(v-if="itemGroup.adminShop.isAddTax") 円(税込)
              small(v-else) 円
            .point
              |ポイント :
              small  {{selectedItem.grantPoint}} pt ({{selectedItem.pointRate}}%)
          .shipping
            .prefecture {{selectedPrefectureName}}への
            .price
              .free-shipping(v-if="isFreeShippingToItem(selectedItem)")
                | 送料無料
              template(v-else)
                .group-shipping(v-if="selectedItem.itemDeliveryMethod.isCompactDelivery || selectedItem.itemDeliveryMethod.isPostDelivery")
                  | 送料：{{ Math.floor(100 / selectedItem.capacityRatio) }}点まで
                  span.shipping-price {{ defaultShippingPrice(selectedItem) | numberWithDelimiter }}
                  | 円（{{ Math.floor(100 / selectedItem.capacityRatio) + 1 }}点以上は通常宅配便：
                  span.shipping-price {{ itemPrefectureShippingPriceInTax(selectedItem) | numberWithDelimiter }}
                  | 円税込）
                .default-shipping(v-else)
                  | 送料：
                  span.shipping-price {{ defaultShippingPrice(selectedItem) | numberWithDelimiter }}
                  | 円（税込）
                .cool(v-if="isShowCoolPrice")
                  | （クール料金込み）
        .buy
          .delivery
            p(v-if="selectedItem.isSubscriptionItem") 配送頻度
            select(v-if="selectedItem.isSubscriptionItem" v-model="subscriptionItemInterval" @change="changeSubscriptionItemInterval")
              option(v-for="(interval, i) in subscriptionItemIntervalOptions" :key="i" :value="interval.intervalDays") {{interval.intervalName}}
            AlertBase.my-xs.word-pre(v-if="selectedItem.orderMessage  || itemGroup.publishedNotice" type="info" :outlined="true")
              p(v-if="itemGroup.publishedNotice") {{itemGroup.notice}}
              .order-message(v-if="selectedItem.orderMessage")
                p.bold ご注文時の注意事項
                p {{selectedItem.orderMessage}}
            AlertBase.my-xs.word-pre(v-if="selectedItem.isSubscriptionItem" type="warning")
              | この商品は{{selectedItem.subscriptionMinDeliveryCount}}回以上お届けする定期購入商品です。事前に商品の事をよくご確認ください。
              | {{selectedItem.subscriptionMinDeliveryCount}}回以上商品を受け取った後は、いつでも定期購入を解除することができます。
              | またお申し込み後でも、配送頻度やお届け予定日はご都合に合わせて変更することが可能です。
              | ※季節や在庫状況により定期購入を停止させていただく場合がございます。ご了承ください。
          .gift(v-if="isGiftPossible && !selectedItem.soldOut && selectedItem.isNowOnSale && !selectedItem.isSubscriptionItem")
            ul
              li(v-if="itemGroup.isWrappingPossible")
                WrappingIcon
                .box
                  .name ラッピング
                  .content {{giftWrappingText}}
              li(v-if="itemGroup.isNoshiPossible")
                NoshiIcon
                .box
                  .name のし
                  .content {{giftNoshiText}}
              li(v-if="itemGroup.isMessageCardPossible")
                PenIcon
                .box
                  .name メッセージ
                  .content {{giftMessageText}}
            button(@click="openGiftModal()") ギフト設定する

          .pre-sale(v-if="itemGroup.isPreSale")
            AlertBase.my-xs( type="primary" :outlined="true")
              .alert(v-if="itemGroup.afterPreSale") 予約受付は終了しました。
              .alert(v-if="itemGroup.beforePreSale") 予約受付開始前です。
              .box
                .name 予約期間
                .content {{itemGroup.formatPreSaleStartAt}} 〜 {{itemGroup.formatPreSaleEndAt}}
              .box
                .name 発送予定日
                .content {{itemGroup.formatScheduledShippingStartAt}}頃から順次発送
            .messages
              p ※予約期間、内容は予定なく変更になる場合があります。
              p ※準備数に達した場合、早期に受付終了となる場合があります。

          AlertBase.my-xs(v-if="itemGroup.isAlcohol" type="warning")
            | 未成年者の酒類のご購入はお断りします。

          .buttons
            ButtonCount(v-if="(!selectedItem.soldOut && selectedItem.isNowOnSale && !itemGroup.isPreSale) || (!selectedItem.soldOut && itemGroup.nowPreSale)" v-model="quantity" @input="changeQuantity" :min="1" :max="maxAddCartNum" )
            div
              ButtonBase(v-if="!selectedItem.isNowOnSale || !itemGroup.isNowOnSale || itemGroup.beforePreSale || itemGroup.afterPreSale" @click="addRestockNotice()" :block="true")
                MailIcon.mail
                |再入荷通知
                template(v-if="!currentUser") (ログイン)
              ButtonBase(v-else-if="selectedItem.soldOut" @click="addRestockNotice()" :block="true")
                MailIcon.mail
                |再入荷通知
                template(v-if="!currentUser") (ログイン)
              ButtonBase(v-else-if="selectedItem.isSubscriptionItem" @click="addSubscriptionCart()" :block="true")
                ReloadIcon.subscription
                |定期購入の申込
                template(v-if="!currentUser") (ログイン)
              ButtonBase(v-else @click="itemGroup.isAlcohol ? openAlcoholModal() : addCart()" :block="true")
                template(v-if="itemGroup.nowPreSale") 予約する
                template(v-else)
                  CartLineIcon.cart
                  |カートへ入れる
          p.maxadd(v-if="!selectedItem.soldOut && selectedItem.isNowOnSale && selectedItem.maxAddCartNum > 0" ) ※1回のご注文あたり最大{{selectedItem.maxAddCartNum}}点まで購入可能
        .delivery
          h3 配送について
          p.free-shipping-region(v-if="displayItemFreeShippingRegions") 現在選択中の商品は、{{displayItemFreeShippingRegions}}で送料無料
          .extra(v-if="selectedItem.itemDeliveryMethod.isCompactDelivery")
            p 宅急便コンパクト対応可
            ProgressBar(:progress="totalCapacityRatio" overFlowComment="100%を超えた為、宅配便にて配送")
            p
              | {{`この商品1点で宅急便コンパクト対応可能量の${selectedItem.capacityRatio}%を使用します。宅急便コンパクト対応商品の合計が100%を超えた場合は`}}
              | {{itemPrefectureShippingPriceInTax(selectedItem) === '0' ?  '宅配便にて配送します。' : '通常宅配便送料となります。' }}
            p.link
              a(:href="`/shop/${itemGroup.adminShop.code}/items/compact/compact`") 宅急便コンパクト対応の同梱商品はこちら
          .extra(v-if="selectedItem.itemDeliveryMethod.isPostDelivery")
            p メール便対応可
            ProgressBar(:progress="totalCapacityRatio" overFlowComment="100%を超えた為、宅配便にて配送")
            p
              | {{`この商品1点でメール便対応可能量の${selectedItem.capacityRatio}%を使用します。合計が100%を超えた場合は`}}
              | {{itemPrefectureShippingPriceInTax(selectedItem) === '0' ?  '宅配便にて配送します。' : '通常宅配便送料となります。' }}
            p.link
              a(:href="`/shop/${itemGroup.adminShop.code}/items/compact/nekopos`") メール便対応の同梱商品はこちら
          p.link
            a(:href="`/shop/${itemGroup.adminShop.code}/items/storage/${itemGroup.storageType}`" @click="bundleItems") 同梱対応商品はこちら
          p.link
            a(@click="openDeliveryModal()") 各地域の送料を見る
          AlertBase.my-xs.word-pre(v-if="adminShop.delivery.fixedMemo || adminShop.hasDeliveryTimerMemo || adminShop.hasWithinYearMemo" type="warning")
            template(v-if="adminShop.delivery.fixedMemo")
              div {{adminShop.delivery.fixedMemo}}
            template(v-if="adminShop.hasDeliveryTimerMemo")
              div {{adminShop.delivery.timerMemo}}
            template(v-if="adminShop.hasWithinYearMemo")
              div 年内お届けのご注文は{{adminShop.delivery.shippingWithinYearLastAt | moment('YYYY/MM/DD')}}までとなります。
          p
            | この商品は{{adminShop.name}}が{{adminShop.prefectureName}}から発送します。
            template(v-if="adminShop.delivery.minDeliveryTerm && adminShop.delivery.maxDeliveryTerm && adminShop.delivery.avgShippingTerm < 8")
              br
              |発送までの平均日数 :
              strong {{adminShop.delivery.minDeliveryTerm}}~{{adminShop.delivery.maxDeliveryTerm}}
              |(過去注文より自動表示)
          p(v-if="selectedItem.displayItemDeliveryDayOfWeeks")
            | この商品は{{selectedItem.displayItemDeliveryDayOfWeeks}}に出荷するため、お届け日指定はできません。
            br
            | 次回 {{selectedItem.displayShortestDeliveryOn}}出荷分は、{{selectedItem.displayNextLimitOrderOn}}までにご注文ください。
          p.free-shipping-price(v-if="adminShop.delivery.freeShippingStatus === 'free_shipping_on'")
            | {{adminShop.name}}の商品は、{{adminShop.delivery.freeShippingPrice | numberToCurrency}}以上で送料無料。
            small
              | (各配送温度帯で{{adminShop.delivery.freeShippingPrice | numberToCurrency}}以上。
              template(v-if="adminShop.addFeePrefectureList.length > 0") {{adminShop.addFeePrefectureList.join(' ')}}は除く
              | )
        .contact
          ButtonBase(v-if="itemGroup.canWritingReview" variant="normal" size="inline" href="/mypage/purchasehistory/" @click="sendEventGoToReviewWriting()")
            IconPencil
            | レビューを書く
          ButtonBase(v-if="adminShop.qaSupportStatus === 'qa_support_on'" variant="normal" size="inline" v-scroll-to="{ el: '#item-qa-section', offset: -60 }" @click="sendEventGoToQuesAnswer()")
            IconQA
            | 商品に関するQ&A
          ButtonBase.button(v-if="adminShop.qaSupportStatus === 'qa_support_off'" variant="normal" size="inline" :href="`/shop/${adminShop.code}/contact/`" @click="sendEventGoToContact()")
            MailIcon
            | お問い合わせ

        DeliveryModal(ref="deliveryModal" :postage="postage"
          :compact-percent="selectedItem.capacityRatio"
          :mail-percent="selectedItem.capacityRatio"
          :is-post-delivery="selectedItem.itemDeliveryMethod.isPostDelivery"
          :is-compact-delivery="selectedItem.itemDeliveryMethod.isCompactDelivery")
        GiftModal(ref="giftModal" v-model="giftForm" :itemGroup="itemGroup" :item="selectedItem" @input="changeGift")
        ModalAddCart(ref="addCartCompleteModal" :cartItem="cartItem")
        AlcoholModal(ref="alcoholModal" @agree="addCart")
    template(v-else)
      .image
        img(src="https://cdn.taberutokurasuto.com/image/common/noimage_300x300.gif")
      .info
        .shop
          .logo
            img(src="https://cdn.taberutokurasuto.com/image/common/noimage_100x100.gif")
        .item-group-name
        ul.list
          ItemSkuList(item-id="")
          ItemSkuList(item-id="")
</template>

<script>
  import { CreateCartItem } from "../../../graphqls/CartItems";
  import { CreateItemRestockNotice } from "../../../graphqls/ItemRestockNotices";
  import { InitSubscriptionOrder } from "../../../graphqls/SubscriptionOrder";

  import GlobalMixin from '../../../mixin/GlobalMixin'
  import GiftMixin from '../../../mixin/GiftMixin'

  import ArrowSvg from '../../svg/ArrowSvg'
  import WrappingIcon from '../../icon/WrappingIcon'
  import NoshiIcon from '../../icon/NoshiIcon'
  import PenIcon from '../../icon/PenIcon'
  import MailIcon from '../../icon/MailIcon'
  import ReloadIcon from '../../icon/ReloadIcon'
  import CartLineIcon from '../../icon/CartLineIcon'
  import IconPencil from '../icon/IconPencil'
  import IconQA from '../icon/IconQA'

  import ItemSkuList from '../item/ItemSkuList'
  import ProgressBar from '../../progress/ProgressBar'
  import ButtonCount from '../../button/ButtonCount'
  import AlertBase from '../alert/AlertBase'
  import ButtonBase from '../button/ButtonBase'
  import DeliveryModal from '../../modal/DeliveryModal'
  import GiftModal from '../../modal/GiftModalForApi'
  import AddCartCompleteModal from "../../modal/AddCartCompleteModal"
  import AlcoholModal from "../../modal/AlcoholModal";
  import ModalAddCart from "../modal/ModalAddCart";

  export default {
    name: 'SectionItemSKU',
    mixins: [GlobalMixin, GiftMixin],
    components: {
      ModalAddCart,
      ItemSkuList,
      AlcoholModal,
      AddCartCompleteModal,
      ButtonBase,
      ButtonCount,
      WrappingIcon,
      NoshiIcon,
      PenIcon,
      MailIcon,
      ReloadIcon,
      CartLineIcon,
      AlertBase,
      ProgressBar,
      DeliveryModal,
      GiftModal,
      ArrowSvg,
      IconPencil,
      IconQA,
    },
    props: {
      position: {
        type: String,
        default: ''
      },
      selectedPrefectureId: {
        type: String,
        default: '13'
      },
      quantity: {
        type: Number,
        default: 1
      },
      subscriptionItemInterval: {
        type: String,
        default: ''
      },
      itemGroup: {
        type: Object,
        default() {
          return {
            adminShop: {
              name: '',
              logoImageSet: {
                xsSqImage: "https://cdn.taberutokurasuto.com/image/common/noimage_100x100.gif"
              },
              prefectureName: '',
              minDeliveryTerm: '',
              maxDeliveryTerm: '',
              hasDeliveryTimerMemo: false,
              hasWithinYearMemo: false,
              isAddTax: false,
              delivery: {
                fixedMemo: '',
                timerMemo: '',
              },
              freeShippingPrice: '',
              isDeliveryFreeShippingOn: false,
              addFeePrefectureList: []
            },
            name: '',
            storageType: '',
            showGiftArea: false,
            noshiPossible: false,
            wrappingPossible: false,
            messageCardPossible: false,
            isAlcohol: false,
            isNowOnSale: false,
            firstSelectItem: null,
            items: []
          }
        }
      },
      selectedSkuItem: {
        type: Object,
        default() {
          return {
            id: '',
            size: '',
            skuName: '',
            properPriceInTax: '0',
            salePriceInTax: '0',
            sellingPriceInTax: '0',
            listImageOrItemGroupListImageUrl: '',
            innerCapacity: '',
            isSale: false,
            displaySaleName: '',
            grantPoint: 0,
            pointRate: 0,
            maxAddCartNum: 0,
            soldOut: false,
            isNowOnSale: false,
            isSubscriptionItem: false,
            subscriptionMinDeliveryCount: '',
            itemDeliveryMethod: {
              isPostDelivery: false,
              isCompactDelivery: false
            },
            capacityRatio: 100,
            orderMessage: '',
            subscriptionItemIntervals: [],
            displayItemDeliveryDayOfWeeks: '',
            displayShortestDeliveryOn: '',
            displayNextLimitOrderOn: '',
            icons: [],
            shippingRegionPriceList: [],
            displayItemFreeShippingRegions: ''
          }
        }
      },
      giftForm: {
        type: Object,
        default() {
          return {
            noshiId: '',
            noshiNameMiddle: '',
            noshiNameRight: '',
            noshiNameLeft: '',
            noshiLastName: '',
            noshiMemo: '',
            noshiNamePrintType: 'nothing',
            wrappingId: '',
            messageCardId: '',
            messageCardMessage: '',
          }
        }
      }
    },
    data() {
      return {
        cartItem: {
          item: {
            skuName: ''
          },
          itemTotalInTax: "",
          quantity: ""
        },
      }
    },
    computed: {
      adminShop() {
        return this.itemGroup.adminShop
      },
      listImageOrItemGroupListImageUrl() {
        if (this.selectedItem?.itemImageUrl) {
          return this.selectedItem?.itemImageSets?.mdSqImage
        }
        return this.itemGroup?.listImageOrItemGroupImageSet?.mdSqImage
      },
      reviews() {
        return this.itemGroup?.publishedReviews || []
      },
      reviewNum() {
        return this.reviews.length
      },
      items() {
        return this.itemGroup.openItems
      },
      reviewsRatingStarsUrl() {
        if (this.reviewNum === 0) return null;
        let sumReview = 0
        this.reviews.forEach(review => sumReview = sumReview + review.stars)
        let starCount = Math.round(sumReview / this.reviewNum)
        return `https://cdn.taberutokurasuto.com/image/common/star/star${starCount}.png`
      },
      isSale() {
        return this.selectedItem.isSale
      },
      saleName() {
        return this.selectedItem.saleName ? this.selectedItem.saleName : 'SALE'
      },

      isGiftPossible() {
        return this.itemGroup.isNoshiPossible ||
            this.itemGroup.isWrappingPossible ||
            this.itemGroup.isMessageCardPossible
      },
      delivery() {
        return this.itemGroup?.adminShop?.delivery
      },
      isFreeShipping() {
        if (this.itemGroup?.isForceFreeShipping) return true

        if (this.delivery?.freeShippingStatus === 'free_shipping_on') {
          if (this.selectedItem?.sellingPrice > this.devlivery?.freeShippingPrice) return true
        }
        return false
      },
      hasPostDeliveryItem() {
        return this.items.some(item => item.itemDeliveryMethod?.isPostDelivery)
      },
      hasCompactDelivery() {
        return this.items.some(item => item.itemDeliveryMethod?.isCompactDelivery)
      },
      hasFreeShippingRegions() {
        return this.selectedItem.itemFreeShippingRegions.length > 0
      },
      totalSalableStock() {
        let result = 0
        this.items.forEach(item => result = result + item.salableStock)
        return result
      },
      fewInStock() {
        return 0 < this.selectedItem.salableStock && this.selectedItem.salableStock < 10
      },
      displayItemFreeShippingRegions() {
        let result = [];
        for (let itemFreeShippingRegion of this.selectedItem.itemFreeShippingRegions) {
          result.push(itemFreeShippingRegion.shippingRegion.name)
        }
        result.join('・')
      },


      postage() {
        return this.selectedItem.shippingRegionPriceList
      },
      subscriptionItemIntervalOptions() {
        return this.selectedItem.subscriptionItemIntervals
      },
      selectedItem() {
        return this.selectedSkuItem.id ? this.selectedSkuItem : null
      },
      totalCapacityRatio() {
        return this.selectedItem.capacityRatio * this.quantity
      },
      maxAddCartNum() {
        return this.selectedItem.maxAddCartNum > 0 ? this.selectedItem.maxAddCartNum : null;
      },
      giftWrappingText() {
        let text = 'なし'

        if (this.giftForm.wrappingId) {
          text = this.wrappingOptions.find(o => o.id === this.giftForm.wrappingId).text
        }

        return text
      },
      giftNoshiText() {
        let text = 'なし'

        if (this.giftForm.noshiId) {
          text = `${this.noshiOptions.find(o => o.id === this.giftForm.noshiId).text} / ${this.namePrintTypeOptions.find(o => o.id === this.giftForm.noshiNamePrintType).text}`

          if (this.giftForm.noshiNamePrintType === 'name_1') {
            text += `: ${this.giftForm.noshiNameMiddle}`
          } else if (this.giftForm.noshiNamePrintType === 'name_2') {
            text += `: 名(左) ${this.giftForm.noshiNameLeft} 名(右) ${this.giftForm.noshiNameRight}`
          } else if (this.giftForm.noshiNamePrintType === 'family_join2') {
            text += `: 姓 ${this.giftForm.noshiLastName} 名(左) ${this.giftForm.noshiNameLeft} 名(右) ${this.giftForm.noshiNameRight}`
          }
          if (this.giftForm.noshiMemo) {
            text += ` / ${this.selectedNoshi.isMemoRequired ? '表書き・' : ''}ご要望 : ${this.giftForm.noshiMemo}`
          }
        }

        return text
      },
      selectedNoshi() {
        return this.itemGroup.publishedNoshis.find(i => i.id === this.giftForm.noshiId)
      },
      giftMessageText() {
        let text = 'なし'

        if (this.giftForm.messageCardId) {
          text = `${this.messageCardOptions.find(o => o.id === this.giftForm.messageCardId).text} / ${this.giftForm.messageCardMessage}`
        }

        return text
      },
      wrappingOptions() {
        let result = []
        for (let wrapping of this.itemGroup.publishedWrappings) {
          result.push({id: wrapping.id, text: `${wrapping.name} (+${Number(wrapping.priceInTax).toLocaleString()}円)`})
        }
        return result
      },
      noshiOptions() {
        let result = []
        for (let noshi of this.itemGroup.publishedNoshis) {
          result.push({id: noshi.id, text: noshi.name})
        }
        return result
      },
      messageCardOptions() {
        let result = []
        for (let messageCard of this.itemGroup.publishedMessageCards) {
          result.push({id: messageCard.id, text: `${messageCard.name} (+${Number(messageCard.priceInTax).toLocaleString()}円)`})
        }
        return result
      },
      namePrintTypeOptions() {
        return [
          {id: 'nothing', text: '名前なし'},
          {id: 'name_1', text: '名入れあり(1名)'},
          {id: 'name_2', text: '名入れあり(2名)'},
          {id: 'family_join2', text: '名入れあり(連名)'},
        ]
      },
      currentUser() {
        return this.$store.getters.currentUser.user
      },
      selectedPrefectureName() {
        if (this.postage.length === 0) return ''
        let prefecture = this.postage.find(i => i.prefectureId ===  this.selectedPrefectureId)
        return prefecture.prefectureName
      },
      isShowCoolPrice() {
        return this.itemGroup.isCold || this.itemGroup.isFreeze
      }

    },
    mounted() {
    },
    methods: {
      selectItem(item) {
        this.$emit('changeSelectedSkuItem', item)
        this.$_gaSendEvent('SKU選択', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name} ${this.selectedItem.skuName}`)
      },
      openDeliveryModal() {
        this.$_gaSendEvent('地域別送料モーダル表示', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`)
        this.$refs.deliveryModal.open()
      },
      openGiftModal() {
        this.$refs.giftModal.open()
      },
      openAddCartCompleteModal() {
        this.$refs.addCartCompleteModal.open()
      },
      openAlcoholModal() {
        this.$refs.alcoholModal.open()
      },
      itemPrefectureShippingPriceInTax(item) {
        return this.selectedItemShippingRegionPrices(item).priceInTax
      },
      itemPrefectureCompactPriceInTax(item) {
        return this.selectedItemShippingRegionPrices(item).compactPriceInTax
      },
      itemPrefecturePostDeliveryPriceInTax(item) {
        return this.selectedItemShippingRegionPrices(item).postDeliveryPriceInTax
      },
      selectedItemShippingRegionPrices(item) {
        return item.shippingRegionPriceList.find(i => i.prefectureId === this.selectedPrefectureId)
      },
      isFreeShippingToItem(item) {
        return this.defaultShippingPrice(item) === '0'
      },
      defaultShippingPrice(item) {
        if(item.itemDeliveryMethod.isPostDelivery) {
          return this.itemPrefecturePostDeliveryPriceInTax(item)
        } else if (item.itemDeliveryMethod.isCompactDelivery) {
          return this.itemPrefectureCompactPriceInTax(item)
        } else {
          return this.itemPrefectureShippingPriceInTax(item)
        }
      },
      showCapacityOverPrice(item) {
        return this.defaultShippingPrice(item) > 0 && this.itemPrefectureShippingPriceInTax(item) > 0 && (item.itemDeliveryMethod.isPostDelivery || item.itemDeliveryMethod.isCompactDelivery)
      },
      changePrefecture(e) {
        let prefecture = this.postage.find(i => i.prefectureId ===  e.target.value)
        this.$_gaSendEvent('都道府県選択', `商品詳細SKU(${this.position})`, prefecture.prefectureName)
        this.$emit('changePrefecture', e.target.value)
      },
      changeSubscriptionItemInterval(e) {
        this.$_gaSendEvent('配送頻度選択', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`, e.target.value)
        this.$emit('changeSubscriptionItemInterval', e.target.value)
      },
      changeQuantity(quantity) {
        this.$_gaSendEvent('カートの数量変更', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`, quantity)
        this.$emit('changeQuantity', quantity)
      },
      changeGift(form) {
        this.$_gaSendEvent('ギフト設定変更', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`)
        this.$emit('changeGift', form)
      },
      bundleItems() {
        this.$_gaSendEvent('同梱対象商品一覧へ遷移', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`)
      },
      sendEventGoToReviewList() {
        this.$_gaSendEvent('レビューエリアページ内遷移', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`)
      },
      sendEventGoToQuesAnswer() {
        this.$_gaSendEvent('QAエリアページ内遷移', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`)
      },
      sendEventGoToContact() {
        this.$_gaSendEvent('お問い合わせへ遷移', `商品詳細SKU(${this.position})`, `${this.adminShop.name}のお問い合わせ`)
      },
      sendEventGoToReviewWriting() {
        this.$_gaSendEvent('注文履歴へ遷移', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`)
      },
      async addCart() {
        if (this.itemGroup.isAlcohol)
          this.loadingStart();
        const { data } = await this.$apollo
        .mutate({
          mutation: CreateCartItem,
          // Parameters
          variables: {
            input: {
              itemId: this.selectedItem.id,
              quantity: Number(this.quantity),
              ...this.giftForm
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        });
        const errors = data.createCartItem.errors
        if (errors.length > 0) {
          const messageList = errors.map(e => e.fullMessage)
          this.setToast({ toast: { type: 'error', messages: messageList }})
        } else {
          this.$_gaSendEvent('カートへ追加', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`);
          this.cartItem = data.createCartItem.cartItem
          this.$gtag.event('add_to_cart', {
            currency: 'JPY',
            value: this.cartItem.itemTotalInTax,
            items: [{
              item_id: this.itemGroup.id,
              item_name: `${this.adminShop.name} ${this.itemGroup.name}`,
              affiliation: this.adminShop.code,
              item_brand: this.adminShop.name,
              item_variant: this.cartItem.item.skuName,
              quantity: this.cartItem.quantity,
            }]
          })
          this.openAddCartCompleteModal()
        }
        this.loadingStop();
      },
      async addSubscriptionCart() {
        if (this.itemGroup.isAlcohol)
          this.loadingStart();
        const { data } = await this.$apollo
        .mutate({
          mutation: InitSubscriptionOrder,
          // Parameters
          variables: {
            input: {
              itemId: this.selectedItem.id,
              quantity: this.quantity,
              interval: this.subscriptionItemInterval
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        });
        const errors = data.initSubscriptionOrder.errors
        if (errors.length > 0) {
          if (errors[0].redirectPath) {
            location.href = errors[0].redirectPath
            return
          }
          const messageList = errors.map(e => e.fullMessage)
          this.setToast({ toast: { type: 'error', messages: messageList }})
        } else {
          this.$_gaSendEvent('定期購入の申込', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`);
          let subscriptionOrder = data.initSubscriptionOrder.subscriptionOrder
          location.href = subscriptionOrder.cartPath
          return
        }
        this.loadingStop();
      },
      async addRestockNotice() {
        this.loadingStart();
        const { data } = await this.$apollo
        .mutate({
          mutation: CreateItemRestockNotice,
          // Parameters
          variables: {
            input: {
              itemId: this.selectedItem.id
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        });
        const errors = data.createItemRestockNotice.errors
        if (errors.length > 0) {
          if (errors[0].redirectPath) {
            location.href = errors[0].redirectPath
            return
          }
          const messageList = errors.map(e => e.fullMessage)
          this.setToast({ toast: { type: 'error', messages: messageList }})
        } else {
          this.$_gaSendEvent('再入荷通知', `商品詳細SKU(${this.position})`, `${this.adminShop.name} ${this.itemGroup.name}`);
          this.setToast({ toast: { type: 'success', messages: ['再入荷通知のご登録が完了しました。商品が購入可能になりましたらご登録のメールへお知らせします。'] }})
        }
        this.loadingStop();
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";
  .section-item-sku {
    display: flex;
    background: $gray-200;
    @include margin-top(md);
    @include padding(sm);
    @include mq-down(md) {
      margin-left: -15px;
      margin-right: -15px;
    }

    > .image {
      width: 33.33%;
      max-width: 300px;
      @include margin-right(sm);
      @include mq-down(md) {
        display: none;
      }

      > img {
        width: 100%;
      }
    }

    > .info {
      width: 66.66%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      > .shop {
        display: flex;
        align-items: center;
        @include margin-bottom(xxs);
        @include mq-down(md) {
          order: 0;
        }

        > .logo {
          width: 18px;
          height: 18px;
          @include margin-right(xxxs);

          > img {
            width: 100%;
          }
        }

        > a {
          color: $black-400;
          line-height: 157%;
          text-decoration: none;
          @include font-size(xs);

          &:hover {
            color: $olive-300;
          }
        }
      }

      > .tags {
        display: flex;
        flex-wrap: wrap;
        @include margin-bottom(xxxs);

        > .tag {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          height: 20px;
          background: $white;
          border: 1px solid $olive-300;
          margin-right: -1px;
          color: $olive-300;
          line-height: 100%;
          @include padding(zero, xxxs);
          @include font-size(xxxs);
        }
      }

      > h1 {
        @include margin-bottom(xxxs);
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(md);
        line-height: $line-height-tight;
        color: $black-400;
      }

      > .item {
        @include margin-bottom(sm);

        > .name {
          font-family: $font-family-serif;
          color: $black-400;
          line-height: 163%;
          @include margin-bottom(xxxs);
          @include font-size(sm);
        }

        > .original-price {
          align-items: baseline;
          font-weight: bold;
          line-height: 100%;
          text-decoration: line-through;
          @include margin-right(xxxs);
          @include font-size(sm);
        }
        > .sale-name {
          color: $olive-300;
        }

        > .prices {
          display: flex;
          align-items: center;
          color: $black-400;

          > .price {
            display: flex;
            align-items: baseline;
            font-weight: bold;
            line-height: 100%;
            @include margin-right(xxxs);
            @include font-size(sm);

            &.sale-price {
              color: $olive-300;
            }

            > small {
              font-weight: normal;
              line-height: 100%;
              @include font-size(xxs);
            }
          }

          > .point {
            line-height: 200%;
            @include font-size(xs);

            > small {
              @include font-size(xxs);
            }
          }
        }

        > .shipping {
          display: flex;
          align-items: center;
          color: $black-400;

          > .prefecture {
            @include font-size(xs);
          }

          > .price {
            display: flex;
            align-items: baseline;
            line-height: 100%;
            @include margin-right(xxxs);
            @include font-size(xs);

            .free-shipping {
              font-weight: bold;
              color: $red-300;
              @include font-size(sm);
            }

            .shipping-price {
              font-weight: bold;
              @include font-size(sm);
            }
          }
        }
      }

      > .image {
        width: 100%;
        @include margin-bottom(sm);
        @include mq-up(md) {
          display: none;
        }

        > img {
          width: 100%;
        }
      }

      > .review {
        @include margin-bottom(xxxs);

        > .inline {
          display: inline-flex;
          align-items: center;
          cursor: pointer;

          > .star {
            height: 12px;
            @include margin-right(xxxs);
            @include mq-down(md) {
              height: 10px;
            }
          }

          > .count {
            @include font-size(xxs);
            line-height: 1;
            color: $olive-300;
            white-space: nowrap;
            @include margin-right(xxxs);
          }

          > .icon {
            stroke: $olive-300;
          }
        }
      }

      > .postage {
        display: flex;
        align-items: center;
        color: $black-400;
        font-weight: bold;
        line-height: 100%;
        @include margin-bottom(xxxs);
        @include font-size(xs);

        > select {
          height: 30px;
          background: $white;
          border: 1px solid $gray-400;
          border-radius: 4px;
          outline: none;
          @include padding(zero, xxxs);
          @include margin-right(xxxs);
          font-weight: $font-weight-regular;
          font-size: 16px;
          -moz-appearance: menulist;
          -webkit-appearance: menulist;
        }
      }

      > ul.list {
        list-style: none;
        padding: 0;
        margin: 0;
        @include margin-bottom(xs);
      }

      > .buy {
        @include margin-bottom(md);
        @include mq-down(md) {
          order: 8;
        }
        > .alert-message {
          @include margin-bottom(xxs);
        }

        > .delivery {
          > p {
            color: $black-400;
            font-weight: bold;
            line-height: 100%;
            @include margin-bottom(xxxs);
            @include font-size(xs);

          }

          > select {
            width: 100%;
            height: 30px;
            background: $white;
            border: 1px solid $gray-400;
            border-radius: 4px;
            outline: none;
            @include padding(zero, xxxs);
            @include margin-bottom(xxs);
            @include margin-right(xxxs);
            @include font-size(xs);
            -moz-appearance: menulist;
            -webkit-appearance: menulist;
          }

          > .alert-message {
            @include margin-bottom(xxs);
          }
        }

        > .gift {
          background: $white;
          @include padding(xxxs);
          @include margin-bottom(sm);

          > ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            //margin: 0 -#{map-get($padding-margin-pc, xlg)}px 0 0;
            @include padding(zero, xxxs, xxxs, xxxs);
            @include mq-down(md) {
              //margin-right: -#{map-get($padding-margin-sp, xlg)}px;
            }

            > li {
              display: flex;
              @include margin-right(xlg);
              @include font-size(xxs);

              > .icon-base {
                width: 23px;
                min-width: 23px;
                height: 23px;
                margin-top: .5em;
                @include margin-right(xxxs);
              }

              > .box {
                line-height: 192%;
                @include font-size(xxs);

                > .name {
                  color: $black-200;
                  font-weight: bold;
                }

                > .content {
                  color: $red-300;
                  word-break: break-all;
                }
              }
            }
          }

          > button {
            width: 100%;
            background: $white;
            border: 1px solid $olive-300;
            outline: none;
            cursor: pointer;
            color: $black-400;
            line-height: 143%;
            text-align: center;
            @include padding(xxs, zero);
            @include font-size(xs);
          }
        }
        > .pre-sale {
          .alert-base {
            display:flex;
            flex-flow: column;
            @include margin-bottom(xxxs);;
            .alert {
              font-weight: $font-weight-bold;
              color: $red-300;
            }
            .box {
              .name {
                font-weight: $font-weight-bold;
              }
              +.box {
                @include margin-top(xs);;
              }
            }
          }
          .messages {
            @include font-size(xs);
            @include margin-bottom(md);;
          }
        }

        > .buttons {
          // display: flex;
          // align-items: center;
          // flex-wrap: wrap;

          > .button-count {
            @include margin-bottom(xs);
          }

          > .button {
            width: 100%;
            height: 44px;
            @include font-size(sm);

            > .icon-base {
              @include margin-right(xxxs);

              &.mail {
                width: 20px;
                height: 16px;
              }
              &.subscription {
                width: 25px;
                height: 26px;
                margin-right: 0;
              }
              &.cart {
                width: 18px;
                height: 18px;
              }
            }
          }
        }

        > .maxadd {
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xs);
          line-height: $line-height-normal;
          color: $black-400;
        }
      }

      > .delivery {
        color: $black-400;
        @include margin-bottom(xs);
        @include mq-down(md) {
          order: 9;
        }

        > h3 {
          margin: 0;
          font-weight: normal;
          line-height: $line-height-tight;
          @include margin-bottom(xxxs);
          @include font-size(sm);
          font-family: $font-family-serif;
        }

        > .link {
          @include margin-bottom(xxxs);
          a {
            font-family: $font-family-sans;
            font-weight: $font-weight-regular;
            font-style: $font-style-not-italic;
            @include font-size(xs);
            line-height: $line-height-normal;
            color: $black-400;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        > .alert-message {
          @include margin-bottom(xxxs);
        }

        > p {
          margin: 0;
          line-height: 163%;
          @include font-size(xs);

          &.free-shipping-price {
            color: $olive-300;
            font-weight: bold;
          }

          &.free-shipping-region {
            color: $olive-300;
            @include margin-bottom(xxxs);
          }
        }

        > .extra {
          color: $black-400;
          @include margin-bottom(xxxs);

          > p {
            margin: 0;
            line-height: 163%;
            @include margin-bottom(xxxs);
            @include font-size(xs);
          }

          > .progress-bar {
            @include margin-bottom(xxxs);
          }

          > .link {
            @include margin-bottom(xxxs);
            a {
              font-family: $font-family-sans;
              font-weight: $font-weight-regular;
              font-style: $font-style-not-italic;
              @include font-size(xs);
              line-height: $line-height-normal;
              color: $black-400;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }

      > .contact {
        display: flex;
        justify-content: flex-end;
        @include mq-down(md) {
          order: 10;
        }

        > .button-base:not(:first-child) {
          @include margin-left(xxs);
        }
      }
    }
    &.skeleton {
      .info {
        .item-group-name {
          height: 20px;
          background-color: $gray-400;
          border-radius: 5px;
          width: 60%;
          @include margin-bottom(xxs);;
        }
      }
    }

  }
</style>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/styles";
  .section-item-sku {
    > .info {
      > .contact {
        > .button {
          > .icon-base {
            > svg {
              fill: $black-400;
            }
          }
        }
      }
    }
  }
</style>
