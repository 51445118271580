<template lang="pug">
  a.item-link(:href="itemGroup.detailPagePath" @click="$_gaSendEvent('商品詳細へ遷移', '商品詳細商品説明', `${adminShop.name} ${itemGroup.name}`)")
    .image
      ImgResponsive(:src="itemGroup.listImageOrItemGroupImageSet.xsSqImage")
    .desc
      ul.tags
        li(v-for="icon in itemGroup.icons") {{icon}}
      .name {{itemGroup.name}}
      .price(:class="{'is-sale': itemGroup.isSale}")
        |{{itemGroup.properPriceInTax | numberToCurrency}}
        template(v-if="itemGroup.maxProperPriceInTax") ~{{itemGroup.maxProperPriceInTax | numberToCurrency}}
        span (税込)
      .sale-price(v-if="itemGroup.isSale")
        |{{itemGroup.salePriceInTax | numberToCurrency}}
        template(v-if="itemGroup.maxSalePriceInTax") ~{{itemGroup.maxSalePriceInTax | numberToCurrency}}
        span (税込)
</template>

<script>
  import GlobalMixin from '../../mixin/GlobalMixin'

  import ImgResponsive from '../image/ImgResponsive'

  export default {
    name: "item-link",
    mixins: [GlobalMixin],
    components: {
      ImgResponsive
    },
    props: {
      itemGroup: {
        type: Object,
        default() {
          return {}
        }
      },
      adminShop: {
        type: Object,
        default() {
          return {}
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .item-link {
    display: flex;
    background: $gray-light;
    cursor: pointer;
    @include padding(xs);
    text-decoration: none;

    &:hover {
      > .desc {
        > .name,
        > .price {
          color: $olive-dark;
        }
      }
    }

    > .image {
      width: 15%;
      @include mq-down() {
        width: 30%;
      }
    }

    > .desc {
      width: 85%;
      @include padding-left(xs);
      @include mq-down(){
        width: 70%;
      }

      > .tags {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;

        > li {
          display: flex;
          align-items: center;
          height: 20px;
          padding: 0 5px;
          margin: -1px -1px 0 0;
          background: $white;
          border: 1px solid $olive-dark;
          color: $olive-dark;
          line-height: 1;
          @include font-size(xxxs);
        }
      }

      > .name,
      > .price,
      > .sale-price {
        color: $black-dark;
        font-weight: bold;
        @include margin-top(xxxs);
        @include font-size(sm);
      }

      > .price,
      > .sale-price {
        > span {
          font-weight: normal;
          @include font-size(xxs);
        }
      }

      > .price {
        &.is-sale{
          font-weight: normal;
          text-decoration: line-through;
          @include font-size(xs);

          > span {
            @include font-size(xxxs);
          }
        }
      }

      > .sale-price {
        margin-top: 0;
        color: $olive-dark;
      }
    }
  }
</style>
