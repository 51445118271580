<template lang="pug">
  .gifts-show(:class="giftPage.designType")
    img(v-if="giftPage.mainImageSet" :src="giftPage.mainImageSet.mdImage" :alt="`${giftPage.name}のメイン画像`"  class="gift-page-image")
    .mt-lg(v-for="giftPageBody in giftPage.publishedGiftPageBodies" :id="`outline${giftPageBody.id}`" :key="giftPageBody.id")
      template(v-if="giftPageBody.bodyType === 'menu'")
        SectionMenu(:giftPageBody="giftPageBody" :giftPageBodies="giftPage.giftPageBodies")
      template(v-else-if="giftPageBody.bodyType === 'ranking'")
        SectionRanking(:giftPageBody="giftPageBody")
      template(v-else-if="giftPageBody.bodyType === 'budget'")
        SectionBudget(:giftPageBody="giftPageBody")
      template(v-else-if="giftPageBody.bodyType === 'free'")
        SectionFree(:giftPageBody="giftPageBody")
      template(v-else-if="giftPageBody.bodyType === 'category'")
        SectionCategory(:giftPageBody="giftPageBody")
      template(v-else-if="giftPageBody.bodyType === 'recommend'")
        SectionRecommend(:giftPageBody="giftPageBody")
      //- タグ
      template(v-else-if="giftPageBody.bodyType === 'tag'")
        SectionTag(:giftPageBody="giftPageBody" :giftPageName="giftPage.name" :giftPageCode="giftPage.code" )
      //- 目次
      template(v-else-if="giftPageBody.bodyType === 'outline'")
        SectionOutline(:giftPageBodies="giftPage.publishedGiftPageBodies")
    SectionItemArchive.mt-lg
</template>

<script>
  import { GetGiftPageForApi } from '../../graphqls/Gifts'
  import GlobalMixin from '../../mixin/GlobalMixin'
  import SectionBudget from '../../components/v1/gift/SectionBudget'
  import SectionCategory from '../../components/v1/gift/SectionCategory'
  import SectionFree from '../../components/v1/gift/SectionFree'
  import SectionMenu from '../../components/v1/gift/SectionMenu'
  import SectionRanking from '../../components/v1/gift/SectionRanking'
  import SectionRecommend from '../../components/v1/gift/SectionRecommend'
  import SectionOutline from '../../components/v1/gift/SectionOutline'
  import SectionItemArchive from '../../components/v1/section/SectionItemArchive'
  import SectionTag from "../../components/v1/gift/SectionTag";

  export default {
    name: 'GiftsShow',
    mixins: [GlobalMixin],
    components: {
      SectionTag,
      SectionBudget,
      SectionCategory,
      SectionFree,
      SectionMenu,
      SectionRanking,
      SectionRecommend,
      SectionItemArchive,
      SectionOutline
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    data() {
      return {
        giftPage: {}
      }
    },
    mounted: function () {
      // サイドメニュー表示
      this.showSide()
      // クーポン表示
      this.showCommonCoupon()
      // ギフトページ取得
      this.fetchGiftPage()
    },
    methods: {
      async fetchGiftPage () {
        this.loadingStart()
        const { data } = await this.$apollo
        .query({
          query: GetGiftPageForApi,
          variables: {
            code: this.$route.params.code
          },
          client: 'apiClient'
        })
        this.giftPage = data.giftPage
        // パンくずリストを設定
        this.setBreadcrumbs({ breadcrumbs: [
          {
            path: '/',
            name: 'TOP',
          },
          {
            path: `/gifts/${this.giftPage.code}/`,
            name: this.giftPage.name,
          },
        ]})
        this.loadingStop()
      }
    }
  }
</script>

<style>
.gift-page-image {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  height: auto;
}
</style>