import gql from 'graphql-tag'

export const LogoutAdminUser = gql`
mutation LogoutAdminUser($input: LogoutAdminUserInput!) {
  logoutAdminUser(input: $input) {
    adminUser {
      id
    }
  }
}`
