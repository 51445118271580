<template lang="pug">
  .wrapper
    TheHeader
    .top-of-contents
      FullWidthBanner.mb-md(v-if="hasBanner")
      template(v-if="isTopPage")
        .container(v-if="hasTopMessages" )
          AlertBase(v-for="message in topMessages" :key="message.id").mb-sm(:type="message.messageType")
            a(v-if="message.url" :href="message.url")
              include ../assets/images/icon/exclamation-circle-solid.svg
              | {{message.message}}
            p(v-else)
              include ../assets/images/icon/exclamation-circle-solid.svg
              | {{message.message}}
        TopBannerSection.mb-md
    TheBreadcrumbs(v-if="!isTopPage")
    .container.mb-md(:class="{ '-has-side': hasSide }")
      main.main
        router-view
      aside.side(v-if="hasSide")
        TheSideMenu
    .bottom-of-contents
      FullWidthBanner.mb-md(v-if="hasBanner")
    TheFooter
    TheLoading
    AlertToast
    CouponToast(v-if="hasCoupon")
</template>

<script>
  import { mapGetters } from 'vuex'
  import TheHeader from './packs/components/v1/header/TheHeader'
  import TopBannerSection from './packs/components/section/TopBannerSection'
  import TheBreadcrumbs from './packs/components/v1/breadcrumbs/TheBreadcrumbs'
  import TheSideMenu from './packs/components/v1/sidemenu/TheSideMenu'
  import TheFooter from './packs/components/v1/global/TheFooter'
  import TheLoading from './packs/components/v1/global/TheLoading'
  import FullWidthBanner from './packs/components/v1/global/FullWidthBanner'
  import AlertToast from './packs/components/v1/alert/AlertToast'
  import CouponToast from './packs/components/v1/alert/CouponToast'
  import AlertBase from './packs/components/v1/alert/AlertBase'
  import {GetTopMessages} from "./packs/graphqls/TopMessages";

  export default {
    components: {
      TheHeader,
      TopBannerSection,
      TheBreadcrumbs,
      TheSideMenu,
      TheFooter,
      TheLoading,
      FullWidthBanner,
      AlertToast,
      CouponToast,
      AlertBase
    },
    data() {
      return {
        topMessages: [],
      }
    },
    computed: {
      ...mapGetters([
        'hasSide',
        'hasBanner',
        'hasCoupon',
      ]),
      isTopPage() {
        return this.$route.name == 'home-index'
      },
      hasTopMessages() {
        return this.topMessages.length > 0
      }
    },
    mounted() {
      if (this.isTopPage) {
        this.fetchTopMessages()
      }
      // console.log(this.$route)
    },
    methods: {
      async fetchTopMessages() {
        const { data } = await this.$apollo
            .query({
              query: GetTopMessages,
              client: 'apiClient'
            })
            .catch(error => {
              return
            })
        this.topMessages = data.topMessages.items
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/v1/styles";

  .wrapper {
    padding-top: 140px;
    @include mq-down(md) {
      padding-top: 50px;
    }
    &.with-admin-toolbar {
      padding-top: 180px;
      @include mq-down(md) {
        padding-top: 90px;
      }
    }

    > .container {
      width: 100%;
      max-width: 1300px;
      margin-left: auto;
      margin-right: auto;
      @include padding(zero, xs);
      display: flex;

      > .main {
        width: 100%;
      }

      &.-has-side {
        flex-direction: row-reverse;
        > .main {
          width: 78%;
          margin-left: 2%;
        }
        > .side {
          width: 20%;
        }
        @include mq-down(md) {
          flex-direction: column;
          > .main {
            width: 100%;
            margin-left: 0;
          }
          > .side {
            width: 100%;
            @include margin-top(md);
          }
        }
      }
    }

    > .top-of-contents, > .bottom-of-contents {
      width: 100%;
      > .container {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        @include padding(zero, xs);
      }
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/v1/reset";
</style>
