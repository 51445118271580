<template lang="pug">
  .users-login.mx-auto
    h1.heading-1.text-center.mb-sm ログイン
    ContainerSheet
      section
        h3.heading-3.text-center.my-sm 外部アカウントでログイン
        p.paragraph.text-center.mb-xs
          a.anker-link.text-xs(@click="$refs.invalidMailModal.open()") ご利用いただけないメールアドレスについて
        InvalidMailModal(ref="invalidMailModal")
        ButtonAmazon.mx-auto.mb-xs(href="/auth/amazon")
        ButtonYahoo.mx-auto.mb-xs(text="Yahoo! JAPAN IDでログイン" href="/shop/page/24032817012/")
        ButtonFacebook.mx-auto.mb-xs(text="Facebookでログイン" href="/auth/facebook")
        ButtonGoogle.mx-auto.mb-xs(text="Googleでログイン" href="/auth/google_oauth2")
      hr.my-sm
      section
        h3.heading-3.text-center.my-md メールアドレスでログイン
        AlertBase(v-if="recaptchaErrorMessage" type="error")
          p {{ recaptchaErrorMessage }}
        FormItem
          template(slot="title")
            FormLabel(type="required") メールアドレス
          template(slot="input")
            FormTextfield(
              v-model="form.email"
              type="email"
              placeholder="info@taberutokurasuto.com"
              :error="'email' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.email"
            )
        FormItem
          template(slot="title")
            FormLabel(type="required") パスワード
          template(slot="input")
            FormTextfield(
              v-model="form.password"
              type="password"
              placeholder="8~20文字 半角英数字・記号"
              :error="'password' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.password"
            )
        ButtonBase.mb-md(@click="loginUser()" variant="primary" :block="true")
          IconLogin(color="white")
          | ログイン
        p.paragraph.text-center.mb-xs
          a.anker-link.text-xs(href="/regist/") 新規会員登録はこちら
        p.paragraph.text-center.mb-xs
          a.anker-link.text-xs(href="/recover/") パスワードをお忘れの方はこちら
</template>

<script>
  import { LoginUser } from '../../graphqls/Users'
  import GlobalMixin from '../../mixin/GlobalMixin'
  import ContainerSheet from '../../components/v1/container/ContainerSheet'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormTextfield from '../../components/v1/form/FormTextfield'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import ButtonAmazon from '../../components/v1/button/ButtonAmazon'
  import ButtonYahoo from '../../components/v1/button/ButtonYahoo'
  import ButtonFacebook from '../../components/v1/button/ButtonFacebook'
  import ButtonGoogle from '../../components/v1/button/ButtonGoogle'
  import IconLogin from '../../components/v1/icon/IconLogin'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import InvalidMailModal from "../../components/v1/modal/InvalidMailModal";

  export default {
    name: 'Login',
    mixins: [GlobalMixin],
    components: {
      InvalidMailModal,
      ContainerSheet,
      FormItem,
      FormLabel,
      FormTextfield,
      ButtonBase,
      ButtonAmazon,
      ButtonYahoo,
      ButtonFacebook,
      ButtonGoogle,
      IconLogin,
      AlertBase
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    data() {
      return {
        form: {
          email: '',
          password: '',
          recaptchaToken: ''
        }
      }
    },
    methods: {
      async loginUser() {
        this.loadingStart()
        this.$_clearValidationErrors()

        const token = await this.$_createRecaptchaToken('login')
        this.form.recaptchaToken = token

        const { data } = await this.$apollo
            .mutate({
              mutation: LoginUser,
              variables: {
                input: this.form
              }
            })
            .catch(error => {
              return this.$_redirect_internal_server_error(error)
            })

        const errors = data.loginUser.errors
        if (errors) {
          this.$_parseError(errors)
          this.loadingStop()
        } else {
          this.$gtag.event('login', {'method': 'email'})
          location.href = data.loginUser.previousUrl
        }
      }
    },
    mounted: function () {
      // サイドメニューを非表示
      this.hideSide()
      // パンくずリストを設定
      this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/login/',
          name: 'ログイン',
        },
      ]})
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .users-login {
    width: 450px;
    @include mq-down(md) {
      width: 100%;
    }

    hr {
      width: 100%;
      border-top: 1px solid $black-400;
    }
  }
</style>
