<template lang="pug">
  .progress-bar
    .progress(:style="{width: `${percent}%`}" :class="{overflow: isOverFlow}")
      template(v-if="isOverFlow && overFlowComment") {{overFlowComment}}
      template(v-else) {{percent}}%
</template>

<script>
  export default {
    name: "progress-bar",
    props: {
      progress: {
        type: Number,
        default: 0
      },
      overFlowComment: {
        type: String,
        default: ""
      },
    },
    computed: {
      percent() {
        return this.isOverFlow ? 100 : this.progress
      },
      isOverFlow() {
        return this.progress > 100
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .progress-bar {
    width: 100%;
    height: 22px;
    background: repeating-linear-gradient(-45deg, $gray-light, $gray-light 5px, $white 5px, $white 10px);
    border: 1px solid $black-light;
    box-sizing: border-box;
    overflow: hidden;

    > .progress {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: #839FA3;
      overflow: hidden;
      color: $white;
      font-family: Roboto;
      line-height: 100%;
      border-radius: 0;
      @include font-size(xxxs);
      &.overflow {
        background: $red-base;
      }
    }
  }
</style>
