<template lang="pug">
  .form-group
    slot
</template>

<script>
  export default {
    name: "form-group",
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .form-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    + .form-group {
      @include margin-top(md);
    }

    > .form-group {
      @include margin-top(xxxs);
      @include margin-left(md);
    }
  }
</style>
