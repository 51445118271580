<template lang="pug">
  .recover
    AlertBase.mt-sm(v-if="recaptchaErrorMessage || validateErrors.length > 0" type="error")
      p(v-if="recaptchaErrorMessage") {{ recaptchaErrorMessage }}
      p(v-for="message of $_validationErrorMessage") {{message}}
    AlertBase.mt-sm(v-if="successMessage" type="success")
      p {{ successMessage }}
    section.section
      h1.heading-1.text-center.my-md パスワード再設定
      p.paragraph.text-center.mb-sm ご登録のメールアドレスを入力してパスワードの再設定をしてください。
      ContainerSheet.mb-md
        FormItem
          template(slot="title")
            FormLabel(type="required") メールアドレス
          template(slot="input")
            FormTextfield(
              v-model="form.email"
              type="email"
              :error="'email' in $_validationErrorMessage"
              :error-message="$_validationErrorMessage.email"
            )
        ButtonBase(@click="doRecoverUser" variant="primary" :block="true")
          | パスワード再設定メールを送信
    .other.text-center
      ButtonBase.mb-sm(variant="normal" href="/login/" @click="$_gaSendEvent('ログインページへ遷移', 'パスワード再設定', 'ログイン')") 
        | ログイン
    .other.text-center
      ButtonBase.mb-sm(variant="normal" href="/regist/" @click="$_gaSendEvent('新規会員登録ページへ遷移', 'パスワード再設定', '会員登録')") 
        | 会員登録
</template>

<script>
  import { RecoverUser } from '../../graphqls/Users'
  import GlobalMixin from '../../mixin/GlobalMixin'
  import ContainerSheet from '../../components/v1/container/ContainerSheet'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormTextfield from '../../components/v1/form/FormTextfield'

  export default {
    name: 'Recover',
    mixins: [GlobalMixin],
    components: {
      AlertBase,
      ContainerSheet,
      FormItem,
      FormLabel,
      FormTextfield,
      ButtonBase,
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    data() {
      return {
        form: {
          email: '',
          recaptchaToken: ''
        },
        successMessage: ''
      }
    },
    methods: {
      async doRecoverUser() {
        this.loadingStart()
        this.successMessage = ''
        this.$_clearValidationErrors()

        const token = await this.$_createRecaptchaToken('recover')
        this.form.recaptchaToken = token

        const { data } = await this.$apollo
        .mutate({
          mutation: RecoverUser,
          variables: {
            input: this.form
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        })

        const errors = data.recoverUser.errors
        if (errors) {
          this.$_parseError(errors)
        } else {
          this.user = data.recoverUser.user
          this.successMessage = data.recoverUser.message
          this.$scrollTo("body")
        }
        this.loadingStop()
      }
    },
    mounted: function () {
      // パンくずリストを設定
      this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/recover/',
          name: 'パスワード再設定',
        },
      ]})
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .recover {
    > .alert-base {
      width: 100%;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }

    > .section {
      > .container-sheet {
        width: 100%;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 32px;
        padding-bottom: 32px;
  
        > .form-item {
          margin-top: 0;
        }
      }
    }
  }
</style>
