<template lang="pug">
  .site-navbar-pc(:class="{ '-fixed': scrollY > 40 }")
    .container
      h1.site-description(v-if="isHome") 無添加･無農薬などのこだわり生産者直売のオーガニックモール
      p.site-description(v-if="!isHome && !isSimple") 無添加･無農薬などのこだわり生産者直売のオーガニックモール
      .global-navigation(:class="{ '-is-simple': isSimple }")
        .logo
          span(v-if="isSimple" )
            include ../../../../../assets/images/logo/logo-pc.svg
          a(v-else href="/" @click="$_gaSendEvent('トップへ遷移', 'Gナビ PC', 'ロゴ')")
            include ../../../../../assets/images/logo/logo-pc.svg
        .navbar(v-if="!isSimple" )
          FormSearch
          nav.navigation
            ul
              li(v-if="!currentUserObj.user")
                a(href="/regist/" @click="$_gaSendEvent('会員登録へ遷移', 'Gナビ PC', '会員登録')") 会員登録
              li(v-if="!currentUserObj.user")
                a(href="/login/" @click="$_gaSendEvent('ログインへ遷移', 'Gナビ PC', 'ログイン')") ログイン
              li(v-if="currentUserObj.user")
                a(href="/mypage/" @click="$_gaSendEvent('マイページへ遷移', 'Gナビ PC', 'マイページ')") マイページ
              li
                a(href="/shop/favorite/" @click="$_gaSendEvent('お気に入りへ遷移', 'Gナビ PC', 'お気に入り')") お気に入り
              li.news(v-if="currentUserObj.user")
                a(href="/mypage/contacts/" @click="$_gaSendEvent('お知らせへ遷移', 'Gナビ PC', 'お知らせ')") 
                  include ../../../../../assets/images/icon/bell.svg
                  span.num(v-if="currentUserObj.unconfirmedUserNoticeNum > 0") {{ currentUserObj.unconfirmedUserNoticeNum }}
              li
                a.cart(href="/shop/cart/" @click="$_gaSendEvent('カートへ遷移', 'Gナビ PC', 'カート')")
                  IconCart(:size="40")
                  .count {{ currentUserObj.cartItemsNum }}
</template>

<script>
  import GlobalMixin from "../../../mixin/GlobalMixin"
  import IconCart from '../icon/IconCart'
  import FormSearch from '../form/FormSearch'

  export default {
    name: 'SiteNavBarPc',
    mixins: [GlobalMixin],
    components: {
      IconCart,
      FormSearch
    },
    props: {
      currentUserObj: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {
        scrollY: 0,
      }
    },
    computed: {
      isHome: function () {
        return document.getElementById('home') != null
      },
      pathname() {
        return location.pathname
      },
      isOrder() {
        const regexp = /^\/shop\/.+\/order.*/;
        return regexp.test(this.pathname)
      },
      isOrderComplete () {
        const regexp = /^\/shop\/.+\/order\/create.*/;
        return regexp.test(this.pathname) || this.pathname.includes('/order/dev_complete')
      },
      isSubscriptionOrder () {
        const regexp = /^\/shop\/.+\/subscriptionorder\/.*/;
        return regexp.test(this.pathname)
      },
      isSubscriptionOrderComplete () {
        const regexp = /^\/shop\/.+\/subscriptionorder\/create.*/;
        return regexp.test(this.pathname)
      },
      isCart() {
        const regexp = /^\/shop\/cart/;
        return regexp.test(this.pathname)
      },
      isSimple: function () {
        return this.isCart
            || (this.isOrder && !this.isOrderComplete)
            || (this.isSubscriptionOrder && !this.isSubscriptionOrderComplete)
      }
    },
    mounted: function () {
      window.addEventListener('scroll', this.handleScroll)

    },
    methods: {
      handleScroll: function() {
        this.scrollY = window.scrollY
      },
      search: function() {
        location.href = `/shops/items/search/?q=${this.searchQuery}`
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .site-navbar-pc {
    background-color: $white;

    &.-fixed {
      > .container {
        .site-description {
          transition: all 300ms 0s ease;
          width: 0px;
          height: 0px;
          overflow: hidden;
        }
      }
    }

    > .container {
      width: 100%;
      max-width: 1300px;
      @include padding(zero, xs);
      margin: 0 auto;

      > .site-description {
        height: 50px;
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(xs);
        line-height: 50px;
        color: $black-400;
        transition: all 300ms 0s ease;
      }

      > .global-navigation {
        display: flex;
        align-items: center;
        width: 100%;
        @include padding(xxxs, zero);
        &.-is-simple {
          @include padding(md, zero);
        }
        > .logo {
          width: 20%;
          > a {
            cursor: pointer;
            > svg path {
              fill: $black-400;
            }
            &:hover > svg path {
              fill: $olive-300;
            }
          }
        }

        > .navbar {
          width: 80%;
          display: flex;
          align-items: center;
          @include padding-left(md);

          .navigation {
            @include padding-left(md);

            > ul {
              display: flex;
              align-items: center;

              > li {
                > a {
                  font-family: $font-family-serif;
                  font-weight: $font-weight-regular;
                  font-style: $font-style-not-italic;
                  @include font-size(xs);
                  line-height: $line-height-normal;
                  letter-spacing: 2px;
                  color: $black-400;
                  cursor: pointer;
                  text-decoration: none;
                  white-space: nowrap;
                  &:hover {
                    color: $olive-300;
                  }
                  @include padding(xxxs);

                  &.cart {
                    position: relative;
                    &:hover {
                      opacity: 0.7;
                    }
                    > .count {
                      width: 20px;
                      height: 20px;
                      position: absolute;
                      top: 14px;
                      left: 22px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-family: $font-family-sans;
                      font-weight: $font-weight-bold;
                      font-style: $font-style-not-italic;
                      @include font-size(xs);
                      line-height: $line-height-none;
                      letter-spacing: 1px;
                      color: $white;
                    }
                  }
                }
              }
              .news {
                position: relative;
                margin-right: 8px;
                padding-top: 6px;

                a {
                  > svg path {
                      fill: $black-400;
                      width: 100%;
                      height: 100%; 
                      text-align: center;
                      justify-content: center;
                    }
                    &:hover > svg path {
                      fill: $olive-300;
                    }
                  .num {
                    position: absolute;
                    top: -4px;
                    left: calc(100% - 14px);
                    background: $red-300;
                    box-sizing: border-box;
                    border-radius: 10px;
                    min-width: 20px;
                    height: 20px;
                    padding: 0px 3px 8px 3px;
                    font-family: $font-family-sans;
                    @include font-size(xxs);
                    color: $white;
                    letter-spacing: 0px;
                    text-align: center;
                  }
                }
              }      
            }
          }    
        }
      }
    }
  }
</style>
