<template>
  <svg width="12" height="13" viewBox="0 0 12 13" fill="#383838" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.50024 8V12.5H7.50024V8H12V5H7.50023V0.5H4.50023V5H0V8H4.50024Z"/>
  </svg>
</template>
<script>
  export default {
    name: "plus-svg"
  }
</script>
