<template>
  <!-- Generator: Adobe Illustrator 19.2.1, SVG Export Plug-In  -->
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
       x="0px" y="0px" viewBox="0 0 50.5 50.5" enable-background="new 0 0 50.5 50.5"
       xml:space="preserve">
    <defs>
    </defs>
        <g>
    	<path d="M49.7,0.8c-1.1-1.1-2.9-1.1-4.1,0L25.2,21.2L4.9,0.8C3.8-0.3,2-0.3,0.8,0.8s-1.1,2.9,0,4.1l20.3,20.3L0.8,45.6
    		c-1.1,1.1-1.1,2.9,0,4.1c0.6,0.6,1.3,0.8,2,0.8s1.5-0.3,2-0.8l20.3-20.3l20.3,20.3c0.6,0.6,1.3,0.8,2,0.8s1.5-0.3,2-0.8
    		c1.1-1.1,1.1-2.9,0-4.1L29.3,25.3L49.7,4.9C50.8,3.8,50.8,2,49.7,0.8z"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: "close-svg"
  }
</script>
