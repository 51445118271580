<template lang="pug">
  .home-index
    // 新着・再販売商品
    SectionItemNew

    // おすすめタグ商品
    SectionItemCommon.mt-lg(v-for="itemTag of itemTags" :key="itemTag.id"
      :itemTagId="itemTag.id"
      :itemTagCode="itemTag.code"
      :itemTagName="itemTag.name"
      :itemTagSubTitle="itemTag.subTitle")

    // 新着レビュー
    SectionNewReview.mt-lg

    // 今週の人気ランキング
    SectionWeeklyRanking.mt-lg

    // 生産者のお便りとお知らせ
    SectionShopPost.mt-lg

    // おすすめカテゴリ
    SectionItemCategory.mt-lg(eventCategory="トップカテゴリ")

    // 生産者一覧
    SectionShopNewly.mt-lg

    // 最近チェックした商品
    SectionItemArchive.mt-lg

    //// 最近チェックした商品からあなたにおすすめの商品
    //SectionItemArchiveRecommend.mt-lg
</template>

<script>
  import {GetItemTags} from '../../graphqls/ItemTags'
  import GlobalMixin from '../../mixin/GlobalMixin'
  import SectionItemNew from '../../components/v1/section/SectionItemNew'
  import SectionItemCommon from '../../components/v1/section/SectionItemCommon'
  import SectionNewReview from '../../components/v1/section/SectionNewReview'
  import SectionWeeklyRanking from '../../components/v1/section/SectionWeeklyRanking'
  import SectionItemCategory from '../../components/v1/section/SectionItemCategory'
  import SectionShopPost from '../../components/v1/section/SectionShopPost'
  import SectionShopNewly from '../../components/v1/section/SectionShopNewly'
  import SectionItemArchive from '../../components/v1/section/SectionItemArchive'
  // import SectionItemArchiveRecommend from '../../components/v1/section/SectionItemArchiveRecommend'

  export default {
    name: 'HomeIndex',
    mixins: [GlobalMixin],
    components: {
      SectionItemNew,
      SectionItemCommon,
      SectionNewReview,
      SectionWeeklyRanking,
      SectionItemCategory,
      SectionShopPost,
      SectionShopNewly,
      SectionItemArchive,
      // SectionItemArchiveRecommend,
    },
    metaInfo() {
      return {
        script: [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: [{
                '@type': 'ListItem',
                'position': 1,
                'item': {
                  '@id': 'https://staging.taberutokurasuto.com/',
                  'name': 'たべるとくらすと'
                }
              }]
            }
          },
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              'url': 'https://taberutokurasuto.com/',
              'potentialAction': {
                '@type': 'SearchAction',
                'target': 'https://taberutokurasuto.com/shops/items/search/?q={query}',
                'query-input': 'required name=query'
              }
            }
          }
        ]
      }
    },
    data() {
      return {
        itemTags: []
      }
    },
    mounted () {
      // サイド表示
      this.showSide()
      // クーポン表示
      this.showHomeCoupon()

      this.fetchItemTags()
    },
    methods: {
      async fetchItemTags() {
        const { data } = await this.$apollo
        .query({
          query: GetItemTags,
          variables: {
            search: JSON.stringify({show_home_status_eq: 'show_home'}),
            orderBy: 'item_tags.row_order'
          },
          client: 'apiClient'
        })
        this.itemTags = data.itemTags.items
      }
    }
  }
</script>
