<template lang="pug">
  div.container-brackets
    slot
</template>

<script>
  export default {
    name: 'ContainerBrackets'
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .container-brackets {
    position: relative;
    width: 100%;
    padding: 1em;
    font-family: $font-family-serif;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    @include font-size(sm);
    line-height: $line-height-normal;
    color: $black-400;

    &:before, &:after {
      content: '';
      width: 20px;
      height: 30px;
      position: absolute;
      display: inline-block;
    }

    &:before {
      border-left: solid 1px $black-400;
      border-top: solid 1px $black-400;
      top: 0;
      left: 0;
    }

    &:after {
      border-right: solid 1px $black-400;
      border-bottom: solid 1px $black-400;
      bottom: 0;
      right: 0;
    }
  }
</style>
