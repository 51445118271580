<template lang="pug">
  div.alert-base(:class="classes")
    slot
</template>

<script>
  export default {
    name: 'AlertBase',
    props: {
      type: {
        type: String,
        required: true,
        default: undefined,
        validator: function (value) {
          return ['success', 'info', 'warning', 'error', 'primary'].includes(value)
        }
      },
      outlined: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
      }
    },
    computed: {
      classes: function () {
        return [
          `-${this.type}`,
          {'-outlined': this.outlined}
        ]
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .alert-base {
    @include padding(xs);
    border-radius: 3px;
    white-space: pre-wrap;

    &.-success {
      background: $green-100;
      color: $black-400;
    }
    &.-info {
      background: $gray-200;
      color: $black-400;
      a {
        color: $black-400;
        &:hover {
          text-decoration: none;
        }
      }
      &.-outlined {
        background: transparent;
        border: 1px solid $black-400;
        color: $black-400;
      }
    }
    &.-warning {
      background: $red-100;
      color: $red-300;
      a {
        color: $red-300;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.-error {
      background: $red-300;
      color: $white;
      a {
        color: $white;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.-primary {
      background: $white;
      color: $olive-500;;
      a {
        color: $olive-500;
        &:hover {
          text-decoration: none;
        }
      }
      &.-outlined {
        background: $white;
        border: 1px solid $olive-400;
        color: $olive-400;
      }
    }

    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    @include font-size(xs);
    line-height: $line-height-normal;

    .bold {
      font-weight: $font-weight-bold;
    }

    .order-message {
          margin-top: 16px;
        }

    .note {
      padding-left: 1.5em;
      position: relative;
      &::before {
        content: "※";
        position: absolute;
        left: 0;
        top: 0;
      }
      &:not(:first-child) {
        margin-top: 0.5em !important;
      }
      &:not(:last-child) {
        margin-bottom: 0.5em !important;
      }
    }

    ol {
      padding-left: 1.5em;
      > li {
        list-style-type: decimal;
      }
    }

    svg {
      width: 14px;
      vertical-align: text-bottom;
      display: inline-block;
      margin-right: 0.25rem;
    }
  }
</style>
