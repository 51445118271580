<template lang="pug">
  .form-checkbox
    label(:class="{'-disabled': disabled}")
      input(
        type="checkbox"
        v-model="localValue"
        :disabled='disabled'
      )
      span.label
        slot
</template>

<script>
  export default {
    name: 'FormCheckbox',
    props: {
      value: {
        type: [String, Number, Boolean],
        required: true,
        default: undefined
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
      }
    },
    computed: {
      localValue: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
          this.$emit('change', newValue)
        }
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .form-checkbox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;

    > label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      &.-disabled {
        cursor: not-allowed;
      }

      > input {
        width: 24px;
        height: 24px;
        border: none;
        pointer-events: none;
        -webkit-appearance: none;
        outline: none;

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: $white;
          border: 1px solid $gray-400;
          border-radius: 3px;
        }

        &:checked {
          &:before {
            background-color: $olive-300;
            background-image: url("../../../../../assets/images/icon/check-white.svg");
            background-size: 70% auto;
            background-position: center;
            background-repeat: no-repeat;
            border-color: $olive-300;
          }
        }

        &:disabled {
          &:before {
            background-color: $gray-200;
            border-color: $gray-200;
          }

          &:checked {
            &:before {
              background-image: url("../../../../../assets/images/icon/check-black-lighter.svg");
            }
          }
        }
      }


      > .label {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-none;
        color: $black-400;
        @include margin-left(xxs);
      }
    }
  }
</style>
