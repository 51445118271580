<template lang="pug">
  .lps-covid-free-shipping
    section.main
      ImgResponsive(src="https://cdn.taberutokurasuto.com/image/lp/covid_free_shipping/20200828_main.png" :is-full="!isPc")
      CommonHeading(:size="2")
        |コロナで出荷が停滞した食材販売を支援しています！
        br
        |対象品目のご注文送料無料！
      p
        |たべるとくらすとでは、新型コロナウイルスの感染拡大の影響を受け、
        br
        |在庫の滞留や売上げの減少などが顕著な食材の販売促進を支援するため、
        br
        |対象品目の送料を補助する事業※に参加しています。
      small
        |※令和２年度 国産農林水産物等販売促進緊急対策事業
        br
        |品目横断的販売促進緊急対策事業におけるインターネット販売推進事業
        br
        |URL&nbsp;
        a(href="https://ec-hanbai-suishin.jp/") https://ec-hanbai-suishin.jp/

    section.list
      YaneHeading(title="キャンペーン対象商品")
      p 対象商品はご注文送料が無料となります。
      Row.itemlist.-sm-vertical
        Col.item(v-for="(itemGroup, index) in itemGroups" :key="`${index}_${itemGroup.id}`" :sizes="['xs-12', 'sm-4', 'md-3']")
          ItemList(:itemGroup="itemGroup" eventCategory="キャンペーン対象商品")

      ButtonBlock
        ButtonBase(href="/shops/items/freeshipping/" @click="$_gaSendEvent('商品一覧へ遷移', 'LP', '送料無料商品')") その他の送料無料商品を見る

</template>

<script>
  import { GetItemGroups } from '../../graphqls/ItemGroups'

  import GlobalMixin from '../../mixin/GlobalMixin'

  import ImgResponsive from '../../components/image/ImgResponsive'
  import CommonHeading from '../../components/heading/CommonHeading'
  import YaneHeading from '../../components/heading/YaneHeading'
  import Row from '../../components/layout/Row'
  import Col from '../../components/layout/Col'
  import ItemList from '../../components/list/ItemList'
  import ButtonBlock from "../../components/button/ButtonBlock";
  import ButtonBase from "../../components/button/ButtonBase";

  export default {
    name: "lps-covid-free-shipping",
    mixins: [GlobalMixin],
    components: {
      ButtonBase,
      ButtonBlock,
      ImgResponsive,
      CommonHeading,
      YaneHeading,
      Row,
      Col,
      ItemList,
    },
    props: [],
    data() {
      return {
        itemGroups: [],
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 48,
          orderBy: this.$route.query.order || 'recommend_tag'
        },
      }
    },
    computed: {
      tagCode() {
        return '46'
      },
    },
    mounted() {
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroups,
          variables: {
            tagCode: this.tagCode,
            ...this.pageInfo
          }
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroups = data.itemGroups.items;
        this.loadingStop();
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .lps-covid-free-shipping {
    > .main {
      text-align: center;

      > .img-responsive {
        @include margin-bottom(lg);
      }

      > h2 {
        @include margin-bottom(xxs);
      }

      > p {
        color: $black-dark;
        line-height: 141%;
        @include margin-bottom(md);
        @include font-size(xs);
      }

      > small {
        line-height: 150%;
        color: $black-light;
        @include font-size(xxs);

        > a {
          color: $black-light;
        }
      }
    }

    > .list {
      @include margin-top(xlg);

      > p {
        color: $black-dark;
        line-height: 141%;
        text-align: center;
        @include margin-top(xxs);
        @include margin-bottom(md);
        @include font-size(xs);
      }
    }
  }
</style>
