import gql from 'graphql-tag'

export const GetHasItemRootItemCategories = gql`
query($perPage: Int) {
  hasItemRootItemCategories(perPage: $perPage) {
    items {
      id
      name
      subTitle
      code
      ogImageSet {
        mdImage
      }
    }
  }
}
`

export const GetItemCategory = gql`
query ($itemCategoryId: ID, $itemCategoryCode: String) {
  itemCategory (itemCategoryId: $itemCategoryId, itemCategoryCode: $itemCategoryCode) {
    id
    code
    name
    subTitle
    comment
    isRoot
    root {
      id
      name
      code
    }
    ogImageUrl
    ogImageSet {
      ogpImage
    }
    rootChildCategories {
      id
      code
      name
      openItemNum
    }
  }
}`


// export const GetShopItemCategories = gql`
// query ($adminShopId: String) {
//   shopItemCategories (adminShopId: $adminShopId) {
//     items {
//       id
//       code
//       name
//       treeName
//     }
//   }
// }
// `
//
// export const GetItemCategories = gql`
// query ($adminShopId: String, $search: String) {
//   itemCategories (adminShopId: $adminShopId, search: $search) {
//     items {
//       id
//       code
//       name
//       childrenCount
//     }
//   }
// }
// `
//
// export const GetItemCategory = gql`
// query ($itemCategoryId: ID!) {
//   itemCategory (itemCategoryId: $itemCategoryId) {
//     id
//     parentId
//     code
//     name
//     subTitle
//     comment
//     metaTitle
//     metaDescription
//     ogImage
//     noteOverview
//     noteMetaTitle
//     noteMetaDescription
//     noteOgImage
//     googleProductCategory
//     adminShopId
//     adminShop {
//       name
//       code
//     }
//     childrenCount
//     childCategories {
//       id
//       code
//       name
//       childrenCount
//     }
//     siblingsCategories {
//       id
//       code
//       name
//       childrenCount
//     }
//     itemCategoryNotes {
//       id
//       publishedStatus
//       title
//       ogImage
//       frontUrl
//     }
//   }
// }`
//

