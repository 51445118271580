<template lang="pug">
  .form-body
    slot
</template>

<script>
  export default {
    name: "form-body",
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .form-body {
    flex: 1 100 auto;
    max-width: 100%;

    .form-group {
      + .form-divider {
        @include margin-top(md);
      }
    }
    .form-divider {
      + .form-group {
        @include margin-top(md);
      }
    }
  }
</style>
