<template lang="pug">
  .form-block
    slot
</template>

<script>
  export default {
    name: "form-block",
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";
  .form-block {
    display: flex;
    @include mq-down(md) {
      flex-wrap: wrap;
    }
  }
</style>
