<template lang="pug">
  .contacts-index
    h1.heading-1.text-center.my-md メッセージ
    template(v-if="userNoticeItems.length > 0" )
      NoticeItem(v-for="userNotice in userNoticeItems" :key="userNotice.id" :notice="userNotice")
      Pager(@change="changePage" :custom-page-info="pageInfo")
    AlertBase(v-else type="info")
      p お知らせはありません
    .text-center.my-lg
      ButtonBase(variant="normal" href="/mypage/") マイページへ戻る
</template>

<script>
import {GetUserNotices} from "../../graphqls/UserNotices";
import GlobalMixin from '../../mixin/GlobalMixin'
import ButtonBase from '../../components/v1/button/ButtonBase'
import NoticeItem from "../../components/v1/notice/NoticeItem";
import AlertBase from "../../components/v1/alert/AlertBase";
import Pager from "../../components/v1/pager/Pager";

export default {
    name: 'ContactsIndex',
    mixins: [GlobalMixin],
    components: {
      Pager,
      AlertBase,
      NoticeItem,
      ButtonBase,
    },
    data() {
      return {
        userNotices: null,
        errorMessages: [],
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 30,
        },
      }
    },
    computed: {
      userNoticeItems() {
        return this.userNotices ? this.userNotices.items : []
      }
    },
    mounted() {
      this.getUserNotices()
      this.setBreadcrumbs({ breadcrumbs: [
          {
            path: '/',
            name: 'TOP',
          },
          {
            path: '/mypage/',
            name: 'マイページ',
          },
          {
            path: '/mypage/contacts/',
            name: 'メッセージ',
          },
        ]})
    },
    methods: {
      async getUserNotices() {
        this.loadingStart()
        const { data } = await this.$apollo
        .query({
          query: GetUserNotices,
          variables: this.pageInfo
        })
        this.userNotices = data.userNotices
        this.pageInfo =  this.userNotices.pageInfo
        this.loadingStop()
      },
      changePage(newPage) {
        this.pageInfo.currentPage = newPage
        this.getUserNotices()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";

  .contacts-index {
    > .pager-wrap {
      @include margin-top(md)
    }
  }
</style>
