<template lang="pug">
  ModalBase(ref="modalBase" size="large")
    template(slot="header") {{ page.title }}
    template(slot="body")
      template(v-for="pageBody in page.pageBodies")
        .title(v-if="pageBody.contentType === 'title_content'")
          HeadingArticle {{ pageBody.title }}
        .image(v-else-if="pageBody.contentType === 'image_content'")
          ImgResponsive(:src="pageBody.image")
        .desc(v-else-if="pageBody.contentType === 'text_content'")
          ItemMarkdown(:description="pageBody.description")
        .item(v-else-if="pageBody.contentType === 'item_group_content'")
          ItemLink(:item-group="pageBody.itemGroup" :adminShop="page.adminShop" )
    template(slot="footer")
      ButtonBase(@click="close()" variant="normal" size="inline") 閉じる
</template>

<script>
  import ModalBase from "../modal/ModalBase"
  import HeadingArticle from '../typography/HeadingArticle'
  import ImgResponsive from '../../image/ImgResponsive'
  import ItemLink from '../item/ItemLinkForApi'
  import ButtonBase from '../button/ButtonBase'
  import ItemMarkdown from "../item/ItemMarkdown";

  export default {
    name: 'ModalPage',
    components: {
      ItemMarkdown,
      ModalBase,
      HeadingArticle,
      ImgResponsive,
      ItemLink,
      ButtonBase
    },
    props: {
      page: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    },
    methods: {
      open: function(e) {
        this.$refs.modalBase.open()
        this.$emit('open', e)
      },
      close: function(e) {
        this.$refs.modalBase.close()
        this.$emit('close', e)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .modal-base {
    .title, .image, .desc, .item {
      @include margin-bottom(md);
    }
    .title:not(:first-child) {
      @include margin-top(xlg);
    }
    .item {
      a {
        text-decoration: none;
      }
    }
  }
</style>

<style lang="scss">
  @import "../../../../../assets/stylesheets/v1/variables";
  @import "../../../../../assets/stylesheets/v1/mixins";
  .modal-base {
    .desc {
      h3 {
        font-family: $font-family-serif;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-snug;
        color: $black-400;
        border-left: 4px solid $gray-200;
        padding: 7px 10px;
      }
      p {
        @include margin-bottom(xs);
      }
    }
  }
</style>
