<template lang="pug">
  IconBase
    NoshiSvg
</template>
<script>
  import IconBase from './IconBase'
  import NoshiSvg from "../svg/NoshiSvg";
  export default {
    name: "noshi-icon",
    components: {
      IconBase,
      NoshiSvg
    }
  }
</script>
