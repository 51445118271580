<template lang="pug">
  .container
    .row
      .col-xs-12
        h1 マイページ
        a(v-if="hasReviewPageUrls" href="/mypage/purchasehistory/")
          img.review-banner(src="https://cdn.taberutokurasuto.com/image/common/banner/reviewbanner.png")
        .form_wrapper
          .form_line
            .ttl 注文履歴
            .ipt
              p
                a(href="/mypage/purchasehistory/") 注文履歴
              p
                a(href="/mypage/subscriptionorders/") 定期購入管理
              p
                a(href="/mypage/invoice/") 請求書一括ダウンロード
          .form_line
            .ttl ポイント履歴
            .ipt
              p
                a(href="/mypage/pointhistory/") ポイント履歴
          .form_line
            .ttl アカウント設定
            .ipt
              p
                a(href="/mypage/contactaddress/") 会員情報の確認・変更（氏名・電話番号・住所）
              p
                a(href="/mypage/changeemail/") メールアドレスの変更
              p
                a(href="/mypage/addressbooks/") 配送先
              p
                a(href="/mypage/contacts/") メッセージ
              p
                router-link(:to="{name: 'mypage-credit'}") クレジットカード
              p
                a(href="/mypage/coupon/") クーポン
              p
                a(href="/mypage/mailmagazine/") 情報配信サービスについて
              p
                a(href="/mypage/changepassword/") パスワードの変更
              p
                a(@click="logoutUser") ログアウト
          .form_line
            .ttl 退会
            .ipt
              p
                a(href="/mypage/resign/") 退会のお申し込み

</template>

<script>
  import GlobalMixin from "../../mixin/GlobalMixin";
  import {LogoutUser} from "../../graphqls/Users";
  import {GetReviewPageUrls} from "../../graphqls/Reviews";

  export default {
    name: "mypage-index",
    mixins: [GlobalMixin],
    components: {
    },
    props: [],
    data() {
      return {
        reviewPageUrls: []
      }
    },
    computed: {
      hasReviewPageUrls() {
        return this.reviewPageUrls.length > 0
      },
      currentUser() {
        return this.$store.getters.currentUser.user
      },
      isInvoiceBankTransfer() {
        return this.currentUser.bankTransferMethod === "invoice_bank_transfer"
      }
    },
    mounted () {
      this.fetchReviewPageUrls();
    },
    methods: {
      async logoutUser() {
        this.loadingStart();
        const { data } = await this.$apollo
        .mutate({
          mutation: LogoutUser,
          // Parameters
          variables: {
            input: {}
          }
        })
        .catch(error => {
          location.href="/"
          return;
        });
        localStorage.removeItem('user_access_token')
        location.href="/"
      },
      async fetchReviewPageUrls () {
        const { data } = await this.$apollo
            .query({
              query: GetReviewPageUrls,
              client: 'apiClient'
            })
        this.reviewPageUrls = data.reviewPageUrls.items
      },
    },
  }
</script>

<style lang="scss" scoped>
.review-banner {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
