<template lang="pug">
  transition(name="fade")
    .alert-toast(v-if="toast")
      .inner(:class="classes")
        p(v-for="message in toast.messages") {{ message }}
        .icon(@click="close()")
          include ../../../../../assets/images/icon/close.svg
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'

  export default {
    name: 'AlertToast',
    mixins: [GlobalMixin],
    computed: {
      classes: function () {
        return [
          this.toast ? `-${this.toast.type}` : ''
        ]
      }
    },
    methods: {
      close: function () {
        this.clearToast()
      }
    },
    updated() {
      setTimeout(() => {
        this.close()
      }, 3000)
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .fade-enter-active, .fade-leave-active {
    transition: all .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .alert-toast {
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    bottom: 7%;

    .inner {
      width: 100%;
      max-width: 600px;
      @include padding(xxs, xs);
      @include margin(zero, xs);
      border-radius: 3px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

      display: flex;
      align-items: center;
      justify-content: space-between;

      &.-success {
        background: $green-100;
        > p {
          color: $black-400;
        }
      }
      &.-error {
        background: $red-300;
        > p {
          color: $white;
        }
      }

      > p {
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        @include font-size(sm);
        line-height: $line-height-normal;
      }

      > .icon > svg {
        width: 14px;
        height: 14px;
      }
    }
  }
</style>
