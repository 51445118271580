<template lang="pug">
  div
    .visual-title.-xs.-white(:style="`background-image: url(https://cdn.taberutokurasuto.com/image/shop/item/list/random.jpg);`")
      .title
        i.h1rem
          LogoYaneSvg
        h1.heading2 すべての商品
        .heading3.curve-bottom All Item
    .archives-description
      p たべるとくらすとの全商品をご紹介。まだ食べたことのない無添加食品や無農薬野菜などチェック！素材や製法にこだわったの生産者から産地直送。

    .row.row-eq-height.itemlist.new_margin_0_7.new_margin_0_5_sp.-sm-vertical#item_list
      ItemList(v-for="itemGroup in itemGroups" :key="itemGroup.id" :itemGroup="itemGroup" class="col-xs-12 col-sm-4 col-md-4 item" eventCategory="全商品一覧")
      .col-xs-12(v-if="itemGroups.length === 0")
        .success-notice
          .text-center 商品がありません。
    .row
      .col-xs-12
        Pager(@change="changePage" :custom-page-info="customPageInfo")

    SectionItemArchive
</template>

<script>
  import {GetItemGroups} from "../../graphqls/ItemGroups";

  import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
  import ItemList from "../../components/list/ItemList";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Pager from "../../components/paginate/Pager";
  import SectionItemArchive from "../../components/v1/section/SectionItemArchive";

  export default {
    name: "items-index",
    mixins: [GlobalMixin],
    components: {
      SectionItemArchive,
      Pager,
      ItemList,
      LogoYaneSvg,
    },
    props: [],
    data() {
      return {
        itemGroups: [],
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 48,
          orderBy: 'item_groups.id desc'
        },
        prevPageUrl: undefined,
        nextPageUrl: undefined,
      }
    },
    computed: {
      customPageInfo() {
        return this.pageInfo
      }
    },
    customPageInfo() {
      return this.pageInfo
    },
    mounted() {
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroups,
          variables: {
            nowOnSale: true,
            stockFilter: 'instock',
            ...this.pageInfo
          }
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroups = data.itemGroups.items;
        this.pageInfo = data.itemGroups.pageInfo
        // this.setPrevNexPageUrl()
        this.loadingStop();
        this.$_gaSendViewItemList(this.itemGroups, 'shops/items', this.pageInfo)
      },
      changePage(newPage) {
        this.pageInfo.currentPage = newPage
        newPage === 1 ? this.$router.push({name: 'items-index'}) : this.$router.push({name: 'items-page-index', params: {page: newPage}})
        this.fetchItemGroups();
      },
      setPrevNexPageUrl() {
        // this.prevPageUrl = this.pageInfo.currentPage === 1 ? null : this.$router.resolve({ name: "items-page-index", params: {page: this.pageInfo.currentPage - 1} }).href;
        this.prevPageUrl = "aaaa"
      }
    }
  }
</script>

<style scoped>
</style>
