import gql from "graphql-tag";

export const GetTopMessages = gql`
query {
  topMessages {
    items {
      id
      message
      messageType
      url
    }
  }
}`;
