<template lang="pug">
  div
    .visual-title.-xs.-white(:style="`background-image: url(https://cdn.taberutokurasuto.com/image/shop/item/list/freeshipping2.jpg);`")
      .title
        i.h1rem
          LogoYaneSvg
        h1.heading2 {{title}}
        .heading3.curve-bottom Production prefecture
    .archives-description
      p {{selectedPrefecture.text}}からお届けする食材・グルメ一覧です。全国の生産地域別でお選びいただけます。

    ItemFilterBlock
      DropDown(@change="$_changeOrder" v-model="pageInfo.orderBy" :options="$_orderDropDownOptions")
      //DropDown(@change="$_changeStockFilter" v-model="stockFilter" :options="$_stockDropDownOptions")
      DropDown(@change="changePrefecture" v-model="prefectureCode" :options="regionOptions")
      .stock-checkbok
        FormCheckbox(@change="$_changeStockFilter" v-model="stockOnly") 販売中のみ表示

    ItemCardList
      .item.lg(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" eventCategory="商品一覧")
    .row
      .col-xs-12(v-if="itemGroups.length === 0")
        .success-notice
          .text-center 商品がありません。
      .col-xs-12
        Pager(@change="$_changePage" :custom-page-info="$_customPageInfo")


    SectionItemArchive
</template>

<script>
import {GetItemGroupsForApi} from "../../graphqls/ItemGroups";

  import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
  import ItemList from "../../components/list/ItemList";
  import GlobalMixin from "../../mixin/GlobalMixin";
  import Pager from "../../components/paginate/Pager";
  import DropDown from "../../components/dropdown/DropDown";
  import ItemListMixin from "../../mixin/ItemListMixin";
  import Chip from "../../components/badge/Chip";
  import Row from "../../components/layout/Row";
  import Col from "../../components/layout/Col";
  import SectionItemArchive from "../../components/v1/section/SectionItemArchive";
  import ItemCardList from "../../components/v1/item/ItemCardList";
  import ItemFilterBlock from "../../components/v1/item/ItemFilterBlock";
  import FormCheckbox from "../../components/v1/form/FormCheckbox";
  import ItemCard from "../../components/v1/item/ItemCardForApi";

  export default {
    name: "production-prefecture-items-index",
    mixins: [GlobalMixin, ItemListMixin],
    components: {
      ItemCard,
      FormCheckbox,
      ItemFilterBlock,
      ItemCardList,
      SectionItemArchive,
      Col,
      Row,
      Chip,
      DropDown,
      Pager,
      ItemList,
      LogoYaneSvg
    },
    props: [],
    data() {
      return {
        itemGroups: [],
        pageInfo: {
          currentPage:  Number(this.$route.params.page || 1),
          totalCount: 0,
          perPage: 48,
          orderBy: this.$route.query.order || 'popular'
        },
        stockOnly: this.$route.query?.stock === 'instock',
        withinYearItem: this.$route.query.wiy === 'true',
        prefectureCode: this.$route.params.prefecture || ''
      }
    },
    computed: {
      regionOptions() {
        let result = []
        result.push({id: '01', text: '北海道'})
        result.push({id: '02', text: '青森県'})
        result.push({id: '03', text: '岩手県'})
        result.push({id: '04', text: '宮城県'})
        result.push({id: '05', text: '秋田県'})
        result.push({id: '06', text: '山形県'})
        result.push({id: '07', text: '福島県'})
        result.push({id: '08', text: '茨城県'})
        result.push({id: '09', text: '栃木県'})
        result.push({id: '10', text: '群馬県'})
        result.push({id: '11', text: '埼玉県'})
        result.push({id: '12', text: '千葉県'})
        result.push({id: '13', text: '東京都'})
        result.push({id: '14', text: '神奈川県'})
        result.push({id: '15', text: '新潟県'})
        result.push({id: '16', text: '富山県'})
        result.push({id: '17', text: '石川県'})
        result.push({id: '18', text: '福井県'})
        result.push({id: '19', text: '山梨県'})
        result.push({id: '20', text: '長野県'})
        result.push({id: '21', text: '岐阜県'})
        result.push({id: '22', text: '静岡県'})
        result.push({id: '23', text: '愛知県'})
        result.push({id: '24', text: '三重県'})
        result.push({id: '25', text: '滋賀県'})
        result.push({id: '26', text: '京都府'})
        result.push({id: '27', text: '大阪府'})
        result.push({id: '28', text: '兵庫県'})
        result.push({id: '29', text: '奈良県'})
        result.push({id: '30', text: '和歌山県'})
        result.push({id: '31', text: '鳥取県'})
        result.push({id: '32', text: '島根県'})
        result.push({id: '33', text: '岡山県'})
        result.push({id: '34', text: '広島県'})
        result.push({id: '35', text: '山口県'})
        result.push({id: '36', text: '徳島県'})
        result.push({id: '37', text: '香川県'})
        result.push({id: '38', text: '愛媛県'})
        result.push({id: '39', text: '高知県'})
        result.push({id: '40', text: '福岡県'})
        result.push({id: '41', text: '佐賀県'})
        result.push({id: '42', text: '長崎県'})
        result.push({id: '43', text: '熊本県'})
        result.push({id: '44', text: '大分県'})
        result.push({id: '45', text: '宮崎県'})
        result.push({id: '46', text: '鹿児島県'})
        result.push({id: '47', text: '沖縄県'})

        return result
      },
      selectedPrefecture() {
        return this.regionOptions.find(r => r.id === this.prefectureCode)
      },
      title() {
        if (this.selectedPrefecture && this.prefectureCode) {
          return `${this.selectedPrefecture.text}産の商品`
        }
        return '産地別商品'
      },
      topRouterName() {
        return 'production-prefecture-items-index'
      },
      pageRouterName() {
        return 'production-prefecture-items-page-index'
      },
      stockFilter() {
        return this.stockOnly ? 'instock' : 'outstock'
      }
    },
    mounted() {
      this.fetchItemGroups()
    },
    methods: {
      async fetchItemGroups() {
        this.loadingStart();
        const { data } = await this.$apollo
        .query({
          query: GetItemGroupsForApi,
          variables: {
            productionPrefecture: this.prefectureCode,
            stockFilter: this.stockFilter,
            withinYearItem: this.withinYearItem,
            ...this.pageInfo
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.loadingStop()
          return;
        });
        this.itemGroups = data.itemGroups.items;
        this.pageInfo = data.itemGroups.pageInfo
        this.loadingStop();
        this.$_gaSendViewItemList(this.itemGroups, 'shops/items/productionarea', this.pageInfo)
      },
      updateItemGroup(newItemGroup) {
        if (this.itemGroups[0].id === newItemGroup.id) {
          this.firstItemGroup = newItemGroup
        }
      },
      changePrefecture(region) {
        this.prefectureCode = region
        this.$router.push({name: 'production-prefecture-items-index', params: {prefecture: this.prefectureCode}})
        this.fetchItemGroups()
      }
    },
    watch: {
      prefectureCode: function (newQuestion, oldQuestion) {
        this.stockOnly = false
        this.pageInfo.orderBy = 'popular'
        this.pageInfo.currentPage = 1
        this.fetchItemGroups()
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
