<template lang="pug">
  section.section-free
    HeadingGift.mb-sm
      h2 
        span.sub {{ giftPageBody.subTitle }}
        br 
        | {{ giftPageBody.title }}
    VueMarkdown.paragraph.text-center {{ giftPageBody.description }}
</template>

<script>
  import HeadingGift from '../heading/HeadingGift'
  import VueMarkdown from 'vue-markdown'

  export default {
    name: 'SectionFree',
    components: {
      HeadingGift,
      VueMarkdown
    },
    props: {
      giftPageBody: {
        type: Object,
        required: true,
        default() {
          return {
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-free {

  }
</style>
