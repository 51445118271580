<template lang="pug">
  section.section-shop-post(v-if="posts.length > 0")
    //- ショップ名を表示
    HeadingArticle.mb-xs {{adminShop.name}}のお便りとお知らせ
    .post-list
      .item(v-for="post in posts")
        a.post-card(
          :href="`/shop/${post.adminShop.code}/blog/entry/${post.customUrl}/`"
          @click="$_gaSendEvent('ショップお知らせ詳細へ遷移', 'トップ生産者のお便りとお知らせ', `${post.adminShop.name} ${post.title}`)"
        )
          .thumbarea
            //- ブログ（お知らせ）アイキャッチ画像を表示
            img(:src="post.displayListImage")
          .textarea
            .namedate
              //- 投稿日を表示
              .postdate {{ post.publishedStartAt| moment('YYYY/MM/DD') }}
            //- 本文冒頭を表示
            .posttitle.my-xxxs {{ post.title }}
    .row.mb4
      .col-xs-10.col-xs-push-1.col-sm-4.col-sm-push-4
        ButtonBase(variant="primary" :href="`/shop/${adminShop.code}/blog/`") お便りとお知らせの一覧
</template>

<script>
import { GetPosts } from '../../../graphqls/Posts'
import HeadingRoof from '../heading/HeadingRoof'
import ButtonBase from '../button/ButtonBase'
import HeadingArticle from '../typography/HeadingArticle'

export default {
  name: 'SectionEachShopPost',
  components: {
    HeadingRoof,
    ButtonBase,
    HeadingArticle,
  },
  props: {
    adminShop: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: '',
          code: '',
        }
      }
    },
  },
  data() {
    return {
      posts: []
    }
  },
  mounted () {
    this.getPosts()
  },
  methods: {
    async getPosts() {
      const { data } = await this.$apollo
      .query({
        query: GetPosts,
        variables: {
          perPage: 10 , orderBy: 'published_start_at desc',
          search: JSON.stringify({
            admin_shop_id_eq: this.adminShop.id,
          }),
        },
        client: 'apiClient'
      })
      this.posts = data.posts.items
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .section-shop-post {
    > .post-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 48px;
      > .item {
        padding: 12px 16px;
        border-bottom: 1px solid #F4F4F4;
        width: calc(100% - 20px);
        @include mq-up() {
          width: calc(50% - 20px);
        }
        > .post-card {
          display: flex;
          overflow: hidden;

          cursor: pointer;
          text-decoration: none;
          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-snug;
          color: $black-400;
          &:hover {
            color: $olive-400;
          }

          > .thumbarea {
              width: 80px;
              height: 60px;
              > img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
          > .textarea {
            width: calc(100% - 96px);
            padding-left: 16px;
            > .namedate {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              line-height: 1;
            }
            > .posttitle {
              font-family: $font-family-serif;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              font-size: 18px;
            }
            // > .postdescription {
            //   display: -webkit-box;
            //   -webkit-box-orient: vertical;
            //   -webkit-line-clamp: 3;
            //   @include font-size(xs);
            // }
          }
        }
      }

      @include mq-up() {
        > .item:nth-child(odd) {
          margin-right: 40px;
        }
      }
    }
    @media (max-width: 768px) {
      > .post-list {
        > .item {
          padding: 12px;
          width: 100%;
          > .post-card {
            > .thumbarea {
              width: 96px;
              height: 72px;
            }
            > .textarea {
              width: calc(100% - 110px);
              padding-left: 12px;
              > .namedate {
                font-size: 12px;
              }
              > .posttitle {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
</style>
