<template lang="pug">
  .form-wrapper
    slot
</template>

<script>
  export default {
    name: "form-wrapper"
  }
</script>

<style scoped lang="scss">
  @import "../../../../assets/stylesheets/--new/styles";

  .form-wrapper {
    background: $yellow-lighter;
    @include padding(sm);
  }
</style>
