<template lang="pug">
  .blogList
    slot
</template>

<script>
  export default {
    name: 'BlogList',
    components: {
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
    .blogList{
      width: 100%;
      max-width: 720px;
      margin: auto;
    }
</style>
