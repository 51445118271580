<template lang="pug">
  .full-width-banner(v-if="isShowBanner")
    a.banner(:href="infoBanner.url" @click="$_gaSendEvent($route.path, 'ヘッダー共通バナーSP', infoBanner.name)")
      img.banner(:src="infoBanner.imageSet.orgImage" :alt="infoBanner.name")
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import { GetInfoBanner } from '../../../graphqls/InfoBanners'

  export default {
    name: 'FullWidthBanner',
    mixins: [GlobalMixin],
    data() {
      return {
        infoBanner: {
          id: null,
          url: '',
          alt: '',
          imageSet: {
            mdImage: ''
          }
        }
      }
    },
    mounted () {
      this.fetchInfoBanners()
    },
    methods: {
      async fetchInfoBanners() {
        const { data } = await this.$apollo
            .query({
              query: GetInfoBanner,
              variables: {
                path: this.$route.path
              },
              client: 'apiClient'
            })
            .catch(error => {
              return
            })
        this.infoBanner = data.infoBanner
      }
    },
    computed: {
      isShowBanner() {
        return !!this.infoBanner.id
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .full-width-banner {
    overflow: hidden;
    .banner {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 480px;
      @include mq-down() {
        width: 100%;
      }
    }
  }
</style>
