<template lang="pug">
  section.section-item-archive(v-if="itemGroups.length > 0")
    HeadingArticle.mb-xs {{ itemCategoryName }}カテゴリの人気商品
    ItemArchive
      .item(v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id")
        ItemCard(:itemGroup="itemGroup" :archive="true" eventCategory="同じカテゴリの人気商品" :pageContents="`${itemCategoryName}カテゴリの人気商品`")
</template>

<script>
import {GetItemGroupsForApi} from '../../../graphqls/ItemGroups'
  import HeadingArticle from '../typography/HeadingArticle'
  import ItemCard from '../item/ItemCardForApi'
  import ItemArchive from '../item/ItemArchive'

  export default {
    name: 'SectionItemSameCategory',
    components: {
      HeadingArticle,
      ItemCard,
      ItemArchive
    },
    props: {
      itemCategory: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    },
    data() {
      return {
        itemGroups: []
      }
    },
    computed: {
      itemCategoryName() {
        return this.itemCategory.name
      }
    },
    mounted: function () {
      this.fetchSameCategoryItemGroups()
    },
    methods: {
      async fetchSameCategoryItemGroups() {
        const { data } = await this.$apollo
            .query({
              query: GetItemGroupsForApi,
              variables: {
                categoryCode: this.itemCategory.code,
                nowOnSale: true,
                stockFilter: 'instock',
                perPage: 6,
                orderBy: 'monthly_ranking desc'
              },
              client: 'apiClient'
            })
        this.itemGroups = data.itemGroups.items
      }
    }
  }
</script>
