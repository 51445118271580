<template lang="pug">
  .image-popup
    a
      img(:src="src" :alt="alt" :style="{ width: width }" @click="toggleImage")
    .overlay(v-if="isShowPopup" @click="toggleImage")
      img(:src="src")
</template>

<script>
  export default {
    name: 'ImagePopup',
    props: {
      src: {
        type: String,
        required: true,
        default: ''
      },
      alt: {
        type: String,
        required: false,
        default: ''
      },
      width: {
        type: String,
        required: false,
        default: '200px'
      },
    },
    data() {
      return {
        isShowPopup: false
      }
    },
    methods: {
      toggleImage() {
        this.isShowPopup = !this.isShowPopup
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .image-popup {
    > a > img {
      height: auto;
      border-radius: 3px;
      cursor: pointer;
    }
    .overlay {
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgba($white, 0.85);
      cursor: pointer;
      > img {
        max-width: 100%;
        max-height: 80%;
      }
    }
  }
</style>
