<template lang="pug">
  section.section-contents#contents
    nav.table-of-contents
      h2 目次
      ul
        li(v-for="outline in giftPageBodies" :key="outline.id" v-if="showOutlineItem(outline)" @click="$scrollTo(`#outline${outline.id}`, {offset: -110})")
          p {{outline.title}}
          ArrowSvg.icon
</template>

<script>
import ArrowSvg from '../../svg/ArrowSvg'

export default {
  name: 'SectionOutline',
  components: {
    ArrowSvg,
  },
  props: {
    giftPageBodies: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    }
  },
  computed: {
    oulLineList() {
      return this.giftPageBodies.filter((giftPageBody) => giftPageBody.bodyType !== 'outline')
    }
  },
  methods: {
    showOutlineItem(outline) {
      if (outline.bodyType === 'menu' || outline.bodyType === 'outline') return false
      if (outline.bodyType !== 'tag') return true
      if (outline.itemTag && outline.itemTag.publishedStatus === 'published') {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .section-contents {
    > .table-of-contents {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      max-width: 640px;
      color: $black-400;
      > h2{
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        font-family: $font-family-serif;
      }
      > ul {
        width: 100%;
        > li {
          display: flex;
          padding: 12px 0px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-bottom: 1px solid $olive-300;
          cursor: pointer;
          > p {
            color: $black-400;
            text-decoration: none;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>
