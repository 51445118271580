<template lang="pug">
  button.button-yahoo(@click="onClick")
    img.icon(src="../../../../../assets/images/sns/yahoo_japan_icon_w_64.png")
    span.text {{ text }}
</template>

<script>
  export default {
    name: 'ButtonYahoo',
    props: {
      text: {
        type: String,
        required: true,
        default: '',
      },
      href: {
        type: String,
        required: true,
        default: '',
      },
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
      onClick() {
        location.href = this.href
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .button-yahoo {
    height: 40px;
    padding: 12px;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: none;
    background: #FF0033;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 20px;
      height: auto;
      margin-right: 12px;
    }
    .text {
      // font-family: $font-family-sans;
      font-family: 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', MS PGothic, sans-serif;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      line-height: $line-height-none;
      // @include font-size(sm);
      font-size: 16px;
    }
  }
</style>
