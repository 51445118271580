<template lang="pug">
  .gift-accordion(v-if="orderItem.noshiId || orderItem.messageCardId || orderItem.wrappingId")
    .title(@click="showGift = !showGift")
      .svg(v-if="showGift")
        include ../../../../../assets/images/icon/arrow-drop-up.svg
      .svg(v-else)
        include ../../../../../assets/images/icon/arrow-drop-down.svg
      span.label ギフト設定を見る
    .details(v-if="showGift" )
      .item(v-if="orderItem.noshiId")
        h4 のし
        .noshi_detail
          p {{orderItem.noshiName}}
          p 名入れ : {{orderItem.noshiNaire}}
          p(v-if="orderItem.noshiMemo" ) ご要望など : {{orderItem.noshiMemo}}
      .item(v-if="orderItem.messageCardId")
        h4 メッセージカード
        .message_detail
          p {{orderItem.displayMessageCard}}
      .item(v-if="orderItem.wrappingId")
        h4 ラッピング
        .wrapping_detail
          p {{orderItem.displayWrapping}}

</template>

<script>
export default {
  name: "GiftDetail",
  props: {
    orderItem: {
      type: Object,
      required: true,
      default: () =>({
        noshiId: '',
        noshiName: '',
        noshiNaire: '',
        noshiMemo: '',
        messageCardId: '',
        displayMessageCard: '',
        wrappingId: '',
        displayWrapping: '',
      })
    }
  },
  data() {
    return {
      showGift: false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
.gift-accordion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: $gray-100;
  @include padding(xxs);
  justify-content: center;
  align-self: stretch;
  > .title {
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    > .svg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      > svg {
        height: auto;
        > path {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  > .details {
    @include margin-top(xxs);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    > .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
}

</style>