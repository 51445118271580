import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { config } from './config.js'


const httpLink = createUploadLink({
  uri: config.graphql_url,
  credentials: 'same-origin',
  headers: {
    "X-FRONT-CSRF-TOKEN": csrfToken(),
    "X-ADMIN-CSRF-TOKEN": adminCsrfToken()
  }
})
const httpApiLink = createUploadLink({
  uri: config.graphql_api_url,
  credentials: 'include',
  headers: {
    "X-FRONT-CSRF-TOKEN": csrfToken(),
    "X-ADMIN-CSRF-TOKEN": adminCsrfToken()
  }
})
const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  }
})

export const apolloApiClient = new ApolloClient({
  link: httpApiLink,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  }
})

function csrfToken() {
  const cookies = document.cookie;
  const array = cookies.split(';');
  let csrf_token = null
  for(let cookie of array) {
    const content = cookie.split('=');
    if (content[0].trim() === config.cookie_user_csrf_token) {
      csrf_token = content[1].trim();
    }
  }
  return csrf_token
}

function adminCsrfToken() {
  const cookies = document.cookie;
  const array = cookies.split(';');
  let csrf_token = null
  for(let cookie of array) {
    const content = cookie.split('=');
    if (content[0].trim() === config.cookie_admin_csrf_token) {
      csrf_token = content[1].trim();
    }
  }
  return csrf_token
}
