<template lang="pug">
  .coupon-item(:class="{ unobtained: !coupon.hasCoupon }")
    .thumbnail
      img(:src="coupon.thumbnail" :alt="coupon.couponName")
    .desc
      .remaining(v-if="coupon.hasCoupon && coupon.totalCount >= 2") {{ `残り${coupon.totalCount}回分` }}
      .title
        p.benefits {{ coupon.benefits }}
        p {{ coupon.couponName }}
      .info
        .info-item
          include ../../../../../assets/images/icon/shop.svg
          span {{ coupon.availableShopName }}
        .info-item
          include ../../../../../assets/images/icon/clock.svg
          span.bold(v-if="coupon.expirationDays") {{ coupon.expirationDays }}
          span {{ coupon.expirationDate }}
        p {{ `※${coupon.minUseAmount}以上ご購入で利用可` }}
      template(v-if="coupon.hasCoupon")
        a(v-if="coupon.availableShopCode" :href="`/shop/${coupon.availableShopCode}/items`") 対象商品を見る
        a(v-else href="/") トップで対象商品を見る
    .button(v-if="!coupon.hasCoupon")
      ButtonBase(variant="primary" :href="`/coupon/${coupon.couponCode}`") クーポンを取得する
</template>

<script>
import ButtonBase from '../button/ButtonBase'

  export default {
    name: 'CouponItem',
    components: {
      ButtonBase,
    },
    props: {
      coupon: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
  .coupon-item {
    position: relative;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background-color: $white;
    color: $black-400;
    > .thumbnail {
      width: 96px;
      height: auto;
      > img {
        width: 100%;
      }
    }
    > .desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      flex: 1 0 0;
      font-size: 14px;
      position: relative;
      p {
        line-height: $line-height-snug;
      }

      > .remaining {
        position: absolute;
        top: 0;
        right: 0;
        height: 20px;
        padding: 4px 6px;
        background: $black-300;
        font-size: 12px;
        font-weight: $font-weight-bold;
        line-height: $line-height-none;
        text-align: left;
        color: $white;
      }

      > .title {
        > .benefits {
          color: #A46761;
          font-size: 18px;
          font-weight: bold;
          line-height: $line-height-tight;
        }
      }
      > .info {
        > .info-item {
          display: flex;
          align-items: center;
          line-height: $line-height-tight;
          > svg {
            width: 16px;
            height: auto;
            margin-right: 4px;
            > path {
              fill: $black-400;
            }
          }
          > .bold {
            font-weight: bold;
            margin-right: 2px;
          }
        }
      }
      > a {
        color: $olive-400;
        text-decoration: none;
      }
    }
    &.unobtained {
      border-radius: 4px;
      border: 1px solid $olive-400;
      > .button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
      }
    }
    &.unobtained::before {
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      opacity: 0.8;
      content: '';
      z-index: 1;
    }
  }
</style>
