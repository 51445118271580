<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="#4B4B4B" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.7977 9.48128C22.488 9.10013 21.9282 9.04058 21.547 9.35026L17.6164 12.5424L17.0804 12.0064L11.0059 5.93182L10.4699 5.39583L13.662 1.46523C13.9717 1.08408 13.9121 0.524263 13.531 0.214579C13.1498 -0.0951051 12.59 -0.0355505 12.2803 0.345599L8.56412 4.88366C8.27825 5.24099 8.30208 5.75316 8.62367 6.07475L8.89762 6.34871L2.63247 9.92198C2.40617 10.053 2.25133 10.2674 2.19177 10.5294L0.0120711 21.9401C-0.0474835 22.226 0.0478039 22.5357 0.262201 22.7381C0.428954 22.9049 0.655261 23.0002 0.89348 23.0002C0.953034 23.0002 1.00068 23.0002 1.06023 22.9883L12.459 20.7967C12.721 20.749 12.9354 20.5942 13.0664 20.356L16.6397 14.0908L16.9137 14.3648C17.0923 14.5434 17.3186 14.6268 17.545 14.6268C17.7474 14.6268 17.938 14.5553 18.1048 14.4243L22.6667 10.7319C23.0478 10.4342 23.1074 9.86243 22.7977 9.48128ZM11.7205 19.1172L3.58535 20.6775L9.02864 15.2581C9.32642 15.3891 9.64801 15.4487 9.98152 15.4487C10.6247 15.4487 11.2322 15.1985 11.6848 14.7459C12.6257 13.8049 12.6257 12.2803 11.6848 11.3394C11.2322 10.8868 10.6247 10.6366 9.98152 10.6366C9.33833 10.6366 8.73087 10.8868 8.27825 11.3394C7.82564 11.792 7.57551 12.3995 7.57551 13.0426C7.57551 13.3762 7.64698 13.6977 7.76608 13.9955L2.31088 19.4269L3.87121 11.2798L10.2078 7.67082L15.3176 12.7806L11.7205 19.1172ZM9.54081 13.4833C9.4217 13.3642 9.36215 13.2094 9.36215 13.0426C9.36215 12.8759 9.4217 12.7211 9.54081 12.6019C9.65992 12.4828 9.81476 12.4233 9.98152 12.4233C10.1483 12.4233 10.3031 12.4828 10.4222 12.6019C10.6604 12.8402 10.6604 13.2332 10.4222 13.4833C10.3031 13.6025 10.1483 13.662 9.98152 13.662C9.81476 13.662 9.65992 13.6025 9.54081 13.4833Z"/>
  </svg>
</template>
<script>
  export default {
    name: "pen-svg"
  }
</script>
