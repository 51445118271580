<template lang="pug">
  a.category-tag(:href="href" @click="click")
    | {{ name }}
    template(v-if="num") ({{ num }})
    //- include ../../../../../assets/images/icon/caret-right-solid.svg
</template>

<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'

  export default {
    name: 'CategoryTag',
    mixins: [GlobalMixin],
    props: {
      href: {
        type: String,
        required: true,
        default: '',
      },
      name: {
        type: String,
        required: true,
        default: '',
      },
      num: {
        type: [String, Number],
        required: false,
        default: 0,
      },
      eventAction: {
        type: String,
        required: false,
        default: ''
      },
      eventCategory: {
        type: String,
        required: false,
        default: ''
      },
    },
    methods: {
      click() {
        this.$_gaSendEvent(this.eventAction, this.eventCategory, this.name)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .category-tag {
    display: inline-flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 8px;
    background: $gray-300;
    border-radius: 16px;

    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    @include font-size(xs);
    line-height: 34px;
    color: $black-400;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $olive-300;
      > svg > path {
        fill: $olive-300;
      }
    }

    > svg {
      width: 14px;
      height: 14px;
    }
  }
</style>
